import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./fileDropZone.css";
import * as XLSX from "xlsx";
import { BsXCircleFill } from "react-icons/bs";
import styled from "styled-components";

const FileUploadedDiv = styled.div`
  font-weight:700;
  display: flex;
  align-items:center;
  justify-content: space-between;
  & svg {
    cursor: ${(props)=>props.$resLoading ? "not-allowed" : "pointer"};
    color: var(--primary-color);
  }
`
export default function (props) {
  const [fileArr, setFileArr] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setIsOverDropzone(false);
    for (let file of acceptedFiles) {
      if (
        file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type == "text/csv"
      ) {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          console.log(data);
          props.file(data);
        };

        reader.readAsArrayBuffer(file);
        setFileArr((prevArr) => {
          return [...prevArr, file];
        });
        return;
      } else {
        alert("Unsupported file.");
      }
    }

    console.log("This are the file(s)", fileArr.current);
  }, []);

  useEffect(() => {
    console.log(fileArr);
  }, [fileArr]);

  const [isOverDropzone, setIsOverDropzone] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragOver: () => setIsOverDropzone(true),
    onDragLeave: () => setIsOverDropzone(false),
    maxFiles: 1,
  });
  return (
    <>
      {!fileArr[0] ? (
        <div
          className="dropZone"
          style={{
            borderWidth: isOverDropzone ? "3px" : "1px",
            transition: "border-width 0.1s ease",
          }}
        >
          <div className="inputFile" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="inputFile">
              <center>
                {
                  "Click or Drag and drop a reference here for checking (.csv/.xlsx)"
                }
              </center>
            </div>
          </div>
        </div>
      ) : (
        <FileUploadedDiv $resLoading={props.resLoading}>
          {fileArr[0].name}
          <BsXCircleFill onClick={() => {
              if(!props.resLoading){
                setFileArr([]);
                props.exportButton(false);
                props.setPerc("--");
                props.docState.current = [];
                props.setLLM([]);
                props.file([]);
                props.tableRes.current = [];
                props.tableResCor.current = [];
              }
            }}/>
          {/* <button
            className="optionButton"
            onClick={() => {
              setFileArr([]);
              props.exportButton(false);
              props.setPerc("--");
              props.docState.current = [];
              props.setLLM([]);
              props.file([]);
              props.tableRes.current = [];
              props.tableResCor.current = [];
            }}
          >
            Remove
          </button> */}
        </FileUploadedDiv>
      )}
    </>
  );
}
