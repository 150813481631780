
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import MilvusConfig from './MilvusKeys'
import RedisKeys from './RedisKys'


export default function OrganizationConfig() {

    return (
        <div className='w-full'>
            <div className='h-auto py-8 space-y-6'>
                <Tabs defaultValue="milvus" className="space-y-4">
                    <TabsList className='w-full'>
                        <TabsTrigger className='w-full' value="milvus">Milvus Keys</TabsTrigger>
                        <TabsTrigger className='w-full' value="redis">Redis Keys</TabsTrigger>
                    </TabsList>

                    <TabsContent value="milvus">
                        <MilvusConfig />
                    </TabsContent>

                    <TabsContent value="redis">
                        <RedisKeys />
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    )
}
