import { projectOHttpClient, createWebSocketClient } from "api/clients/project-o.httpClient";


class UnifiedModelWebsocketService {

    /**
     * Creates a new model (Instruction).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async createModel(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/models/celery/model/create`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/models/celery/ws/model_create/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    /**
     * Updates model (Instruction).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async updateModel(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/models/celery/model/update_model`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/models/celery/ws/update_model/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }
    
    /**
     * Runs an experiment using the model.
     * @param {Object} data - The experiment data to run.
     * @param {String} model_type - The model type to use from ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async experiment(data, model_type) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/models/celery/model/experiment?model_type=${model_type}`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/models/celery/ws/experiment/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }
    
    /**
     * Predict using a created model (Instruction).
     * @param {Object} data - The experiment data to run.
     * @param {String} model_type - The model type to use from ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async predict(data, model_type) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/models/celery/model/predict?model_type=${model_type}`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/models/celery/ws/predict/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

}

const unifiedModelWSService = new UnifiedModelWebsocketService();
export { unifiedModelWSService };