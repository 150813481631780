import { projectOHttpClient } from 'api/clients/project-o.httpClient';


/**
 * Service class for performing RAG operations.
 */
class WatsonXService {

    /**
     * Send a Prompt.
     * @returns {Promise<Object>}
     */
    async Experiment(body, mode){
        const response = await projectOHttpClient.post(`/model/foundationModel/experiment${mode ? `?mode=${mode}` : ''}`, body, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        return response;
    }


    
}

const watsonxService = new WatsonXService()
export { watsonxService }
