import React from 'react'
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';

function OrgForm({ orgData, setOrgData }) {

    const handleChange = (e, name) => {
        const {value} = e.target
        setOrgData(prev=> ({...prev,[name]:value}))
    }

    return (
        <div className="space-y-6">
            <div className="space-y-3">
                <Label htmlFor="org-name" className="text-md">Organization Name</Label>
                <Input
                value={orgData.name}
                onChange={e => handleChange(e, 'name')}
                name='name'
                id="org-name"
                placeholder="Enter suborganization name"
                className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                />
            </div>
        </div>
    )
}

export default OrgForm