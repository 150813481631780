// axios.config.js

import axios from "axios"


const defaultOnRequestIntercept = config => config
const defaultOnResponseIntercept = response => response


const defaultOnRequestError = error => Promise.reject(error)
const defaultOnResponseError = error => Promise.reject(error)

/**
 * A Dynamic API client maker with request and response interceptors.
 * @param {string} baseURL - The base URL for the API.
 * @param {string} endpoint - The API endpoint.
 * @param {number} [port=4000] - The port number, defaults to 4000.
 * @param {Function} [onRequest=defaultOnRequestIntercept] - Optional function to be called on request intercept.
 * @param {Function} [onResponse=defaultOnResponseIntercept] - Optional function to be called on response intercept.
 * @param {Function} [onRequestError=defaultOnRequestError] - Optional function to be called on request error.
 * @param {Function} [onResponseError=defaultOnResponseError] - Optional function to be called on response error.
 * @returns {import("axios").AxiosInstance} The Axios instance for making API requests.
 */
export const createApiClient = (
    baseURL,
    endpoint,
    onRequest = defaultOnRequestIntercept,
    onResponse = defaultOnResponseIntercept,
    onRequestError = defaultOnRequestError,
    onResponseError = defaultOnResponseError
) => {
    /**
     * Axios instance for making API requests.
     * @type {import("axios").AxiosInstance}
     */
    const apiClient = axios.create({
        baseURL: `${baseURL}${endpoint}`,
        withCredentials: true,
        headers: {
            "Content-type": "application/json"
        }
    })

    /**
     * Request interceptor for the API client.
     * @see {@link https://axios-http.com/docs/interceptors}
     * @param {import("axios").AxiosRequestConfig} config - The request configuration.
     * @returns {import("axios").AxiosRequestConfig} The modified request configuration.
     */
    apiClient.interceptors.request.use(
        config => onRequest(config),
        error => onRequestError(error)
    )

    /**
     * Response interceptor for the API client.
     * @see {@link https://axios-http.com/docs/interceptors}
     * @param {import("axios").AxiosResponse} response - The response received from the API.
     * @returns {import("axios").AxiosResponse} The unmodified response.
     */
    apiClient.interceptors.response.use(
        response => onResponse(response),
        error => onResponseError(error)
    )

    return apiClient
}
