import React from "react";
import "./docResultCard.css";
import { useEffect } from "react";
import Collapsible from "../../../../../../components/CollapsibleContainer";
import { GoCheckCircleFill } from "react-icons/go";
import { GoXCircleFill } from "react-icons/go";
import styled from "styled-components";

const PreTag = styled.pre`
  font-family: inherit;
  white-space: pre-wrap;
  text-wrap: wrap;
`

const handleCheckCrossMark = (reply, correct) => {
  return reply.llmResponse.reply == correct ? (
    <GoCheckCircleFill color="rgb(0, 194, 0)"/>
  ) : (
  <>
  <span><GoXCircleFill color="rgb(194, 0, 0)"/> <i style={{'color':"rgb(194, 0, 0)"}}>Correct answer: {correct}</i></span>
  </>
    
  )
}
export default function (props) {
  var count = 0;
  console.log(props.response);
  return (
    <>
      {props.response ? (
        <div className="card cardResult">
          <div className="card-body cardBodyLimit">
            {props.response.map((q, indQ) => {
              if (props.query == q.query) {
                return (
                  <div key={`${q}${indQ}`} className="perAnswer">
                    <h3>Query: {q.query}</h3>
                    <div>
                      {q.llmResp.map((reply, i) => {
                        console.log(reply);

                        let correct = "";
                        if(props.checkMode){
                          for (let doc = 1; doc < props.file.length; doc++) {
                            if (props.file[doc][0] == reply.documentID) {
                              correct = props.file[doc][props.cardIndex + 2];
                              break;
                            }
                          }
                          if (reply.llmResponse.reply == correct) {
                            count++;
                            props.correctArr.current[props.cardIndex] = count;
                            console.log("corrarr", props.correctArr.current);
                          }
                        }
                        
                        console.log("CORRECT:", correct);
                        return (
                          <>
                            <div>
                              <span className="boldText">Document Name:</span>{" "}
                              {reply.documentName}
                            </div>
                            <Collapsible title="Prompt">
                              <PreTag>{reply.llmResponse.prompt}</PreTag>
                            </Collapsible>

                            {/* <div>Prompt: {reply.llmResponse.prompt}</div> */}
                            <p className="answerLabel">
                              Output: <PreTag>{reply.llmResponse.reply}</PreTag>{" "}
                              {props.checkMode ? handleCheckCrossMark(reply, correct) : <></>}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
