import { useContext, useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import { Trash2, ChevronDown } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import InputValidation from "components/InputValidation";
import handler from "./OpCodeBuilderHandler";
import { unifiedModelService } from "api/services/PROJECT-O/UnifiedModel.service";  // Assume the service for fetching model details
import { Combobox } from "components/ui/combobox";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import StepLLM from "./StepLLM";
import StepNonLLMFunction from "./StepNonLLMFunction";
import StepNonLLMAPI from "./StepNonLLMAPI";

function extractKeys(str) {
    const regex = /{{\s*output\['(\w+)'\]\s*}}/g;
    let match;
    const keys = [];

    while ((match = regex.exec(str)) !== null) {
        keys.push(match[1]);
    }

    return keys;
}

export default function InputGroup({ group, index, handleRemoveGroup, inputGroups, setInputGroups, modelDetails }) {
    
    // console.log(group)
    const getPreviousOutputs = (currentIndex) => {
        if (currentIndex <= 0) return [];

        return inputGroups
            .slice(0, currentIndex)
            .map((group, index) => ({
                value: group.output_var || "",
                label: `Step ${index + 1} Output`,
            }))
            .filter((item) => item.value.trim() !== "");
    };

    return (
        <div className="space-y-2 bg-gray-50 border shadow rounded p-2">
            <div className="flex flex-row gap-2 items-center justify-between">
                <h3 className="text-lg font-semibold">Step {index + 1} - {!group?.registry_type ? group?.step_type : `${group?.step_type} (${group?.registry_type.toUpperCase()})`}</h3>
                <div className="flex items-center">
                    {inputGroups.length > 0 && (
                        <Button variant="destructive" className="w-full" onClick={() => handleRemoveGroup(index)}>
                            <Trash2 className="h-5 w-5" />
                        </Button>
                    )}
                </div>
            </div>
            
            {group?.step_type === "LLM" ? (
                <StepLLM group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} modelDetails={modelDetails} getPreviousOutputs={getPreviousOutputs}/>
            ) 
            // : group?.registry_type === "function" ? (
            //     <StepNonLLMFunction  group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} getPreviousOutputs={getPreviousOutputs}/>
            // )
            : group?.registry_type === "api" ? (
                <StepNonLLMAPI  group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} getPreviousOutputs={getPreviousOutputs}/>
            ) : "" }

        </div>
    );
}
