import React, { useContext, useState } from 'react';
import { Button } from "components/ui/button";
import { SquareTerminal } from 'lucide-react';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "components/ui/tooltip";
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/DemoPromptContext';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { Textarea } from "components/ui/textarea";
import { Context } from 'context/GlobalState';




const ConsolidatedDialogDemo = () => {
    const [open, setOpen] = useState(false);
    const [consolidatedPrompt, setConsolidatedPrompt] = useState("");
    const { addNewNotifcation } = useContext(Context);
    const {
        instruction,
        context,
        targetFormat,
        sampleInput,
        sampleOutput,
        inputData
    } = useContext(GlobalContext)

    const handleOnOpen = () => {
        setOpen(true);
        let prompt = labHelper.ConstructPromptUnified({
            "instruction": instruction.trim(),
            "context": context.trim(),
            "target_format": targetFormat.trim(),
            "sample_input": sampleInput.trim(),
            "sample_output": sampleOutput.trim(),
            "input_data": inputData.trim(),
        });
        setConsolidatedPrompt(prompt);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(consolidatedPrompt);
        addNewNotifcation("Copied to clipboard.", "success");
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                            <Button
                                size="icon"
                                variant="ghost"
                                onClick={handleOnOpen}
                            >
                                <SquareTerminal className="h-5 w-5" />
                            </Button>
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>View Consolidated Prompt</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent className="sm:max-w-[600px] select-none">
                <DialogHeader>
                    <DialogTitle>Consolidated Prompt</DialogTitle>
                    <DialogDescription>
                        Use this prompt on other GenAI tools. Modify the prompt
                        as needed.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Textarea
                        rows={10}
                        className="w-full max-h-[425px]"
                        value={consolidatedPrompt}
                        onChange={(e) => {
                            setConsolidatedPrompt(e.target.value);
                        }}
                    />
                </div>
                <DialogFooter>
                    <Button
                        type="submit"
                        variant="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                    <Button type="submit" onClick={handleCopy}>
                        Copy
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}


export default ConsolidatedDialogDemo;