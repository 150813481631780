import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`

    border: 1px solid #ddd; /* Add border to wrapper */
    border-radius: 10px; /* Add rounded corners to wrapper */
    overflow: hidden; /* Ensure rounded corners are applied */
    margin: 1rem 0 1rem 0;

`
const TableWrapper = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default TableWrapper;