import axios from "axios";
import { httpRequestY } from "./httpsRequest";
const fetchResponse = (documentID, projectId, collectionId, queries, context) => {
  let documentFilter = "";
  console.log("DOCID", documentID)
  console.log("projectId", projectId)
  console.log("collectionId", collectionId)
  console.log("queries", queries)
  console.log("context", context)

  console.log(documentFilter);
  var llmResp = httpRequestY.post("/Y/api/discovery/createquery", {
    projectId: projectId,
    title: "From test playground",
    collectionIds: collectionId,
    queryDetails: [
      {
        // "Document_id":"b3cc50b4-c7b4-4ee3-85b9-32cb21e63f87",
        query: [], //"What is the type of flexible benefits applied, answer only with 'Allowance','Reimbursment' or 'Not stated'
        context: context,
        naturalLanguageQuery: queries, // Array of string
        filter: documentID,

        tableResults: {
          enabled: true, // TRUE or FALSE depends on user selection
          count: "2",
        },
      },
    ],
    passages: {
      enabled: true,
      per_document: true,
      max_per_document: 3,
      fields: ["text", "table", ""], // text is mandatory, table is optional depending on user selection
      characters: 800,
    },
  });

  return llmResp;

};

export default fetchResponse;
