import { httpRequest, httpRequestO, httpRequestX, serverX } from '../../../../../utils/httpsRequest'

export async function ChatResponse(messages) {
  return httpRequestX.post("/X/api/pdf/chat", messages);
}
export async function ChatResponseWatsonx(messages) {
  return httpRequestX.post("/X/api/pdf/chatWatson", messages);
}
export async function ChatResponseWatsonxDemo(messages) {
  return httpRequestX.post("/X/api/pdf/chatWatsonDemo", messages);
}
export async function ChatResponseWatsonxDemoCompare(messages) {
  return httpRequestX.post("/X/api/pdf/chatWatsonDemo?mode=compare", messages);
}
export async function ChatResponseTextEmbWatsonxDemo(messages) {
  return httpRequestX.post("/X/api/pdf/chatTextEmbeddingWatsonDemo", messages);
}
export async function ChatResponseChatpdfDemo(messages) {
  return httpRequestX.post("/X/api/pdf/chatDemo", messages);
}
export async function ChatDocument(params, ragMethod) {
  return await httpRequestX.post(`/X/api/pdf/chatDocument`,params);
}
export async function DeleteDocument(params) {
  // return await httpRequestX.delete(`/X/api/pdf/deleteDocument`,params);
  return fetch("http://localhost:3002/X/api/pdf/deleteDocument",
    {method: 'DELETE',
    body: params,}
  )
}
export async function ClassifyDocument(params) {
  return await httpRequestX.post(`/X/api/pdf/classifyDocument?withExplanation=true`,params, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
export async function CompareClause(params) {
  return await httpRequestX.post(`/X/api/pdf/compareDemo`,params);

}
export async function GetDocuments(orgId, subOrgId, filter) {
  // const { organizationId } = useParams()
  // console.log(organizationId)
  return await httpRequestO.get(`/projecto/api/v1/document_api_service/list-document/?organization_id=${orgId}&sub_organization_id=${subOrgId}&filter=${filter}`);
}
export async function GetClauses() {
  return await httpRequestX.get(`/X/api/pdf/getDemoClauses`);
}

// export async function UploadDocuments(data, organizationId, collectionId, projectId, ragMethod = "methodA") {
//   const form = new FormData();

//   form.append("file", data);
//   form.append("organizationId", organizationId)
//   form.append("collectionId", collectionId)
//   form.append("projectId", projectId)
//   form.append("ragMethod", ragMethod)
//   return httpRequestX.post("/X/api/pdf/uploadDocs", form, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
// }
export async function UploadDocuments(file, collectionId, projectId, organizationId, subOrganizationId, chunk_size, chunk_overlap, project, uploadedBy, ragMethod, isTemp) {
  const form = new FormData();

  form.append("file", file);
  form.append("organizationId", organizationId)
  form.append("subOrganizationId", subOrganizationId)
  form.append("collectionId", collectionId)
  form.append("projectId", projectId)
  form.append("ragMethod", ragMethod)
  form.append("isTemp", isTemp)
  form.append("chunk_size", chunk_size)
  form.append("chunk_overlap", chunk_overlap)
  form.append("project", project)
  form.append("uploadedBy", uploadedBy)
  // return httpRequestX.post("/X/api/pdf/uploadDocs", form, {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // });
  return fetch(serverX+'/X/api/pdf/uploadDocs',{
    method: 'POST',
    body: form,
    headers: {
      'X-Content-Type-Options': 'nosniff',
    },
  }).catch((e)=>console.log("error uploading:", e))
}

export async function TestEndpoint() {
  return fetch('http://localhost:3002/X/api/pdf/testExtract/',{
    method: 'POST',
  })
}


export async function GetConversation(sourceId) {
  return await httpRequestX.get(`/X/api/pdf/getconversation/${sourceId}?reverse=true`);
}

export async function GetUserAnnotation(sourceId) {
  return await httpRequestX.get(`/X/api/pdf/getannotation/${sourceId}`)
}
export async function AddUserAnnotation(annotation) {
  console.log(annotation)
  return await httpRequestX.post(`/X/api/pdf/adduserannotation`, annotation)
}

export async function GetProjects(organizationId) {
  return await httpRequestX.get(`/X/api/discovery/getProjectsDatabase/${organizationId}`)
}
export async function GetCollections(projectId) {
  return await httpRequestX.get(`/X/api/discovery/getCollectionsDatabase/${projectId}`)
}