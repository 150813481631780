import React from 'react'
import Header from './Header'
import Footer from './Footer'
import GlobalState, { Context } from "../context/GlobalState";
import '../App.css';
import { AppLoader } from '../components/LoaderSpinner';
import Notifcation from '../components/Notifcation';
import { Outlet } from 'react-router-dom';

export default function Wrapper({ children }) {

    const store = GlobalState()

    return (
        <Context.Provider value={store}>
            <div className='bex-container bg-gray-100'>
                <Notifcation />
                {store.loading ? <AppLoader size={30} /> : (
                    <>
                        {children}
                    </>
                )}
            </div>
        </Context.Provider>
    )
}


// src/components/Layout.js

export const Layout = () => {

    return (
        <>
            <Header />
                <main>
                    <Outlet />
                </main>
            <Footer />
        </>
    );
};
