import { createContext, useState } from "react";
import { AppLoader } from "components/LoaderSpinner";

export const OrganizationContext = createContext({});

export const OrganizationProvider = ({ children }) => {
    
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [loading, setLoading] = useState(false);

    // Actions
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "SET_LOADING":
                return setLoading(payload);
            case "SET_SELECTED_ORGANIZATION":
                return setSelectedOrganization(payload);
            default:
                return loading;
        }
    };

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <OrganizationContext.Provider
        value={{
            actions,
            loading,
            selectedOrganization,
        }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
