import { useContext, useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import InputValidation from "components/InputValidation";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import { Combobox } from "components/ui/combobox";
import handler from "./OpCodeBuilderHandler";
import InstructionSelectionModal from "./InstructionSelection";

export default function StepLLM({ group, index, inputGroups, setInputGroups, modelDetails, getPreviousOutputs }) {
    const [currentModelDetails, setCurrentModelDetails] = useState(modelDetails || null);
    const [loading, setLoading] = useState(false);
    const { savedModels, savedModelsGroq } = useContext(OpCodeBuilderContext);

    // Handle the change in form inputs
    const handleInputChange = (field, value) => {
        const newInputGroups = [...inputGroups];

        if (field === 'unique_name') {
            newInputGroups[index].unique_name = value;
        } else if (field === 'model_type') {
            newInputGroups[index].model_type = value;
            newInputGroups[index].unique_name = ""; // Reset unique_name when model_type changes
        } else if (field === 'output_var') {
            newInputGroups[index].output_var = value;
        } else if (field === 'dynamic_inputs') {
            newInputGroups[index].dynamic_inputs = value[0] === "" ? [] : value; // Ensure empty array instead of [""] 
        }

        setInputGroups(newInputGroups);
    };

    const handleMultiSelectChange = (selectedValues) => {
        handleInputChange("dynamic_inputs", selectedValues);
    };

    // Extract dynamic inputs from the first step
    const firstStep = inputGroups[0];
    let firstStepDynamicInput = [];
    if (firstStep?.step_type === "LLM") {
        firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
            value,
            label: "Step 1 Input",
        })) || [];
    } else if (firstStep?.step_type === "Non-LLM" && firstStep.dynamic_inputs?.query_text) {
        firstStepDynamicInput = [
            {
                value: firstStep.dynamic_inputs.query_text,
                label: "Step 1 Input",
            },
        ];
    }

    // Combine previous outputs with the first step's dynamic input
    const multiSelectOptions = [
        ...firstStepDynamicInput,
        ...getPreviousOutputs(index),
    ];

    return (
        <div className="grid grid-cols-8 gap-4">
            {/* Model Type */}
            <div className="col-span-1">
                <Label htmlFor={`modelType-${index}`}>Instruction Type</Label>
                <Select onValueChange={(value) => handleInputChange("model_type", value)} value={group?.model_type || ""}>
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="-" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="IBM">IBM</SelectItem>
                        <SelectItem value="Groq">Groq</SelectItem>
                    </SelectContent>
                </Select>
            </div>

            {/* Unique Name */}
            <div className="col-span-3">
                <Label htmlFor={`uniqueName-${index}`}>Instruction Name</Label>

                <InstructionSelectionModal
                    group={group}
                    index={index}
                    inputGroups={inputGroups}
                    setInputGroups={setInputGroups}
                    model_type={group?.model_type}
                    instructions={(group?.model_type === "IBM" ? savedModels : group?.model_type === "Groq" ? savedModelsGroq : [])}/>
            </div>

            {/* Dynamic Inputs */}
            <div className="col-span-2">
                <Label htmlFor={`input-${index}`}>Step Input</Label>
                {index === 0 ? (
                    // Text Input for the First Step
                    <InputValidation
                        id={`dynamicInput-${index}`}
                        value={group?.dynamic_inputs?.[0] || ""}
                        onChange={(e) => handleInputChange("dynamic_inputs", [e.target.value])}
                        className="bg-white"
                        validation={handler.validateInputName}
                        disabled={
                            group?.dynamic_inputs?.query_text !== "" && (
                                // For LLM steps
                                inputGroups.slice(index + 1).some(
                                    nextGroup => nextGroup.step_type === "LLM" && nextGroup.dynamic_inputs?.includes(group?.dynamic_inputs?.[0])
                                )
                                ||
                                // For Non-LLM steps
                                inputGroups.slice(index + 1).some(
                                    nextGroup => nextGroup.step_type === "Non-LLM" && nextGroup.dynamic_inputs?.query_text?.includes(group?.dynamic_inputs?.[0])
                                )
                            )
                        }
                    />
                ) : (
                    // Multi-select Dropdown for Subsequent Steps
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" role="combobox" className="w-full justify-between bg-white">
                                {group?.dynamic_inputs?.length > 0 ? `${group.dynamic_inputs.length} selected` : "Select inputs"}
                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-[200px]">
                            {multiSelectOptions.map((item) => (
                                <DropdownMenuCheckboxItem
                                    key={item.value}
                                    checked={group?.dynamic_inputs?.includes(item.value)}
                                    onCheckedChange={(checked) => {
                                        const newInputs = checked
                                            ? [...(group.dynamic_inputs || []), item.value]
                                            : (group.dynamic_inputs || []).filter(input => input !== item.value);

                                        handleMultiSelectChange(newInputs);
                                    }}
                                >
                                    {item.label}
                                </DropdownMenuCheckboxItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
            </div>

            {/* Output Variable */}
            <div className="col-span-2">
                <Label htmlFor={`outputVar-${index}`}>Step Output</Label>
                <InputValidation
                    id={`outputVar-${index}`}
                    value={group?.output_var || ""}
                    onChange={(e) => handleInputChange("output_var", e.target.value)}
                    className="bg-white"
                    validation={handler.validateOutputName}
                    disabled={
                        group?.output_var !== "" && (
                            // For LLM steps
                            inputGroups.slice(index + 1).some(
                                nextGroup => nextGroup.step_type === "LLM" && nextGroup.dynamic_inputs?.includes(group.output_var)
                            )
                            ||
                            // For Non-LLM steps
                            inputGroups.slice(index + 1).some(
                                nextGroup => nextGroup.step_type === "Non-LLM" && nextGroup.dynamic_inputs?.query_text?.includes(group.output_var)
                            )
                        )
                    }
                />
            </div>
        </div>
    );
}
