import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from "components/ui/button";
import { FileText, Trash2, Upload } from 'lucide-react';
import * as ShadeDD from "components/ui/dropdown-menu";
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Input } from 'components/ui/input';
import { v4 as uuidv4 } from 'uuid';
import { DocChatContext } from 'pages/PromptingAndRagTraining/context/DocChatContext';
import { ragService } from 'api/services/PROJECT-O';


export const SampleList = ({inputMessage, setInputMessage}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoadingDocs, setIsLoadingDocs] = useState(false);
    const [documents, setDocuments] = useState([]);
    
    

    const {
        selectedSample, setSelectedSample,
        setSelectedDocuments,
        getFileExtension
    } = useContext(DocChatContext);
    // const SAMPLES = [
    //     {_id: uuidv4(), name: "GitHub (Technical)", type: "ppt", value: "sample_1", query: ""},
    //     {_id: uuidv4(), name: "GenAI (Educational)", type: "ppt", value: "sample_2", query: ""},
        
    // ];

    const GetDocuments = async () => {
        try{
            setIsLoadingDocs(true);
            const predefinedDocs = await ragService.listDocuments(process.env.REACT_APP_COMMON_ORGANIZATION_ID, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, {
                "assistant" : "ai-lab",
                "feature": "ai-teacher"
            });

            // console.log(predefinedDocs);
            setDocuments(predefinedDocs.data);
        }
        catch(error){
            console.error(error);
        }
        finally{
            setIsLoadingDocs(false);

        }
    };
    useEffect(() => {
        GetDocuments();
    }, [])
    

    // {
    //     "message": "Documents processed successfully",
    //     "document_ids": [
    //       "66b9e0425451bcc1c07fba97",
    //       "66b9e0495451bcc1c07fbaa0"
    //     ]
    //   }

    const truncateText = (filename, count) => {
        let txt = filename.length > count ? filename.slice(0, count) + '...' + getFileExtension(filename) : filename;

        return txt;
    }

    const OnSampleSelected = (sample) => {
        // console.log(sample);
        setSelectedDocuments([]);
        // console.log(sample._id)
        switch(sample.file_name) {
            case "GitHub (Technical).pptx":
                setInputMessage("What is GitHub and what are branches?");
                break;
            case "GenAI (Educational).pptx":
                setInputMessage("Generate a quiz about GenAI based on the document.");
                break;
        }
        setSelectedSample( [
            { _id: sample._id, name: sample.file_name, file_url: sample.file_url },
        ]);
        setIsOpen(false);
    };

    return(
        <ShadeDD.DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" className="px-4">
                    {/* <FileText className="w-5 h-5" /> */}
                    Samples
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="center" side="bottom" className="w-[300px]">
                {
                    isLoadingDocs ?
                    <ShadeDD.DropdownMenuLabel>
                        Loading Samples...
                    </ShadeDD.DropdownMenuLabel>
                    :
                    documents.length === 0 ?
                    <ShadeDD.DropdownMenuLabel>
                        No samples found
                    </ShadeDD.DropdownMenuLabel>
                    :
                    ""
                }
                <ShadeDD.DropdownMenuGroup>
                    {documents.map((s) => (
                        <div
                            key={s._id}
                        >
                            <Button 
                                variant="ghost"
                                className="w-full whitespace-normal break-words"
                                onClick={() => OnSampleSelected(s)}>
                                {truncateText(s.file_name, 50)}
                            </Button>    
                        </div>
                    
                    ))}
                </ShadeDD.DropdownMenuGroup>
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


