import { createContext, useState } from "react";
import { EventEmitter } from 'events';
export const eventBus = new EventEmitter();

export const ManagerContext = createContext({})

export default function ManagerGlobalState() {
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchListIntEnt, setSearchListIntEnt] = useState([])
    const [searchListVaEx, setSearchListVaEx] = useState([])
    const [expand, setExpand] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedContainer, setSelectedContainer] = useState('item')
    const [isOpen, setIsOpen] = useState(false);
    const [checkNewRows, setCheckNewRows] = useState(false)
    const [intentData, setIntentData] = useState({
        name: '',
        description: '',
        examples: { text: '' },
    })

    const [entityData, setEntityData] = useState({
        name: '',
        description: '',
        type:'synonyms',
        value:'',
        synonyms:[]
    });
    const [showMessages, setShowMessages] = useState(false)
    const [workspaceObject, setWorkspaceObject] = useState({})
    

    // console.log(searchListIntEnt)

    const managerActions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "SET_ITEM_SELECTION":
                return handleSelect(payload)
            case "SET_EXPAND_SELECTION":
                return handleExpandSelect(payload)
            case "SET_SEARCH_LIST_INTENT_ENTITY":
                return setSearchListIntEnt(payload)
            case "SET_SEARCH_LIST_VAEX":
                return setSearchListVaEx(payload)
            case "SET_SELECTED_CONTAINER":
                return setSelectedContainer(payload)
            case "SET_OPEN_MODAL":
                return setOpenModal(!openModal)
            case "SET_INTENT_DATA":
                return setIntentData(payload)
            case "HANDLE_CHANGE":
                return handleChange(payload)
            case "HANDLE_ISOPEN":
                return setIsOpen(!isOpen)
            case "SET_ENTITY_DATA":
                return setEntityData(payload)
            case "SET_SHOW_MESSAGE":
                return setShowMessages(payload)
            case "SET_WORKSPACE_OBJECT":
                return setWorkspaceObject(payload)
            default:
                return ''

        }
    };

    const handleChange = (payload) => {
        const { name, value } = payload.target;
        setIntentData((prev) => ({ ...prev, [name]: value }))
    };
    // console.log(selectedItem, expand)

    const handleSelect = (payload) => {
        if (payload?.index) {
            setSelectedItem((prevSelected) => {
                return prevSelected?.index === payload?.index ? null : payload;
            });
        } else {
            return setSelectedItem(payload)
        }
    }

    const handleExpandSelect = (payload) => {
        if (payload) {
            setExpand((prevSelected) => {
                return prevSelected === payload ? null : payload;
            });
        } //else {
        //     return setExpand(payload)
        // }
    }

    const onSearch = (searchTerm) => {
        eventBus.emit('searchTermChanged', searchTerm.toLowerCase()); // Emit an event
    };

    const onCloseModal = (onClose) => {
        setOpenModal(false)
        setSearchListIntEnt([])
        setSearchListVaEx([])
        handleSelect(null)
        handleExpandSelect(null)
        setIntentData({ name: '', description: '', examples: { text: '' } })
        setIsOpen(false)
        setEntityData({
            name: '',
            description: '',
            type:'synonyms',
            value:'',
            synonyms:[]
        })
        onClose && onClose()
        setSelectedContainer('item')
    }

    return {
        managerActions,
        setCheckNewRows,
        checkNewRows,
        onSearch,
        onCloseModal,
        setOpenModal,
        openModal,
        expand,
        selectedItem,
        searchListIntEnt,
        searchListVaEx,
        setEntityData,
        entityData,
        intentData,
        isOpen,
        selectedContainer,
        showMessages,
        workspaceObject
    }
}

