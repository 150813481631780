import { useState, useContext } from "react";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import { Context } from "context/GlobalState";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import OpCodeGrid from "./OpCodeGrid";
import { ScrollArea } from "components/ui/scroll-area";
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { opcodeWSService } from "api/services/PROJECT-O/WEBSOCKETS/OpCode.websocket.service";

export default function OpCodeExecution() {
    const { 
        opCodes, setOpCodes,
        selectedOpCode, setSelectedOpCode,
        executeInput, setExecuteInput,
        executionResults, setExecutionResults,
        isExecuting, setIsExecuting,
        contextKey
    } = useContext(OpCodeBuilderContext)
    const { addNewNotifcation } = useContext(Context);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);

    const handleExecute = async () => {
        if (!selectedOpCode) {
            addNewNotifcation("Please select a workflow.", "warning");
            return;
        }
        if (!contextKey) {
            addNewNotifcation("Context Key missing.", "warning");
            return;
        }

        try {
            if (!selectedOpCode || executeInput.trim() === "") {
                addNewNotifcation("Please select an OpCode ID and give an input.", "warning");
                return;
            }
            setIsExecuting(true);
            setExecutionResults([]);
            const body = {
                "opcode_id": selectedOpCode,
                "org_id": selectedOrganization._id,
                "sub_org_id": selectedSubOrganization._id,
                "context": {
                  [contextKey]: executeInput.trim()
                }
            }
            console.log("execute body:", body);

            const res = await opcodeWSService.executeOpCode(body)
            // const res = await opCodeService.executeOpCode(body)
            console.log(res)
            console.log("Execution result", res)
            if (typeof res === "object" && res.execution_log) {
                setExecutionResults(res.execution_log);
                addNewNotifcation("OpCode executed successfully.", "success");
            } else {
                throw new Error(res)
            }

        } catch (e) {
            console.error("Error executing OpCode:", e);
            addNewNotifcation("Error when executing OpCode. Please try again.", "danger");
            setExecutionResults([]);
        } finally {
            setIsExecuting(false);
        }
    };

    return (
        <div className="space-y-2">
            <div className="">
                <OpCodeGrid workflows={opCodes} setWorkflows={setOpCodes} setSelectedOpCode={setSelectedOpCode}/>
            </div>

            <div className="space-y-2 flex flex-col">
                <Label>Selected Workflow: </Label>
                <span className="select-text font-bold text-lg px-2 py-2 border rounded">{selectedOpCode ? selectedOpCode : "No workflow selected"}</span>
            </div>
            <div className="space-y-2">
                <Label>Input</Label>
                <Textarea value={executeInput} rows="6" onChange={(e) => setExecuteInput(e.target.value)} placeholder="Enter input for execution" />
            </div>
            <Button onClick={handleExecute} className="w-full" disabled={isExecuting}>{isExecuting ? "Executing OpCode..." : "Execute OpCode"}</Button>
            {executionResults.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-4">Execution Results</h3>
                    {executionResults.map((result, index) => (
                        <div key={index} className="bg-muted p-4 rounded mb-4 shadow border">
                            <h4 className="font-semibold mb-2">Step {index + 1}</h4>
                            <p><strong>Action:</strong> {result.Action}</p>
                            <p><strong>Output:</strong> {
                                Object.entries(result.Outputs).map(([key, value], index) => (
                                    <div key={index} className="bg-white h-fit border select-text">
                                        <ScrollArea className="max-h-[500px] p-2">
                                            <strong className="text-gray-500">{key}:</strong>
                                            <p className="whitespace-pre-wrap text-sm">{value.trim()}</p>
                                        </ScrollArea>
                                    </div>
                                ))
                            }</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
