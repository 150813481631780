import React, { useContext, useEffect, useState } from 'react';
import { httpRequest } from '../../../utils/httpsRequest';
import { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import {
    Dialog,
    DialogOverlay,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
} from '../../../components/ui/dialog';
import { Context } from '../../../context/GlobalState';
import CreateWorkflowTypeDialog from "./AddWorkflowType"
import UpdateWorkflowTypeDialog from './EditWorkflowType';
import { LoaderSpinner } from 'components/LoaderSpinner';
import { workflowService } from 'api';

const WorkflowTypes = () => {
    const [workflowTypes, setWorkflowTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [currentWorkflowType, setCurrentWorkflowType] = useState(null);
    const { isAdminAuth, addNewNotifcation, user } = useContext(Context);

    useEffect(() => {
        workflowService.getWorkflowTypes('admin')
            .then((res) => {
                setWorkflowTypes(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log('error: ', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [showAdd, showEdit]);

    const getAssistants = async () => {
        try {
            const assistants = await httpRequest.get(`/db/bex-assistants/get-assistants`)
            setAssistants(assistants.data);
        } catch (error) {
            console.log('error: ', error);
        }
    }
    useEffect(()=>{
        getAssistants();
    }, [])

    const openEditDialog = (workflowType) => {
        setCurrentWorkflowType(workflowType);
        setShowEdit(true);
    };

    const openDeleteDialog = (assistant) => {
        setCurrentWorkflowType(assistant);
        setShowDelete(true);
    };

    const handleDeleteAssistant = async() => {
        console.log(currentWorkflowType);
        const id = currentWorkflowType._id;
        setDeleteLoading(true);
        httpRequest.delete(`/db/workflows/delete-workflow-type/${id}`)
        .then((res) => {
            setWorkflowTypes(workflowTypes.filter(w => w._id !== currentWorkflowType._id));
            addNewNotifcation("Successfully deleted the workflow type.", "success");
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation("Something went wrong. Could not delete the workflow type.", "success");
        }).finally(() => {
            setDeleteLoading(false);
            setShowDelete(false);
        });
    }

    function truncateString(str, num) {
        if (!str) {
            return '';
        }
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    }

    const getAssistantNamesByIdList = (idList) => {
        const names = idList.map(id => {
            const assistant = assistants.find(assistant => assistant._id === id);
            return assistant ? assistant.name : null;
        }).filter(name => name !== null).join(', ');
        return names;
    };

    return (
        <div className="flex flex-col w-full m-4 p-4">
            <div className="flex flex-row justify-between">
                <h1 className="text-2xl font-bold">Workflow Types</h1>
                {isAdminAuth && <Button onClick={() => { setCurrentWorkflowType(null); setShowAdd(true); }}>Add Workflow Type</Button>}
            </div>
            <div className="flex flex-row gap-4 flex-wrap m-4">
                {loading ? <h1>Loading...</h1> : workflowTypes.map((workflowType) => (
                    <Card key={workflowType._id} className="max-w-[250px] m-2 h-50">
                        <CardHeader className="h-20">
                            <CardTitle className="flex w-full h-full break-words items-center">
                                <span>{workflowType.name}</span>  
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="h-24 overflow-y-hidden">
                            <CardDescription>
                                {truncateString(getAssistantNamesByIdList(workflowType.assistants), 60)}
                            </CardDescription>
                        </CardContent>
                        <CardFooter className="flex flex-row flex-wrap items-center gap-2">
                            <Button onClick={() => openEditDialog(workflowType)}>Edit Workflow Type</Button>
                            <Button onClick={() => openDeleteDialog(workflowType)} variant="outline">Delete</Button>
                        </CardFooter>
                    </Card>
                ))}
            </div>
            <CreateWorkflowTypeDialog show={showAdd} setShow={setShowAdd} user={user} addNewNotifcation={addNewNotifcation} />
            <UpdateWorkflowTypeDialog show={showEdit} setShow={setShowEdit} user={user} addNewNotifcation={addNewNotifcation} workflowType={currentWorkflowType} />

            <Dialog open={showDelete} onOpenChange={setShowDelete}>
                <DialogOverlay />
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Workflow Type</DialogTitle>
                        <DialogDescription>Are you sure you want to delete this workflow type?</DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button onClick={() => setShowDelete(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleDeleteAssistant} variant="destructive">{ deleteLoading ? <LoaderSpinner/> : "Delete Workflow Type"}</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default WorkflowTypes;