import React, { useState } from 'react'
import Editor from '@monaco-editor/react';
import axios from 'axios';
import { Combobox } from '../../components/ui/combobox';
import { Button } from '../../components/ui/button';

export default function Sandbox() {
    const [language, setLanguage] = useState('javascript');
    const [code, setCode] = useState('// Write your code here');
    const [output, setOutput] = useState('');
    const [loading, setLoading] = useState(false);
  

    function isObject(value) {
        return value !== null && typeof value === 'object' && !Array.isArray(value);
    }

    const runCode = async () => {
        try {
            setLoading(true)
            console.log(language, code);
            const res = await axios.post('https://stgapp.coolriots.ai/code-exe/execute', {language, code}, {
                headers: {
                'Content-Type': 'application/json',
                },
            });
            console.log('data: ', res);
            if (!isObject(res.data.result)) {
                setOutput(res.data.result);
            }
        } catch (error) {
            console.log('error: ', error);
            setOutput('Error executing code. ', error);
        } finally {
            setLoading(false)
        }
    };

    const languages = [
        {_id: 1, name: 'javascript'},
        {_id: 2, name: 'python'},
    ]

    const onSelect = (value) => {
        setLanguage(languages.find(item => value._id === item._id).name)
    }

    return (
        <div className='flex flex-col flex-grow p-10 overflow-scroll'>

            <div className='flex justify-between mb-2'>
                <h1 className="flex text-2xl">BeX Sandbox Environment</h1>
                <div className="flex gap-3 items-center">
                    <Combobox
                        items={languages}
                        selectedValue={languages.find(item => item.name === language)}
                        setter={onSelect}
                    />
                    <Button disabled={loading} onClick={runCode}>
                        Run Code
                    </Button>
                </div>
            </div>
            
            <div className='flex w-full min-h-[500px] h-[500px] overflow-hidden'>
                <CodeEditor 
                language={language}
                code={code}
                setCode={setCode} />
            </div>

            <div className='flex flex-col mt-3 w-full bg-white p-3 rounded'>
                <h3> Output </h3>

                <div className='p-2 bg-gray-100 roudned mt-2'>
                    <pre>{output}</pre>
                </div>
                
            </div>
        </div>
    )
}



const CodeEditor = ({ language, code, setCode }) => {
    return (
      <Editor
        height="90vh"
        language={language}
        value={code}
        theme="vs-dark"
        onChange={(newValue) => setCode(newValue)}
      />
    );
};
  