import React, { Children, useState } from "react";
import styled, { keyframes, css } from "styled-components";


const Header = styled.div`
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-style: solid;
  border-width: 0 0 1px 0;
`;
const NavTabContainer = styled.div`
  margin: 10px;
  overflow:hidden;
`;
const TabContainer = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
`;
const Tabs = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  border-style: none;
  background-color: ${(props) =>
    props.keyId === props.$activeTab ? "#b1092a" : "rgb(230, 230, 230)"};
  color: ${(props) => (props.keyId === props.$activeTab ? "white" : "black")};
  padding: 10px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.keyId === props.$activeTab ? "#b1092a" : "rgb(210, 210, 210)"};
  }
`;

function NavTab({ children, tabsList, defaultTab }) {

  const [activeTab, setActiveTab] = useState(defaultTab);
  // const renderTab = () => {
  //   return children.find((item, i) => {
  //     console.log("from Find", item);
  //     return item.props.id === activeTab;
  //   });
  // };

  return (
    <NavTabContainer>
      <Header>
        <TabContainer>
          {tabsList.map((tab, index) => (
            <Tabs
              key={tab.keyTab}
              keyId={tab.keyTab}
              $activeTab={activeTab}
              onClick={() => setActiveTab(tab.keyTab)}
            >
              {tab.title}
            </Tabs>
          ))}
        </TabContainer>
      </Header>

      {children.find((item, i) => {
        console.log("from Find", item);
        return item.props.id === activeTab;
      })}
    </NavTabContainer>
  );
}

export default NavTab;
