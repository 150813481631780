import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 0px !important;
    height: 100%;
`
export const ProtectedRoute = () => {

    const { isAuth } = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem('redirect-url', location.pathname)
            navigate('/auth/login')
        }
    }, [location.pathname])

    return (
        <StyledContainer>
            <Outlet />
        </StyledContainer>
    )
  };