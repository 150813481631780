import React, { useContext, useState } from 'react'
import './settings-styles.css'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Context } from '../../context/GlobalState'
import { IoIosArrowDown } from 'react-icons/io'
import { settingsList, settingsOrgList } from '../../data/userSettingsMenu'
import VerifyEmailBox from './VerifyEmailBox'
import { OrganizationContext } from "context/OrganizationContext";

export default function Settings() {

    const { selectedOrganization } = useContext(OrganizationContext);


    return (
        <div className='settings-container'>
            <VerifyEmailBox />
            <div className='settings-content-container'>
                <aside className='setting-aside-menu'>
                    <div className='settings-nav-container'>
                        <ul className='settings-nav-list'>
                            {settingsList(selectedOrganization._id).map((item, i) => (
                                <li key={i}>
                                    <SettingSidebar {...item} />
                                </li>
                            ))}
                            <SettingsSection title='Organization' list={settingsOrgList(selectedOrganization._id)} />
                        </ul>
                    </div>
                </aside>

                <div className='settings-page-container'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export const SettingsSection = ({ list, title }) => {

    return (
        <li className='setting-item-gorup'> 
            <span className='setting-group-title'> {title} </span>
            <div className='settings-nav-container'>
                <ul className='settings-nav-list'>
                    {list.map((item, i) => (
                        <li key={i}>
                           {!item.nested ? <SettingSidebar {...item} /> : <NestedSettingSidebar {...item} />}
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    )
}

const NestedSettingSidebar = ({ name, icon, nestedlist }) => {

    const [showDrowDown, setShowDrowDown] = useState(false)

    return (
        <div className='nested-setting-item-container'>
            <div className='nested-setting-header'>
                <div onClick={() => setShowDrowDown(!showDrowDown)} className={`setting-item-container`}>
                    <span> {icon} </span>
                    <span> {name} </span>
                    <div className={`nested-dropdown-icon ${showDrowDown ? 'nested-dropdown-icon__active' : ''}`}>
                        <IoIosArrowDown size={16} />
                    </div>
                </div>
            </div>
            {showDrowDown && (
                <div className='nested-dropdown-list-container'>
                    {nestedlist.map((item) => (
                        <SettingSidebar key={item.path} {...item} />
                    ))}
                </div>
            )}
        </div>
    )
}

const SettingSidebar = ({ name, path, icon }) => {
    const loaction = useLocation()

    return (
        <>
        {path.includes('#') ? (
            <a href={path} className={`setting-item-container ${loaction.hash.split('#')[1] === path.split('#')[1] ? 'setting-item__active' : ''}`}>
                <span> {icon} </span>
                <span> {name} </span>
            </a>
        ) : (
            <Link to={path} className={`setting-item-container ${loaction.pathname === path ? 'setting-item__active' : ''}`}>
                <span> {icon} </span>
                <span> {name} </span>
            </Link>
        )}
        </>
    )
}