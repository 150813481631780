import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './globals.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import Wrapper from './layout/Wrapper';
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
      <Wrapper>
        <App />
      </Wrapper>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

reportWebVitals();
