import React, { useState } from 'react';
import { Button } from "components/ui/button"; 
import { ChevronDown } from 'lucide-react';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogClose } from 'components/ui/dialog';
import { ScrollArea } from "components/ui/scroll-area";
import watsonxModels from "../../json/watsonxModels";  

const ModelSelectionModal = ({ finalModel, onModelSelect }) => {
    const [selectedModel, setSelectedModel] = useState("");
    const [recommendedTasks, setRecommendedTasks] = useState([]);
    const [maxTokens, setMaxTokens] = useState(null);  
    const [description, setDescription] = useState("");

    const handleModelSelect = (model) => {
        if (selectedModel === model.name) {
            resetModelDetails();
        } else {
            setSelectedModel(model.name);
            setRecommendedTasks(model.tasks);  
            setMaxTokens(model.max_tokens);  
            setDescription(model.description || ""); 
        }
    };

    const resetModelDetails = () => {
        setSelectedModel("");
        setRecommendedTasks([]); 
        setMaxTokens(null);  
        setDescription("");
    };

    const handleDone = () => {
        if (!selectedModel) {
            addNewNotification("Please select a model first.", "warning");
            return;
        }
        console.log(selectedModel)
        onModelSelect(selectedModel);
    };

    return (
        <Dialog onOpenChange={(isOpen) => isOpen && resetModelDetails()}>
            <DialogTrigger asChild>
                <Button variant="outline">
                    <ChevronDown className="mr-2 h-4 w-4" />
                    {finalModel || "Select a model"}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select a Model</DialogTitle>
                    <DialogDescription>
                        Choose a model and see recommended tasks.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex flex-col md:flex-row gap-4">
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="grid grid-cols-3 grid-rows-5 gap-4 p-4">
                                {watsonxModels.map((model) => (
                                    <Button
                                        key={model.name}
                                        variant={selectedModel === model.name ? "default" : "outline"}
                                        onClick={() => handleModelSelect(model)}
                                        className="justify-start h-auto text-left"
                                    >
                                        <div className="w-full">
                                            <div className="font-semibold w-full truncate">{model.name.split('/')[1]}</div>
                                            <div className="text-xs text-muted-foreground">{model.name.split('/')[0]}</div>
                                        </div>
                                    </Button>
                                ))}
                            </div>
                        </ScrollArea>
                        {selectedModel && (
                            <div className="md:w-1/3 p-4 bg-muted rounded-md h-96 max-h-96 overflow-y-auto">
                                <h2 className="mb-2 font-semibold text-xl">{selectedModel}</h2>
                                {description && (
                                    <div className="mb-4">
                                        <p>{description}</p>
                                    </div>
                                )}
                                {maxTokens && (
                                    <div>
                                        <h4 className="mb-2">
                                            <span className="font-semibold">Max Tokens:</span> {maxTokens}
                                        </h4>
                                    </div>
                                )}
                                {recommendedTasks.length > 0 && (
                                    <div>
                                        <h4 className="font-semibold">Recommended Tasks:</h4>
                                        <ul className="list-disc pl-5">
                                            {recommendedTasks.map((task, index) => (
                                                <li key={index}>{task}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end space-x-2">  
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        {selectedModel && (
                            <DialogTrigger asChild>
                                <Button variant="default" onClick={handleDone}>
                                    Select
                                </Button>
                            </DialogTrigger>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ModelSelectionModal;
