import React, { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai'
import styled, { keyframes } from 'styled-components';
import { Progress } from "../components/ui/progress"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const LoaderContainer = styled.div`
    animation: ${rotate} 1s infinite linear;
`;

const AppLoaderContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: rgba(238, 238, 238, 0.5);
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LoaderContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 100vh;
`


export const LoaderModal = ({...loadingProps}) => {

    return (
        <AppLoaderContainer>
            <LoaderContainer>
                <AiOutlineLoading size={40} {...loadingProps} />
            </LoaderContainer>
        </AppLoaderContainer>
    )
}

export const LoaderModalWithChild = ({ children }) => {

    return (
        <AppLoaderContainer>
            {children}
        </AppLoaderContainer>
    )
}


export function LoadingIndicator() {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                return prevProgress + 2.86; // Increment by 4 every second
            });
        }, 1000); // Update every 1 second (1000 milliseconds)
    
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="w-full max-w-md mx-auto space-y-4">
            <Progress value={progress} className="w-full" />
            <p className="text-center text-sm text-gray-500">
                Loading: {Math.round(progress)}%
            </p>
        </div>
    )
}