import { useState,useEffect, useContext } from "react";
import { Context } from "../context/GlobalState";
import { organizationService } from "api";
import { OrganizationContext } from "context/OrganizationContext";


export function useOrganization() {
    const [organizations, setOrganizations] = useState([])
    const [loading, setLoading] = useState(true)
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext);

    useEffect(() => {
        if (!selectedOrganization) {
            return setLoading(false)
        }
        organizationService.getOrganization(selectedOrganization._id)
        .then((res)=>{
            console.log('res: ', res);
            setOrganizations([res.data])
        }).catch(err=>{
            console.log(err)
            if (err.response?.status === 404) {
                addNewNotifcation('No Intent found for this User. Please check your configuration.', 'danger')
            }
        }).finally(() => setLoading(false))
    }, []) 
  
    return { organizations, setOrganizations, loading };
}
