import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"
import { PlusCircledIcon } from "@radix-ui/react-icons"

import { cn } from "../../lib/utils"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from "./tooltip"
import { ScrollArea } from "./scroll-area"

export function Combobox({ className, items, selectedValue, setter, buttonProps, defaultDisplayed = "Select Item" }) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(selectedValue)

  const listRef = React.useRef(null)

  React.useEffect(() => {
    setValue(selectedValue)
  }, [selectedValue])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between text-accent-foreground overflow-hidden"
        >
           <span className="truncate" title={value ? value?.name : defaultDisplayed ? defaultDisplayed : "Select Item"}>
            {value ? value?.name : defaultDisplayed ? defaultDisplayed : "Select Item"}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("w-[200px] p-0 z-[9999]", className)}>
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup>
              <ScrollArea className="max-h-60">
                {items?.map((item) => (
                  <CommandItem
                    key={item?._id}
                    value={item?.name}
                    onSelect={() => {
                      setValue(item)
                      setter && setter(item)
                      setOpen(false)
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value?.name === item?.name ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {item?.name}
                  </CommandItem>
                ))}
                </ScrollArea>
          </CommandGroup>
          <CommandSeparator />
        </Command>
        {/* <div className="absolute bottom-10 left-0 w-full h-4 flex items-center justify-center bg-gradient-to-t from-white pointer-events-none">
            <ChevronsUpDown className="h-4 w-4 opacity-50 bg-primary rounded-full text-white" />
        </div> */}
          {buttonProps && (
            <Button
            onClick={buttonProps.onClick}
            className="mt-3 w-[100%] text-primary bg-transparent p-1 hover:bg-primary hover:text-white border-none shadow-none size-sm"
            >
              <PlusCircledIcon className="mr-2 h-5 w-5" />
              {buttonProps.text}
            </Button>
          )}
      </PopoverContent>
    </Popover>
  )
}
