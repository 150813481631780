import { Card } from "components/ui/card";

export default function Component({ fileName, fileSize }) {
  return (
    <Card className="w-full max-w-sm bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-4">
        <div className="bg-muted rounded-md flex items-center justify-center p-3">
          <PaperclipIcon className="w-6 h-6 text-muted-foreground" />
        </div>
        <div className="flex-1">
          <h3 className="text-sm md:text-xl font-semibold">{fileName}</h3>
          <p className="text-muted-foreground text-sm">Attached file</p>
          {/* File size */}
            <p className="text-muted-foreground text-sm">{fileSize}</p>
        </div>
      </div>
    </Card>
  );
}

function PaperclipIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48" />
    </svg>
  );
}
