import { knowledgeBuilderHttpClient } from "api/clients/knowledgeBuilder.httpClient";

/**
 * Service class for performing Knowledge Builder Operations
 */
class KnowledgeBuilderService {

    /**
     * L1 Processing from COS
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>}
     */
    async processFromCOS(body) {
        const res = await knowledgeBuilderHttpClient.post(`/l1_processing_v2/process_from_cos`, body);
        return res;
    }


    async processFromCOSV2(body) {
        const res = await knowledgeBuilderHttpClient.post(`/l1_processing_v2/process_from_cos_v2`, body);
        return res;
    }
}

const kbService = new KnowledgeBuilderService();
export { kbService };
