import { Trash2 } from "lucide-react";
import { Button } from "components/ui/button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { LoaderSpinner } from "./LoaderSpinner";
import { buttonVariants } from "components/ui/button"

export default function DeleteDialog({label, loading, itemNames, onDelete, iconOnly=false, size=null}) {

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button
                    variant="destructive"
                    disabled={itemNames.length === 0}
                    size={size || 'default'}
                    className="flex gap-2"
                >
                    <Trash2 className="h-4 w-4" />
                    {iconOnly ? "" : label ? label : "Delete Item"}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        Are you sure?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="whitespace-pre-wrap">
                        This action cannot be undone. This will permanently delete the selected item(s):{"\n"} 
                        {itemNames.length > 1 ? 
                            itemNames.join(',\n') : 
                            itemNames[0]
                        }
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onDelete} className={buttonVariants({ variant: "destructive" })}>
                        {loading ? <LoaderSpinner/> : "Delete"}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
                        
    );
}
