import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ChatResponse, GetDocuments, GetConversation, GetUserAnnotation, ChatResponseChatpdfDemo, ChatResponseWatsonx, ChatResponseWatsonxDemo, ChatResponseTextEmbWatsonxDemo, GetClauses, ChatResponseWatsonxDemoCompare } from "./utils/httpRequests.ts";
import ChatX from "./components/ChatX.tsx";
import { BsSendFill } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import { RedButton } from "../../../../components/Buttons.jsx";
import { PiCaretUpBold } from "react-icons/pi";
import { PiCaretDownBold } from "react-icons/pi";
import { ChatContext } from "./context/chat-context.tsx";
import './conversation-legal.css'
import { SampleMessage } from "./context/sample-message.ts";
type ReferenceSchema = {
    pageNumber: number;
  };
  
interface Chat { 
    content: string;
    role: "user" | "assistant";
    date: number
    reference?: ReferenceSchema 
}
  
interface Message {
    role: "user" | "assistant";
    content: string;
  }

const DisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width:100%;
    gap:8px;

    max-height: 80vh;
    // height:100%;
    overflow:auto;
    
`
const DropdownLabelDivHorizontal = styled.div`
    display: flex;
    gap: 1rem;
`
const DropdownDiv = styled.div`
    display: flex;
    flex-direction: column;

`

const ChatBox = styled.div`
  border-radius: 10px;
  border-style: none;
  border-width: 1px;
  border-color: grey;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-grow:1;
`;

const TextAreaChat = styled.textarea`
  box-sizing: border-box;
  font-family: "Arial";
  border-style: none;
  background-color: #d6d6d6;
  resize: none;
  padding: 10px;
  border-radius: 10px;
  flex-grow: 1;
  outline: none;
  height: 100%;
`;

const SendButtonChat = styled.div`
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #b1092a;
  display: flex;
  &:hover {
    background-color: #e30b36;
    cursor: pointer;
  }
`;
const PDFView = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(0,0,0,0.1);
  border-style: none;
  border-width: 1px;
  border-color: grey;
  box-sizing: border-box;
  color: gray;
  flex-grow:1;
  
  
`;
const LabelHeader = styled.div`
  display: flex;
  flex-direction: column;

  gap:15px;
  
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;

`
const IframeView = styled.iframe`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-style: none;
`

const MiddleSection = styled.div`
  display: flex;
  height: 75%;
  gap:10px;
  width:100%;
`
const LeftPane = styled.div`
  display: flex;
  gap: 5px;
flex-shrink:0;
  flex-direction:column;
  width:50%;

`
const ExtractedParam = styled.div`
  
  bottom:50px;
  display: flex;
  gap: 15px;
  flex-direction:column;
  border-style: none;
  box-shadow: 0px 5px 10px rgb(0,0,0,0.1);
  border-width: 1px;
  padding:10px;
  border-radius: 10px;
  transition: height 0.1s ease;
  border-color: gray;
  height:fit-content;
  max-height: 18vh;

`

interface ExtractedParamHeader{
  $state:boolean
}
const ExtractedParamHeader = styled.div<ExtractedParamHeader>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & svg{
    transition: rotate 0.2s ease;
    cursor: pointer;
    rotate: ${({$state})=>$state ? "180deg":"0deg" };
  }
`
const ExtractedParamListCont = styled.div`
  display: flex;
  gap:8px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow:auto;
`
const ParamLabeled = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  & svg {
    &:hover {
      cursor: pointer;
      scale: 1.05;
    }
    &:active {
      scale: 0.9;
    }
  }
`;
type LabeledParameterType = {
    param: string;
    value: string;
  };
const ParamDataDisplay = styled.div`
    background-color: white;
    border-style: none;
    border-radius: 5px;
`;


function LabeledParameter({ param, value }: LabeledParameterType) {
    const copy = async (text: string) => {
      await navigator.clipboard.writeText(text);
    };
    return (
      <div style={{ fontSize: "0.9em" }}>
        <label>{param}:</label>
        <ParamLabeled>
          <ParamDataDisplay style={{ width: "80%" }}>{value}</ParamDataDisplay>
          <MdContentCopy onClick={() => copy(value)} />
        </ParamLabeled>
      </div>
    );
  }
  type DemoModes = "comparison"|"freeChat";
  type DemoClause = {name:string, clause:string};
export default function ConversationDemoV2 () {
    const chatRef = useRef<HTMLTextAreaElement>(document.createElement("textarea"));
    const [mode, setMode] = useState<DemoModes|string>('comparison')
    const [messages, setMessages] = useState<Chat[]>([]);
    const [files, setFiles] = useState([]);
    const [documentRepoLoading, setDocumentRepoLoading] = useState(false)
    const [selectedDocument, setSelectedDocument] = useState({
      name: "",
      sourceId: "",
      documentId: {
        methodA: "",
        methodB: "",
        methodC: "",
      },
      ragMethod: "",
      type: "",
      metadata: [],
      url:""
    });
    const [selectedRAGMethod,setSelectedRAGMethod] = useState("methodA")
    const [clauses, setClauses] = useState([])
    const [clause1, setClause1] = useState<DemoClause|undefined>({name:'',clause:''})
    const [clause2, setClause2] = useState<DemoClause|undefined>({name:'',clause:''})
    const [selectedSample, setSelectedSample] = useState('')

    const handleGetFiles = () => {
        setDocumentRepoLoading(true)
        GetDocuments("PROJECTXDEMO", "a237c735-75ca-8fe0-0000-018eaf09897b") //PROJECTXDEMO-projectId, actual watson discovery demo collection
          .then((file) => {
            setFiles(file.data);
            setDocumentRepoLoading(false);
          })
          .catch(() => {
            console.log("error occured")
            setDocumentRepoLoading(false)
          });
      };
      useEffect(()=>{
        console.log(selectedSample)
        chatRef.current.value = selectedSample
      },[selectedSample])
    const handleGetClauses = () => {
        // setDocumentRepoLoading(true)
        GetClauses() //PROJECTXDEMO-projectId, actual watson discovery demo collection
          .then((clauses) => {
            setClauses(clauses.data);
            // setDocumentRepoLoading(false);
          })
          .catch(() => {
            console.log("error occured")
            // setDocumentRepoLoading(false)
          });
      };
      const sendMessageCompare = () => {
        if (chatRef.current.value) {
        console.log("Send")

        setMessages((prevArr:any) => [
          { content: chatRef.current.value, role: "user" },
          ...prevArr,
        ]);

        ChatResponseWatsonxDemoCompare({
          messages: [...messages.map((msg)=>({
              role: msg.role,
              content: msg.content
          })).reverse().slice(-5), { content: chatRef.current.value, role: "user" }],
          projectId: "b36df648-68c4-4cb7-9150-8d6fed0a4fca",
          collectionId: ["a237c735-75ca-8fe0-0000-018eaf09897b"],
          documentId: selectedDocument.documentId.methodA,
          passages: [clause1,clause2]
        }).then((reply) =>
          setMessages((prevArr:any) => [
            { content: reply.data.reply, role: "assistant" },
            ...prevArr,
          ])
        )
        .catch((e) => console.log("Not sent", e));
      }
      }
      const sendMessage = () => {
        
        console.log(selectedDocument.sourceId)
        console.log(selectedDocument)        
        if (chatRef.current.value && selectedDocument.documentId[selectedRAGMethod]) {
            console.log("Send")
          setMessages((prevArr:any) => {
    
            return [
            { content: chatRef.current.value, role: "user" },
            ...prevArr,
          ]});
          switch(selectedRAGMethod){
            case "methodA": 
              ChatResponseWatsonxDemo({
                messages: [...messages.map((msg)=>({
                    role: msg.role,
                    content: msg.content
                })).reverse().slice(-5), { content: chatRef.current.value, role: "user" }],
                projectId: "b36df648-68c4-4cb7-9150-8d6fed0a4fca",
                collectionId: ["a237c735-75ca-8fe0-0000-018eaf09897b"],
                documentId: selectedDocument.documentId.methodA,
              }).then((reply) =>
                setMessages((prevArr:any) => [
                  { content: reply.data.reply, role: "assistant" },
                  ...prevArr,
                ])
              )
              .catch((e) => console.log("Not sent", e));
              break;
            
            case "methodB": 
            console.log("chose B")
            ChatResponseTextEmbWatsonxDemo({
                messages: [...messages.map((msg)=>({
                    role: msg.role,
                    content: msg.content
                })).reverse().slice(-5), { content: chatRef.current.value, role: "user" }],
                documentId: selectedDocument.documentId.methodB,
              })
                .then((reply) =>
                  setMessages((prevArr:any) => [
                    { content: reply.data.reply, role: "assistant" },
                    ...prevArr,
                  ])
                )
                .catch((e) => console.log("Not sent", e));
              break
          }
          
        } 
      };

      const handleKeyPress = (event: any) => {
        if (event.keyCode == 13 && !event.shiftKey) {
          // prevent default behavior
          event.preventDefault();
          if(mode === 'freeChat'){
            sendMessage();
          } else {
            sendMessageCompare()
          }
        }
      };

      useEffect(()=>{
        handleGetFiles()
        handleGetClauses()
      },[])
      useEffect(()=>{
        setSelectedDocument({
          name: "",
          sourceId: "",
          documentId: {
            methodA: "",
            methodB: "",
            methodC: "",
          },
          ragMethod: "",
          type: "",
          metadata: [],
          url:""
        })
        console.log(mode)
        setClause1({name: '', clause:''})
        setClause2({name: '', clause:''})
      },[mode])

      useEffect(()=>{
        chatRef.current.value =""
      },[messages])
      useEffect(()=>{
        setMessages([])
      },[clause1,clause2])

    return (
        <DisplayContainer>
            <LabelHeader>
                <DropdownDiv>
                  <div>
                    <label className="conversation-dropdown-label">Select Mode:</label>
                    <select className="response-select conversation-width-fill"  name="Modes" onChange={(event) => {
                      console.log(event.target.value)
                        setMode(event.target.value)
                        setMessages([])
                        }}>
                        {/* {files.map((file:any)=>
                            <option key={file._id} value={file.documentId[selectedRAGMethod]}>
                                {file.name}
                            </option>)} */}
                        <option value="comparison">
                          Comparison, Generation with two clauses
                        </option>
                        <option  value="freeChat">
                          Free Chat Document
                        </option>
                  
                    </select>
                    {mode === 'freeChat' ? 
                    <>
                    <label className="conversation-dropdown-label">Select Document:</label>
                    <select className="response-select conversation-width-fill" defaultValue="selectMode"  name="Modes" onChange={(event) => {
                      console.log(event.target.value)
                        let selected = files.find((file:any)=>file.documentId[selectedRAGMethod] == event.target.value) || {
                            name: "",
                            sourceId: "",
                            documentId: {
                              methodA: "",
                              methodB: "",
                              methodC: "",
                            },
                            ragMethod: "",
                            type: "",
                            metadata: [],
                            url:""
                          }
                        setSelectedDocument({
                            name: selected.name,
                            sourceId: selected.sourceId,
                            documentId: selected.documentId,
                            ragMethod: selected.ragMethod,
                            type: selected.type,
                            metadata: selected.metadata,
                            url:selected.url
                        })
                        setMessages([])
                        }}>
                        <option disabled value="selectMode">
                             Select a Document...
                        </option>
                        {files.map((file:any)=>
                            <option key={file._id} value={file.documentId[selectedRAGMethod]}>
                                {file.name}
                            </option>)}
                    </select>
                    </>: null}
                  </div>
                  {mode === 'comparison' ? <DropdownLabelDivHorizontal>
                    <div className="conversation-dropdown-horizontal">
                      <label className="conversation-dropdown-label">Select Clause 1:</label>
                      <select  className="response-select conversation-width-fill" defaultValue="Select a clause..."  name="Clause1" onChange={(event) => {
                          setClause1(clauses.find((passage:any)=> passage._id === event.target.value))

                          }}>
                          <option disabled value="Select a clause...">
                          Select a clause...
                          </option>
                          {clauses.map((item:any)=><option value={item._id}>
                          {item.name}
                          </option>)}
                      </select>
                    </div>
                    <div className="conversation-dropdown-horizontal">
                      <label className="conversation-dropdown-label">Select Clause 2:</label>
                      <select className="response-select conversation-width-fill" defaultValue="Select a clause..."  name="Clause2" onChange={(event) => {
                          // setSelectedRAGMethod(event.target.value)
                          setClause2(clauses.find((passage:any)=> passage._id === event.target.value))
                          }}>
                          <option disabled value="Select a clause...">
                          Select a clause...
                          </option>
                          {clauses.map((item:any)=><option value={item._id}>
                          {item.name}
                          </option>)}
                      </select>
                    </div>
                  </DropdownLabelDivHorizontal> : null}
                </DropdownDiv>
            </LabelHeader>
            <MiddleSection>
              <LeftPane>
                  {mode === 'comparison' ? <>
                    <div className="conversation-clause-container conversation-element-shadow"><span className="conversation-dropdown-label">Clause 1</span>
                    <div className="conversation-clause-view">
                      <pre className="conversation-clause-pre">{clause1?.clause.replace(/\\n/g, "\n").replace(/\\r/g, "\r")}</pre>
                    </div>
                    </div>
                    <div className="conversation-clause-container conversation-element-shadow"><span className="conversation-dropdown-label">Clause 2</span>
                    <div className="conversation-clause-view">
                      <pre className="conversation-clause-pre">{clause2?.clause.replace(/\\n/g, "\n").replace(/\\r/g, "\r")}</pre>
                    </div>
                    </div>
                  </> :
                  <>
                    <PDFView>
                  {selectedDocument.url ? <IframeView src={selectedDocument.url}></IframeView> : 
                  <>
                      <center>
                      <i>Select a document to view the PDF here.</i>
                      </center>
                  </>}
                  </PDFView>
                  </>}
              </LeftPane>
              <SampleMessage.Provider value={{selectedSample, setSelectedSample}}>
              <ChatX
                  withSampleMessage={mode === 'comparison' ? true : false}
                  sendButton={false}
                  sendMessage={mode === 'freeChat' ? sendMessage : sendMessageCompare}
                  selectedDocument={selectedDocument}
                  handleKeyPress={handleKeyPress}
                  chatRef={chatRef}
                  MessageSent={messages}
                  ChatName={selectedDocument.name || ""}
              />
              </SampleMessage.Provider>
            </MiddleSection>
            
          {/* </ChatView> */}

          <ActionButtons>
            <RedButton onClick={mode === 'freeChat' ? sendMessage : sendMessageCompare}>Send</RedButton>
          </ActionButtons>
        </DisplayContainer>

    )
}