import React, { useEffect, useState } from 'react'
import { Button } from './button'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'


export default function SideMenu({ section }) {

    const [isOpen, setIsOpen] = useState(true)
    const route = useLocation()

    const { pathname } = route

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth < 760) {
                setIsOpen(false);
            }
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Call handler initially in case the screen is already less than 760
        handleResize();
    
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getRoute = (path) => {
        if (!path.includes('/')) {
            return path === pathname.split('/').at(-1)
        } else {
            return path === pathname
        }
    }
    
    return (
        <div className={`bg-gray-900 text-white ${isOpen ? 'w-[50px] md:w-64 md:min-w-64 p-6' : 'w-[50px] py-6 px-4'} transition-all flex flex-col gap-6`}>
            <nav className="flex flex-col gap-3">
                <span className='font-bold mb-3 text-[15px] truncate'> {isOpen ? section.name || '-' : '-'} </span>
                {section.list.map((item) => (
                    <Link key={item.path} to={item.path} className={`flex items-center gap-2 mb-2 hover:text-white ${getRoute(item.path) ? 'text-white' : 'text-gray-400'}`}>
                        {item.icon}
                        {isOpen && <span>{item.name}</span>}
                    </Link>
                ))}
            </nav>

            <div className='md:flex w-full justify-end mt-auto hidden'>
                <Button size='icon' onClick={() => setIsOpen(!isOpen)} className='text-white hover:bg-[transparent] hover:text-gray-300' variant='ghost'>
                    {isOpen ? <ArrowLeft /> : <ArrowRight /> }
                </Button>
            </div>
        </div>
    )
}
