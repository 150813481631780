// src/components/Header.js

import React, { useContext, useState, useEffect } from 'react';
import '../globals.css'
import './styles.css';
import Profile from './Profile';
import { Context } from '../context/GlobalState';
import coolriots from '../assets/coolriots.png';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import DashboardSubHeader from '../components/DashboardSubHeader';
import OrgDropdown from '../components/OrgDropdown';
import { useNavigate } from 'react-router-dom';
import { authService } from 'api'

export default function Header() {
    const MAXIMUM_WIDTH = 900;

    const { isAuth } = useContext(Context);
    const [showMenu, setShowMenu] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const navigate = useNavigate();

    const toggleMenu = () => { 
        setShowMenu(!showMenu);
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    // Refactor: this function to use the GlobalState context
    const handleLogOut = () => {
        authService.logout()
          .then((res) => {
              console.log(res);
            //   localStorage.removeItem('selectedSubOrg');
            console.log('selectedSubOrg: ', localStorage.getItem('selectedSubOrg'));
            navigate('/auth/login');
          }).catch((error) => {
              console.log(error);
          })
    };

    return (
        <header className="header-container border-b">
            <div className='flex gap-3 items-center'>
                <div onClick={() => (window.location = '/organizations')} className="header-icon-container">
                    <img src={coolriots} alt="coolriots" />
                </div>
                <div className='hidden md:block'>
                    {isAuth && <OrgDropdown />}
                </div>
            </div>


            {!window.location.pathname.includes('secure') && (
                <div className={`nav-container ${showMenu ? 'show-menu' : ''}`}>
                    
                    <div className='md:hidden block'>
                        {isAuth && <OrgDropdown />}
                    </div>
                    {isAuth && <DashboardSubHeader />}

                    {windowWidth < MAXIMUM_WIDTH && isAuth ? (
                        <>
                            <span onClick={handleLogOut}>Logout</span>
                        </>
                    ) : (
                        isAuth ? <Profile /> : <span><Link to="/auth/login">Login</Link></span>
                    )}
                </div>
            )}
            
            <div className="burger-icon" onClick={toggleMenu}>
                <GiHamburgerMenu />
            </div>
        </header>
    );
}