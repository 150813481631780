// TextToSpeechForm.js
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { Context } from 'context/GlobalState';
import { OrganizationContext } from "context/OrganizationContext";
import { TextToSpeechService } from 'api';
import UploadedFileOverviewCard from './UploadedFileOverviewCard.jsx';
import TTSOperationHistory from './TTSOperationHistory.jsx';

import { LoaderSpinner } from "components/LoaderSpinner.jsx";
import { BsArrowCounterclockwise, BsDownload, BsPauseFill, BsPlayFill } from 'react-icons/bs';
import { UploadIcon } from "@radix-ui/react-icons";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Label } from 'components/ui/label';
import { Textarea } from "components/ui/textarea";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import { Button } from "components/ui/button";


// Constants
const TTS_FORM_MODES = {
    Standard: 'Standard',
    Instruction: 'Instruction'
};
const TTS_FORM_MODES_HINT = {
    Standard: 'Input your own text to convert to audio',
    Instruction: 'Drop a text file to convert to audio'
};

// DO NOT CHANGE THE VALUES OF ACCEPTABLE_FILE_TYPES - IT IS USED IN THE BACKEND
const ACCEPTABLE_FILE_TYPES = ['DOC', 'PDF', 'TXT', 'DOCX'];
const DOWNLOADABLE_AUDIO_FILE_NAME = 'your_audio_file.mp3';

const TextToSpeechForm = ({ initialTextToRead = '', onGenerate }) => {
    // State
    const [formMode, setFormMode] = useState(null);
    const [textToRead, setTextToRead] = useState(initialTextToRead);
    const [initialText, setInitialText] = useState(initialTextToRead);
    const [audioUrl, setAudioUrl] = useState('');
    const [audioGenerated, setAudioGenerated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ttsVoice, setTtsVoice] = useState('');
    const [listOfVoices, setListOfVoices] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [generateClicked, setGenerateClicked] = useState(false);
    const [audio, setAudio] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadedFileSize, setUploadedFileSize] = useState('');
    const [inputFiles, setInputFiles] = useState(null);

    // Context
    const { addNewNotifcation, user } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext);
    const { subOragID } = useParams()
    
    // Services
    const textToSpeechService = new TextToSpeechService(user, selectedOrganization?._id);

    // Effects
    useEffect(() => {
        retriveVoices(); // TIP: You need to have the BEX-FEO API running to get the voices otherwise you will receive an axios error
    }, []);

    useEffect(() => {
        if (audioUrl) {
            const newAudio = new Audio(audioUrl);
            setAudio(newAudio);
            newAudio.onended = () => setIsPlaying(false);
            return () => {
                newAudio.pause();
                setAudio(null);
            };
        }
    }, [audioUrl]);

    // Actions
    const handlePlay = () => {
        if (!audio) return;
        if (isPlaying) {
            audio.pause();
            setIsPlaying(false);
        } else if (audioUrl) {
            audio.play();
            setIsPlaying(true);
        }
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        
        setGenerateClicked(true);
        setAudioUrl(null);
        setIsLoading(true);
        setInitialText(textToRead);

        if (textToRead) {
            if (isInstructionForm && inputFiles) {
                generateAudioFromInstruction(inputFiles);
            } else {
                generateAudioFromText(textToRead);
            }
        } else {
            addNewNotifcation('Please input text or upload a file.', 'danger');
            setIsLoading(false);
        }
    };

    const handleSelectVoiceChange = (value) => {
        setTtsVoice(value);
        setAudioUrl(null);
        setAudioGenerated(false);
    };

    const handleInputChange = (event) => {
        setTextToRead(event.target.value);
    };

    const handleDownload = () => {
        if (audioUrl) {
            const link = document.createElement('a');
            link.href = audioUrl;
            link.setAttribute('download', DOWNLOADABLE_AUDIO_FILE_NAME);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    const handleFormModeChange = (value) => {
        resetForm();
        setFormMode(value);
        
    };

    const handleUnableToProcessRequest = () => {
        resetForm();
        addNewNotifcation('Unable to process the request. Please try again.', 'danger');
    };

    const handleUnsupportedFileType = () => {
        addNewNotifcation('Unsupported file type. Please upload one of the supported file types.', 'danger');
    };

    // Common logic for generating audio
    const generateAudio = async (generateFunction, ...params) => {
        try {
            setIsLoading(true);
            const audioURL = await generateFunction(...params);
            setAudioUrl(audioURL);
            setAudioGenerated(true);
            setIsLoading(false);
            if (onGenerate) onGenerate(audioURL);
        } catch (error) {
            console.error('error:', error);
            handleUnableToProcessRequest();
            setIsLoading(false);
        }
    };

    const generateAudioFromText = (text) => {
        generateAudio(textToSpeechService.textToSpeech.bind(textToSpeechService), text, ttsVoice);
    };

    const generateAudioFromInstruction = (file) => {
        generateAudio(textToSpeechService.instructionToSpeech.bind(textToSpeechService), file, ttsVoice, subOragID);
    };

    
    const retriveVoices = async () => {
        try {
            const listOfVoices = await textToSpeechService.getListOfVoices();
            setListOfVoices(listOfVoices);
        } catch (error) {
            handleUnableToProcessRequest();
        }
    };

    const resetForm = () => {
        setTextToRead('');
        setAudioUrl('');
        setAudioGenerated(false);
        setIsPlaying(false);
        setGenerateClicked(false);
        setInitialText('');
        setIsLoading(false);
        setInputFiles(null);
        setUploadedFileName('');
        setUploadedFileSize('');
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const fileText = event.target.result;
            setTextToRead(fileText);
        };

        const fileType = file.name.split('.').pop().toUpperCase();
        if (ACCEPTABLE_FILE_TYPES.includes(fileType)) {
            reader.readAsText(file);
            setInputFiles(file);
            setUploadedFileName(file.name);
            setUploadedFileSize(file.size);
        } else {
            handleUnsupportedFileType();
        }
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({ onDrop });

    const extractVoiceName = (voiceName) => {
        const namePart = voiceName.substring(6);
        const [name, type] = namePart.split(/(?=[A-Z])/);
        return type === 'Voice' ? `${name} (V1)` : `${name} (${type})`;
    };

    // Getters
    const isStandardForm = formMode === TTS_FORM_MODES.Standard;
    const isInstructionForm = formMode === TTS_FORM_MODES.Instruction;
    const isResetDisabled = textToRead === '' && ttsVoice === '';
    const isPlayDisabled = (generateClicked && textToRead !== initialText);

    const sortedVoices = Array.isArray(listOfVoices) ? listOfVoices.slice()
    .map((voice) => {
        const displayName = extractVoiceName(voice.name).replace(/V(\d)/, 'Ver. $1');
        return {
            displayName,
            originalName: voice.name
        };
    })
    .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
    }) : []; // Default to an empty array if listOfVoices is not an array

    const supportedFilesFormatted = ACCEPTABLE_FILE_TYPES.join(', ').toLowerCase();
    const formattedFileSize = uploadedFileSize ? `${(uploadedFileSize / 1024).toFixed(2)} KB` : '';

        
    return (
        <>
            <Tabs defaultValue="form" className='w-full'>
                <TabsList className="grid w-full md:w-full md:max-w-lg lg:max-w-4xl grid-cols-2 mx-auto">
                    <TabsTrigger value="form">
                        Form
                    </TabsTrigger>

                    <TabsTrigger value="history">
                        History
                    </TabsTrigger>
                </TabsList>

                <TabsContent value="form">
                    <div className="flex flex-col w-full md:w-full md:max-w-lg lg:max-w-4xl mx-auto p-4 m-4 bg-card rounded-lg shadow-lg">
                        <div className="text-xl sm:text-2xl font-bold mb-4">
                            Text to Speech
                        </div>
                        
                        <div className="space-y-4">
                            {/* 0: Select Form Type */}
                            <div>
                                <Label htmlFor="select-form-type">Type</Label>
                                <Select onValueChange={handleFormModeChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Choose a type..." />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {Object.values(TTS_FORM_MODES).map((formType, index) => (
                                            <SelectItem key={index} value={formType}>
                                                {formType}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <p className="text-muted-foreground text-sm mt-2">
                                    {TTS_FORM_MODES_HINT[formMode]}
                                </p>
                            </div>
                    
                            {/* 1: Select Voice */}
                            <div>
                                <Label htmlFor="select-voice">Select Voice</Label>
                                <Select onValueChange={handleSelectVoiceChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Choose an AI voice..." />
                                    </SelectTrigger>
                                    <SelectContent>
                                    {sortedVoices.map((voice, index) => (
                                            <SelectItem key={index} value={voice.originalName}>
                                                {voice.displayName}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            {/* 2 : File Dropzone */}
                            { isInstructionForm && (
                                <div className='space-y-2'>
                                    <Label htmlFor="text-file">
                                        Drop Text File
                                    </Label>
                    
                                    <div
                                        {...(isLoading ? {} : getRootProps())}
                                        className="flex items-center justify-center w-full border-2 hover:cursor-pointer
                                        border-muted border-dashed rounded-lg px-6 py-10 mt-1">
                                        <div className="text-center">
                                            { !uploadedFileName && (
                                                <>
                                                    <UploadIcon
                                                        className="w-12 h-12 text-muted-foreground mb-2 mx-auto" />
                                                    <p className="text-sm text-muted-foreground">
                                                        Drag and drop your text file here, or click to select a file
                                                    </p>
                    
                                                    <p className="text-xs text-muted-foreground">
                                                        Supported Formats: { supportedFilesFormatted }
                                                    </p>
                                                    <input
                                                        {...getInputProps()}
                                                        />
                                                    {isDragActive ? <p>Drop the files here ...</p> : null}
                                                </>
                                            )}
                                            {uploadedFileName &&
                                                <UploadedFileOverviewCard
                                                    fileName={uploadedFileName}
                                                    fileSize={formattedFileSize} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* 3: Textarea Input */}
                            {  isStandardForm && (
                                <div className="space-y-1">
                                    <Label htmlFor="textarea">
                                        Input your own text to convert to audio
                                    </Label>
                                    <Textarea
                                        id="textarea"
                                        rows={5}
                                        value={textToRead}
                                        onChange={handleInputChange}
                                        placeholder="Type your text here..."
                                    />
                                </div>
                            )}
                        </div>
                        {/* 4: Buttons */}
                        <div className='flex md:flex-row flex-col justify-between'>
                            <div className="flex md:flex-row flex-col md:justify-end sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
                                <Button
                                    onClick={handlePlay}
                                    disabled={isPlayDisabled || !audioUrl}>
                                    {isPlaying ? (
                                        <>
                                            <BsPauseFill className="mr-2 h-4 w-4" />
                                            Pause
                                        </>
                                    ) : (
                                        <>
                                            <BsPlayFill className="mr-2 h-4 w-4" />
                                            Play
                                        </>
                                    )}
                                </Button>
                                <Button
                                    onClick={handleDownload}
                                    variant="outline"
                                    disabled={!audioGenerated || !audioUrl}>
                                    <BsDownload className="mr-2 h-4 w-4" />
                                    Download
                                </Button>
                            </div>
                    
                            <div className="flex md:flex-row flex-col md:justify-end sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
                                <Button
                                    onClick={handleOnSubmit}
                                    disabled={isLoading || (!textToRead && !inputFiles)}>
                                    {isLoading ? (
                                        <>
                                            <LoaderSpinner className="mr-2" />
                                            Processing...
                                        </>
                                    ) : (
                                        'Generate'
                                    )}
                                </Button>
                    
                                <Button
                                    onClick={resetForm}
                                    variant="secondary"
                                    disabled={isResetDisabled || isLoading}>
                                    <BsArrowCounterclockwise
                                        className="mr-2 h-4 w-4" />
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </div>
                </TabsContent>

                <TabsContent value="history">
                    <TTSOperationHistory />
                </TabsContent>
            </Tabs>
        </>
    );
};

export default TextToSpeechForm;
