import axios from "axios";

const REACT_ENV = process.env.REACT_APP_MODE || 'development'
console.log('react env: ', REACT_ENV);
export const server = REACT_ENV === 'development' ? 'http://localhost:4000/cm' : REACT_ENV === 'staging' ? 'https://stgbex.coolriots.ai/cm' : 'https://bex.coolriots.ai/cm'

export const serverO = REACT_ENV === 'development' ? 'https://app.coolriots.ai' : 'https://app.coolriots.ai'
export const serverOSetup = REACT_ENV === 'development' ? 'http://localhost:3001/project-o-server/api' : REACT_ENV === 'staging' ? 'https://stgbex.coolriots.ai/project-o-server/api' : 'https://bex.coolriots.ai/project-o-server/api'

export const serverY = REACT_ENV === 'development' ? 'http://localhost:3000' : 'https://bex.coolriots.ai'

export const serverX = REACT_ENV === 'development' ? 'http://localhost:3002' : 'https://bex.coolriots.ai'

export const httpRequest = axios.create({
    withCredentials: true,
    baseURL: server
});

export const httpRequestO = axios.create({
    withCredentials: true,
    baseURL: serverO
});

export const httpRequestOSetup = axios.create({
    withCredentials: true,
    baseURL: serverOSetup
});

export const httpRequestY = axios.create({
    withCredentials: true,
    baseURL: serverY
});
export const httpRequestX = axios.create({
    withCredentials: true,
    baseURL: serverX
});

export const fastApiCall = async (url, method, bodyObj = {}, headers = {}) => {
  try {
    const res = await httpRequest.post(
      "/bex-platform/opcodes-request",
      {
        url: `${serverO}${url}`,
        method: method,
        bodyObj: bodyObj,
      },
      { headers: headers }
    );
    return res;
  } catch (err) {
    console.log("error: ", err);
    throw new Error(err);
  }
};

