import React, { useContext, useEffect, useRef, useState } from 'react'
import './users-styles.css'
import { httpRequest } from '../../../utils/httpsRequest'
import { AppLoader, LoaderContainerDiv, LoaderSpinner } from '../../../components/LoaderSpinner'
import { PopUpModal } from '../../Manager/EntityIntent/components/PopUpModal'
import { Container, PrimaryInput } from '../../../components/FormInput'
import { BiSolidHide, BiSolidShow } from 'react-icons/bi'
import { ContainerPass } from '../../Signup'
import NewStyledTable from '../../../components/ui/StyledTable'
import { BsPersonFillAdd } from 'react-icons/bs'
import AlertModal from '../../../components/Alertmodal'
import { Context } from '../../../context/GlobalState'

export default function Users() {

    const { addNewNotifcation } = useContext(Context)
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    // delete user
    const [openDelModal, setOpenDelModal] = useState(false)
    const [deletedUser, setDeletedUser] = useState({})
    const [delUserLoading, setDelUserLoading] = useState(false)
    
    // add user
    const [addUserModal, setAddUserModal] = useState(false)
    const [addKey, setAddKey] = useState(0)

    useEffect(() => {
        httpRequest.get('admin/get-users')
        .then((res) => {
            console.log('res: ', res);
            setUsers(res.data?.map((item) => ({...item, id: item._id})))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <LoaderContainerDiv> <AppLoader size={50} /> </LoaderContainerDiv>;
    }

    const OnAddUserClick = () => {
        setAddUserModal(true)

        // to ensure compoent rerender
        setAddKey(prev => prev+1)
    }

    const handleDelete = (id) => {
        setDeletedUser(users.find(item => item._id === id))
        setOpenDelModal(true)
    }

    const handleDeleteUser = () => {

        setDelUserLoading(true)
        httpRequest.delete(`/admin/users/delete-user/${deletedUser._id}`)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('User deleted successfully', 'success')
            setUsers(users.filter((item) => item._id !== deletedUser._id))
            setOpenDelModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setDelUserLoading(false)
        })
    }

    const userRowActions = [
        {'name': 'Remove', 'onclick': handleDelete},
    ]

    const userTableActions = [
        {'name': 'Add New', 'icon': <BsPersonFillAdd size={18} />, 'onclick': OnAddUserClick}
    ]


    return (
        <div className='admin-users-container'>
            <div className='admin-users-header'>
                <span>Users</span>
            <AddNewUserModal addUserModal={addUserModal} setAddUserModal={setAddUserModal} key={addKey} />
            </div>
            {/* delete user alert */}
            <AlertModal setopenModal={setOpenDelModal} openModal={openDelModal} loading={delUserLoading} onDelete={handleDeleteUser} />

            <div>
                <NewStyledTable
                    columns={[{ field: 'name', headerName: 'Name'}, { field: 'email', headerName: 'Email'}]}
                    data={users}
                    multiSelect={false}
                    rowActions={userRowActions}
                    tableActions={userTableActions}
                />
            </div>
        </div>

    )
}

const AddNewUserModal = ({ addUserModal, setAddUserModal }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const nameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    useEffect(() => {
        passwordRef.current.value = 'coolriotsbex123'
    }, [])

    const handleAddUser = () => {

    }

    return (
        // <div>
        <PopUpModal setOpenModal={setAddUserModal} openModal={addUserModal} text={'Add New User'}>
            {/* <AddAdminForm userInput={userInput} setUserInput={setUserInput} isModalOpen={openAddAdminModal}/> */}
            <form>
                <Container labelName='Name'>
                    <PrimaryInput ref={nameRef} type="text" name='name' />
                </Container>
                <Container labelName='Email'>
                    <PrimaryInput ref={emailRef} type="email" name='email' />
                </Container>
                <ContainerPass labelName='Password'>
                    <PrimaryInput className='pass-input' ref={passwordRef}name='password'
                    type={showPassword ? 'text' : 'password'}
                    />
                        <span
                            className='password-toggle'
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <BiSolidHide size={25} /> : <BiSolidShow size={25} />}
                        </span>
                </ContainerPass>
            </form>
            <button disabled={btnLoading} className='secure-admins-permissions-create-btn' onClick={handleAddUser}>
                {btnLoading ? <LoaderSpinner /> : 'Save Admin'}
            </button>
        </PopUpModal>
        // </div>
    )
}