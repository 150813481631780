import React from 'react';
import { HOProvider } from './HotelOpsContext';
import HotelOpDemo from './Components/HotelOpDemo';

export default function HrAssistant() {

    return (
        <div className='w-full h-[calc(screen - 60px)]'>
            <HOProvider>
                <HotelOpDemo/>
            </HOProvider>
        </div>
    )
}