import React, { useContext, useRef, useState } from 'react'
import './reset-password-styles.css'
import { Context } from '../../context/GlobalState'
import ResetPasswordForm from './ResetPasswordForm'
import { authService } from 'api'

export default function ResetPassword() {

    const [loading, setLoading] = useState(false)
    const { addNewNotifcation } = useContext(Context);
    
    const resetPasswordFormRef = useRef()

    const handleOnSubmit = (e) => {
        e.preventDefault()

        const emailRef = resetPasswordFormRef.current.getEmailRef();

        setLoading(true)
        authService.forgotPassword(emailRef.value)
        .then(async (res) => {
            console.log(res);
            addNewNotifcation('Reset password link has been sent to your email.', 'success')
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status == 404) {
                addNewNotifcation('This email is not registered yet. Please check the entered email.', 'danger')
            } else {
                addNewNotifcation("Could not reset your password. Please check all your entries.", 'danger')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='reset-password-container'>
            <ResetPasswordForm ref={resetPasswordFormRef} loading={loading} onSubmit={handleOnSubmit} />
        </div>
    )
}
