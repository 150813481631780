import { Building2, FileSliders, Settings, Users } from "lucide-react";

export default {
    name: 'Organization',
    list: [
        {name: 'Sub Organizations', path: 'sub', icon: <Building2 className="h-5 w-5" />},
        {name: 'Members', path: 'members', icon: <Users className="h-5 w-5" />},
        {name: 'Configurations', path: 'configurations', icon: <FileSliders className="h-5 w-5" />},
        {name: 'Settings', path: 'settings', icon: <Settings className="h-5 w-5" />},
    ]
}