import { useEffect, useState, useContext } from "react";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import { Button } from "components/ui/button";
import InputValidation from "components/InputValidation";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import handler from "./OpCodeBuilderHandler";
import { SubOrgContext } from "context/SubOrganizationContext";

function formatToTitleCase(str) {
    return str
        .replace(/_/g, ' ')           // Replace underscores with spaces
        .replace(/\w\S*/g, function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });                           // Convert to Title Case
}

export default function StepNonLLMAPI({ group, index, inputGroups, setInputGroups, getPreviousOutputs }) {
    const [apis, setApis] = useState([]);
    const [functionRegistry, setFunctionRegistry] = useState({});
    const [selectedFunctionProps, setSelectedFunctionProps] = useState({});
    const { project } = useContext(SubOrgContext);

    useEffect(() => {
        // Fetch the list of functions and their properties from FUNCTION_REGISTRY
        const fetchFunctions = async () => {
            try {
                const res = await opCodeService.getApiRegistry();
                if (res.data && typeof res.data === 'object') {
                    setFunctionRegistry(res.data);
                    setApis(Object.keys(res.data));

                    // Set properties if a function is already selected
                    if (group?.registry_key) {
                        setSelectedFunctionProps(res.data[group.registry_key]?.properties || {});
                    }
                }
            } catch (err) {
                console.error("Error fetching functions:", err);
            }
        };

        fetchFunctions();
    }, [group.registry_key]);

    const firstStep = inputGroups[0];
    let firstStepDynamicInput = [];
    if (firstStep?.step_type === "LLM") {
        firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
            value,
            label: "Step 1 Input",
        })) || [];
    } else if (firstStep?.step_type === "Non-LLM" && firstStep.dynamic_inputs?.query_text) {
        firstStepDynamicInput = [
            {
                value: firstStep.dynamic_inputs.query_text,
                label: "Step 1 Input",
            },
        ];
    }

    // Combine previous outputs with the first step's dynamic input
    const inputSelection = [
        ...firstStepDynamicInput,
        ...getPreviousOutputs(index),
    ];

    const handleInputChange = (field, value) => {
        const newInputGroups = [...inputGroups];
        if (field === "registry_key") {
            newInputGroups[index].registry_key = value;
            setSelectedFunctionProps(functionRegistry[value]?.properties || {});
        } else if (field === "output_var") {
            newInputGroups[index].output_var = value;
        } else if (selectedFunctionProps[field] === "fixed") {
            if (field === "top_k" || field === "top_n") {
                newInputGroups[index].fixed_inputs[field] = parseInt(value) || 0;
            } else if (field === "use_rerank") {
                // Convert string back to boolean for `use_rerank`
                newInputGroups[index].fixed_inputs[field] = value === "true";
            } else {
                newInputGroups[index].fixed_inputs[field] = value;
            }
        } else if (selectedFunctionProps[field] === "dynamic") {
            if (value !== "-"){
                newInputGroups[index].dynamic_inputs = { query_text: value };
            }
            else{
                newInputGroups[index].dynamic_inputs = { query_text: "" };
            }
        }
        console.log(newInputGroups);
        setInputGroups(newInputGroups);
    };

    return (
        <div className="grid grid-cols-4 gap-4">
            {/* Function Name Selection */}
            <div className="col-span-1">
                <Label htmlFor={`functionName-${index}`}>API Name</Label>
                <Select
                    onValueChange={(value) => handleInputChange("registry_key", value)}
                    value={group?.registry_key || ""}
                >
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select an API" />
                    </SelectTrigger>
                    <SelectContent>
                        {apis.map((funcName) => (
                            <SelectItem key={funcName} value={funcName}>
                                {funcName}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            {/* Render Inputs Based on Properties */}
            {Object.entries(selectedFunctionProps)
            .filter(([param, propType]) => {
                // Exclude specific parameters unconditionally
                if (param === "organization_id" || param === "sub_organization_id" || param === "filters") {
                    return false;
                }

                // Exclude "top_n" only if "use_rerank" is set to true
                if (param === "top_n" && !group?.fixed_inputs?.["use_rerank"]) {
                    return false;
                }

                // Include all other parameters
                return true;
            }).map(([param, propType], idx) => (
                <div key={idx} className="col-span-1">
                    {param === "collection_id" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${index}`}>Collection</Label>

                        <Select
                            onValueChange={(value) => handleInputChange(param, value)}
                            value={group?.fixed_inputs?.[param] || ""}
                        >
                            <SelectTrigger className="w-full bg-white">
                                <SelectValue placeholder="Select a collection" />
                            </SelectTrigger>
                            <SelectContent>
                                {project.collections.map((collection) => (
                                    <SelectItem key={collection.name} value={collection._id}>
                                        {collection.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        </div>
                    ) : param === "use_rerank" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${index}`}>Use Rerank</Label>

                        <Select
                            defaultValue={group?.fixed_inputs?.[param] ? "true" : "false"}
                            onValueChange={(value) => handleInputChange(param, value)}
                            value={group?.fixed_inputs?.[param] ? "true" : "false"}
                        >
                            <SelectTrigger className="w-full bg-white">
                                <SelectValue placeholder="Select Option" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem key={"rerank_true"} value={"true"}>
                                    True
                                </SelectItem>
                                <SelectItem key={"rerank_false"} value={"false"}>
                                    False
                                </SelectItem>
                            </SelectContent>
                        </Select>
                        </div>
                    ) : param === "top_k" || param === "top_n" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${index}`}>{formatToTitleCase(param)}</Label>
                            <Input
                                type="number"
                                min="0"
                                id={`param-${param}-${index}`}
                                value={group?.fixed_inputs?.[param] || 0}
                                onChange={(e) => handleInputChange(param, parseInt(e.target.value) || 0)}
                                className="bg-white"
                            />
                        </div>
                    ) : param === "query_text" && index === 0 ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${index}`}>Input Name</Label>
                            <InputValidation
                                id={`param-${param}-${index}`}
                                value={group?.dynamic_inputs?.[param] || ""}
                                onChange={(e) => handleInputChange("query_text", e.target.value)}
                                validation={handler.validateInputName}
                                className="bg-white"
                                disabled={
                                    group?.dynamic_inputs?.query_text !== "" && (
                                        // For LLM steps
                                        inputGroups.slice(index + 1).some(
                                            nextGroup => nextGroup.step_type === "LLM" && nextGroup.dynamic_inputs?.includes(group?.dynamic_inputs?.query_text)
                                        )
                                        ||
                                        // For Non-LLM steps
                                        inputGroups.slice(index + 1).some(
                                            nextGroup => nextGroup.step_type === "Non-LLM" && nextGroup.dynamic_inputs?.query_text?.includes(group?.dynamic_inputs?.query_text)
                                        )
                                    )
                                }
                            />
                        </div>
                    ) : param === "query_text" ? (
                        <div className="">
                            <Label htmlFor={`param-${param}-${index}`}>Input Name</Label>
                            <Select
                                onValueChange={(value) => handleInputChange(param, value)}
                                value={group?.dynamic_inputs?.[param] || ""}
                            >
                                <SelectTrigger className="w-full bg-white">
                                    <SelectValue placeholder="Select input" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem key={"default"} value={"-"}>
                                        None
                                    </SelectItem>
                                    {inputSelection.map((item) => (
                                        <SelectItem key={item.label} value={item.value}>
                                            {item.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    ) : ""}
                    
                </div>
            ))}

            {/* Output Variable */}
            <div className="col-span-1">
                <Label htmlFor={`outputVar-${index}`}>Step Output</Label>
                <InputValidation
                    id={`outputVar-${index}`}
                    value={group?.output_var || ""}
                    onChange={(e) => handleInputChange("output_var", e.target.value)}
                    className="bg-white"
                    validation={handler.validateOutputName}
                    disabled={
                        group?.output_var !== "" && (
                            // For LLM steps
                            inputGroups.slice(index + 1).some(
                                nextGroup => nextGroup.step_type === "LLM" && nextGroup.dynamic_inputs?.includes(group.output_var)
                            )
                            ||
                            // For Non-LLM steps
                            inputGroups.slice(index + 1).some(
                                nextGroup => nextGroup.step_type === "Non-LLM" && nextGroup.dynamic_inputs?.query_text === group.output_var
                            )
                        )
                    }
                />
            </div>
        </div>
    );
}
