import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import {
  MdOutlineFileDownload,
  MdOutlineRefresh,
  MdContentCopy,
} from "react-icons/md";
import Message from "./Message.tsx";
import { BsSendFill } from "react-icons/bs";
import { SAMPLE_MESSAGES } from "../utils/sample-messages.ts";
import { useContext } from "react";
import { SampleMessage } from "../context/sample-message.ts";
type ReferenceSchema = {
  pageNumber: number;
};
type ChatMessage = {
  content: String;
  role: "user" | "assistant";
  date: number
  reference?: ReferenceSchema
};
type ChatXType = {
  enableChatName?: boolean;
  withSampleMessage?: boolean;
  ChatName: String;
  MessageSent: ChatMessage[];
  selectedDocument: {
    name: string,
    sourceId: string,
    type: string,
    metadata: string[],
    url:string
  }
  handleKeyPress: any
  chatRef:React.MutableRefObject<HTMLTextAreaElement>;
  sendMessage: any,
  sendButton: boolean
};

type ChatWindowProps = {
  $MessageArr: ChatMessage[];
};
const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div {
    box-sizing: border-box;
    width:100%;
    & h2 {
      margin: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      
    }
  }
`;
const ChatBox = styled.div`
  border-radius: 10px;
  border-style: none;
  border-width: 1px;
  border-color: grey;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-grow:1;
`;
const TextAreaChat = styled.textarea`
  box-sizing: border-box;
  font-family: "Arial";
  border-style: none;
  background-color: #e0e0e0;
  resize: none;
  padding: 10px;
  border-radius: 10px;
  flex-grow: 1;
  outline: none;
  height: 100%;
`;

const ChatWindow = styled.div<ChatWindowProps>`
  overflow: auto;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
  box-sizing: border-box;
  display: flex;
  flex-grow:5;
  max-height:60vh;
  flex-direction: column-reverse;
  & i {
    color: gray;
  }
  ${({ $MessageArr }) =>
    $MessageArr.length < 1 ? "justify-content: center" : ""}
`;
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const ChatXDiv = styled.div`
  display: flex;
  overflow:hidden;
  max-width:600px;
  box-sizing: border-box;
  flex-direction: column;
  gap:10px;
  width:100%;
  height:100%;
`;

const SendButtonChat = styled.div`
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #b1092a;
  display: flex;
  &:hover {
    background-color: #e30b36;
    cursor: pointer;
  }
`;

export default function ChatX({ ChatName, MessageSent, selectedDocument, handleKeyPress, chatRef, sendMessage, sendButton, enableChatName, withSampleMessage }: ChatXType) {
  const refMessage = useRef(null)
  const selectRef = useRef<HTMLSelectElement>(document.createElement("select"))
  const {selectedSample, setSelectedSample} = useContext(SampleMessage);
  useEffect(()=>{
    if(withSampleMessage){console.log(selectRef.current.value)
    selectRef.current.value = 'selectMode'}
  },[MessageSent])
  return (
    <ChatXDiv>
      {enableChatName ? <ChatHeader>
        <div>
          <h2>{ChatName}</h2>
        </div>
      </ChatHeader> : null}
      <ChatWindow $MessageArr={MessageSent}>
        {MessageSent.length < 1 ? (
          <center>
            <i>Start a conversation</i>
          </center>
        ) : (
          MessageSent.map((mess, i) => (
            <>
              <Message
                key={`${mess.content}${mess.role}${i}`}
                content={mess.content}
                role={mess.role}
                date={mess.date}
              />
            </>
          ))
        )}

      </ChatWindow>
      {withSampleMessage ? <select ref={selectRef} className="response-select conversation-width-fill" defaultValue={'selectMode'} onChange={(event) => 
        {
          setSelectedSample(event.target.value)
        }
      }>
        <option  disabled value="selectMode" >Select sample message</option>
        {SAMPLE_MESSAGES.map((item)=>
        
        <option value={item.message}>{item.name}</option>
        )}
      </select> : null}
      <ChatBox>
        <TextAreaChat
          ref={chatRef}
          onKeyDown={handleKeyPress}
          placeholder="Write a message for the document..."
        />
        {sendButton?<SendButtonChat
          onClick={() => {
            sendMessage();
          }}
        >
        <BsSendFill size={20} color="white" />
      </SendButtonChat> :  <></>}
      </ChatBox>
      
    </ChatXDiv>
  );
}
