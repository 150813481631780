import React from 'react';
import { Container, PrimaryInput } from '../../../components/FormInput';


const EditUserForm = ({ userInput, setUserInput }) => {

    const handleInputChange = (key, value) => {
        setUserInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div>
            <Container labelName={'Name'} style={{ fontWeight: '600' }}>
                <PrimaryInput onChange={(e) => handleInputChange('name', e.target.value)}
                    type="text"
                    name="name"
                    placeholder="Type new name here"
                    required
                    value={userInput.name}
                />
            </Container>
        </div>
    );
};

export default EditUserForm;