import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import useComponentVisible from "../../../hooks/useClickOutside";
import { v4 as uuidv4 } from 'uuid';

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  overflow: visible;
`;

const SelectOptionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
  border: 0.4px solid #d9d9d9;
  align-items: center;
  border-radius: 5px;
`;

const DropdownIcon = styled.div`
  padding: 5px;
  display: flex;
  transition: all 200ms ease;
  transform: ${(props) => (props.$showMenu ? "rotate(180deg)" : "rotate(0deg)")};
`;

const SelectOptionList = styled.div`
  display: ${(props) => (props.$showMenu ? "flex" : "none")};
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  gap: 5px;
  border: 0.2px solid #e5e5e5;
  padding: 10px;
  position: absolute;
  top: 45px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0.5px rgba(191, 191, 191, 0.75);
  background-color: #fff;
  z-index: 9999;
`;

const ItemSelect = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: #e5e5e5;
  }
`;

export function Dropdown({ title, options = [], selected, onSelect, onClose, fetchDoc }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleCheckboxChange = (obj) => {
    console.log("OBJ",obj.id)
    console.log("Selected", selected)
    const updatedSelectedItems = selected.find((element) => element.id === obj.id )
      ? selected.filter((item) => item.id  !== obj.id)
      : [...selected, obj];
      onSelect(updatedSelectedItems);
    if(fetchDoc){
        fetchDoc()
    }
  };

  useEffect(() => {
    if (!isComponentVisible) {
      onClose && onClose();
    }
  }, [isComponentVisible]);

  useEffect(() => {
    // onSelect(selected);
    console.log(selected)
    console.log(selected.filter((element) => element.id || element.document_id !== "ae5d8b24-7c12-8750-0000-018dcf4ac593"))
  }, [selected]);

  const handleShowMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const dropdownOptions = useMemo(() => {
    
    if(options.length > 0){
      return options.map((item) => (
      <ItemSelect key={item.id || item.document_id} onClick={() => handleCheckboxChange(item)}>
        <input
          type="checkbox"
          key={item.id || item.document_id}
          value={item.id || item.document_id}
          checked={selected.find((element) => element.id === item.id )}
          onChange={() => handleCheckboxChange(item)}
        />
        {item.name || item.filename}
      </ItemSelect>
    ))
    }
    }, [options, selected]);

  return (
    <SelectContainer ref={ref}>
      <SelectOptionHeader onClick={handleShowMenu}>
        <label>Select {title}:</label>
        <DropdownIcon onClick={handleShowMenu} $showMenu={isComponentVisible}>
          <IoIosArrowDown size={20} />
        </DropdownIcon>
      </SelectOptionHeader>
      <SelectOptionList $showMenu={isComponentVisible}>
        {dropdownOptions}
      </SelectOptionList>
    </SelectContainer>
  );
}
export function DropdownOne({ title, options = [], selected, onSelect, onClose, add }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleClick = (obj) => {
   
      // onSelect(updatedSelectedItems);
      console.log(obj)
    // if(fetchDoc){
    //     fetchDoc()
    // }
    let queryList = []
    for(let i in obj.query){
      console.log(i)
      queryList.push({
          label: `Query`,
          context: obj.context[i],
          textValue: obj.query[i],
          deleteButton: i < 1 ? false : true,
          keyVal: uuidv4(),
        },
      )
    }

    onSelect(queryList)
  };

  useEffect(() => {
    if (!isComponentVisible) {
      onClose && onClose();
    }
  }, [isComponentVisible]);

  useEffect(() => {
    // onSelect(selected);
    
  }, [selected]);

  const handleShowMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const dropdownOptions = useMemo(() => {
    
    if(options.length > 0){
      return options.map((item,i) => (
      <ItemSelect key={i} onClick={() => handleClick(item)}>

        {item.name || item.filename}
      </ItemSelect>
    ))
    }
    }, [options, selected]);

  return (
    <SelectContainer ref={ref}>
      <SelectOptionHeader onClick={handleShowMenu}>
        <label>Select {title}:</label>
        <DropdownIcon onClick={handleShowMenu} $showMenu={isComponentVisible}>
          <IoIosArrowDown size={20} />
        </DropdownIcon>
      </SelectOptionHeader>
      <SelectOptionList $showMenu={isComponentVisible}>
        {dropdownOptions}
      </SelectOptionList>
    </SelectContainer>
  );
}
