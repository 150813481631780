import React, { useContext, useEffect, useState } from 'react'
import './legal-contract-styles.css'
import SetupCard from '../../../components/AppSetup/SetupCard'
import SetupModal from '../../../components/AppSetup/SetupModal'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/GlobalState'
import ConversationDemoV2 from '../../LegalContract/components/ConversationPage/conversationPageDemov2.tsx'
import Breadcrumb from 'components/Breadcrumb'
import DemoModeX from '../../LegalContract/components/ConversationPage/conversationPagev3.jsx'
import axios from 'axios'
import { SubOrgContext } from 'context/SubOrganizationContext'
import { OrganizationContext } from "context/OrganizationContext";

export default function LegalContractDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your contract repository where the BeX AI Assistant will be connecting to and reading',
        'A set of legal clauses that you want the Assistant to extract by default'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for legal contract management is designed for corporate lawyers who need to manage their documents.',
        'This BeX AI Assistant is capable of reading your repository of contract documents, extract out the key clauses and handles the contract workflow '
    ];

    const navigate = useNavigate()
    const { addNewNotifcation } = useContext(Context)
    const { selectedSubOrganization } = useContext(SubOrgContext)
    const { selectedOrganization } = useContext(OrganizationContext);

    const param = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [pdfData, setPdfData] = useState("")

    const handleDemoClick = () => {
        // setOpenModal(!openModal)
        if (selectedOrganization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/legal-demo`)
        } else {
            navigate(`/organizations`)
        }
        console.log(param.oragID, param.subOragID)
    }

    const handleSetupClick = () => {
        if (selectedOrganization) {
            // navigate(`/organizations/organization/${param.oragID}/legal-contract-management`)
            addNewNotifcation("Setup is locked.", "danger")
        } else {
            navigate(`/organizations`)
        }
    }

    // const displayPdf = async () =>{
    //     await axios.get("https://legalrepo-x.s3.jp-tok.cloud-object-storage.appdomain.cloud/tempFilef4c131bb-2959-4a6e-a573-3510bdc0788cMURATA%20ELECTRONICS.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=372f555f0e86442f800cf31326389c37%2F20240604%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240604T134718Z&X-Amz-Expires=60&X-Amz-Signature=bc562401090a7574aaa49dd9d8831ebaf90ab49d84e1e2ed6d447a12903098af&X-Amz-SignedHeaders=host").then((resp)=>{
    //         console.log(resp.data)    
    //     setPdfData(resp.data)
    //     }) 
    // }
    useEffect(()=>{
        // displayPdf()
    },[])

    return (
        <div className='legal-contract-dashboard-container'>
            <div className='legal-contract-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4}/>
                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for Legal Contract Management'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant' 
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                        <SetupModal title={'Demo Mode for BeX-X001 - BeX AI Assistant for reading legal documents'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='legal-contract-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO: Select a document/clause to chat.
                                    </div>
                                    <div className='demo-insturctions__text'>
                                        <span>
                                            This BeX AI Assistant is capable of understanding legal documents, comparing clauses, and generating something based from the legal documents. Interact with this BeX AI Assistant by chatting.
                                        </span>
                                    </div>
                                </div>
                                
                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                                <DemoModeX/>
                            </SetupModal.StyledSetup>
                        </SetupModal>
                    </div>
                </div>
            </div>
        </div>
    )
}