import React, { useContext, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "components/ui/card";
import { Label } from 'components/ui/label';
import { Combobox } from 'components/ui/combobox';
import { ScrollArea } from "components/ui/scroll-area";
import StyledTextArea from "../StyledTextArea";
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/DemoPromptContext';
import sampleTaskSetup from "../../json/samplePromptSetupList.json";
// import watsonxModels from "../../json/watsonxModels";

export function Anatomy(){
    const {
        instruction, setInstruction,
        context, setContext,
        targetFormat, setTargetFormat,
        sampleInput, setSampleInput,
        sampleOutput, setSampleOutput,
        inputData, setInputData,
        selectedTask, setSelectedTask,
    } = useContext(GlobalContext);

    const SAMPLE_TASK_LIST = [
        {_id: 1, name: "Summarization", value: "summarization"},
        {_id: 2, name: "Classification", value: "classification"},
        {_id: 3, name: "Extraction", value: "extraction"},
        {_id: 4, name: "Content Generation", value: "content_generation"},
        {_id: 5, name: "Translation", value: "translation"},
        {_id: 6, name: "Question Answering", value: "question_answering"},
        {_id: 7, name: "Code Generation", value: "code_generation"},
    ];

    useEffect(()=>{
        if (!selectedTask || selectedTask === null || (typeof selectedTask === 'object' && Object.keys(selectedTask).length === 0)) return;
        setInstruction(sampleTaskSetup[selectedTask.value]['prompt_data']['instruction']);
        setContext(sampleTaskSetup[selectedTask.value]['prompt_data']['context']);
        setTargetFormat(sampleTaskSetup[selectedTask.value]['prompt_data']['target_format']);
        setSampleInput(sampleTaskSetup[selectedTask.value]['prompt_data']['sample_input']);
        setSampleOutput(sampleTaskSetup[selectedTask.value]['prompt_data']['sample_output']);
        setInputData(sampleTaskSetup[selectedTask.value]['prompt_data']['input_data']);

        // let model = watsonxModels.find(model => model.value === sampleTaskSetup[selectedTask.value]['model']);
        // setSelectedModel(model);
        // setParametersDemo(sampleTaskSetup[selectedTask.value]['parameters']);
        console.log(selectedTask)
    }, [selectedTask]);

    return (
        <Card className="w-full flex flex-col h-full justify-between h-fit">
            {/* HEADER */}
            <CardHeader className="flex flex-row border-b-[1px] border-gray-200 px-6 py-3.5 gap-4 justify-between">
                <div className="select-none">
                    <CardTitle className="text-sm">Generative AI Tasks Demo</CardTitle>
                    <CardDescription className="text-xs">Try sample prompts for different tasks.</CardDescription>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <Label className="">Samples:</Label>
                    <Combobox
                        defaultDisplayed='GenAI Tasks'
                        items={SAMPLE_TASK_LIST}
                        selectedValue={selectedTask}
                        setter={setSelectedTask}
                    />
                </div>
            </CardHeader>


            {/* CONTENT */}
            <CardContent className="p-0 flex flex-col max-h-[360px]">
                <ScrollArea className="max-h-[360px]">
                    <div className='flex flex-col gap-4 px-6 py-4'>
                        <StyledTextArea
                            value={instruction}
                            onChange={(e)=>{setInstruction(e.target.value)}}
                            labelText="Instruction"
                            tooltipText="Provide specific tasks or questions for the language model to address. This sets the purpose
                            and direction for the response."
                            required={true}
                            // placeholder={"Describe the process of making a cup of coffee."}
                            // disabled={true}
                            readOnly={true}
                        />
                        <StyledTextArea
                            value={context}
                            onChange={(e)=>{setContext(e.target.value)}}
                            labelText="Context"
                            tooltipText="Supply background information or details that help the language model understand the scenario
                            or topic, ensuring relevant and accurate responses."
                            required={false}
                            // placeholder={"For someone who is new to making coffee at home, you need to provide a step-by-step guide
                            // that includes selecting coffee beans, grinding them, and brewing the coffee."}
                            // disabled={true}
                            readOnly={true}
                        />
                        <StyledTextArea
                            value={targetFormat}
                            onChange={(e)=>{setTargetFormat(e.target.value)}}
                            labelText="Target Format"
                            tooltipText="Specify the structure and style of the response. This ensures that the output is consistent
                            with your needs, whether it's a specific layout, tone, or type of information."
                            required={false}
                            // placeholder={"Provide the response in a numbered list format with each step clearly outlined."}
                            // disabled={true}
                            readOnly={true}
                        />
                        <div className="grid grid-cols-2 gap-4">
                            <StyledTextArea
                                value={sampleInput}
                                onChange={(e)=>{setSampleInput(e.target.value)}}
                                labelText="Sample Input"
                                tooltipText="Offer an example of what the input data might look like. This helps clarify the format
                                and type of data expected."
                                required={false}
                                // placeholder={"Coffee beans: Robusta, ground size: medium-fine, water temperature: 200°F"}
                                // disabled={true}
                                readOnly={true}
                            />
                            <StyledTextArea
                                value={sampleOutput}
                                onChange={(e)=>{setSampleOutput(e.target.value)}}
                                labelText="Sample Output"
                                tooltipText="Show a representative example of the desired response. This demonstrates the format
                                and quality of output you are looking for from the language model."
                                required={false}
                                // placeholder={"1. Select Robusta beans\n2. Grind to medium-fine\n3. Heat water to 200°F\n4. Brew using French Vanilla"}
                                // disabled={true}
                                readOnly={true}
                            />
                        </div>
                        <StyledTextArea
                            value={inputData}
                            onChange={(e)=>{setInputData(e.target.value)}}
                            labelText="Input Data"
                            tooltipText="Include any data or details that the language model needs to process in order to generate
                            a meaningful response. This can include text, numbers, or structured data."
                            required={false}
                            // placeholder={"Coffee beans: Arabica, ground size: medium-fine, water temperature: 195-205°F."}
                            // disabled={true}
                            readOnly={true}
                        />
                    </div>
                </ScrollArea>
            </CardContent>
        </Card>
    );
}
