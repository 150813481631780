import styled, { keyframes } from "styled-components";
import { useContext } from "react";
import { Context } from "../context/GlobalState";
import { BsCheckCircle } from 'react-icons/bs'
import { LuAlertCircle } from 'react-icons/lu'
import { BiError } from 'react-icons/bi'


const NotifcationContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    top:0;
    margin:auto;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    width :500px;
    padding:5px;
    height:auto;
    max-height:400px;
    overflow: 'hidden';
    gap: 10px;
    z-index: 10000;

`
const AnimationEffect = keyframes`
    from {
        transform: translateY(-80px);
    }

    to {
        transform : translateY(0px);
    }
`
const NotficationContent = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 400px;
    background-color: ${(props) => {
        if (props.$type === 'danger') {
            return '#ED4545'
        } else if (props.$type === 'success') {
            return '#DFF8F3'
        } else {
            return '#fff'
        }
    }};
    padding: 10px;
    border:  ${(props) => {
        if (props.$type === 'danger') {
            return 'none'
        } else if (props.$type === 'success') {
            return '1px solid #7EA49B'
        } else {
            return '1px solid #aa4b07'
        }
    }}; 
    border-radius: 5px;
    animation: ${AnimationEffect} 0.2s ease-in ;    
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    color :${(props) => {
        if (props.$type === 'danger') {
            return '#fff'
        } else if (props.$type === 'success') {
            return '#294E4B'
        } else {
            return '#bd5c17'
        }
    }};
`

const MessageContainer = styled.div`
    word-wrap: break-word;
    flex-grow: 1;
    max-width: 350px;
    min-height:20px;
    max-height:80px;
    overflow: hidden;
    color :${(props) => {
        if (props.$type === 'danger') {
            return '#fff'
        } else if (props.$type === 'success') {
            return '#294E4B'
        } else {
            return '#bd5c17'
        }
    }};
`

const NotificationMessage = styled.span`
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
`

export default function Notifcation() {

    const { notificationStatus } = useContext(Context);

    function routitIcons(type) {
        if (type === 'danger') 
            return <BiError color="#fff" />
        else if (type === 'success') 
            return <BsCheckCircle color="#0DBE5E" />
        else 
            return <LuAlertCircle color="#ca6a25" />        
    }


    return (
        <NotifcationContainer>
            {notificationStatus?.map((notification, i) => (
                <NotficationContent key={i} $type={notification.type}>
                    <IconContainer $type={notification.type}>
                        {routitIcons(notification.type)}
                    </IconContainer>
                    <MessageContainer $type={notification.type}>
                        <NotificationMessage>
                            {notification.message}
                        </NotificationMessage>
                    </MessageContainer>
                </NotficationContent>
            ))}
        </NotifcationContainer>
    )
}