import { projectOHttpClient } from "api/clients/project-o.httpClient";

/**
 * Service class for performing OpCode operations.
 */
class OpCodeService {

    /**
     * Creates a new OpCode.
     * @param {Object} data - The data to create a new OpCode.
     * @returns {Promise<Object>} The response from the server regarding OpCode creation.
     */
    async createOpCode(data) {
        const response = await projectOHttpClient.post(`/opcodes/create_opcode`, data);
        return response;
    }

    async deleteOpCodes(data){
        const response = await projectOHttpClient.delete(`/opcodes/delete_opcodes`, {data});
        return response;
    }

    /**
     * Updates an existing OpCode.
     * @param {Object} data - The updated data for the OpCode.
     * @returns {Promise<Object>} The response from the server regarding OpCode update.
     */
    async updateOpCode(data) {
        const response = await projectOHttpClient.post(`/opcodes/update_opcode`, data);
        return response;
    }

    /**
     * Executes an OpCode.
     * @param {Object} data - The input data for executing the OpCode.
     * @returns {Promise<Object>} The response containing execution results.
     */
    async executeOpCode(data) {
        const response = await projectOHttpClient.post(`/opcodes/execute_opcode`, data);
        return response;
    }

    /**
     * Fetches OpCodes by organization and sub-organization.
     * @param {String} organizationId - The organization ID.
     * @param {String} subOrganizationId - The sub-organization ID.
     * @returns {Promise<Object>} The response containing the list of OpCodes.
     */
    async getOpCodesByOrgSubOrg(organizationId, subOrganizationId) {
        const url = `/opcodes/get_opcodes/${organizationId}/${subOrganizationId}`;
        const response = await projectOHttpClient.get(url);
        return response;
    }

    /**
     * Fetches OpCodes by unique names.
     * @param {Object} data - The unique names to fetch OpCodes.
     * @returns {Promise<Object>} The response containing the list of OpCodes.
     */
    async getOpCodesByUniqueNames(data){
        const response = await projectOHttpClient.post(`/opcodes/get_opcodes_by_unique_names`, data);
        return response;
    }


    // Opcode2
    /**
     * Fetches the Functions available for OpCode Non-LLM steps.
     * @returns {Promise<Object>} The response containing the list of functions and parameters
     */
    async getFunctionRegistry(){
        const response = await projectOHttpClient.get(`/opcodes/get_function_registry`);
        return response;
    }


    /**
     * Fetches the APIs available for OpCode Non-LLM steps.
     * @returns {Promise<Object>} The response containing the list of functions and parameters
     */
    async getApiRegistry(){
        const response = await projectOHttpClient.get(`/opcodes/get_api_registry`);
        return response;
    }
}

const opCodeService = new OpCodeService();
export { opCodeService };
