import React, { useContext, useState } from 'react'
import './hr-assistant.css'
import SetupCard from '../../../components/AppSetup/SetupCard.jsx'
import SetupModal from '../../../components/AppSetup/SetupModal.jsx'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/GlobalState.js'
import Breadcrumb from 'components/Breadcrumb'
import WebChat from '../../WebEngagement/Components/WebChat.jsx'
import { OrganizationContext } from "context/OrganizationContext";


export default function HRDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'BeX AI Assistants HR theme focusing on internships',
        'Job or candidate matching'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'Human Resources For HR, generative AI automates repetitive tasks like screening resumes, conducting interviews, documenting policies, generating reports, and answering common employee questions. ',
        'AI assistants also provide personalized guidance on learning, development, and retention based on performance insights.With these skills, generative AI will transform HR operations, empowering teams to deliver more value through automation and augmentation.  '
    ];

    const navigate = useNavigate()
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext);

    const param = useParams()

    const [openModal, setOpenModal] = useState(false)

    const handleDemoClick = () => {
        // setOpenModal(!openModal)
        if (selectedOrganization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/hr-demo`)
        } else {
            navigate(`/organizations`)
        }
        console.log(param.oragID, param.subOragID)
    }
    const handleSetupClick = () => {
        addNewNotifcation('No setup is needed for this assistant. Click Try Assistant.', 'success');
       
    };
    // const handleSetupClick = () => {
    //     if (selectedOrganization) {
    //         navigate(`/organizations/organization/${selectedOrganization}/hr-ai-assistant`)
    //     } else {
    //         navigate(`/organizations`)
    //     }
    // }

    return (
        <div className='hr-assistant-dashboard-container'>
            <div className='hr-assistant-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    {/* <BreadcrumbsCustom /> */}

                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for HR'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant'
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                        <SetupModal title={'Demo Mode for BeX AI Assistant for HR'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='hr-assistant-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO: Job or candidate matching
                                    </div>
                                    <div className='demo-insturctions__text'>
                                        <span>
                                        HR teams can create tailored, compliant programs and content at scale while optimizing the employee lifecycle. Generative AI saves time on rote tasks to better focus on more strategic initiatives.
                                        </span>
                                    </div>
                                </div>

                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                             
                                <WebChat />
                            </SetupModal.StyledSetup>
                        </SetupModal>

                    </div>
                </div>
            </div>
        </div>
    )
}