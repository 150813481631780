import { RotateCw } from 'lucide-react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from 'components/ui/breadcrumb';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from 'components/ui/dropdown-menu';
import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { LoaderModalWithChild, LoadingIndicator } from 'components/LoaderModal';

const FileNavBar = ({ getFileSystem, currentPath, setCurrentPath, setSelectedFolder, setExpandedFolders }) => {
    const maxVisibleItems = 3; // Number of visible items (first + last)
    const [loading, setLoading] = useState(true);

    const handleBreadcrumbClick = (item, index) => {
        setSelectedFolder(item.id);
        const newPath = currentPath.slice(0, index + 1);
        setCurrentPath(newPath);

        const parentFolders = newPath.map(pathItem => pathItem.id);
        setExpandedFolders(prevExpanded => {
            const newExpanded = new Set([...prevExpanded, ...parentFolders]);
            return Array.from(newExpanded);
        });
    };

    const handleRefresh = async () => {
        try{
            setLoading(true);
            console.log("Getting files from COS...");
            await getFileSystem();

        } catch (error) {
            console.error('Error refreshing file system:', error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleRefresh()
    }, []);

    // Determine which items to show when collapsed
    const shouldCollapse = currentPath.length > maxVisibleItems;
    const firstItems = currentPath.slice(0, 1); // First one items
    const lastItems = currentPath.slice(-2);    // Last two items
    const hiddenItems = currentPath.slice(1, -2); // Items in the middle

    return (
        <>
            {loading && 
                <LoaderModalWithChild>
                    <LoadingIndicator />
                </LoaderModalWithChild>
            }
            <nav className="bg-gray-100 p-4 flex flex-row flex-nowrap gap-2 items-center border-b">
                <div className="bg-white w-[90%] py-2 px-4 overflow-hidden rounded-md shadow">
                    <Breadcrumb>
                        <BreadcrumbList className="flex-nowrap text-xs">
                            {/* If the breadcrumb should collapse */}
                            {shouldCollapse ? (
                                <>
                                    {/* First items */}
                                    {firstItems.map((item, index) => (
                                        <React.Fragment key={item.id}>
                                            {index > 0 && <BreadcrumbSeparator />}
                                            <BreadcrumbItem className="cursor-pointer">
                                                <BreadcrumbLink onClick={() => handleBreadcrumbClick(item, index)}>
                                                    {item.name}
                                                </BreadcrumbLink>
                                            </BreadcrumbItem>
                                        </React.Fragment>
                                    ))}

                                    <BreadcrumbSeparator />

                                    {/* Dropdown for hidden middle items */}
                                    <BreadcrumbItem className="cursor-pointer">
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <span className="px-2 cursor-pointer">...</span>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                {hiddenItems.map((item, index) => (
                                                    <DropdownMenuItem key={item.id} onClick={() => handleBreadcrumbClick(item, index + 2)}>
                                                        {item.name}
                                                    </DropdownMenuItem>
                                                ))}
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </BreadcrumbItem>

                                    <BreadcrumbSeparator />

                                    {/* Last items */}
                                    {lastItems.map((item, index) => (
                                        <React.Fragment key={item.id}>
                                            {index > 0 && <BreadcrumbSeparator />}
                                            <BreadcrumbItem className="cursor-pointer">
                                                {index === lastItems.length - 1 ? (
                                                    <BreadcrumbPage>{item.name}</BreadcrumbPage>
                                                ) : (
                                                    <BreadcrumbLink onClick={() => handleBreadcrumbClick(item, currentPath.length - lastItems.length + index)}>
                                                        {item.name}
                                                    </BreadcrumbLink>
                                                )}
                                            </BreadcrumbItem>
                                        </React.Fragment>
                                    ))}
                                </>
                            ) : (
                                // When the breadcrumb is short, show all items
                                currentPath.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        {index > 0 && <BreadcrumbSeparator />}
                                        <BreadcrumbItem className="cursor-pointer">
                                            {index === currentPath.length - 1 ? (
                                                <BreadcrumbPage>{item.name}</BreadcrumbPage>
                                            ) : (
                                                <BreadcrumbLink onClick={() => handleBreadcrumbClick(item, index)}>
                                                    {item.name}
                                                </BreadcrumbLink>
                                            )}
                                        </BreadcrumbItem>
                                    </React.Fragment>
                                ))
                            )}
                        </BreadcrumbList>
                    </Breadcrumb>
                </div>
                <Button variant="ghost"className="text-gray-400 hover:text-black flex items-center gap-2" onClick={handleRefresh}>
                    <RotateCw className={`w-5 h-5 ${loading ? 'animate-spin' : ''}`} /> 
                    <span>Refresh</span>
                </Button>
            </nav>
        </>

    );
};

export default FileNavBar;
