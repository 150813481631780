import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BsArrowRight } from 'react-icons/bs'
import { LoaderSpinner } from './LoaderSpinner';

const Button = styled.button`
    display: flex;
    width: 100%;
    padding: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const PrimaryDiv = styled(Button)`
    color: #fff;
    background-color: var(--primary-color);
    border: 1px transparent solid;

    &:hover {
        background-color: var(--primary-color-hover);
    }

    &:disabled,
    &[disabled]{
        background-color: var(--primary-color-hover);
        cursor: not-allowed;
    }
`;

const DivBtn = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const NormalDiv = styled(Button)`
    color: black;
    background-color: transparent;
    border: 1px black solid;
    
    &:hover {
        background-color: #F1F1F1;
    }

    &:disabled,
    &[disabled]{
        background-color: #F1F1F1;
        cursor: not-allowed;
    }
`;

const Span = styled.span`
    font-size: 18px;
    font-weight: 500;
`;

export const RedButton = styled(Button)`
    background-color: var(--primary-color);
    color: white;
    padding: 0px 12px;
    height: 40px;
    font-weight: 600;
    // margin-left: 10px;
    min-width: 88px;
    width: fit-content;
    border: 1px solid var(--primary-color);
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box; 
    transition: all 200ms ease;
    // border-radius: 5px;

    &:disabled{
        background-color: var(--primary-color-hover);
        color: #fff;
        // border: none;
        // padding: 10px 0;
        cursor: not-allowed;
    }

    &:hover:enabled {
        background-color: var(--primary-color-hover);
        border: 1px solid var(--primary-color-hover);
    }
`;

export const WhiteButton = styled(Button)`
    background-color: white;
    color: black;
    padding: 0px 8px;
    height: 40px;
    font-weight: 600;
    // margin-left: 10px;
    min-width: 88px;
    width: fit-content;
    border: 1px solid var(--primary-color);
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box; 
    transition: all 200ms ease;
    
    &:disabled{
        background-color: rgb(213, 213, 213);
        color: rgb(83, 83, 83);
        border: 1px solid rgb(213, 213, 213);
        // padding: 10px 0;
        cursor: not-allowed;
    }

    &:hover:enabled{
        background-color: #f2f2f2;
    }
`;

// export const RedButton1 = ({loading, children, ...rest}) => {
//     const buttonRef = useRef(null);

//     useEffect(() => {
//       // Reset the button width when loading changes
//       if (buttonRef.current) {
//         buttonRef.current.style.width = 'auto';
//       }
//     }, [loading]);
  
//     useEffect(() => {
//       // Adjust button width based on content
//       if (!loading && buttonRef.current) {
//         buttonRef.current.style.width = `${buttonRef.current.offsetWidth}px`;
//       }
//     }, [loading, children]);

//     return (
//         <RedButton {...rest} disabled={loading || rest.disabled}>
//             {loading ? <LoaderSpinner color='#fff' size={19} /> : (
//                 {children}
//             )}
//         </RedButton>
//     )
// }

export const PrimaryButton = (props) => {

    return (
        <PrimaryDiv {...props} disabled={props.$loading || props.disabled}>
            {props.$loading ? <LoaderSpinner color='#fff' size={19} /> : (
                <DivBtn>
                    <Span>{props.$text}</Span>
                    <BsArrowRight size={22} />
                </DivBtn>
            )}
        </PrimaryDiv>
    )
}

export const SecondaryButton = (props) => {

    return (
        <NormalDiv {...props} disabled={props.$loading}>
            {props.$loading ? <LoaderSpinner color='black' size={19} /> : (
                <DivBtn >
                    <Span>{props.$text}</Span>
                    <BsArrowRight size={22} />
                </DivBtn>
            )}
        </NormalDiv>
    )
}

