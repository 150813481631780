import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "../../components/ui/card"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { Button } from "../../components/ui/button"
import {Link} from "react-router-dom"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"


const SignupForm = forwardRef((props, ref) => {
    const { loading, onSubmit } = props
  
    const nameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()
  
    useImperativeHandle(ref, () => ({
      getNameRef: () => nameRef.current,
      getEmailRef: () => emailRef.current,
      getPasswordRef: () => passwordRef.current,
      getConfirmPasswordRef: () => confirmPasswordRef.current
    }));
  
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleTogglePassword = (e, field) => {
        e.preventDefault()

        if (field === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    return (
        <Card className="w-full max-w-md">
            <CardHeader>
            <CardTitle className="text-2xl">Sign Up</CardTitle>
            <CardDescription>Enter your full name, email and password to sign up.</CardDescription>
            </CardHeader>
            <CardContent>
            <form onSubmit={onSubmit} className="space-y-4">
                <div>
                <Label htmlFor="fullName">Full Name</Label>
                <Input ref={nameRef} id="fullName" type="text" placeholder="Enter your full name" required />
                </div>
                <div>
                <Label htmlFor="email">Email</Label>
                <Input ref={emailRef} id="email" type="email" placeholder="Enter your email" required />
                </div>
                <div className="relative">
                <Label htmlFor="password">Password</Label>
                <Input ref={passwordRef} id="password" type={showPassword ? 'text' : "password" }placeholder="Enter your password" required />
                <Button onClick={(e) => handleTogglePassword(e, 'password')} variant="ghost" size="icon" className="absolute bottom-1 right-1 h-7 w-7">
                    <EyeIcon className="h-4 w-4" />
                    <span className="sr-only">Toggle password visibility</span>
                </Button>
                </div>
                <div className="relative">
                <Label htmlFor="confirmPassword">Confirm Password</Label>
                <Input ref={confirmPasswordRef} id="confirmPassword" type={showConfirmPassword ? 'text' : "password"} placeholder="Confirm your password" required />
                <Button onClick={(e) => handleTogglePassword(e, 'confirmPassword')} variant="ghost" size="icon" className="absolute bottom-1 right-1 h-7 w-7">
                    <EyeIcon className="h-4 w-4" />
                    <span className="sr-only">Toggle password visibility</span>
                </Button>
                </div>
                <Button disabled={loading} onSubmit={onSubmit} type="submit" className="w-full">
                Sign Up
                </Button>
            </form>
            </CardContent>
            <CardFooter className='flex flex-col'>
            {/* <Button variant='outline' className="w-full bg-[#B1092A] text-white hover:text-white hover:bg-[#8d0721] focus:bg-[#8d0721]">Sign in</Button> */}
            <div className="mt-4 text-center text-sm">
            Already have an account?{"  "}
                <Link
                to="/auth/login"
                className="font-medium text-[#B1092A] underline underline-offset-2 hover:text-[#8d0721] focus:text-[#8d0721]"
                >
                Login
                </Link>
            </div>
            </CardFooter>
        </Card>
    )
})

function EyeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
      <circle cx="12" cy="12" r="3" />
    </svg>
  )
}

export default SignupForm