import React from 'react';
import Laboratory from "./components/Laboratory.jsx";


export default function AILab() {
    return (
        <>
            <Laboratory/>
        </>
    );
}

