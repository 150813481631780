import React, { useState, useEffect } from "react";
import styled from "styled-components";

const TabButton = styled.button`
    background-color: ${({$isSelected})=> $isSelected ? 'var(--primary-color)' : 'white'};
    color: ${({$isSelected})=> $isSelected ? 'white' : 'black'};
    box-shadow: 0 5px 10px ${({$isSelected})=> $isSelected ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0)'};
    transition: color 0.1s ease, background-color 0.4s ease;
    padding: 0.8rem 1rem 0.8rem 1rem;
    user-select: none;
    border-radius: 0.75rem;
    border-style: none;
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
    opacity: ${({disabled}) => disabled ? '0.5' : '1'};
`

const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    border-style: solid;
    border-radius: 0.75rem;
    border-color: lightgray;
    font-size: 0.8rem;
    border-width: 1px;
    padding: 0.5rem;
`
const DisplayContainer = styled.div`
    padding: 0.5rem;
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex-grow: 1;
    // height: 1px;
`

const NavTabDiv = styled.div`
display: flex;
flex-direction: column;
margin-top: 0.5rem;
flex-grow: 1;
`
function Tab({title, keyTab, activeTab, isDisabled, ...rest}) {
    const [isSelected, setIsSelected] = useState(false)
    useEffect(()=>{
        if(activeTab === keyTab ){
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
        
    }, [activeTab])
    return (
        <TabButton disabled={isDisabled} $isSelected={isSelected} {...rest}>
            {title}
        </TabButton>
    )
}
export function NavTabV2 ({children, setSelectedTab, tabList, defaultTab, selectedDocument, result, passageToCompare }) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const handleClickTab = (keyTab) => {
        setActiveTab(keyTab)
        
    }
    const showTabContent = () => {
        
    }
    useEffect(()=>{
        setActiveTab(defaultTab)
    },[selectedDocument])
    useEffect(()=>{
        setSelectedTab(activeTab)
    },[activeTab])

    useEffect(()=>{
        if(passageToCompare){
            setActiveTab('Select Document')
        }
    }, [passageToCompare])
    return (
        <NavTabDiv>
            <TabsContainer className="overflow-auto">
                {tabList.map((tab) => <Tab activeTab={activeTab} isDisabled={tab.keyTab === 'Select Document' ? false :  result ? false : true} onClick={()=>handleClickTab(tab.keyTab)} keyTab={tab.keyTab} key={tab.keyTab} title={tab.title}/>)}
            </TabsContainer>
            <DisplayContainer>
                {Array.isArray(children) ? children.find((item, i) => item.props.id === activeTab) : children}
                {/* {Array.isArray(children) ? children.forEach((content)=> console.log(content)) : children} */}
                {/* {children} */}
            </DisplayContainer>
        </NavTabDiv>
    )
}