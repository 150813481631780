import { TextSearch, EyeOffIcon, EyeIcon } from "lucide-react";
import { Button } from "components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useContext, useState } from "react";
import { milvusService } from "api/services/PROJECT-O/Milvus.service";
import { OrganizationContext } from "context/OrganizationContext";
import { SubOrgContext } from "context/SubOrganizationContext";
import { useParams } from "react-router-dom";
import { Card, CardContent, CardHeader } from "components/ui/card"
import { Switch } from "components/ui/switch"
import { ScrollArea } from "components/ui/scroll-area";

export function CollectionQueryModal({collectionName}) {
    const [query, setQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [isQuerying, setIsQuerying] = useState(false);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    const { collectionId } = useParams()
    const [presignedURLs, setPresignedURLs] = useState([])
    const [queryResults, setQueryResults] = useState([])
    const [message, setMessage] = useState("")
    const [viewerShownMap, setViewerShownMap] = useState({});

    const handleSendQuery = async () => {
        setQueryResults([])
        setPresignedURLs([])
        setViewerShownMap({})
        try{
            setIsQuerying(true)
            const body = {
                "organization_id": selectedOrganization._id,
                "sub_organization_id": selectedSubOrganization._id,
                "collection_id": collectionId,
                "filters": {},
                "query_text": query,
                "top_k": 10,
                "metric_type": "COSINE"
            }
            console.log(body)
            const res = await milvusService.queryEmbeddings(body)
            console.log("Milvus res", res)

            const results = res.data.results;
            const presignedUrls = res.data.presigned_urls;
            if (results.length === 0) {
                setMessage("No matching documents found.")
                return;
            }

            // Append the matching URL to each result object
            const updatedResults = results.map(result => {
                const fileKey = result.file_key;
                if (presignedUrls[fileKey]) {
                    return {
                        ...result,
                        url: presignedUrls[fileKey]
                    };
                }
                return result;
            });

            // Set the updated results and presigned URLs in the state
            setQueryResults(updatedResults);
            setPresignedURLs(presignedUrls);
        } catch (error) {
            console.error("Error sending query", error)
        } finally {
            setIsQuerying(false)
        }
    }
    const handleClear = () => {
        setQuery("");
        setQueryResults([]);
        setPresignedURLs([]);
        setViewerShownMap({});
    }

    const toggleViewer = (index) => {
        setViewerShownMap(prevState => ({
            ...prevState,
            [index]: !prevState[index] // Toggle the state for this document
        }));
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost" className="flex gap-2 text-xs" disabled={!collectionName}>
                    <TextSearch className='w-4 h-4' /> Query
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-6xl select-none h-fit max-h-[650px] min-h-[300px]">
                <DialogHeader>
                    <DialogTitle>Query</DialogTitle>
                    <DialogDescription>
                        The system will search across the whole collection.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex items-center space-x-2">
                    <div className="grid flex-1 gap-2">
                        <Input value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter your query here." />
                    </div>
                    <Button type="submit" size="sm" className="px-3" onClick={handleSendQuery} disabled={isQuerying}>
                        { isQuerying ? "Querying..." : "Send Query" }
                    </Button>
                    <Button type="submit" size="sm" className="px-3" onClick={handleClear}>
                        Clear
                    </Button>
                </div>
                <ScrollArea className="max-h-[480px] h-fit">
                    <div className="h-fit pr-4">
                        {queryResults.map((doc, index) => (
                            <Card key={index} className="w-full mb-4 bg-gray-100">
                                <CardHeader className="py-2 px-6">
                                    <div className="grid grid-cols-2 gap-2 text-xs">
                                        <div>
                                            <span className="font-semibold">File Key: </span>
                                            <span className="text-gray-600 break-words">{doc.file_key}</span>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Page Number: </span>
                                            <span>{doc.page_number}</span>
                                        </div>
                                        <div className="col-span-2">
                                            <span className="font-semibold">URL: </span>
                                            <a href={doc.url} target="_blank" className="hover:underline text-blue-400">{doc.document_name}</a>
                                        </div>
                                        <div>
                                            <span className="font-semibold">Score: </span>
                                            <span>{doc.distance}</span>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent className="space-y-2 py-2 px-6">
                                    <div>
                                        <h3 className="font-semibold mb-2 text-sm">Text:</h3>
                                        <ScrollArea className="max-h-[150px] w-full rounded-md p-0 border bg-white">
                                            <p className="text-xs whitespace-pre-wrap p-2 border-none">{doc.text.trim()}</p>
                                        </ScrollArea>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center space-x-2">
                                            <Switch
                                                checked={viewerShownMap[index] || false}
                                                onCheckedChange={() => toggleViewer(index)}
                                            />
                                            {viewerShownMap[index] ? (
                                                <EyeIcon className="h-4 w-4 text-gray-500" />
                                            ) : (
                                                <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                            )}
                                        </div>
                                        <div className="">
                                            {viewerShownMap[index] && doc.url && (
                                                doc.document_type === "application/pdf" ? (
                                                    // Render iframe for PDF files
                                                    <iframe src={doc.url} frameborder="0" className="w-full h-[400px]"></iframe>
                                                ) : (
                                                    // Render iframe for other document types (office files)
                                                    <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.url)}`} frameborder="0" className="w-full h-[400px]"></iframe>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </ScrollArea>
                {message && (
                    <Label>
                        {message}
                    </Label>
                )}
                {/* <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter> */}
            </DialogContent>
        </Dialog>
    );
}
