import React, { useEffect } from "react";
import { useRef, useState } from "react";
// import "./questionField.css";
import styled from 'styled-components'
import TextAreaAuto  from './textAreaAuto'
import { FaTrash } from "react-icons/fa";
const DeleteButton = styled.button`
    cursor: ${(props) => props.resLoading ? 'not-allowed' :  'pointer'};
    opacity: ${(props) => props.resLoading ? '0.5' :  '1'};
    margin: 0 5px 0 5px;
    padding: 4px;
    border-radius: 4px;
    border-width: 0px;
    width: 100px;
    background-color: rgb(175, 175, 175);
    color: rgb(31, 31, 31);
    height: 40px;
    &:hover {
      background-color: rgb(134, 134, 134);
    }
`
const TextAreaContext = styled.textarea`
    height: fit-content;
    border-radius: 8px;
    border-width: 1px;
    padding: 10px;
    width: 100%;
    resize: vertical;
    text-wrap: true;
    font-family: inherit;
`

const QueryRow = styled.div`
    display: flex;
    flex-direction:column;

    gap: 5px;
    flex-grow: 1;
    & div {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap:5px;
      & p {
        width: 80px;
      }
    }
`
const ButtonsDiv = styled.div`
    display: flex;
    padding: 10px
    align-items: center;
    justify-content: center;
    width: 10%;
    color: grey;
    opacity: ${(props)=>props.$resLoading ? "0.5" : "1"};
    
    & svg {
      transition: scale 0.3s;
      &:hover {
        cursor: ${(props)=>props.$resLoading ? "not-allowed" : "cursor"};
        scale: 1.1;
        color: var(--primary-color);
      }
    }
`

const PerQueryDiv = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #d9d9d9;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
`
export default function ({ textLabel, deleteButton, indexVal, setObj, textAreaVal, resLoading }) {
  // const textArea = useRef(null);
  // console.log(textAreaVal);



  const deleteFunc = () => {
    setObj.setQ((prevQuestions) =>
      prevQuestions.filter((item, i) => i !== indexVal)
    );
  };

  const handleContextChange = (event) => {
    // setMessage(event.target.value);
    setObj.setQ((prevArray) => {
      // Create a new array by mapping over the previous array
      const newArray = prevArray.map((item, i) => {
        // If this is the item we want to update
        if (i === indexVal) {
          // Return a new object with the old item and the new key-value pair
          return { ...item, context: event.target.value };
        }
        // Otherwise, return the old item
        return item;
      });
      // Return the new array from the function
      return newArray;
    });
  };
  const handleQueryChange = (event) => {
    // setMessage(event.target.value);
    setObj.setQ((prevArray) => {
      // Create a new array by mapping over the previous array
      const newArray = prevArray.map((item, i) => {
        // If this is the item we want to update
        if (i === indexVal) {
          // Return a new object with the old item and the new key-value pair
          return { ...item, textValue: event.target.value };
        }
        // Otherwise, return the old item
        return item;
      });
      // Return the new array from the function
      return newArray;
    });
  };

  // useEffect(() => {
  //   setObj.setQ((prevArray) => {
  //     // Create a new array by mapping over the previous array
  //     const newArray = prevArray.map((item, i) => {
  //       // If this is the item we want to update
  //       if (i === indexVal) {
  //         // Return a new object with the old item and the new key-value pair
  //         return { ...item, textValue: message };
  //       }
  //       // Otherwise, return the old item
  //       return item;
  //     });
  //     // Return the new array from the function
  //     return newArray;
  //   });
  // }, [message]);

  return (
    <PerQueryDiv>
        <QueryRow>

        <div>
          <p>Context</p>
          <TextAreaAuto
            // ref={textArea}
            value={textAreaVal.context}
            onChangeText={handleContextChange}
            size="30px"
          />
        </div>
        <div>
        <p>Query</p>
          <TextAreaAuto
            // ref={textArea}
            value={textAreaVal.query}
            onChangeText={handleQueryChange}
            size="30px"
          />
        </div>
        </QueryRow>
        <ButtonsDiv $resLoading={resLoading} >
            {deleteButton ? (
            <FaTrash size={"1.4em"} onClick={()=> {
              console.log('FROM DELETE',resLoading)
              if(!resLoading){
                console.log('delete')
                deleteFunc();
              }
              
            }} />
            ) : (
              ""
            )}
          </ButtonsDiv>
    </PerQueryDiv>
  );
}
