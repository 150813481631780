import React, { useContext, useEffect, useState} from 'react'
import './secure-admins-permissions-styles.css'
import { Context } from '../../../../context/GlobalState'
import { httpRequest } from '../../../../utils/httpsRequest';
import { MdDelete, MdEdit } from 'react-icons/md';
import AlertModal from '../../../../components/Alertmodal';
import NewStyledTable from 'components/ui/StyledTable';
import AddAdminForm from './AddAdminForm';


export default function AdminList() {
    const [loading, setLoading] = useState(false);

    // Modals
    const [openAddAdminModal, setOpenAddAdminModal] = useState(false);
    const [openAddRoleModal, setOpenAddRoleModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);

    // Inputs
    const [userInput, setUserInput] = useState({ email: '', password: '', name: '', role: '' });
    const [roleInput, setRoleInput] = useState({ name: '', permissions: '' });
    const [btnLoading, setBtnLoading] = useState(false);
    const { addNewNotifcation } = useContext(Context);
    const [adminTableData, setAdminTableData] = useState([]);
    const [alertModal, setAlertModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    useEffect(() => {
        httpRequest.get('/admin/get-admins')
            .then(async (res) => {
                console.log("response", res);
                const editable = res.data.map((item) => {
                    if (item.role === 'superadmin') {
                        item.editable = false
                    }
                    // console.log(item);
                    return {id: item._id, name: item.name, email: item.email, role: item.role.name};
                })
                // console.log('editable: ', editable);
                setAdminTableData(editable);
            }).catch((error) => {
                console.log(error);
            })
    }, [openAddAdminModal]);

    useEffect(() => {
        setUserInput({ email: '', password: '', name: '', role: '' });
        setRoleInput({ name: '', permissions: '' });

    }, [openAddAdminModal, openAddRoleModal]);


    const handleAddAdminOnSubmit = ((e) => {
        if (!userInput.email || !userInput.password || !userInput.name || !userInput.role) {
            return addNewNotifcation('Please fill in all fields.', 'warning');
        }

        e.preventDefault()
        setLoading(true)
        httpRequest.post(`/admin/add-admin`, {
            ...userInput
        })
        .then(async (res) => {
            if (res.status == 200){
                addNewNotifcation('Admin has been added successfully added.', 'success');
                setOpenAddAdminModal(false);
                setUserInput({ email: '', password: '', name: '', role: '' });
            }
            console.log(res);
        }).catch((error) => {
            console.log('error: ', error);
            // Need to determin error, backend return response status to which we can notify why the system failed to add the admin.
            addNewNotifcation('Failed to add admin.', 'error');
        }).finally(() => {
            setLoading(false)
        })
    });

    const handleAddRoleOnSubmit = ((e) => {
        e.preventDefault()
        setLoading(true)
        // Add role endpoint
        let perms = [];
        perms.push("65ffec9dd16bc538123b5edf");
        perms.push("65ffeca1d16bc538123b691f");

        roleInput.permissions = perms;
        console.log(roleInput);
        httpRequest.post(`/role/add-role`, {
            ...roleInput
        })
        .then(async (res) => {
            if (res.status == 200){
                addNewNotifcation('Admin has been added successfully added.', 'success');
                setOpenAddRoleModal(false);
                setRoleInput({ name: '', permissions: '' });
            }
            console.log(res);
        }).catch((error) => {
            console.log('error: ', error);
            // Need to determin error, backend return response status to which we can notify why the system failed to add the admin.
            addNewNotifcation('Failed to add role.', 'error');
        }).finally(() => {
            setLoading(false)
        })
    });


    // Delete Click Select Item
    const handleDelete = (itemId) => {
        setAlertModal(true);
        const data = adminTableData.find((item) => item.id === itemId);
        setSelectedItem(data);
    }

    // Update Click Select Item
    const handleUpdate = (itemId) => {
        setOpenUpdateModal(true);
        const data = adminTableData.find((item) => item.id === itemId);
        setSelectedItem(data);
    }


    const handleOnDelete = () => {
        setBtnLoading(true);

        console.log('id', selectedItem.id)
        httpRequest.delete(`/admin/delete-admin/${selectedItem.id}`)
        .then((res) => {
            setAdminTableData(prev => {
                return prev.filter((item) => item.id !== selectedItem.id)
            })
            console.log('Response', res);
            addNewNotifcation('Admin removed successfully.', 'success');
            setAlertModal(false);
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.status === 404)
                addNewNotifcation('Admin could not be removed. Admin is not registered. Please check all your entries.', 'warning')
            else
                addNewNotifcation('Admin could not be removed. Please try again.', "danger")
        })
        .finally(() => {
            setBtnLoading(false)
        });
    }

    const subOrgRowActions = [
        {'name': 'Edit', 'icon': <MdEdit size={18} />, 'onclick': handleUpdate},
        {'name': 'Remove', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDelete},
    ]

    const subOrgTableActions = [
        {'name': 'Delete Selected', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': () => {}},
        {'name': 'Add New', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': () => setOpenAddAdminModal(true)}
    ]

    return (
        <div className='secure-admins-permissions-container'>
            <div className='secure-admins-permissions-header-container'>

                <NewStyledTable
                cardTitle={`Admins & Permissions`}
                columns={[
                    { 
                        field: 'name', 
                        headerName: 'Name', 
                    }, 
                    { 
                    field: 'email', 
                    headerName: 'Email', 
                    },
                    { 
                    field: 'role', 
                    headerName: 'Role', 
                    }
                ]}
                data={adminTableData}
                rowActions={subOrgRowActions}
                tableActions={subOrgTableActions}
                />

                {/* {userAdmin.role.name === 'superadmin' && ( */}

                {/* add new admin dialog */}
                <AddAdminForm userInput={userInput} setUserInput={setUserInput} openAddAdminModal={openAddAdminModal} setOpenAddAdminModal={setOpenAddAdminModal} handleAddAdminOnSubmit={handleAddAdminOnSubmit} btnLoading={loading} />

                {/* delete admin alert */}
                <AlertModal loading={btnLoading} openModal={alertModal} setopenModal={setAlertModal} onDelete={handleOnDelete} />
            </div>
        </div>
    )
}
