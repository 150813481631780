import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { RiRobot2Line } from "react-icons/ri";
import { LuSendHorizonal } from "react-icons/lu";
import { ChatDocument } from "../pages/LegalContract/components/ConversationPage/utils/httpRequests-1";
import Markdown from "react-markdown";
import renderers from "../pages/LegalContract/renderers/renderers";
import remarkGfm from "remark-gfm";
import { MessageSquareOff } from 'lucide-react';
import { Button } from "./ui/button";

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
//   height: 100%;
// flex-grow:1;
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   width: 30%;
  position: relative;
//   margin-left: 100px;
font-size: 0.8rem;
`;

const Header = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: start;
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #ccc;
`;

const MessageList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: 10px;
`;

const MessageItem = styled.div`
  display: flex;
  align-items: flex-start; /* Align items at the start */
  margin-bottom: 10px;
  ${(props) =>
    props.isUser ? "" : "justify-content: flex-start;"}
  flex-direction: ${(props) => (props.isUser ? "row-reverse" : "row")}; /* Adjust direction based on user */
`;

const MessageContent = styled.div`
  background-color: ${(props) => (props.isUser ? "#e0e0e0" : "#0000")};
  color: #333;
  padding: 10px;
  border-radius: 18px;
  max-width: 80%;
  display: flex;
  align-items: center;
  /box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);/
  margin-right:30px;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  margin-top: 5px;
  flex-shrink: 0;
  background-color: #6f6f6f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;

`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const MessageInput = styled.textarea`
  flex: 1;
  padding: 10px;
  border: none;
  resize: none;
  outline: none;
  font: inherit;
  background-color: white;
  border-radius: 18px;
  padding-left: 15px;
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
`;

const SendIcon = styled.span`
  font-size: 18px;
  color: grey;
`;

const TypingLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: 5px 0;

  div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #b1092a;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }

  div:nth-child(1) {
    animation-delay: -0.32s;
  }

  div:nth-child(2) {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const WebChat = ({ model, className, parentMessage, SendFunction, SendFunctionParams }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false); // State to manage loading dots
  const inputRef = useRef(null);
  const messageListRef = useRef(null);
  
  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };
  
  const handleClearMessage = () =>{
    if (parentMessage) {
      parentMessage.setMessages([]);
  } else {
      setMessages([]);
  }
  }
  const handleSendMessage = () => {
    if(!isLoading && !parentMessage.messageLoading){
      let paramsCopy = { ...SendFunctionParams };
      if (parentMessage) {
          parentMessage.setMessageLoading(true);
          parentMessage.setMessages((curr) => [...curr, { role: 'user', content: inputRef.current.value }]);
          paramsCopy.messages = [...[...parentMessage.messages].slice(-5), { role: 'user', content: inputRef.current.value }];
      } else {
          setIsLoading(true);
          setMessages((curr) => [...curr, { role: 'user', content: inputRef.current.value }]);
          paramsCopy.messages = [...[...messages].slice(-5), { role: 'user', content: inputRef.current.value }];
      }
      setInputMessage('');
      console.log(paramsCopy);

      SendFunction(paramsCopy)
        .then((response)=>{
          if (parentMessage) {
              parentMessage.setMessageLoading(false);
              parentMessage.setMessages((curr) => [...curr, { role: 'assistant', content: response.data.reply }]);
          } else {
              setIsLoading(false);
              setMessages((curr) => [...curr, { role: 'assistant', content: response.data.reply }]);
          }
        }
      )
      .catch((e)=>{
        if (parentMessage) {
          parentMessage.setMessageLoading(false);
        } else {
          setIsLoading(false);
        }
        console.log('Message not sent', e)

      })
  }
}


  const handleEnterPressed = (e) => {
    if(e.key === 'Enter' && !e.shiftKey){
        e.preventDefault()
        handleSendMessage()
    }
  }


  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages, parentMessage?.messages, isLoading, parentMessage?.messageLoading]);

  const handleDisplayMessage = () => {
    if(parentMessage){
        return (<>
        {parentMessage.messageLoading && (
            <MessageItem>
              <TypingLoader>
                <div></div>
                <div></div>
                <div></div>
              </TypingLoader>
            </MessageItem>
        )
        } 
        {parentMessage.messages.map((message, index) => (
            <MessageItem key={index} isUser={message.role === "user"}>
              {message.role !== "user" && <Avatar><RiRobot2Line /></Avatar>}
              <MessageContent isUser={message.role === "user"}>
                <Markdown className="custom-markdown" components={renderers} remarkPlugins={[remarkGfm]}>
                  {message.content}
                </Markdown>
              </MessageContent>
            </MessageItem>
          )).reverse()}
          
        </>)
    } else {
        return (<>
        {isLoading && (
            <MessageItem>
              <TypingLoader>
                <div></div>
                <div></div>
                <div></div>
              </TypingLoader>
            </MessageItem>
        )
        } 
        {messages.map((message, index) => (
            <MessageItem key={index} isUser={message.role === "user"}>
              {message.role !== "user" && <Avatar><RiRobot2Line /></Avatar>}
              <MessageContent isUser={message.role === "user"}>
                <Markdown className="custom-markdown" components={renderers} remarkPlugins={[remarkGfm]}>
                  {message.content}
                </Markdown>
              </MessageContent>
            </MessageItem>
          )).reverse()}
          
        </>)
    }
  }
  return (
    <ChatContainer className={className}>
      <Header className="flex items-center justify-between">
        <span>BeX Assistant</span>
        <Button onClick={handleClearMessage} className="text-xs rounded-full">Clear</Button>
      </Header>
      <MessageList ref={messageListRef}>
        {handleDisplayMessage()}
      </MessageList>
      <InputContainer>
        <MessageInput
          ref={inputRef}
          type="text"
          placeholder="Type something..."
          value={inputMessage}
          onChange={handleInputChange}
          onKeyDown={handleEnterPressed}
        />
        <SendButton onClick={()=>handleSendMessage()}>
          <SendIcon><LuSendHorizonal /></SendIcon>
        </SendButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default WebChat;