import React, { useContext, useEffect, useState } from 'react'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { Card, CardContent, CardFooter, CardTitle } from '../../../components/ui/card'
import { Label } from '../../../components/ui/label'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog"
import { httpRequest } from '../../../utils/httpsRequest'
import { Context } from '../../../context/GlobalState'


const ProfileCard = ({ name, email }) => {
  
    const [isOpen, setIsOpen] = useState(false)
    const [isPasswordOpen, setIsPasswordOpen] = useState(false)
    const [nameInput, setNameInput] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const { user, addNewNotifcation, actions } = useContext(Context);

    useEffect(() => {
        setNameInput(name)
        setEmailInput(email)
    }, [])


    const handleUpdateInfo = (e) => {
        e.preventDefault()

                
        if (!nameInput) {
            return addNewNotifcation('Please fill in all fileds', 'warning');
        }
        
        const nameObj = {
            userId: user.id,
            name: nameInput
        }
        setBtnLoading(true);

        httpRequest.post('/db/users/update-name', nameObj)
            .then((res) => {
                actions({ type: 'SET_USER', payload: { ...user, name: nameInput } })
                addNewNotifcation('User Name updated successfully.', 'success');
                setIsOpen(false);
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 404)
                    addNewNotifcation('User Name could not be updated. User is not registered. Please check all your entries.', 'warning')
                else
                    addNewNotifcation('User name could not be updated. Please check all your entries.', "danger")
            })
            .finally(() => setBtnLoading(false));

    }


    return (
        <div className='flex w-full mt-4'>
            <Card className="w-full space-y-8 p-4">
                <CardTitle> Profile Information </CardTitle>
                <CardContent>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-col gap-2'>
                            <Label className='text-gray-500'> Name </Label>
                            <span className='font-[500]'>{name}</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <Label className='text-gray-500'> Email </Label>
                            <span className='font-[500]'>{email}</span>
                        </div>
                    </div>
                </CardContent>
                <CardFooter>
                    <div className='flex w-full gap-3 justify-end'>
                        <Button variant='outline' onClick={() => setIsPasswordOpen(true)}> Update Password </Button>
                        <Button onClick={() => setIsOpen(true)}> Update Information </Button>
                    </div>
                </CardFooter>
            </Card>
            
            {/* update personal info modal */}
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="sm:max-w-[600px]">
                    <DialogHeader>
                    <DialogTitle>Update Information</DialogTitle>
                    <DialogDescription>Update your personal information.</DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleUpdateInfo} className="grid gap-4">
                        <div className='flex flex-col gap-3'>
                            <div>
                                <Label> Name </Label>
                                <Input tpye='text' name='name' value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                            </div>
                            <div>
                                <Label> Email </Label>
                                <Input tpye='text' name='email' value={emailInput} onChange={(e) => setEmailInput(e.target.value)} disabled />
                            </div>
                        </div>
                        <DialogFooter>
                            <Button disabled={btnLoading} type="submit">Update</Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
            
            {/* update password modal */}
            <UpdatePasswordModal isPasswordOpen={isPasswordOpen} setIsPasswordOpen={setIsPasswordOpen} />
        </div>
    )
}

const UpdatePasswordModal = ({ isPasswordOpen, setIsPasswordOpen }) => {

    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)

    const { user, addNewNotifcation } = useContext(Context);



    const handleResetPass = (e) => {
        e.preventDefault()

        if (!newPassword || !password) {
            return addNewNotifcation('Please fill in all fileds', 'warning');
        } else if (newPassword !== confirmNewPassword) {
            return addNewNotifcation('Password does not match. Please check all your entries.', 'warning');
        } else if (newPassword.length < 8) {
            return addNewNotifcation('New password can not be less than 8 character.', 'warning');
        }
        
        const passObj = {
            userId: user.id,
            oldPassword: password,
            newPassword: newPassword
        }
        console.log(passObj);
        setBtnLoading(true);

        httpRequest.post('/db/users/update-password', passObj)
        .then((res) => {
            console.log('Response', res);
            addNewNotifcation('Password has been updated successfully.', 'success');

            setIsPasswordOpen(false);
            setPassword('')
            setNewPassword('')
            setConfirmNewPassword('')
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.status === 404)
                addNewNotifcation('Password could not be updated. User is not registered. Please check all your entries.', 'warning')
            else if (error.response?.status === 400)
                addNewNotifcation('Password could not be updated. Old password is not correct. Please enter the correct password.', 'danger')
            else
                addNewNotifcation('Password could not be updated. Please check all your entries.', 'danger')
        })
        .finally(() => setBtnLoading(false));

    };

    return (
        <Dialog open={isPasswordOpen} onOpenChange={setIsPasswordOpen}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                <DialogTitle>Update Password</DialogTitle>
                <DialogDescription>Update your passowrd.</DialogDescription>
                </DialogHeader>
                <form onSubmit={handleResetPass} className="grid gap-4">
                    <div className='flex flex-col gap-3'>
                        <div>
                            <Label> Password</Label>
                            <Input value={password} name='password' type='password' onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div>
                            <Label> New Password </Label>
                            <Input value={newPassword} name='new-password' type='password' onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        <div>
                            <Label> Confirm New Password </Label>
                            <Input value={confirmNewPassword} name='old-password' type='password' onChange={(e) => setConfirmNewPassword(e.target.value)} />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={handleResetPass} disabled={btnLoading} type="submit">Update</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default ProfileCard