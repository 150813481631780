import React, { useEffect, useState } from 'react'
import { httpRequest } from '../../../utils/httpsRequest'
import {Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent} from '../../../components/ui/card'
import { Button } from '../../../components/ui/button'
import { Link } from 'react-router-dom'
import  PackageForm from './PackageForm'

const Packages = () => {
    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [packageDetails, setPackageDetails] = useState(null);

    useEffect(() => {
        httpRequest.get('db/packages/get-packages')
        .then((res) => {
            setPackages(res.data)
            setLoading(false)
        }
        ).catch((error) => {
            console.log('error: ', error);
        })
        .finally(() => {
            setLoading(false)
        })
    }, [])

    const handleAddPackage = () => {
        httpRequest.post('db/packages/add-package', {
            name: packageDetails.name,
            assistants: packageDetails.assistants,
            description: packageDetails.description,
            channels: packageDetails.channels,
            knowledgeBase: packageDetails.knowledgeBase,
            opCodes: packageDetails.opCodes,
            price: packageDetails.price,
        }).then((res) => {
            setPackages([...packages, res.data])
            console.log('res: ', res);
        }
        ).catch((error) => {
            console.log('error: ', error);
        })  
    }

    function truncateString(str, num) {
        if (str.length <= num) {
          return str;
        }
        return str.slice(0, num) + '...';
      }

    return (
        <div className="flex flex-col w-full m-4 p-4">
            <div className="flex flex-row justify-between">
                <h1 className="text-2xl font-bold">Packages</h1>
                <Button onClick={() => setShow(true)}>Add Package</Button>
            </div>
            <div className="flex flex-row flex-wrap m-4">
                {loading ? <h1>Loading...</h1> : packages.map((packageItem) => (
                    <Card key={packageItem._id} className="w-1/4 m-2 h-40">
                        <CardHeader>
                            <CardTitle>{packageItem.name}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <CardDescription>{truncateString(packageItem.description, 50)}</CardDescription>
                        </CardContent>
                        <CardFooter>
                            <Link to={`/secure/packages/${packageItem._id}`}>View Package</Link>
                        </CardFooter>
                    </Card>
                ))}
            </div>
            <PackageForm show={show} setShow={setShow} setPackageDetails={setPackageDetails} handleAddPackage={handleAddPackage}/>
        </div>
    );
};

export default Packages;