import { CircleCheck } from "lucide-react";
import { Progress } from "../../../../../components/ui/progress";
import React, { useState } from "react";
import styled from "styled-components";


export default function DocumentUploadItem ({label, status, progress=0}) {

    return (
        <div className="border bg-background border-solid p-2 rounded-md mt-1">
            <div className="flex justify-between items-center">
                <span className="block text-lg">{label}</span>
                <span className="block text-lg">{progress === 100 ? <CircleCheck color="green"/>  : null}</span>
            </div>
            <span className="block text-sm text-muted-foreground">Status: {status}</span>
            <div className="flex items-center gap-2 ">
                <Progress value={progress} />
                <span>{progress}%</span>
            </div>
        </div>
    )
}