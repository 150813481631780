import React from 'react';

export default function SetupOverview({ title, descriptions }) {
    return (
        <div className="text-center">
            {/* App Title */}
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-4xl dark:text-gray-50">
                {title}
            </h1>
            
            {/* App Description */}
            {Array.isArray(descriptions) ? (
                descriptions.map((desc, index) => (
                    <p key={index} className="mt-4 text-xl text-gray-600 dark:text-gray-400 font-light text-justify">
                        {desc}
                    </p>
                ))
            ) : (
                <p className="mt-4 text-xl text-gray-600 dark:text-gray-400 font-light text-justify">
                    {descriptions}
                </p>
            )}
        </div>
    );
}