import React, { useContext } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/ui/dialog"
import { Context } from "context/GlobalState"
import { channelService } from "api"
import { NewChannelInput } from './WebChatSetup'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'


export default function UpdateWebChannelWrapper({ isOpen, setOpenEmailModal, emailUpdateObject, setEmailChannels }) {

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px] h-[650px] overflow-scroll">
                <DialogHeader>
                    <DialogTitle>Update Web Channel</DialogTitle>
                </DialogHeader>
                <Tabs defaultValue="general" className="space-y-4">
                    <TabsList className='w-full'>
                        <TabsTrigger className='w-full' value="general">General</TabsTrigger>
                        <TabsTrigger className='w-full' value="Configs">Configs</TabsTrigger>
                    </TabsList>
                    
                    <TabsContent value="general">
                        <WebChannelUpdateDialog setOpenEmailModal={setOpenEmailModal} emailUpdateObject={emailUpdateObject} setEmailChannels={setEmailChannels} />
                    </TabsContent>
                    <TabsContent value="Configs">
                        <div className='flex flex-grow'>
                            <span>Configs</span>
                        </div>
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog>

    )
}

const WebChannelUpdateDialog = ({ setOpenEmailModal, emailUpdateObject, setEmailChannels }) => {

    const { addNewNotifcation } = useContext(Context)

    const handleOnSubmit = async (channelObj) => {

        const channelObj1 = {
            organizationId: channelObj.organizationId,
            subOrganizationId: channelObj.subOrganizationId,
            channelId: channelObj._id,
            welcomeMessage: '',
            homePageMeaage: '',
            topic: channelObj.topic,
            address: channelObj.address,
            origins: channelObj.origins,
            assistantId: channelObj?.assistantId === 'none' ? '' : channelObj?.assistantId
        }

        try {
            const res = await channelService.updateChannel(channelObj1)
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...channelObj}
                    } else {
                        return {...item}
                    }
                })
            })
            addNewNotifcation('Channel Updated successfully', 'success')
            setOpenEmailModal(false)
        } catch(error) {
            console.log('error: ', error);
            if (error.response?.status === 404) {  
                addNewNotifcation('Channel not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }
    }

    if (!emailUpdateObject) return 

    return (
        <NewChannelInput
        newChannelObj={emailUpdateObject}
        isUpdate={true}
        channelType={'web-chat'} setOpenEmailModal={setOpenEmailModal} 
        onSubmit={handleOnSubmit} />
    )
}
