import { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal"
import { PrimaryButton, RedButton, SecondaryButton, WhiteButton } from "../../components/Buttons";
import styled from "styled-components";
import AlertModal from "../../components/Alertmodal";
import { PopUpModal } from "../Manager/EntityIntent/components/PopUpModal";
import { Container, PrimaryInput } from "../../components/FormInput";
import { LoaderSpinner } from "../../components/LoaderSpinner";
import { UploadDocument } from "../../components/UploadFiles";
import { Context } from "../../context/GlobalState";
import { StyledPrimaryCard } from "../../components/Cards/SquareCard";
import { MdBugReport } from "react-icons/md";
import Dropdown from "../Knowledge/Projects/SelectDropdown";

const StyleDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
`

const StyledDemoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const StyledFelx = styled.div`
    display: flex;
    width: 100%;
`

const ComponentDemo = ({ children, show, setShow, visible=false }) => {
    

    useEffect(() => {
        if (visible) {
            setShow(true)
        }
    }, [visible])

    return (
        <StyledDemoContainer>
            <StyledFelx>
                {!visible && <RedButton onClick={() => setShow(!show)}>Try it</RedButton>}
            </StyledFelx>
            <StyledFelx>
                {children}
            </StyledFelx>
        </StyledDemoContainer>
    )
}

export const PrimaryButtonDemo = () => {
    const [show, setShow] = useState(false)


    return (
        <ComponentDemo id='buttons' show={show} setShow={setShow} visible={true}>
            <StyleDiv>
                <PrimaryButton $text='button'  />
                <PrimaryButton $loading={true} $text='button'  />
            </StyleDiv>
        </ComponentDemo>
    )
}

export const SecondaryButtonDemo = () => {
    const [show, setShow] = useState(false)


    return (
        <ComponentDemo id='buttons' show={show} setShow={setShow} visible={true}>
            <StyleDiv>
                <SecondaryButton $text='button'  />
                <SecondaryButton $loading={true} $text='button'  />
            </StyleDiv>
        </ComponentDemo>
    )
}

export const DemoModeButtonDemo = () => {
    const [show, setShow] = useState(false)


    return (
        <ComponentDemo id='buttons' show={show} setShow={setShow} visible={true}>
            <StyleDiv>
                <RedButton> Button </RedButton>
                <RedButton disabled={true} > Button </RedButton>
                <WhiteButton> Button </WhiteButton>
                <WhiteButton disabled={true} > Button </WhiteButton>
            </StyleDiv>
        </ComponentDemo>
    )
}



export const ModalDemo = () => {

    const [show, setShow] = useState(false)

    // const stringFunction = "() => setShow(!show)";
    // const actualFunction = eval(`(${stringFunction})`);

    return (
        <ComponentDemo setShow={setShow} show={show}>
            <Modal setOpenModal={setShow} $isOpen={show}>
                <Modal.Header>Header</Modal.Header>
                <Modal.Body>
                    Body
                </Modal.Body>
                <Modal.Footer>
                    Footer
                </Modal.Footer>
            </Modal>
        </ComponentDemo>
    )
}

export const CustomFormModalDemo = () => {

    const [show, setShow] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    // const stringFunction = "() => setShow(!show)";
    // const actualFunction = eval(`(${stringFunction})`);

    return (
        <ComponentDemo setShow={setShow} show={show}>
            <PopUpModal setOpenModal={setShow} openModal={show} text={'Form Modal'}>
                <form>
                    <Container labelName={'Name'}>
                        <PrimaryInput type="text" />
                    </Container>
                </form>
                <button
                    onClick={() => {}}>
                    {btnLoading ? <LoaderSpinner /> : 'Create'}</button>
            </PopUpModal>
        </ComponentDemo>
    )
}


export const AlertModalDemo = () => {
    const [show, setShow] = useState(false)


    return (
        <ComponentDemo show={show} setShow={setShow}>
            <AlertModal openModal={show} setopenModal={setShow} onDelete={() => {}} loading={false} />
        </ComponentDemo>
    )
}

export const DragAndDropDemo = () => {
    const [show, setShow] = useState(true)
    const [files, setFiles] = useState([])

    return (
        <ComponentDemo show={show} setShow={setShow} visible={true}>
            <UploadDocument multiple files={files} onChange={(e) => setFiles(e.target.files)} />
        </ComponentDemo>
    )
}

export const NotificationDemo = () => {
    const [show, setShow] = useState(true)
    const { addNewNotifcation } = useContext(Context)


    const handleAddSuccessNotification = () => {
        addNewNotifcation('Success Notification', 'success')
    }
    const handleAddWaringNotification = () => {
        addNewNotifcation('Waring Notification', 'waring')
    }

    const handleAddDangerNotification = () => {
        addNewNotifcation('Danger Notification', 'danger')
    }

    return (
        <ComponentDemo show={show} setShow={setShow} visible={true}>
            <RedButton onClick={handleAddSuccessNotification}> Add Success Notification </RedButton>
            <WhiteButton onClick={handleAddWaringNotification}> Add Waring Notification </WhiteButton>
            <WhiteButton onClick={handleAddDangerNotification}> Add Danger Notification </WhiteButton>
        </ComponentDemo>
    )
}

export const PrimaryCardDemo = () => {
    const [show, setShow] = useState(true)


    const optionOne = () => {
        console.log('option One onClick');
    }

    const optionTwo = () => {
        console.log('option two onClick');

    }

    const options = [
        {name: 'Option one', onClick: optionOne},
        {name: 'Option two', onClick: optionTwo, color: 'var(--primary-color)'},
    ]
    
    return (
        <ComponentDemo show={show} setShow={setShow} visible={true}>
            <StyleDiv>
                <StyledPrimaryCard title={'Card Title'}>
                    Card Content
                </StyledPrimaryCard>

                <StyledPrimaryCard optionItems={options} title={'Card Title With Options'} option={true}>
                    Card Content
                </StyledPrimaryCard>
            </StyleDiv>

        </ComponentDemo>
    )
}

export const DropDownDemo = () => {
    const [show, setShow] = useState(true)


    return (
        <ComponentDemo show={show} setShow={setShow} visible={true}>
            {/* <Dropdown /> */}
            {/* <Dropdown title={'Documents'} options={documents.map((item) => ({ name: item.filename, id: item.document_id }))} onSelect={handleSelectDocs} /> */}

        </ComponentDemo>
    )
}
