import React, { useContext, useState } from 'react'
import { Context } from '../../context/GlobalState'
import styled from 'styled-components'
import { httpRequest } from '../../utils/httpsRequest'
import { LoaderSpinner } from '../../components/LoaderSpinner'
import { authService } from 'api'

const VerifyEmailContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 15px;
    // background-color: 	#fff3cd;
    justify-content: space-between;
    align-items: center;
    // color: #856404;
    // border-color: #ffeeba;
    border: 1px solid #f5c6cb;

    color: #721c24;
    background-color: #f8d7da;
    // border-color: #f5c6cb;
`

const VerifyButton = styled.button`
    padding: 7px 15px;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    cursor: pointer;
    min-width: 100px;
`


export default function VerifyEmailBox() {

    const { user, addNewNotifcation } = useContext(Context)
    const [btnLoading, setBtnLoading] = useState(false)

    if (user.verified) return

    const handleVerifyEmail = () => {

        setBtnLoading(true)
        authService.sendEmail(user.email)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('A verification link was sent to your email.', 'success')
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status == 409) {
                addNewNotifcation('Your email address has already been verified.', 'warning')
            } else {
                addNewNotifcation('Verification link could not be sent to your email. Please check your configuration.', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }


    return (
        <VerifyEmailContainer>
            Your Email is not Verified Yet!
            <VerifyButton onClick={handleVerifyEmail} disabled={btnLoading}>
                {btnLoading ? <LoaderSpinner /> : 'Verify Now'}
            </VerifyButton>
        </VerifyEmailContainer>
    )
}
