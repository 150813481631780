import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";

export const AuthRoute = () => {

    const { isAuth } = useContext(Context)

    const navigate = useNavigate()
    
        
    useEffect(() => {
        if (isAuth) {
            navigate('/organizations');
        }

    }, [isAuth])

    return (
        <Outlet />
    )
    
  };