import React from "react";
import { useRef, useEffect, useState } from "react";
import "./table.css";
import styled from 'styled-components'


const TableExport = styled.table`
`
export default function ({ file, llmRes, tableForExport, mode }) {
  console.log(file);
  console.log(llmRes);
  const tableRes = [];
  const [tableState, setTableState] = useState([]);
  const tableRef = useRef([]);
  const table = [];

  for (let row of file) {
    tableRes.push([[row][0][0]]);
  }
  for (let doc = 0; doc < tableRes.length; doc++) {
    table.push(tableRes[doc][0]);
  }

  //   for(let colHead=0;colHead<llmRes)
  if (mode == "answers") {
    if (llmRes[0]) {
      for (let resp = 0; resp < llmRes[0].llmRes.length; resp++) {
        tableRes[0].push(file[0][resp + 1]);
        console.log(resp);
      }
      for (let doc = 1; doc < tableRes.length; doc++) {
        for (let queryColumn in llmRes) {
          for (let responses in llmRes[queryColumn].llmRes) {
            for (let response of llmRes[queryColumn].llmRes[responses]
              .llmResp) {
              //   console.log(response);
              if (table[doc] == response.documentID) {
                tableRes[doc][0] = response.documentName;
                // console.log(tableRes[doc][0]);
                // tableRes[doc].push(response.documentName);
                tableRes[doc].push(response.llmResponse.reply);
                console.log(response.llmResponse.reply);
              }
            }
          }
        }
      }
      tableRef.current = tableRes;
      tableForExport.current = tableRes;
      console.log("tableRef", tableRef);

      //   setTableState((prevArr) => [...prevArr, tableRef.current]);
      console.log("TABLE STATE:", tableState);
    }
  } else if (mode == "check") {
    if (llmRes[0]) {
      for (let resp = 0; resp < llmRes[0].llmRes.length; resp++) {
        tableRes[0].push(file[0][resp + 1]);
        console.log(resp);
      }
      for (let doc = 1; doc < tableRes.length; doc++) {
        for (let queryDetail in llmRes) {
          for (let responses in llmRes[queryDetail].llmRes) {
            for (let response in llmRes[queryDetail].llmRes[responses]
              .llmResp) {
              //   console.log(response);
              if (
                table[doc] ==
                llmRes[queryDetail].llmRes[responses].llmResp[response]
                  .documentID
              ) {
                //     console.log(file[doc][parseInt(responses) + 1]);
                //   console.log(
                //     llmRes[queryDetail].llmRes[responses].llmResp[response]
                //       .llmResponse.reply
                //   );
                tableRes[doc][0] =
                  llmRes[queryDetail].llmRes[responses].llmResp[
                    response
                  ].documentName;
                if (
                  llmRes[queryDetail].llmRes[responses].llmResp[response]
                    .llmResponse.reply == file[doc][parseInt(responses) + 1]
                ) {
                  tableRes[doc].push("Correct");
                } else {
                  tableRes[doc].push("Wrong");
                }
              }
            }
          }
        }
      }
      tableRef.current = tableRes;
      tableForExport.current = tableRes;
      console.log("tableRef", tableRef);

      //   setTableState((prevArr) => [...prevArr, tableRef.current]);
      console.log("TABLE STATE:", tableState);
    }
  }
  const rowBuilder = (row) => {
    let rowList = [];
    for (let data of row) {
      rowList.push(<td>{data}</td>);
    }
    return [rowList];
  };
  return (
    <>
      <table className="tableForExportClass">
        <thead>
          {console.log("from table:", tableRes)}
          {llmRes[0] ? tableRes[0].map((head) => <th>{head}</th>) : <></>}
        </thead>
        <tbody>
          {llmRes[0] ? (
            tableRes.slice(1).map((head) => <tr>{rowBuilder(head)}</tr>)
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </>
  );
}
