import { httpRequest } from "./httpsRequest"


export const assignUserRole = (userId, organizations) => {
    organizations.forEach((org) => {
        // Check if the user is a superAdmin
        if (org.superAdmin === userId) {
            org.userRole = 'superAdmin';
        } else {
            // If not a superAdmin, check if the user is a member
            const userMember = org.members.find((member) => member.id === userId);
            if (userMember) {
                org.userRole = userMember.role;
            } else {
                org.userRole = null;
            }
        }
    });
    
    // Return the organizations array with roles assigned
    return organizations;
};

export const handleLeaveOrganization = async (oragID, userId) => {

    const leaveOrgObj = {
        organizationId: oragID,
        userId: userId
    }
    try {
        const res = await httpRequest.post('/db/organizations/update_organization/leave-organization', leaveOrgObj)
        console.log('res: ', res);
        window.location = '/organizations'
    } catch(error) {
        console.log('error: ', error);
        throw new Error(error)
    }
}