import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Input } from "components/ui/input"
import { Label } from 'components/ui/label';
import { Textarea } from "components/ui/textarea"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select"
import { Button } from "components/ui/button"
import { useDropzone } from 'react-dropzone';
import { PlayIcon, PauseIcon, UploadIcon } from "@radix-ui/react-icons"
import { httpRequest } from 'utils/httpsRequest';
import { LoaderSpinner } from "components/LoaderSpinner";

/**
 * TODO:
 *    - Handle invalid file sizes
 *    - Handle invalid file formats
 *    - Handle invalid file durations
 *    - Handle playback of audio files
 *    - Handle reaching the end of the audio file
 *    - Handle resetting the audio file
 */
const SpeechToTextForm = () => {
    // Constants
    const STT_SAMPLES = [
        {
          "option": "Audio 1 (Welcome Message)",
          "id": "Sample Audio 1",
          "filename": "Sample Audio 1.mp3"
        },
        {
          "option": "Audio 2 (Update Notification)",
          "id": "Sample Audio 2",
          "filename": "Sample Audio 2.mp3"
        },
        {
          "option": "Audio 3 (Support Availability)",
          "id": "Sample Audio 3",
          "filename": "Sample Audio 3.mp3"
        }
    ];

    // States
    const [files, setFiles] = useState([]);
    const [textResult, setTextResult] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedSample, setSelectedSample] = useState("");
    const [fileMode, setFileMode] = useState(false);
    const uploadedFile = useRef(null);
    const audioRef = useRef(null);
    const location = useLocation();

    // Hooks
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                // On route change reset the audio
                audioRef.current.pause();
            }
        };
    }, [location]);

    // Actions
    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'audio/*',
        onDrop: acceptedFiles => {
            // Remove any sample audio selected
            setSelectedSample("");
            setFileMode(true);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setTextResult("");
            for (let file of acceptedFiles) {
                const reader = new FileReader();
                const form = new FormData();
                form.append("fileaudio", file);
                reader.readAsArrayBuffer(file);
                uploadedFile.current = form;
            }
        }
    });

    const handleSelectChange = (selectedValue) => {
        setSelectedSample(selectedValue);
        setTextResult("");
        setFileMode(false);
        setFiles([]);
    };

    // Refactor: Move all API calls to a global file
    const handleOnSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        if (fileMode) {
            httpRequest.post(`/stt/transcribe-audio`, uploadedFile.current, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    setTextResult(res.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            httpRequest.post(`/stt/transcribe-audio-demo`, {
                    selectedAudio: selectedSample
                })
                .then(res => {
                    setTextResult(res.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    // Refactor: Move all API calls to a global file
    const handlePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            let audio;
            if (selectedSample) {
                switch (selectedSample) {
                    case "Sample Audio 1":
                        audio = new Audio("https://bexplatform.blob.core.windows.net/stt-sample-audio/Sample Audio 1.mp3");
                        break;
                    case "Sample Audio 2":
                        audio = new Audio("https://bexplatform.blob.core.windows.net/stt-sample-audio/Sample Audio 2.mp3");
                        break;
                    case "Sample Audio 3":
                        audio = new Audio("https://bexplatform.blob.core.windows.net/stt-sample-audio/Sample Audio 3.mp3");
                        break;
                    default:
                        break;
                }
            } else {
                audio = new Audio(URL.createObjectURL(files[0]));
            }
            audioRef.current = audio;
            audio.play();
            setIsPlaying(true);
        }
    };

    const deleteFile = (fileName) => {
        const updatedFiles = files.filter(file => file.name !== fileName);
        setFiles(updatedFiles);
    };

    // Getters
    const hasFiles = files.length > 0;
    const isTranscribeEnabled = hasFiles || selectedSample;
    
    return (
        <div className="flex flex-col md:w-full h-fit md:max-w-lg lg:max-w-4xl mx-auto p-4  bg-card rounded-lg shadow-lg">
            <div className="text-xl sm:text-2xl font-bold mb-4">
                Speech to Text
            </div>

            <div className="space-y-4">
                {/* 1: Select Sample Audio */}
                <div className='space-y-2'>
                    <div className='flex items-center space-x-3'>
                        <div 
                            className={selectedSample ? "basis-4/5" : "w-full"}>
                            <Select
                                onValueChange={handleSelectChange}
                                value={selectedSample}
                                defaultValue={selectedSample}>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select A sample Audio" />
                                </SelectTrigger>
                                
                                <SelectContent>
                                    {STT_SAMPLES.map((item, index) => (
                                        <SelectItem key={index} value={item.id}>
                                            {item.option}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        
                        <Button
                            onClick={handlePlay}
                            disabled={!selectedSample}
                            className="self-center basis-1/5">
                            {isPlaying && selectedSample ? 'Pause' : 'Play'}
                        </Button>
                    </div>
                </div>
                
                {/* 2: File Upload */}
                <div className='space-y-2'>
                    <Label htmlFor="audio-file">
                        Drop Audio File
                    </Label>
                    
                    <div 
                        {...getRootProps()}
                        className="flex items-center justify-center w-full border-2 hover:cursor-pointer 
                        border-muted border-dashed rounded-lg px-6 py-10 mt-1">
                        <div className="text-center">
                            <UploadIcon 
                                className="w-12 h-12 text-muted-foreground mb-2 mx-auto" />

                            <p className="text-sm text-muted-foreground">
                                Drag and drop your audio file here, or click to select a file
                            </p>

                            <Input 
                                {...getInputProps()}
                                id="audio-file" 
                                type="file" 
                                className="sr-only" />
                        </div>
                    </div>
                </div>

                {/* 3: File List */}
                <div className='space-y-2'>
                    <Label htmlFor="uploaded-file">
                        Uploaded File
                    </Label>

                    {hasFiles? (
                        <div className="rounded-lg border bg-card text-card-foreground shadow-sm h-full p-2">
                            <ul>
                                {files.map(file => (
                                    <li key={file.name} className="flex items-center justify-between">
                                        <span>
                                            {file.name}
                                        </span>

                                        <div className="flex gap-2">
                                            <Button
                                                onClick={handlePlay}
                                                size="sm">
                                                {isPlaying ? (
                                                    <PauseIcon 
                                                        className="w-4 h-4 mr-2"/>
                                                    ) : (
                                                        <PlayIcon
                                                            className="w-4 h-4 mr-2" />
                                                    )}
                                                {isPlaying ? 'Pause' : 'Play'}
                                            </Button>

                                            <Button
                                                onClick={() => deleteFile(file.name)}
                                                variant="destructive"
                                                size="sm">
                                                Delete
                                            </Button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <p className="text-muted-foreground text-center">
                            No file uploaded
                        </p>
                    )}   
                </div>
                    
                {/* 4: Result */}
                <div className='space-y-2'>
                    <Label htmlFor="transcription-text">
                        Transcription Text Result
                    </Label>
                    
                    <Textarea
                        readOnly
                        id="transcription-text"
                        value={textResult}
                        className="mt-1 h-40 resize-none"
                        placeholder={loading ? 'Loading...' : 'Transcription text will appear here...'} />
                </div>
            
                <Button 
                    onClick={handleOnSubmit}
                    type="submit" 
                    className="w-full"
                    disabled={!isTranscribeEnabled}>
                    {
                        loading ? (
                            <LoaderSpinner />
                        ) : (
                            'Transcribe'
                        )
                    }
                </Button>
            </div>
        </div>
    );
};
export default SpeechToTextForm;