import { projectOHttpClient, createWebSocketClient } from "api/clients/project-o.httpClient";


class OpCodeWebsocketService {

    /**
     * Creates a new opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async createOpCode(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/opcodes/celery/create_opcode`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/celery/ws/create_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    /**
     * Executes an opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async executeOpCode(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/opcodes/celery/execute_opcode`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/celery/ws/execute_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    

}

const opcodeWSService = new OpCodeWebsocketService();
export { opcodeWSService };