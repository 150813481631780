class OpCodeBuilderHandler {
    // Validate values (should not be empty and follow variable naming rules)
    validateOpCodeName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Workflow ID field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Workflow ID cannot contain spaces.` };
        }
        if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
            return { isValid: false, message: `Workflow ID can only contain letters, numbers, and underscores, and must not start with a number.` };
        }
        if (value.length < 4){
            return { isValid: false, message: `Workflow ID should be at least 4 characters long.` };
        }
        return { isValid: true, message: `Valid Workflow ID.` };
    }

    validateOutputName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Step Output field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Step Output cannot contain spaces.` };
        }
        if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
            return { isValid: false, message: `Step Output can only contain letters, numbers, and underscores, and must not start with a number.` };
        }
        if (value.length < 4){
            return { isValid: false, message: `Step Output should be at least 4 characters long.` };
        }
        
        return { isValid: true, message: `Valid Output name.` };
    }

    validateGlobalVariableName(value) {
        // value = value.trim()
        // if (!value) {
        //     return { isValid: false, message: `Field cannot be empty.` };
        // }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Global Variable Name should be at least 4 characters long.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Global Variable Name cannot contain spaces.` };
        }
        return { isValid: true, message: `Valid value.` };
    }
    
    validateVariableName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Variable Name field cannot be empty.` };
        }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Variable Name should be at least 4 characters long.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Variable Name cannot contain spaces.` };
        }
        return { isValid: true, message: `Valid value.` };
    }

    validateInputName(value) {
        // value = value.trim()
        if (!value) {
            return { isValid: false, message: `Step Input field cannot be empty.` };
        }
        if (/\s/.test(value)) {
            return { isValid: false, message: `Step Input cannot contain spaces.` };
        }
        if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
            return { isValid: false, message: `Step Input can only contain letters, numbers, and underscores, and must not start with a number.` };
        }
        if (value.length < 4 && value.length > 0){
            return { isValid: false, message: `Step Input should be at least 4 characters long.` };
        }
        return { isValid: true, message: `Step Input valid.` };
    }


}

// Example usage
const handler = new OpCodeBuilderHandler();
export default handler;
