import React, { useContext, useEffect, useState } from 'react';
import { Button } from "components/ui/button";
import { FileText, Upload } from 'lucide-react';
import * as ShadeDD from "components/ui/dropdown-menu";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Input } from "components/ui/input";
import { GlobalContext } from "../../context/LivePromptContext";
import { Context }  from 'context/GlobalState';
import { useParams } from "react-router-dom";
import DeleteDialog from "./DeleteDialog";
import { ragService } from 'api/services/PROJECT-O';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';

export const DocumentList = () => {
    const { user, addNewNotifcation } = useContext(Context);
    const { oragID, subOragID } = useParams();
    const [filesUser, setFilesUser] = useState([]);
    const [filesOrg, setFilesOrg] = useState([]);

    // Global Context
    const {
        fileProcessLoadingUser, setFileProcessLoadingUser,
        fileProcessLoadingOrg, setFileProcessLoadingOrg,
        uploadedFilesUser, setUploadedFilesUser,
        uploadedFilesOrg, setUploadedFilesOrg,
        selectedDocuments, setSelectedDocuments
    } = useContext (GlobalContext);

    const handleIfSelected = (doc) => {
        if (
          selectedDocuments.find(
            (document) => document._id === doc._id
          )
        )
          return true;
        else return false;
    };


    // Process Files
    const handleFileChangeUser = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFilesUser(selectedFiles);
    }

    const handleFileChangeOrg = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFilesOrg(selectedFiles);
    }

    const handleProcessUserFile = async () => {
        try {
            setFileProcessLoadingUser(true);
            const filter = {
                userId: user._id,
                organizationId: oragID,
                subOrganizationId: subOragID,
                assistant: 'ai-lab',
                feature: "live-prompt",
                document_collection: "user"
            }
            const res = await labHelper.ProcessFiles(filesUser, filter, oragID, process.env.REACT_APP_DEMO_MODE, `${oragID}-bex-demo-mode`, 200, 20);
            addNewNotifcation("User document(s) successfully processed.", "success");
            console.log("Processing results: ", res);
        } catch (err) {
            console.log(err);
            addNewNotifcation("User document(s) not processed. Please try again.", "error");
        }
        finally {
            await loadFiles();
            setFileProcessLoadingUser(false);
        }
        
    }

    const handleProcessOrgFile = async () => {
        try {
            setFileProcessLoadingOrg(true);
            const filter = {
                userId: user._id,
                organizationId: oragID,
                subOrganizationId: subOragID,
                assistant: 'ai-lab',
                feature: "live-prompt",
                document_collection: "organization"
            }
            const res = await labHelper.ProcessFiles(filesOrg, filter, oragID, process.env.REACT_APP_DEMO_MODE, `${oragID}-bex-demo-mode`, 200, 20);
            addNewNotifcation("Organization document(s) successfully processed.", "success");
            console.log("Processing results: ", res);
        } catch (err) {
            console.log(err);
            addNewNotifcation("Organization document(s) not processed. Please try again.", "error");
        }
        finally {
            await loadFiles();
            setFileProcessLoadingOrg(false);
        }
    }

    // Load Files
    const handleLoadUserFiles = async () => {
        try{
            const filter = {
                userId: user._id,
                organizationId: oragID,
                subOrganizationId: subOragID,
                assistant: 'ai-lab',
                feature: "live-prompt",
                document_collection: "user"
            }
            const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, filter)
            setUploadedFilesUser(res.data);
            console.log("Loaded User files: ", res);
        } catch (err) {
            console.log(err);
        }
    }

    const handleLoadOrgFiles = async () => {
        try{
            const filter = {
                // userId: user._id,
                organizationId: oragID,
                // subOrganizationId: subOragID,
                assistant: 'ai-lab',
                feature: "live-prompt",
                document_collection: "organization"
            }
            const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, filter)
            setUploadedFilesOrg(res.data);

            console.log("Loaded Org files: ", res);
        } catch (err) {
            console.log(err);
        }
    }

    const loadFiles = async () => {
        try {
            await Promise.all([handleLoadUserFiles(), handleLoadOrgFiles()]); // Run both at the same time
        } catch (err) {
            console.log("Error loading files: ", err);
        }
    };

    useEffect(() => {
        loadFiles();
    }, []);


    return(
        <ShadeDD.DropdownMenu>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="px-2">
                    <FileText className="w-5 h-5" />
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="end" side="bottom" className="w-[350px]">
                <Tabs defaultValue="user" className="w-full h-fit">
                    <TabsList className="flex w-full grid-cols-2 bg-gray-100">
                        <TabsTrigger className="text-xs" value="user">User Documents</TabsTrigger>
                        <TabsTrigger className="text-xs" value="org">Org Documents</TabsTrigger>
                    </TabsList>
                    <ShadeDD.DropdownMenuSeparator />
                    <TabsContent className="w-full h-full m-0" value="user">
                        {/* <PromptPanelDemo/> */}
                        <ShadeDD.DropdownMenuGroup className='max-h-72 overflow-y-auto'>
                            {uploadedFilesUser.map((doc) => (
                                <div
                                    key={doc._id}
                                    className="w-full flex flex-row gap-2 justify-between">
                                    <ShadeDD.DropdownMenuCheckboxItem
                                        className="w-full overflow-hidden"
                                        
                                        checked={handleIfSelected(doc)}
                                        onSelect={(event) => {
                                        const result = selectedDocuments.find(
                                            (document) => document._id === doc._id
                                        );
                                        // console.log(doc._id)
                                        event.preventDefault();
                                        if (result) {
                                            setSelectedDocuments((prevDocuments) =>
                                                prevDocuments.filter((document) => document._id !== doc._id)
                                            );
                                        } else {
                                            if (selectedDocuments.length < 5){
                                                setSelectedDocuments((prevDocuments) => [
                                                    ...prevDocuments,
                                                    { _id: doc._id, name: doc.file_name, file_url: doc.file_url, org_id: doc.organization_id, sub_org_id: doc.sub_organization_id },
                                                ]);
                                            }
                                            
                                        }
                                        
                                        }}
                                    >
                                        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                            {doc.file_name}
                                        </span>
                                        
                                    </ShadeDD.DropdownMenuCheckboxItem>
                                    <DeleteDialog doc={doc} setSelectedDocuments={setSelectedDocuments} loadFiles={loadFiles}/>
                                </div>
                            
                            ))}
                        </ShadeDD.DropdownMenuGroup>
                        <ShadeDD.DropdownMenuSeparator />
                        <ShadeDD.DropdownMenuLabel className="flex flex-row flex-nowrap items-center gap-2">
                            <Input
                                // ref={fileInputRef}
                                type="file"
                                onChange={handleFileChangeUser}
                                multiple
                            />
                            <Button variant="outline"  className="mr-2" disabled={fileProcessLoadingUser} onClick={handleProcessUserFile}>
                                {fileProcessLoadingUser ? <LoaderSpinner/> : <Upload className="w-5 h-5"/>}
                            </Button>
                        </ShadeDD.DropdownMenuLabel>
                    </TabsContent>
                    <TabsContent className="w-full h-full m-0" value="org">
                        <ShadeDD.DropdownMenuGroup className='max-h-72 overflow-y-auto'>
                            {uploadedFilesOrg.map((doc) => (
                                <div
                                    key={doc._id}
                                    className="w-full flex flex-row gap-2 justify-between">
                                    <ShadeDD.DropdownMenuCheckboxItem
                                        className="w-full overflow-hidden"
                                        
                                        checked={handleIfSelected(doc)}
                                        onSelect={(event) => {
                                        const result = selectedDocuments.find(
                                            (document) => document._id === doc._id
                                        );
                                        // console.log(doc._id)
                                        event.preventDefault();
                                        if (result) {
                                            setSelectedDocuments((prevDocuments) =>
                                                prevDocuments.filter((document) => document._id !== doc._id)
                                            );
                                        } else {
                                            if (selectedDocuments.length < 5){
                                                setSelectedDocuments((prevDocuments) => [
                                                    ...prevDocuments,
                                                    { _id: doc._id, WD_id:doc.WD_id, name: doc.file_name, file_url: doc.file_url },
                                                ]);
                                            }
                                            
                                        }
                                        
                                        }}
                                    >
                                        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                            {doc.file_name}
                                        </span>
                                        
                                    </ShadeDD.DropdownMenuCheckboxItem>
                                    <DeleteDialog doc={doc} setSelectedDocuments={setSelectedDocuments} loadFiles={loadFiles}/>
                                </div>
                            
                            ))}
                        </ShadeDD.DropdownMenuGroup>
                        <ShadeDD.DropdownMenuSeparator />
                        <ShadeDD.DropdownMenuLabel className="flex flex-row flex-nowrap items-center gap-2">
                            <Input
                                // ref={fileInputRefOrg}
                                type="file"
                                onChange={handleFileChangeOrg}
                                multiple
                            />
                            <Button variant="outline"  className="mr-2" disabled={fileProcessLoadingOrg} onClick={handleProcessOrgFile}>
                                {fileProcessLoadingOrg ? <LoaderSpinner/> : <Upload className="w-5 h-5"/>}
                            </Button>
                        </ShadeDD.DropdownMenuLabel>
                    </TabsContent>
                </Tabs>
                {/* <ShadeDD.DropdownMenuLabel>
                    {isLoading ? "Loading documents..." : uploadedDocs.length === 0 ? "No documents" : "Documents"}
                </ShadeDD.DropdownMenuLabel> */}
                {/* <ScrollArea className="h-72 w-full "> */}

                {/* </ScrollArea> */}
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


