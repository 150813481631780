import { Folder, File, Ellipsis} from 'lucide-react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "components/ui/table"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Badge } from '@mui/material';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';


const FileListTable = ({ selectedFolderContent, findFolder, handleSelect, getFileSystem, rowActions=[], selectedItems, setSelectedItems, showFailed}) => {


    const toggleSelectAll = (checked) => {
        if (checked) {
          setSelectedItems(selectedFolderContent)
        } else {
          setSelectedItems([])
        }
      }
    
    const toggleSelectItem = (item, checked) => {
        if (checked) {
            setSelectedItems(prev => [...prev, item])
        } else {
            setSelectedItems(prev => prev.filter(selectedItem => selectedItem.id !== item.id))
        }
    }

    const actionCondition = (item) => {
        // Add logic to conditionally show row actions (if needed)
        return true; // Currently always showing the actions
    };

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow className="bg-gray-50 hover:bg-gray-50 sticky top-0 z-10 select-none shadow">
                        <TableHead className="w-12">
                            <Checkbox
                                checked={selectedItems.length === selectedFolderContent.length && selectedFolderContent.length > 0}
                                onCheckedChange={toggleSelectAll}
                                aria-label="Select all"
                            />
                        </TableHead>
                        <TableHead >Name</TableHead>
                        <TableHead className="min-w-[24px]">Type</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead  className="min-w-[24px]">Action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="overflow-y-scroll">
                    {selectedFolderContent
        .filter(item => showFailed || item.status !== 'failed') // Only include non-failed items if showFailed is true
        .map((item) => (
            <TableRow key={item.id} className={item.status === "failed" && item.type === "file" && !showFailed ? `hidden` : ""}>
                <TableCell>
                    <Checkbox
                                    checked={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                    onCheckedChange={(checked) => toggleSelectItem(item, checked)}
                                    aria-label={`Select ${item.name}`}
                                />
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center min-w-[200px] max-w-full flex-grow flex-shrink space-x-2 text-xs break-all">
                                    {item.type === "folder" ? (
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div
                                                        className="flex items-center cursor-pointer"
                                                        onClick={() => handleSelect(item)}
                                                    >
                                                        <Folder className="text-yellow-600 w-4 h-4 flex-shrink-0 mr-2" />
                                                        <span className="line-clamp-1 text-ellipsis">{item.name}</span>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-gray-900 text-white w-fit max-w-[300px] break-all">
                                                    <p>{item.name}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    ) : (
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div
                                                        className="flex items-center"
                                                    >
                                                        <File className="w-4 h-4 flex-shrink-0 mr-2" />
                                                        <a
                                                            target="_blank"
                                                            href={item.presigned_url}
                                                            className="line-clamp-1 text-ellipsis"
                                                        >
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-gray-900 text-white w-fit max-w-[300px] break-all">
                                                    <p>{item.name}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell className="min-w-[24px] max-w-[24px] text-xs">
                                {item.type}
                            </TableCell>
                            <TableCell className='w-[200px] text-xs'>
                                {item.type === 'file' ? (
                                    <>
                                        {item.status === 'success' ? (
                                            <Badge variant="outline" className="px-1 rounded bg-green-500 text-green-50">
                                                {item.status}
                                            </Badge>
                                        ) : item.status === 'failed' ? (
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div>
                                                            <Badge variant="outline" className="px-1 rounded bg-red-500 text-red-50">
                                                                {item.status}
                                                            </Badge>
                                                            <div className="text-xs max-w-[200px] truncate text-muted-foreground text-xs">
                                                                {item.reason}
                                                            </div>
                                                        </div>
                                                    </TooltipTrigger>
                                                    {item.reason && (
                                                        <TooltipContent className="bg-gray-900 text-white">
                                                            <p>{item.reason}</p>
                                                        </TooltipContent>
                                                    )}
                                                </Tooltip>
                                            </TooltipProvider>
                                        ) : (
                                            <Badge variant="outline" className="px-1 rounded bg-yellow-500 text-yellow-50">
                                                {item.status}
                                            </Badge>
                                        )}
                                    </>
                                ) : (
                                    '-'
                                )}
                </TableCell>

                {actionCondition(item) && (
                <TableCell  className="min-w-[24px] max-w-[24px]">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                                <Ellipsis className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            {rowActions.map((action) => (
                                <DropdownMenuItem
                                                    key={action.name}
                                                    onClick={() => action.onclick([item])}
                                                >
                                    {action.icon}
                                    <span className="ml-2">{action.name}</span>
                                </DropdownMenuItem>
                                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </TableCell>
                            )}
            </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
};

export default FileListTable;