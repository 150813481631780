import React from 'react';
import SetupOverview from './SetupOverview';
import SetupDemoCard from './SetupDemoCard';

export default function SetupCard({ 
    overviewTitle,
    overviewDescriptions, 
    title: demoTitle,
    subTitle: demoSubTitle,
    onDemoClick,
    onSetupClick,
    list: demoList = [],
}) {
    const shouldShowDemoCard = demoList && demoList.length > 0;

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20 space-y-4">  
            <SetupOverview 
                title={overviewTitle}
                descriptions={overviewDescriptions} />

            {shouldShowDemoCard && (
                <SetupDemoCard
                    title={demoTitle}
                    subTitle={demoSubTitle}
                    list={demoList}
                    onSetupClick={onSetupClick}
                    onDemoClick={onDemoClick} />
            )}
        </div>
    );
}
