module.exports = [
  {
    opcode: "Email 1: Create Room Booking",
    email: `Email title:  Contact Form (Plaza Lodge Hotel Baguio)\nReceived:  5 Dec 2023 13:41 (6 hours ago)\nto reservations \n\nName            Edward Rich\nEmail             erich@bbb.org\nComment or Message\nI need a room or suite for two adults and one child from 20-27 December. Please advise asap. Edward Rich\n`,
  },
  {
    opcode: "Email 2: Enquire Airport Transport Booking",
    email: `Email Title:  Inquiry by Surma Zur (Dec 18-21, 2023)\nSurma Zur <notifications@agoda-messaging.com>\n13:52 (6 hours ago)\nto reservations\n\nAgoda.com\t\nHi, Plaza Lodge Baguio\nThis traveler has an upcoming booking at your property.\nNew inquiry from Surma Zur\n\n \tSurma Zur\tDec 05, 12:52 PM ICT\t \n \tBooking ID: 1127355345\t \t \n \nMessage : Hi, Noted the below information. I would like to know if you have airport pick up service. If yes, please let me know the price. Regards, SURMA\n`,
  },
  {
    opcode: "Email 3: Update Room Booking",
    email: `Email Title:  Special Request for Booking ID 1045308672 (Nov 7-9, 2023)
    Nicole Bron <notifications@agoda-messaging.com>
    Wed, 18 Oct, 14:25
    to reservations
    
    Agoda.com	
    Hi, Plaza Lodge Hotel
    This traveler has an upcoming booking at your property.
    New special request from Nicole Bron
         Nicole Bron	Oct 18, 01:25 PM ICT	 
         Booking ID: 1045308672	 	 
    Request :	Early check-in (before 3pm)
    Late check-out`,
  },
  {
    opcode: "Email 4: Enquire Hotel Room Payment",
    email: `Email Title:  Cash Payment for hotel stay
    Kato Michelin <katomich2@hotmail.co.jp> 
    Wed, 13 Sept, 15:06
    to reservations
    
    To whom it may concern,
    I have booked a room of your hotel through agoda.
    Booking ID : 990626480
    Guest Name: (Ms.) Michelin Kato
    Check-in：October 6, 2023
    Check-out: October 8, 2023
    Room: Deluxe Suite
    
    Can I check if you can accept cash payment for my hotel stay?  I can’t pay by credit card.
    In addition, I will be checking in around 23:45 because my flight of CEBU pacific 5J 923 will be landing at 23:15.
    Please don't cancel my reservation if I will be arriving from Japan late time.
    I would appreciate it if you could reply me as soon as possible.
    Best regards,
    Michelin Kato`,
  },
  {
    opcode: "Email 5: Enquire Hotel Room",
    email: `from:	Obelardo, Oliver <Oliver.Obelardo@w.com>
    to:	"reservations@plazalodgebaguio.com" <reservations@plazalodgebaguio.com>
    date:	24 Nov 2023, 19:06
    subject:	 Long term stay
    
    Hello reservations,
    Hope all is well!
    My name is Oliver Obelardo and I am from Willis Towers Watson.
    I am writing this email as we are looking for a place to stay at Baguio and I came across with your establishment as we have previous transactions with you regarding long term stay.
    As such, may I know if you have an available unit starting January 2, 2023, good for 1 month? If yes, may you share the room rates for us to check and review.
    We also have a potential colleague arriving on December 18 that we might have to place in a room, so if you could share room rates good for 1 month for these dates, that would be great.
    Thank you, and I hope to hear from you soon.
    Regards,
    _____
    Oliver Obelardo
    Business Support Specialist, Administrative Services`,
  },
  {
    opcode: "Email 6: Update Room Booking",
    email: `Subject: Modification Request for Reservation 56789

    I hope this email finds you well. I recently made a reservation with your hotel under the name Jane Davis, with booking reference 56789. Unfortunately, there has been a change in my travel plans, and I would like to modify the dates of my stay.
    
    Old Booking Details:
    - Check-in Date: June 1st
    - Check-out Date: June 5th
    
    New Requested Dates:
    - Check-in Date: June 10th
    - Check-out Date: June 15th
    
    Please confirm if these modifications are possible and if any additional charges may apply.
    
    Thank you for your understanding, and I appreciate your assistance in this matter.
    
    Best regards,
    Jane Davis`,
  },
  {
    opcode: "Email 7 (multi intent): Create Room Booking, Enquire Hotel Facilities",
    email: `Orbitz guest message from Jinky Chan
    Expedia PartnerCentral Team <3ed6295c-c696c3afe75e@m.expediapartnercentral.com>
    Sun, 3 Dec, 11:56 (3 days ago)
    to reservations
    
    Plaza Lodge Hotel Baguio
    Property ID: 17300538
    Jinky Chan sent you a message
    
    "I just want confirm my booking for the 5th of December for 2 nights, 5 and 6. Is there a parking for 1 car? I was trying to call but no one answered."`,
  },
  {
    opcode: "Email 8 Enquire Hotel Facilities",
    email: `Subject: Inquiry Regarding Viewing Yacht Before Booking

    Hello, I hope this email finds you well. I am interested in your Yacht services but first I would like to know about the possibility of viewing the yacht before finalizing my booking at your hotel. Let me know asap so that I can settle my plans.
    
    Thanks,
    Stefan`,
  },
  {
    opcode: "Email 9 Enquire Hotel Facilities",
    email: `Availability of Sound Systems Onboard

    Dear Help Desk, I have an enquiry regarding the Yacht facilities. I would like to know whether there are sound systems on the Yacht or no.
    
    With regards,
    Alice`,
  },
];
