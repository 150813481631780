import { createApiClient } from './';

const BEX_API_BASE_URL = 'https://bex-kb.coolriots.ai'
const BEX_API_ENDPOINT_PREFIX = "/projecto/api/v1";

/**
 * HTTP client for the Project O API endpoints /  services.
 *
 * @type {ApiClient}
 */
export const knowledgeBuilderHttpClient = createApiClient(
    BEX_API_BASE_URL, 
    BEX_API_ENDPOINT_PREFIX
);