
export function getTimeDifference(inputDateStr) {
    if (!inputDateStr)
        return ''
    const inputDate = new Date(inputDateStr);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - inputDate;
    return formatTimeDifference(diffInMilliseconds);
}

function formatTimeDifference(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30); // Assuming a month is 30 days

    if (months > 0) {
        return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
        return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
        return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
        return "just now";
    }
}

export function convertUnixTimestampToDate(unixTimestamp) {
    try {
        const date = new Date(unixTimestamp * 1000);

        // Extract the date components
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        let hours = date.getHours();
        const minutes = ('0' + date.getMinutes()).slice(-2);
    
        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
    
        // Format the date as 'MMM DD, h:mm AM/PM'
        return `${month} ${day}, ${hours}:${minutes} ${ampm}`;
    } catch (error) {
        return ''
    }

}
  