import React, { useContext, useState } from 'react';
import { Button } from "components/ui/button";
import { ChevronDown } from 'lucide-react';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription } from 'components/ui/dialog';
import { ScrollArea } from "components/ui/scroll-area";
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/DemoPromptContext';
import groqDetails from "../../json/groqDetails";  

const GroqModelSelectionModal = ({ onModelSelect }) => {
    const context = useContext(GlobalContext);  // Don't destructure immediately
    const { groqModels = [], selectedModel, setSelectedModel } = context || {};  // Fallback to empty array if undefined
    const [maxTokens, setMaxTokens] = useState(null);
    const [description, setDescription] = useState("");
    const [recommendedTasks, setRecommendedTasks] = useState([]);

    // Handle model selection
    const handleModelSelect = (model) => {
        if (selectedModel === model.id) {
            setSelectedModel("");
            setMaxTokens(null);
            setDescription("");
            setRecommendedTasks([]);
            onModelSelect("");
        } else {
            setSelectedModel(model.id);
            setMaxTokens(model.context_window || null);
            const modelDetails = groqDetails.models.find(m => m.id === model.id) || {};
            setDescription(modelDetails.description || "No description available.");
            setRecommendedTasks(modelDetails.recommended_tasks || []);
            onModelSelect(model.id);
        }
    };

    const handleDone = () => {
        onModelSelect(selectedModel);
    };

    const filteredModels = groqModels.filter((model) => !model.id.includes("whisper")) || [];

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline">
                    <ChevronDown className="mr-2 h-4 w-4" />
                    {selectedModel || "Select a model"}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select a Model</DialogTitle>
                    <DialogDescription>
                        Choose a model and see its details.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex flex-col md:flex-row gap-4">
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="grid grid-cols-3 grid-rows-5 gap-4 p-4">
                                {filteredModels.length > 0 ? (
                                    filteredModels.map((model) => (
                                        <Button
                                            key={model.id}
                                            variant={selectedModel === model.id ? "default" : "outline"}
                                            onClick={() => handleModelSelect(model)}
                                            className="justify-start h-auto text-left"
                                        >
                                            <div>
                                                <div className="font-semibold">{model.id}</div>
                                                <div className="text-xs text-muted-foreground">{model.owned_by}</div>
                                            </div>
                                        </Button>
                                    ))
                                ) : (
                                    <p>No models available</p>
                                )}
                            </div>
                        </ScrollArea>
                        {selectedModel && (
                            <div className="md:w-1/3 p-4 bg-muted rounded-md h-96 max-h-96 overflow-y-auto">
                                <h2 className="mb-2 font-semibold text-xl">{selectedModel}</h2>

                                {description && (
                                    <div className="mb-4">
                                        <p>{description}</p>
                                    </div>
                                )}

                                {maxTokens && (
                                    <div>
                                        <h4 className="mb-2">
                                            <span className="font-semibold">Context Window (Max Tokens):</span> {maxTokens}
                                        </h4>
                                    </div>
                                )}

                                {recommendedTasks.length > 0 && (
                                    <div>
                                        <h4 className="font-semibold">Recommended Tasks:</h4>
                                        <ul className="list-disc pl-5">
                                            {recommendedTasks.map((task, index) => (
                                                <li key={index}>{task}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {selectedModel && (
                        <div className="flex justify-end">
                            <DialogTrigger asChild>
                                <Button variant="default" onClick={handleDone}>Done</Button>
                            </DialogTrigger>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default GroqModelSelectionModal;
