import React from 'react'
import './components-styles.css'
import AsideMenu from '../../components/AsideMenu'
import { SettingsSection } from '../Settings'
import styled from 'styled-components'
import { AlertModalDemo, CustomFormModalDemo, DemoModeButtonDemo, DragAndDropDemo, ModalDemo, NotificationDemo, PrimaryButtonDemo, PrimaryCardDemo, SecondaryButtonDemo } from './DemoComponents'

export default function Components() {
    // #f0f2f5

    const data = [
        { name: 'Buttons', path: `/components#buttons`, icon: <></>},
        { name: 'Modals', path: `/components#modals`, icon: <></>},
        { name: 'File Upload', path: `/components#file-upload`, icon: <></>},
        { name: 'Notification', path: `/components#notification`, icon: <></>},
        { name: 'Data Display', path: `/components#data-display`, icon: <></>},
    ]

    const compoents = [
        {
            id: 'buttons',
            name: 'Button',
            description: 'A customizable button component.',
            DemoComponent: PrimaryButtonDemo,
            codeBlock: `<PrimaryButton onSubmit={handleOnSubmit} $text='Continue' $loading={loading} />`,
        },
        {
            name: 'Secondary Button',
            description: 'A customizable secondary button component.',
            DemoComponent: SecondaryButtonDemo,
            codeBlock: `<SecondaryButton onSubmit={handleOnSubmit} $text='Continue' $loading={loading} />`,
        },
        {
            name: 'Tertiary Button',
            description: 'A customizable secondary button component.',
            DemoComponent: DemoModeButtonDemo,
            codeBlock: `
    <RedButton> Button </RedButton>
    <RedButton disabled={true} > Button </RedButton>
    <WhiteButton> Button </WhiteButton>
    <WhiteButton disabled={true} > Button </WhiteButton>`,
        },
        {
            id: 'modals',
            name: 'Modal',
            description: 'A customizable Modal component.',
            DemoComponent: ModalDemo,
            codeBlock: `        
    <Modal onClose={onClose} style={{border: 'none', width: '100%', maxWidth: 1250}} $isOpen={isOpen} setOpenModal={setOpenModal}>
        <Modal.Header isClose={false} style={{backgroundColor: 'black', color: '#fff'}}>
            {title}
        </Modal.Header>
        <StyeldMoalContainer>
            {children}
        </StyeldMoalContainer>
    </Modal>`
        },
        {
            name: 'Alert Modal',
            description: 'A customizable Alert Modal component.',
            DemoComponent: AlertModalDemo,
            codeBlock: `<AlertModal openModal={show} setopenModal={setShow} onDelete={() => {}} loading={false} />`
        },
        {
            name: 'Form Modal',
            description: 'A customizable Form Modal component.',
            DemoComponent: CustomFormModalDemo,
            codeBlock: `
    <PopUpModal setOpenModal={setShow} openModal={show} text={'Form Modal'}>
        <form>
            <Container labelName={'Name'}>
                <PrimaryInput type="text" />
            </Container>
        </form>
        <button
            onClick={() => {}}>
            {btnLoading ? <LoaderSpinner /> : 'Create'}</button>
    </PopUpModal>`
        },
        {
            id: 'file-upload',
            name: 'File Upload',
            description: 'A customizable file upload component.',
            DemoComponent: DragAndDropDemo,
            codeBlock: `<UploadDocument />`
        },
        {
            id: 'notification',
            name: 'Notification',
            description: 'A customizable Notification component.',
            DemoComponent: NotificationDemo,
            codeBlock: `   
import { useContext } from "react";
import { Context } from "path-to-file/context/GlobalState";


const Norification = () => {

    const { addNewNotifcation } = useContext(Context)


    const handleAddSuccessNotification = () => {
        addNewNotifcation('Success Notification', 'success')
    }
    const handleAddWaringNotification = () => {
        addNewNotifcation('Waring Notification', 'waring')
    }

    const handleAddDangerNotification = () => {
        addNewNotifcation('Danger Notification', 'danger')
    }

    return (
        <>
            <RedButton onClick={handleAddSuccessNotification}> Add Success Notification </RedButton>
            <WhiteButton onClick={handleAddWaringNotification}> Add Waring Notification </WhiteButton>
            <WhiteButton onClick={handleAddDangerNotification}> Add Danger Notification </WhiteButton>
        </>
    )
}
            `
        },
        {
            id: 'data-display',
            name: 'Cards',
            description: 'A customizable Cards component.',
            DemoComponent: PrimaryCardDemo,
            codeBlock: `   
const options = [
    {name: 'Option one', onClick: function},
    {name: 'Option two', onClick: function, color: 'var(--primary-color)'},
]
            
<StyledPrimaryCard title={'Card Tile'}>
    Card Content
</StyledPrimaryCard>

<StyledPrimaryCard optionItems={options} title={'Card Title With Options'} option={true}>
Card Content
</StyledPrimaryCard>

`
        },
    ]

    return (
        <div className='components-page-container'>
            <div className='components-page-lefttside-container'>
                <div className='components-page-lefttside-menu'>
                    <AsideMenu>
                        <SettingsSection title='Components' list={data} />
                    </AsideMenu>
                </div>
            </div>
            <div className='innet-component-container'>
                {compoents.map((compoent, i) => (
                    <ComponentCode key={i} {...compoent} />
                ))}
            </div>
        </div>
    )
}

const StyledContiner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border: 0.5px solid #e5e5e5;
`

const ComponentCode = ({ id, name, description, codeBlock, DemoComponent }) => {



    return (
        <StyledContiner id={id}>
            <h3>{name}</h3>
            <p> {description} </p>
            {/* <ComponentDemo visible={visible}> */}
                <DemoComponent />
            {/* </ComponentDemo> */}

            <CodeDisplay code={codeBlock} />

        </StyledContiner>
    )
    
}

const StyledPre = styled.pre`
    background-color: #f0f2f5;
    padding: 10px;
    border-radius: 5px;
`

const StyledCode = styled.code`
    display: flex;
    text-wrap: wrap;
`

function CodeDisplay({ code }) {



    return (
        <StyledPre>
            <StyledCode>
                {code}
            </StyledCode>
        </StyledPre>
    );
}
  