import { useContext, useEffect, useState } from "react"
import { AlertCircle, CheckCircle2, Cloud, Pause } from "lucide-react"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "components/ui/card"
import { Button } from "components/ui/button"
import { Badge } from "components/ui/badge"
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog"
import { OrganizationContext } from "context/OrganizationContext"
import { organizationService } from "api"
import { Context } from "context/GlobalState"
import { Input } from "components/ui/input"

export default function OrganizationCluster() {
    
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isTerminating, setIsTerminating] = useState(false)
    const [inputClusterCheckName, setInputClusterCheckName] = useState('')

    const { selectedOrganization } = useContext(OrganizationContext)
    const { addNewNotifcation } = useContext(Context)

    const handleTerminate = () => {
        if (selectedOrganization.userRole !== 'superAdmin') {
            return addNewNotifcation('Unauthorized, Only super admin can perform this action', 'warning')
        }

        setIsTerminating(true)
        organizationService.deleteOrganizationCluster(selectedOrganization._id)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Cluster deleted successfully', 'success')
            setTimeout(() => {
                window.location = '/organizations'
            }, 1000)
        }).catch((error) => {
            console.log('error:', error);
            if (error.response.status === 403) {
                addNewNotifcation('You don not have the right access to delete this cluster. Only super admin can perform this action', 'danger')
            } else if (error.response.status === 409) {
                addNewNotifcation('Organization have active subscription, Please remove any active subscription before proceed', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }).finally(() => {
            setIsTerminating(false)
            setIsDialogOpen(false)
        })
    }

    useEffect(() => {
        setInputClusterCheckName('')
    }, [isDialogOpen])


    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString()
    }

    return (
        <Card className="w-full">
            <CardHeader>
                <CardTitle className="flex items-center justify-between">
                    <span>{selectedOrganization.organizationCluster.name}</span>
                    <StatusIndicator stateName={selectedOrganization.organizationCluster.stateName} paused={selectedOrganization.organizationCluster.paused} />
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-2">
                    <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Status:</span>
                        <Badge variant={selectedOrganization.organizationCluster.stateName === 'IDLE' ? "default" : "secondary"}>
                            {selectedOrganization.organizationCluster.stateName}
                        </Badge>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Created:</span>
                        <span className="font-medium">{formatDate(selectedOrganization.organizationCluster.createDate)}</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Disk Size:</span>
                        <span className="font-medium">{selectedOrganization.organizationCluster.diskSizeGB} GB</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">ID:</span>
                        <span className="font-medium text-xs">{selectedOrganization.organizationCluster.id}</span>
                    </div>
                </div>
            </CardContent>
            <CardFooter className="flex space-y-2">
                <div className="flex gap-3 w-full max-w-[200px] ml-auto items-center justify-end">
                    {selectedOrganization.userRole === 'superAdmin' && (
                        <Button 
                        variant="destructive" 
                        className="w-full" 
                        onClick={() => setIsDialogOpen(true)}
                        >
                            Terminate Cluster
                        </Button>
                    )}
                </div>

            </CardFooter>

            <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete your cluster
                            &quot;{selectedOrganization.organizationCluster.name}&quot; and remove all associated data.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <div className="my-4 flex flex-col">
                        <Input name='cluster-name' value={inputClusterCheckName} onChange={(e) => setInputClusterCheckName(e.target.value)} />
                        <span className="text-sm text-muted-foreground"> Write the cluster name  &quot;{selectedOrganization.organizationCluster.name}&quot; to confirm </span>
                    </div>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <Button variant="destructive"  onClick={handleTerminate} disabled={isTerminating || inputClusterCheckName !== selectedOrganization.organizationCluster.name}>
                            {isTerminating ? "Terminating..." : "Yes, terminate cluster"}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Card>
    )
}

function StatusIndicator({ stateName, paused }) {
    let Icon = Cloud
    let color = "text-blue-500"

    if (paused) {
        Icon = Pause
        color = "text-gray-500"
    } else if (stateName === "IDLE") {
        Icon = CheckCircle2
        color = "text-green-500"
    } else if (stateName !== "IDLE" && stateName !== "CREATING") {
        Icon = AlertCircle
        color = "text-yellow-500"
    }

    return <Icon className={`h-5 w-5 ${color}`} />
}