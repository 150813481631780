import React, { useContext, useEffect } from 'react';
import { Button } from "components/ui/button";
import { FileText } from 'lucide-react';
import * as ShadeDD from "components/ui/dropdown-menu";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { GlobalContext } from "../../context/DemoPromptContext";
import { ragService } from 'api/services/PROJECT-O';


export const DocumentList = () => {

    // Global Context
    const {
        sampleFiles, setSampleFiles,
        selectedDocuments, setSelectedDocuments,
    } = useContext (GlobalContext);

    const handleIfSelected = (doc) => {
        if (
          selectedDocuments.find(
            (document) => document._id === doc._id
          )
        )
          return true;
        else return false;
    };



    // Load Files
    const handleLoadSampleFiles = async () => {
        try{
            const filter = {
                assistant: 'ai-lab',
                feature: "demo-prompt",
                demo_doc: true
            }
            const res = await ragService.listDocuments(process.env.REACT_APP_COMMON_ORGANIZATION_ID, process.env.REACT_APP_DEMO_MODE, filter);
            setSampleFiles(res.data);
            console.log("Loaded Sample files: ", res);
        } catch (err) {
            console.log(err);
        }
    }

    const loadFiles = async () => {
        try {
            await Promise.all([handleLoadSampleFiles()]); // Run both at the same time
        } catch (err) {
            console.log("Error loading files: ", err);
        }
    };

    useEffect(() => {
        loadFiles();
    }, []);


    return(
        <ShadeDD.DropdownMenu>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="px-2">
                    <FileText className="w-5 h-5" />
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="end" side="bottom" className="w-[350px]">
                <Tabs defaultValue="user" className="w-full h-fit">
                    <TabsList className="flex w-full grid-cols-2 bg-gray-100">
                        <TabsTrigger className="text-xs" value="user">Sample Documents</TabsTrigger>
                    </TabsList>
                    <ShadeDD.DropdownMenuSeparator />
                    <TabsContent className="w-full h-full m-0" value="user">
                        {/* <PromptPanelDemo/> */}
                        <ShadeDD.DropdownMenuGroup className='max-h-72 overflow-y-auto'>
                            {sampleFiles.map((doc) => (
                                <div
                                    key={doc._id}
                                    className="w-full flex flex-row gap-2 justify-between">
                                    <ShadeDD.DropdownMenuCheckboxItem
                                        className="w-full overflow-hidden"
                                        
                                        checked={handleIfSelected(doc)}
                                        onSelect={(event) => {
                                        const result = selectedDocuments.find(
                                            (document) => document._id === doc._id
                                        );
                                        // console.log(doc._id)
                                        event.preventDefault();
                                        if (result) {
                                            setSelectedDocuments((prevDocuments) =>
                                                prevDocuments.filter((document) => document._id !== doc._id)
                                            );
                                        } else {
                                            if (selectedDocuments.length < 1){
                                                setSelectedDocuments((prevDocuments) => [
                                                    ...prevDocuments,
                                                    { _id: doc._id, name: doc.file_name, file_url: doc.file_url, org_id: doc.organization_id, sub_org_id: doc.sub_organization_id },
                                                ]);
                                            }
                                            
                                        }
                                        
                                        }}
                                    >
                                        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                            {doc.file_name}
                                        </span>
                                        
                                    </ShadeDD.DropdownMenuCheckboxItem>
                                </div>
                            
                            ))}
                        </ShadeDD.DropdownMenuGroup>
                    </TabsContent>
                </Tabs>
                {/* <ShadeDD.DropdownMenuLabel>
                    {isLoading ? "Loading documents..." : uploadedDocs.length === 0 ? "No documents" : "Documents"}
                </ShadeDD.DropdownMenuLabel> */}
                {/* <ScrollArea className="h-72 w-full "> */}

                {/* </ScrollArea> */}
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


