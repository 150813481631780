import { useContext } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import OpCodeForm from "./OpCodeForm";
import OpCodeExecution from "./OpCodeExecution";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
function OpCodeCreator() {
    const {
        currentStep, setCurrentStep,

    } = useContext(OpCodeBuilderContext)


    

    return (
        <Card className="w-[1000px] select-none">
            <CardHeader>
                <CardTitle className="text-2xl font-bold">Workflow Builder</CardTitle>
            </CardHeader>
            <CardContent>
                <Tabs value={currentStep.toString()} className="w-full">
                    <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="0" onClick={() => setCurrentStep(0)}>Create Workflow</TabsTrigger>
                        <TabsTrigger value="1" onClick={() => setCurrentStep(1)}>Execute Workflow</TabsTrigger>
                    </TabsList>
                    <TabsContent value="0">
                        <OpCodeForm/>
                    </TabsContent>
                    <TabsContent value="1">
                        <OpCodeExecution/>
                    </TabsContent>
                </Tabs>
            </CardContent>
            {/* <CardFooter className="justify-between">
                <Button onClick={handlePrevious} disabled={currentStep === 0} variant="outline" type="button">Previous</Button>
                <Button onClick={currentStep === 0 ? handleNext : undefined} type="button">
                    {currentStep === 0 ? "Create OpCode" : "Next"}
                </Button>
            </CardFooter> */}
        </Card>
    );
}

export default OpCodeCreator;