import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { httpRequest } from '../../../utils/httpsRequest';
import { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import NewStyledTable from '../../../components/ui/StyledTable';
import { Context } from '../../../context/GlobalState';
import  PackageForm from './PackageForm'
import { useNavigate } from 'react-router-dom';

const PackageDetails = () => {
    const { packageId } = useParams();
    const [packageDetails, setPackageDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isAdminAuth } = useContext(Context);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!show) {
            setLoading(true);
            httpRequest.get(`/db/packages/get-package/${packageId}`)
                .then((res) => {
                    console.log('res: ', res);
                    setPackageDetails(res.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('error: ', error);
                    setLoading(false);
                });
        }
    }, [show, packageId]);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    // if (!packageDetails) {
    //     return <h1>No package details found.</h1>;
    // }


   const handleDeletePackage = () => {
        httpRequest.delete(`db/packages/delete-package/${packageDetails._id}`)
        .then((res) => {
            console.log('res: ', res);
            navigate('/secure/packages');
        })
        .catch((error) => {
            console.log('error: ', error);
        });
    }

    return (
        <div className="flex flex-col w-full m-4 p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{packageDetails.name}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CardDescription>{packageDetails.description}</CardDescription>
                    <NewStyledTable
                        data={packageDetails.assistants}
                        columns={[
                            { field: 'name', headerName: 'Assistant Name', width: 200 },
                            { field: 'description', headerName: 'Description', width: 200 },
                            { field: 'created', headerName: 'Created', width: 200 },
                            { field: 'updated', headerName: 'Updated', width: 200 },
                        ]}

                    />
                </CardContent>
                <CardFooter>
                    <p>Price: ${packageDetails.price}</p>
                </CardFooter>
            </Card>
            <div className="flex flex-row justify-end mt-4">
                <Button onClick={() => setShow(true)} className="text-white">Edit Package</Button>
                <Button onClick={handleDeletePackage} className="bg-red-500 text-white">Delete Package</Button>
            </div>
            {/* <PackageForm show={show} setShow={setShow} infoToUpdate={packageDetails} /> */}
            {show && <PackageForm show={show} setShow={setShow} infoToUpdate={packageDetails} />}

        </div>
    );
}; 

export default PackageDetails;