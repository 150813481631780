import React, { useState, useEffect, useRef, useContext } from 'react';
import { httpRequest } from '../../../utils/httpsRequest';
import { DialogTrigger, DialogTitle, DialogDescription, DialogHeader, DialogFooter, DialogContent, Dialog } from '../../../components/ui/dialog';
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Badge } from "../../../components/ui/badge";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner, AppLoader } from '../../../components/LoaderSpinner';
import { Button } from '../../../components/ui/button';
import { cn } from "../../../lib/utils";
import { Context } from '../../../context/GlobalState';

const PackageForm = ({ show, setShow, infoToUpdate = null }) => {
    const packageName = useRef();
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistants, setSelectedAssistants] = useState(infoToUpdate ? infoToUpdate.assistants : []);
    const description = useRef();
    const channels = useRef();
    const knowledgeBase = useRef();
    const opCodes = useRef();
    const price = useRef();
    const [btnLoading, setBtnLoading] = useState(false);
    const { addNewNotifcation } = useContext(Context);

    useEffect(() => {
        if (show) {
            httpRequest.get(`/db/bex-assistants/get-assistants`)
                .then((res) => {
                    setAssistants(res.data);
                }).catch((error) => {
                    console.log(error);
                });
        }
    }, [show]);

    useEffect(() => {
        if (infoToUpdate) {
            packageName.current.value = infoToUpdate.name;
            
            description.current.value = infoToUpdate.description;
            channels.current.value = infoToUpdate.channels;
            knowledgeBase.current.value = infoToUpdate.knowledgeBase;
            opCodes.current.value = infoToUpdate.opCodes;
            price.current.value = infoToUpdate.price;
        }
    }, [infoToUpdate]);

    const handleSubmit = () => {
        const name = packageName.current.value;
        const assis = selectedAssistants.map(a => a._id);
        const desc = description.current.value;
        const ch = channels.current.value;
        const kb = knowledgeBase.current.value;
        const op = opCodes.current.value;
        const pr = price.current.value;

        if (name.length < 3) {
            addNewNotifcation('Package name must be at least 3 characters', 'danger');
            return;
        }
        setBtnLoading(true);

        const apiEndpoint = infoToUpdate ? `/db/packages/update-package/${infoToUpdate._id}` : '/db/packages/add-package';
        const apiMethod = infoToUpdate ? 'put' : 'post';

        httpRequest[apiMethod](apiEndpoint, {
            name,
            assistants: assis,
            description: desc,
            channels: ch,
            knowledgeBase: kb,
            opCodes: op,
            price: pr,
        })
            .then((res) => {
                console.log('res: ', res);
                setShow(false);
                const successMessage = infoToUpdate ? 'Package updated successfully' : 'Package created successfully';
                addNewNotifcation(successMessage, 'success');
            })
            .catch((error) => {
                console.log('error: ', error);
                addNewNotifcation('Something went wrong', 'danger');
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };

    const handleSelectAssistant = (assistant) => {
        if (!selectedAssistants.some(a => a._id === assistant._id)) {
            setSelectedAssistants([...selectedAssistants, assistant]);
        }
    };

    const handleRemoveAssistant = (assistant) => {
        setSelectedAssistants(selectedAssistants.filter((a) => a._id !== assistant._id));
    };

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-[800px] p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                        {infoToUpdate ? 'Update Package' : 'Create Package'}
                    </DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">
                        {infoToUpdate ? 'Update the package details and assistants.' : 'Set up a new package and add assistants.'}
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-6 max-h-[700px] max-w-[800px] overflow-y-auto">
                    <div className="space-y-3">
                        <Label htmlFor="package-name" className="text-md">Name</Label>
                        <Input
                            ref={packageName}
                            id="package-name"
                            placeholder="Enter package name"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                    <div className="flex">
                        <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                            <div className="flex flex-wrap gap-2">
                                {selectedAssistants.map((assistant) => (
                                    <Badge key={assistant._id} variant="secondary" className="p-2 text-sm">
                                        {assistant.name}
                                        <Cross1Icon className="ml-2 cursor-pointer" onClick={() => handleRemoveAssistant(assistant)} />
                                    </Badge>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="relative space-y-3">
                        <Label htmlFor="assistant-search" className="text-md">Assistant Selection</Label>
                        <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                        <Input
                            id="assistant-search"
                            placeholder="Search assistants..."
                            type="search"
                            className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                        <ScrollArea className="border-solid rounded-md h-56">
                            <div className="flex flex-col p-3 space-y-3">
                                {assistants.length === 0 ? <div className="text-center text-muted-foreground">No assistants found</div> : assistants.map((assistant) => (
                                    <div key={assistant._id} className="flex items-center space-x-3 cursor-pointer" onClick={() => handleSelectAssistant(assistant)}>
                                        <div>
                                            <div className="font-medium text-md">{assistant.name}</div>
                                            <div className="text-sm text-muted-foreground">{assistant.description}</div>
                                        </div>
                                        <CheckIcon className={cn("ml-auto h-6 w-6", selectedAssistants.some(a => a._id === assistant._id) ? "opacity-100" : "opacity-0")} />
                                    </div>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="space-y-3">
                        <Label htmlFor="description" className="text-md">Description</Label>
                        <Input
                            ref={description}
                            id="description"
                            placeholder="Enter description"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                    <div className="space-y-3">
                        <Label htmlFor="channels" className="text-md">Channels</Label>
                        <Input
                            ref={channels}
                            id="channels"
                            placeholder="Enter channels"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                    <div className="space-y-3">
                        <Label htmlFor="knowledge-base" className="text-md">Knowledge Base</Label>
                        <Input
                            ref={knowledgeBase}
                            id="knowledge-base"
                            placeholder="Enter knowledge base"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                    <div className="space-y-3">
                        <Label htmlFor="op-codes" className="text-md">Op Codes</Label>
                        <Input
                            ref={opCodes}
                            id="op-codes"
                            placeholder="Enter op codes"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid
                            dark:bg-gray-950"
                        />
                    </div>
                    <div className="space-y-3">
                        <Label htmlFor="price" className="text-md">Price</Label>
                        <Input
                            ref={price}
                            id="price"
                            placeholder="Enter price"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button onClick={handleSubmit} disabled={btnLoading} className="bg-blue-500 text-white">
                        {btnLoading ? <LoaderSpinner /> : 'Save Package'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default PackageForm;