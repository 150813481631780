import React from 'react';
import { Button } from "../ui/button";

export default function SetupDemoCard({ title, subTitle, list = [], onSetupClick, onDemoClick }) {
    return (
        <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden mx-auto">
            <div className="px-6 py-8 sm:p-10">
                <div className="flex flex-col items-center space-y-4">
                    <h3 className="mt-10 scroll-m-20 border-b pb-2 text-xl md:text-3xl font-semibold tracking-tight transition-colors first:mt-0">
                        {title}
                    </h3>

                    <p className="text-gray-800 dark:text-gray-600 font-normal">
                        {subTitle}
                    </p>

                    <ul className="list-decimal list-inside space-y-2 text-gray-600 font-light text-sm sm:text-md dark:text-gray-400">
                        {list.map((item, i) => (
                            <li key={i}>
                                {item}
                            </li>
                        ))}
                    </ul>

                    {(onSetupClick || onDemoClick) && (
                        <div className="flex gap-4">
                            {onSetupClick && (
                                <Button onClick={onSetupClick}>
                                    Activate Assistant
                                </Button>
                            )}
                            {onDemoClick && (
                                <Button variant="secondary" onClick={onDemoClick}>
                                    Try Assistant
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}