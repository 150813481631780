import { createApiClient } from "api/config";
const BEX_API_BASE_URL = 'https://stgapp.coolriots.ai'
const BEX_API_ENDPOINT_PREFIX = "/projecto/api/v1";

const projectOHttpClientStg = createApiClient(
    BEX_API_BASE_URL, 
    BEX_API_ENDPOINT_PREFIX
);

/**
 * Service class for performing RAG operations.
 */
class GroqService {

    /**
     * Send a prompt.
     * @returns {Promise<Object>}
     */
    async Experiment(body){
        const response = await projectOHttpClientStg.post(`/groq_models/groqModel/experiment`, body, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        return response;
    }

    /**
     * Get List of Groq Models.
     * @returns {Promise<Object>}
     */
    async ListModels(){
        const response = await projectOHttpClientStg.get(`groq_models/groqModel/list_models`);
        return response;
    }
    
}

const groqService = new GroqService()
export { groqService }
