import { GiHamburgerMenu } from 'react-icons/gi'
import styled from 'styled-components'
import useComponentVisible from '../../hooks/useClickOutside'



export const PrimaryCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    padding: 16px;
    width: 340px;
    height: 200px;
    border-top: 2px var(--primary-color) solid;
    background-color: #FFFFFF;
    border-radius: 7px;
    box-shadow: 0px 2px 2px 1px rgba(0,0,0,0.10);
`

const StyledCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    width: 100%;

`

const StyledCardTitle = styled.h1`
    font-size: 20px;
    color: #484848;
    font-weight: bolder;
    cursor: pointer;
    max-width: 250px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const StyledCardOptionContainer = styled.div`
    display: flex;
    position: relative;

`

const StyledCardOptionIcon = styled.div`
    display: flex;
    padding: 7px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
        background-color: #e5e5e5;
    }
`
const StyledCardOptionModal = styled.ul`
    position: absolute;
    top: 30px;
    right: 0;
    width: auto;
    min-width: 130px;
    max-width: 230px;
    background-color: #fff;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.10);
    border-radius: 5px; 
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    padding: 3px;

    z-index: 999;
`

const StyledCardOptionModalItem = styled.li`
    display: flex;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px; 
    overflow: hidden;

    &:hover {
        background-color: #e5e5e5;
    }

`


const StyledCardContent = styled.div`
    display: flex;
    flex-grow: 1;
`

export const StyledPrimaryCard = ({ title, option=false, optionItems=[], children, ...rest }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);


    const handleOptionClick = (onClick) => {
        onClick && onClick()
    }
    
    return (
        <PrimaryCard {...rest}>
            <StyledCardHeader>
                <StyledCardTitle>
                    {title}
                </StyledCardTitle>
                {option && (
                    <StyledCardOptionContainer ref={ref}>
                        <StyledCardOptionIcon onClick={() => setIsComponentVisible(!isComponentVisible)}>
                            <GiHamburgerMenu />
                        </StyledCardOptionIcon>
                        {isComponentVisible && (
                            <StyledCardOptionModal>
                                {optionItems?.map((item) => (
                                    <StyledCardOptionModalItem style={{color: item.color}} key={item.name} onClick={handleOptionClick.bind(this, item.onClick)}>
                                        {item.name}
                                    </StyledCardOptionModalItem>
                                ))}
                            </StyledCardOptionModal>
                        )}
                    </StyledCardOptionContainer>
                )}
            </StyledCardHeader>

            <StyledCardContent>
                {children}
            </StyledCardContent>
        </PrimaryCard>
    )
}

