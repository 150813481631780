import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button } from './ui/button';
import { BuildingIcon, ChevronDownIcon, LucideSettings } from 'lucide-react'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from './ui/dropdown-menu';
import { Context } from 'context/GlobalState';


export default function DashboardSubHeader() {
    // Context
    const { organizations } = useContext(Context);
    const location = useLocation()
    const navigate = useNavigate();
    const { oragID } = useParams();

    // State
    const [currentOrganizationID, setCurrentOrganization] = useState(null);

    // Effects
    useEffect(() => {
      handleLocationChange(location);
    }, [location]);

    // Getters
    const userOrganizationCount = organizations.length;
    const hasEnoughOrganizations = userOrganizationCount > 0;
    const organizationTitle = organizations.find(item => item._id === oragID)?.name ?? 'No Organization Selected';

    // Actions   
    const handleOrganizationChange = (org) => {
        window.location = `/organizations/${org._id}`
    }

    const handleLocationChange = (location) => {
      if (location.pathname.includes('/organizations/')) {
        setCurrentOrganization(location.pathname.split('/')[2]);
      } else {
        setCurrentOrganization(null);
      }
    }

    const handleSettingsClick = () => {
        navigate(`/organizations/${currentOrganizationID}/settings`)
    }

    return (
        <DropdownMenu>
            {/* Active Organization */}
            <div className='flex items-center'>
                <DropdownMenuTrigger asChild disabled={!oragID}>
                    <Button variant="ghost" className="flex items-center gap-2">

                        <BuildingIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />

                        <span className="text-gray-700 dark:text-gray-300">
                            {organizationTitle}
                        </span>

                        {hasEnoughOrganizations && (
                            <div className="flex items-center space-x-3">
                                <ChevronDownIcon 
                                className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                            </div>
                        )}
                    </Button>
                </DropdownMenuTrigger>

                {currentOrganizationID && (
                    <LucideSettings 
                        onClick={handleSettingsClick}
                        className="h-4 w-4 text-gray-500 dark:text-gray-400 cursor-pointer" />
                )}
            </div>
        

            {/* Organizations Options List */}
            {/* TODO: Check why the organization are not rendered directly after loading */}
            {hasEnoughOrganizations && ( 
                <DropdownMenuContent className="w-56">
                    {organizations.map((org, index) => (
                        <DropdownMenuItem
                            disabled={org.clusterStatus !== 'DEPLOYED'}
                            key={index}
                            action={() => handleOrganizationChange(org)}>
                            <BuildingIcon 
                            className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
                            {org?.name} 
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            )}
        </DropdownMenu>
    );
}