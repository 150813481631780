import { feoHttpClient } from '../../clients';


/**
 * Service class for performing speech-to-text operations.
 */
class SpeechToTextService {
  async batchTranscription(files) {
    const formData = new FormData()
    files.forEach(file => {
      formData.append('files', file)
    })

    const response = await feoHttpClient.post('/stt-tts/stt/batchTranscription', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }

  async realTimeTranscription(files) {
    const formData = new FormData()
    files.forEach(file => {
      formData.append('files', file)
    })

    const response = await feoHttpClient.post('/stt-tts/stt/realtimeTranscription', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}

const speechToTextService = new SpeechToTextService()
export { speechToTextService }