import React from 'react'
import SideMenu from '../components/ui/SideMenu'
import { Outlet } from 'react-router-dom'

export default function SideMenuWrapper({ section }) {

    return (
        <div className='flex flex-grow h-full'>
            <SideMenu section={section} />

            <div className='flex flex-grow p-8 overflow-scroll'>
                <Outlet />
            </div>
        </div>
    )
}
