/* eslint-disable max-lines */
import { httpRequestO } from "utils/httpsRequest";
import { ragService } from "api/services/PROJECT-O";
import { createApiClient } from "api/config";
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';
import { milvusService } from "api/services/PROJECT-O/Milvus.service";
import { useParams } from 'react-router-dom';

class HotelOpsHelper {

    // Process Multiplee AI Lab Docs 
    async ProcessFiles(files, filter, org, suborg, bucket, chunk_size = 200, chunk_overlap = 20) {
        try {
            const results = await Promise.all(files.map(async (file, index) => {
                const form = new FormData();

                form.append('organization_id', org);
                form.append('sub_organization_id', suborg);
                form.append('bucket_name', bucket);
                form.append('chunk_size', chunk_size);
                form.append('chunk_overlap', chunk_overlap);

                // Make sure the extension is lowercased
                const newFile = this.toLowerCaseExtension(file);
                form.append('files', newFile);

                let filter2 = { ...filter };
                filter2.name = newFile.name;

                form.append('metadata', JSON.stringify(filter2));

                try {
                    const res = await ragService.processFile(form);

                    if (!res.data || res.data.document_ids.length === 0) {
                        console.error(`No document IDs received for file at index ${index}.`);
                        return null;
                    }

                    return res.data;
                } catch (error) {
                    console.error(`Error processing file at index ${index}:`, error);
                    return null;
                }
            }));
            
            return results.filter(result => result !== null && result !== undefined);
        } catch (error) {
            console.log("FILE PROCESSING ERROR", error);
            return [];
        }
    }
     
    async QueryAllDocs(filter, query, org, suborg){
        try{
            const form = new FormData();
            form.append('organization_id', org);
            form.append('sub_organization_id', suborg);
            form.append('query', query);
            form.append('pre_filter', JSON.stringify(filter));
            form.append('k', 15);
            const res = await httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, form);

            console.log("Query ALL API Response: ", res);
            return res;
        }
        catch(error){
            console.log("ERROR: ", error);
        }
    }

    async QueryEmbeddings(query, org, suborg, collectionId){
        try{
            const body = {
                "organziation_id": org,
                "sub_organization_id": suborg,
                "collection_id": collectionId,
                "filters": {},
                "query_text": query,
                "top_k": 5,
                "metric_type": "COSINE"
              }

              console.log("Payload being sent to API: ", body);  
              const res = await milvusService.QueryEmbeddings(body)
              console.log("QueryEmbeddings Result: ", res);
              return res;
        }
        catch(error){
            console.log("ERROR: ", error);
        }
    }

    async QueryAndRerank(query){
        try{
            const body = {
                "collection_name": "test_sync_conf",
                "query_text": query,
                "top_k": 10,
                "top_n": 3
            }
            const res = await milvusService.QueryAndRerank(body)
            return res;
        }
        catch(error){
            console.log("ERROR: ", error);
        }
    }

    constructMilvusRetrievedResults = async (docs, response) => {
        let str = "";
    
        console.log("Full API Response:", response);
    
        const presignedUrls = response?.data?.presigned_urls;
    
        console.log("Presigned URLs:", presignedUrls);
    
        if (!presignedUrls || typeof presignedUrls !== 'object') {
            console.error("Invalid presignedUrls format. It should be an object.");
            return "";
        }
    
        for (const doc of docs) {
            const fileKey = doc.file_key;
            let link = "";
    
            if (Object.prototype.hasOwnProperty.call(presignedUrls, fileKey)) {
                link = `${presignedUrls[fileKey]}#page=${doc.page_number}`;
                const fileExtension = fileKey.split('.').pop().toLowerCase();
    
                if (['txt', 'docx', 'xlsx', 'pptx', 'ppt', 'doc', 'xls'].includes(fileExtension)) {
                    link = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(link)}`;
                }
    
                console.log(link);
            } else {    
                console.log(`No pre-signed URL found for ${fileKey}`);
            }
    
            const fileName = fileKey.split('/').pop();
    
            str += "[Document]\n";
            str += link ? `[Reference Link]${link}[/Reference Link]\n` : "";
    
            const fileExtension = fileKey.split('.').pop().toLowerCase();
            console.log("This is file ext", fileExtension)
            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                if (doc.sheet_name) {
                    str += `[Sheet]${doc.sheet_name}[/Sheet]\n`; 
                } else {
                    console.error("Missing sheet_number for Excel file");
                }
            } else {
                str += `[Page]${doc.page_number}[/Page]\n`; 
            }
    
            str += `[Date Created]${doc.created_at}[/Date Created]\n`;
            str += `[Title]${fileName}[/Title]\n`;
            str += `[Content]${doc.text}[/Content]\n`;
            str += "[/Document]\n\n";
        }
    
        return str;
    };

    constructMongoDBRetrievedResults(docs, uploadedDocs) {
        let str = "";
    
        docs.forEach((doc) => {
            // Determine file extension and types
            const ext = this.getFileExtension(doc.metadata.name).toLowerCase();
            const isOfficeFile = ['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls'].includes(ext);
            const isPdf = ext === "pdf";
    
            // Find matching document in uploadedDocs
            const match = uploadedDocs.find(item => item.name === doc.metadata.name);
            let link = "";
    
            if (match) {
                // Set link based on file type and existence in uploadedDocs
                if (isPdf) {
                    link = `${match.file_url}#page=${doc.metadata.start_page}`;
                } else if (isOfficeFile) {
                    // Modify the link for Office file types to use Office Apps viewer
                    link = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(match.file_url)}`;
                } else {
                    link = match.file_url; // Use the direct link if it's neither PDF nor Office
                }
                console.log("These are the links retrieved", link); // Optional: Logs each constructed link for verification
            } else {
                console.log(`No matching document for ${doc.metadata.name}`);
            }
    
            // Construct document details as a string
            str += "[Document]\n";
            str += `[Reference Link]${link}[/Reference Link]\n`;
            str += (ext === 'ppt' || ext === 'pptx') ? `[Page]${doc.metadata.slide_number}[/Page]\n` : "";
            str += (isPdf || ext === 'docx') ? `[Page]${doc.metadata.start_page}[/Page]\n` : "";
            str += `[Title]${doc.metadata.name}[/Title]\n`;
            str += `[Content]${doc.page_content}[/Content]\n`;
            str += "[/Document]\n\n";
        });
    
        return str;
    }
    
    
    
    getFileExtension(url) {
        // eslint-disable-next-line no-useless-escape
        return url.split('.').pop().split(/\#|\?/)[0];
    }

    toLowerCaseExtension(file) {
        const { name, type } = file;
        const lastDotIndex = name.lastIndexOf('.');
        if (lastDotIndex === -1) return file;
        const newFileName = `${name.slice(0, lastDotIndex)}.${name.slice(lastDotIndex + 1).toLowerCase()}`;
    
        // eslint-disable-next-line no-undef
        return new File([file], newFileName, { type });
    }

    // Groq
    async ConstructPromptGroq(ConstructedRAG, input, config) {
        // let prompt = "You are a helpful assistant. You are trained in Retrieval-Augmented Generation (RAG). Act as a hotel service desk AI. " +
        // "Respond in a friendly and professional manner. You will be given \"Information\" and \"User Query\". " +
        // "You are to respond to user query using the information given. " + 
        // "Do not give any information other than the provided one. " +
        // "Do not start another conversation topic." + 
        // "Always end your response with \"Feel free to ask another question or query about your documents!\"" +
        // `\n\nInformation:\n${ConstructedRAG}\n` + 
        // `User Query:\n${input}\n`;

        let prompt = 
`You are a hotel service desk AI.
Answer the questions based on the provided context only.
Please provide the most accurate response based on the question.
Respond in a friendly and professional manner.
If information is not in context, do not make up information and respond with "I can't find that information. Can you rephrase that?". Do not add any reference link if there's no answer in the context.

Respond with Markdown format.
Strictly respond with a reference link of the information in the format: "\n \nReference/s: [Document Title](Reference Link) - Page # or Sheet Name"
Ensure that the reference link is from constructRetrievedResults, clickable, valid and not changed.

Strictly base your response with
Format: ${config.outputFormat}, and
${config.includeReferences ? 'Include references' : 'Do not include reference links'}

<context>
${ConstructedRAG}
<context>

If ConstructedRAG is empty object, do not generate any answer and strictly respond with "I can't find that information. Can you rephrase that?"
`
        input = 
`Question: ${input}

If input is in different language, translate the context.

Strictly limit the response to a maximum of ${config.wordLimit} words only. Do not exceed. Summarize the response if necessary to stay within the limit.
If the information is too lengthy, prioritize key points, and strictly keep the word count at or below the limit. (Word limit does not include links)
At the end of the response generation, recheck the word count. If it exceeds ${config.wordLimit} words, trim the response to meet the word limit before finalizing.`;
// eslint-disable-next-line max-lines

        console.log(prompt);
        console.log(input);
        try{
            const body = {
                //modelId: "mixtral-8x7b-32768",
                //modelId: "llama-3.1-70b-versatile",
                 //modelId: "llama-guard-3-8b",
                // modelId:"llama-3.1-8b-instant",
                // parameters: {
                //     max_tokens: 900,
                //     response_format: {"type": "text"},
                //     temperature: 0
                // },
                modelId: 'llama-3.1-70b-versatile',
            parameters: {
                max_tokens: 900,
                response_format: {"type": "text"},
                temperature: 0,
                seed: 42
            },
                prompt: prompt,
                query: input
            }

            console.log("IBM payload", body)

            const model_type = "Groq";  
            const response = await unifiedModelService.Experiment(body, model_type);
            //console.log("IBM API Response:", response);
            return response;



        } catch(error){
            console.log("ERROR: ", error);
        }

        
    }

}
const hoHelper = new HotelOpsHelper()
export default hoHelper;