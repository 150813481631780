import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from "components/ui/button";
import { FileText, Trash2, Upload } from 'lucide-react';
import * as ShadeDD from "components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogClose, DialogHeader, DialogTitle, DialogTrigger} from "components/ui/dialog";
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Input } from 'components/ui/input';
// import { processMultipleFiles , deleteDocument } from 'pages/HotelOperations/Components/documentChatOps';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "components/ui/tabs";
import { HotelOpsContext } from '../HotelOpsContext';
import { Context } from 'context/GlobalState';
import hoHelper from "../HotelOpsHelper"; 
import { useParams } from 'react-router-dom';
import { ragService } from 'api/services/PROJECT-O';
    

export const DocumentList = () => {
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const { user, addNewNotifcation } = useContext(Context);
    const { oragID, subOragID } = useParams();
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const {
        uploadedDocs, setUploadedDocs
    } = useContext(HotelOpsContext);

    const handleProcessUserFile = async () => {
        try {
            setIsProcessing(true);
            const filter = {
                userId: user._id,
                organizationId: oragID,
                subOrganizationId: subOragID,
                assistant: 'hotel-operations',
                document_collection: "user"
            }
            const res = await hoHelper.ProcessFiles(files, filter, oragID, process.env.REACT_APP_DEMO_MODE, `${oragID}-bex-demo-mode`, 200, 20);
            addNewNotifcation("User document(s) successfully processed.", "success");
            console.log("Processing results: ", res);
        } catch (err) {
            console.log(err);
            addNewNotifcation("User document(s) not processed. Please try again.", "error");
        }
        finally {
            await handleLoadUserFiles();
            setIsProcessing(false);
        }
        
    }

    const handleLoadUserFiles = async () => {
        try{
            const filter = {
                userId: user._id,
                organizationId: oragID,
                subOrganizationId: subOragID,
                assistant: 'hotel-operations',
                document_collection: "user"
            }
            const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, filter)
            setUploadedDocs(res.data);
            console.log("Loaded User files: ", res);
        } catch (err) {
            console.log(err);
        }
    }

    
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    }
    

    const handleIfSelected = (doc) => {
        if (
          selectedDocuments.find(
            (document) => document._id === doc._id
          )
        )
          return true;
        else return false;
      };


    useEffect(() => {
        console.log("Selected documents:", selectedDocuments);
    }, [selectedDocuments]);

    useEffect(() => {
        handleLoadUserFiles();
    }, []);

    return(
        <ShadeDD.DropdownMenu>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="px-2">
                    <FileText className="w-5 h-5" />
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="center" side="bottom" className="w-[350px]">
                <Tabs defaultValue="user" className="w-full h-fit">
                    <TabsList className="flex w-full grid-cols-4 bg-gray-100">
                        <TabsTrigger className="" value="user">User Documents</TabsTrigger>
                    </TabsList>
                    <ShadeDD.DropdownMenuSeparator />
                    <TabsContent className="w-full h-full m-0" value="user">
                        {/* <PromptPanelDemo/> */}
                        <ShadeDD.DropdownMenuGroup className='max-h-72 overflow-y-auto'>
                            {uploadedDocs.map((doc) => (
                                <div
                                    key={doc._id}
                                    className="w-full flex flex-row gap-2 justify-between">
                                    <ShadeDD.DropdownMenuCheckboxItem
                                        className="w-full overflow-hidden"
                                        
                                        checked={handleIfSelected(doc)}
                                        onSelect={(event) => {
                                        const result = selectedDocuments.find(
                                            (document) => document._id === doc._id
                                        );
                                        // console.log(doc._id)
                                        event.preventDefault();
                                        if (result) {
                                            setSelectedDocuments((prevDocuments) =>
                                                prevDocuments.filter((document) => document._id !== doc._id)
                                            );
                                        } else {
                                            if (selectedDocuments.length < 1){
                                                setSelectedDocuments((prevDocuments) => [
                                                    ...prevDocuments,
                                                    { _id: doc._id, WD_id:doc.WD_id, name: doc.file_name, file_url: doc.file_url },
                                                ]);
                                            }
                                            
                                        }
                                        
                                        }}
                                    >
                                        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                            {doc.file_name}
                                        </span>
                                        
                                    </ShadeDD.DropdownMenuCheckboxItem>
                                    <DeleteDialog doc={doc} setSelectedDocuments={setSelectedDocuments} handleLoadUserFiles={handleLoadUserFiles}/>
                                </div>
                            
                            ))}
                        </ShadeDD.DropdownMenuGroup>
                        <ShadeDD.DropdownMenuSeparator />
                        <ShadeDD.DropdownMenuLabel className="flex flex-row flex-nowrap items-center gap-2">
                            <Input
                                ref={fileInputRef}
                                type="file"
                                onChange={handleFileChange}
                                multiple
                            />
                            <Button variant="outline"  className="mr-2" onClick={handleProcessUserFile} disabled={!fileInputRef.current || fileInputRef.current.files.length === 0}>
                                {isProcessing ? <LoaderSpinner/> : <Upload className="w-5 h-5"/>}
                            </Button>
                        </ShadeDD.DropdownMenuLabel>
                    </TabsContent>
                </Tabs>
                {/* <ShadeDD.DropdownMenuLabel>
                    {isLoading ? "Loading documents..." : uploadedDocs.length === 0 ? "No documents" : "Documents"}
                </ShadeDD.DropdownMenuLabel> */}
                {/* <ScrollArea className="h-72 w-full "> */}

                {/* </ScrollArea> */}
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


const DeleteDialog = ({doc, setSelectedDocuments, handleLoadUserFiles}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user, addNewNotifcation } = useContext(Context);
    const { oragID, subOragID } = useParams();

    const handleDelete = async () => {
        setIsOpen(false);
        setIsLoading(true);
        console.log(oragID, subOragID, doc);
        const res = await ragService.deleteDocument(oragID, process.env.REACT_APP_DEMO_MODE, doc._id, `${oragID}-bex-demo-mode`);
        console.log("Deleted document: ", res);
        addNewNotifcation("Document successfully deleted.", "success")
        // await deleteDocument(doc._id, user, addNewNotifcation);
        setSelectedDocuments([]);
        await handleLoadUserFiles();
        // await loadDocumentsOrg();
        setIsLoading(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost" size="icon" className="px-2" onClick={() => setIsOpen(true)}>
                    {isLoading ? <LoaderSpinner/> : <Trash2 className="w-5 h-5" />}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Delete Document?</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to delete this document?
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-2">
                    <span className=''>{doc.name}</span>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </DialogClose>
                    <Button
                        type="button"
                        className="bg-red-500 hover:bg-red-600"
                        onClick={handleDelete}
                        disabled={isLoading}
                    >
                        Delete Permanently
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
};