import React, { useContext } from 'react'
import { Context } from '../../../context/GlobalState'
import './profile-styles.css'
import ProfileCard from './ProfileCard'


export default function Profile() {

    const { user } = useContext(Context);
    
    return (
        <div className='flex flex-grow'>
            <div className='flex flex-grow flex-col'>
                <ProfileCard name={user.name} email={user.email} />
            </div>
        </div>
    )
}