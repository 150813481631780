/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
import { useContext, useEffect, useState } from 'react'
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "components/ui/card"
import { Eye, EyeOff } from 'lucide-react'
import { secretKeysService } from 'api'
import { useParams } from 'react-router-dom'
import { AppLoader } from 'components/LoaderSpinner'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Context } from 'context/GlobalState'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import { OrganizationContext } from 'context/OrganizationContext'

function camelCaseToTitleCase(str) {
    let result = str.replace(/([A-Z])/g, ' $1').replace(/^./, function(char) {
      return char.toUpperCase();
    });
    
    return result;
}

function getSecretKeys(secretKeys, vectorStoreType) {

    const keys = []
    for (let key in secretKeys) {
        if (key === 'default' || key === 'subOrg') continue
        const keyObj = {
            'label': key,
            'key': camelCaseToTitleCase(key),
            'defaultValue': vectorStoreType === 'MongoDB' ? '*****************' : secretKeys[key],
            'customValue': vectorStoreType === 'MongoDB' ? '*****************' : secretKeys[key],
        }
        keys.push(keyObj)
    }

    return keys
}

export default function MilvusConfig() {

    const [secretKeys, setSecretKeys] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const { oragID } = useParams()

    useEffect(() => {
        secretKeysService.listMilvusSecretKeys(oragID)
        .then((res) => {
            const milvusKeys = getSecretKeys(res.data.milvusKeys, res.data.vectorStoreType)
            const esKeys = getSecretKeys(res.data.elasticsearch, res.data.vectorStoreType)

            setSecretKeys({keys: milvusKeys, esKeys: esKeys, vectorStoreType: res.data.vectorStoreType})
        }).catch(error => {
            console.log('error: ', error);
            setError(error?.response?.message || 'Something went wrong')
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    if (error) {
        return <h1> Error: {error} </h1>
    }

    return (
        <OrganizationKeyCard keyName='Milvus' esKeys={secretKeys.esKeys} keys={secretKeys.keys} vectorStoreType={secretKeys.vectorStoreType} setSecretKeys={setSecretKeys} />
    )
}


const OrganizationKeyCard = ({ keyName, keys, esKeys, vectorStoreType, setSecretKeys }) => {

    const [vectorType, setVectorType] = useState(vectorStoreType)
    const [btnLoading, setBtnLoading] = useState(false)
    const VECTOR_TYPES = ['MongoDB', 'Milvus']

    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { oragID } = useParams()

    const handleCustomValueChange = (value, index, keyType) => {
        setSecretKeys(prev => {
            const keys = prev[keyType].map((item, i) => {
                if (i === index) {
                    return { ...item, 'customValue': value }
                } else {
                    return { ...item }
                }
            })

            return {...prev, [keyType]: keys}

        })
    }

    const filterKeys = (item) => {
        return !item.hasOwnProperty('isDefault') || !item.key === 'Sub Org'
    }

    const handleChange= (value) => {

        const clearedKeys = keys.map(keyObj => {
            return {
                ...keyObj,
                customValue: vectorStoreType === 'MongoDB' ? '' : keyObj.defaultValue,
            }
        });

        const clearedEsKeys = esKeys.map(keyObj => {
            return {
                ...keyObj,
                customValue: vectorStoreType === 'MongoDB' ? '' : keyObj.defaultValue,
            }
        });

        setSecretKeys(prev => ({...prev, keys: clearedKeys, esKeys: clearedEsKeys}));
        setVectorType(value)
    }

    const handleChangeMilvusKeys = () => {
        if (selectedOrganization.userRole !== 'superAdmin') {
            return addNewNotifcation('Only super admin can update milvus keys', 'warning')
        } 

        const obj = {
            vectorStoreType: vectorType,
            milvusKeys: {}
        }
        if (vectorType === 'MongoDB') {
            obj['milvusKeys'] = {
                milvusApiKey: '',
                milvusCloudRegion: '',
                milvusClusterId: '',
                milvusPublicEndpoint: ''
            }
            obj['elasticsearch'] = {
                elasticsearchApiKey: '',
                elasticsearchCloudId: '',
                elasticsearchEndpoint: ''
            }
        } else {
            obj['milvusKeys'] = {
                milvusApiKey: keys.find(item => item.label === 'milvusApiKey').customValue,
                milvusCloudRegion: keys.find(item => item.label === 'milvusCloudRegion').customValue,
                milvusClusterId: keys.find(item => item.label === 'milvusClusterId').customValue,
                milvusPublicEndpoint: keys.find(item => item.label === 'milvusPublicEndpoint').customValue,
            }
            obj['elasticsearch'] = {
                elasticsearchApiKey: esKeys.find(item => item.label === 'elasticsearchApiKey').customValue,
                elasticsearchCloudId: esKeys.find(item => item.label === 'elasticsearchCloudId').customValue,
                elasticsearchEndpoint: esKeys.find(item => item.label === 'elasticsearchEndpoint').customValue,
            }

            const checkEmptyKeys = Object.values(obj.milvusKeys).some(value => !value);
            const checkEsEmptyKeys = Object.values(obj.elasticsearch).some(value => !value);
            if (checkEmptyKeys || checkEsEmptyKeys) {
                return addNewNotifcation('Please fill in all keys', 'warning')
            }
        }

        setBtnLoading(true)

        const secretObj = {
            organizationId: oragID,
            secretKeys: obj
        }

        console.log('secretObj: ',secretObj);
        
        
        secretKeysService.updateMilvusSecretKeys(secretObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Milvus and Elasticsearch keys updated successfully', 'success')

        }).catch((error) => {
            console.log('error: ' ,error);
            if (error?.response?.status === 403) {
                addNewNotifcation('This organization does not have any valid subscription', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Milvus Keys</CardTitle>
                <CardDescription>Manage your organization's configuration keys</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
                <div className="flex flex-col gap-3">
                    <Label htmlFor={'vectorStoreType'}> Vector Store Type </Label>

                    <Select onValueChange={(value) => handleChange(value)} id="vectorStoreType" defaultValue={vectorType}>
                        <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select vector store type" />
                        </SelectTrigger>
                        <SelectContent>
                            {VECTOR_TYPES.map(item => (
                                <SelectItem key={item} value={item}>{item}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
        
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold">{keyName} Configuration</h3>
                    {keys.filter(filterKeys).map((item, index) => (
                        <SecretInputRender key={item.label} {...item} secretKey={item.key} index={index} keyType='keys' onChange={handleCustomValueChange} customKeys={vectorType === 'Milvus'} /> 
                    ))}
                </div>
        
                {vectorType === 'Milvus' && 
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold">Elasticsearch Configuration</h3>
                    {esKeys.filter(filterKeys).map((item, index) => (
                        <SecretInputRender key={item.label} {...item} secretKey={item.key} index={index} keyType='esKeys' onChange={handleCustomValueChange} customKeys={vectorType === 'Milvus'} /> 
                    ))}
                </div>}

            </CardContent>

            <CardFooter>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button disabled={btnLoading} onClick={handleChangeMilvusKeys} className="w-full">Save Configuration</Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-900 text-white">
                            {selectedOrganization.userRole !== 'superAdmin' && (
                                'Only super admin can update milvus keys'
                            )}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </CardFooter>
        </Card>
    )
}

export const SecretInputRender = ({ customKeys, label, index, secretKey, customValue, onChange, keyType, isPassword = true }) => {

    const [showPasswords, setShowPasswords] = useState(false)
    
    return (
        <div className="space-y-2">
            <Label htmlFor={label}>{secretKey}</Label>
            <div className="relative">
                <Input
                type={isPassword && !showPasswords ? "password" : "text"}
                id={label}
                value={customKeys ? customValue : '*'.repeat(8)}
                onChange={(e) => onChange(e.target.value, index, keyType)}
                className="w-full pr-10"
                disabled={!customKeys}
                placeholder={customKeys ? "Enter custom value" : ""}
                />
                {isPassword && (
                <button
                    type="button"
                    onClick={() => setShowPasswords(!showPasswords)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                    {showPasswords ? <EyeOff className="h-4 w-4 text-gray-400" /> : <Eye className="h-4 w-4 text-gray-400" />}
                </button>
                )}
            </div>
        </div>
    )
}