import { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { httpRequest } from "utils/httpsRequest";

export const OrgInfoContext = createContext({});

export const OrganizationInfoProvider = ({ children }) => {
    
    const [organizationData, setOrganizationData] = useState(null);
    const [loading, setLoading] = useState({});
    const [error, setError] = useState(null);

    const { orgId } = useParams();

    // Hooks
    useEffect(() => {
        fetchOrgData()
    }, []);

    const fetchOrgData = async () => {
        try {
            const res = await httpRequest.get(`/db/organizations/get_organization_data/${orgId}`)
            console.log('res: ', res);
            setOrganizationData(res.data)
        } catch (error) {
            console.log('error: ', error);
            setError(error.message)
        } finally {
            setLoading(false);
        }
    };

    return (
        <OrgInfoContext.Provider
        value={{
            loading,
            error,
            organizationData,
        }}
        >
            {children}
        </OrgInfoContext.Provider>
    );
};
