import React, { createContext, useState, useContext, useEffect } from 'react';
import {Context} from "context/GlobalState";
import {SubOrgContext} from "context/SubOrganizationContext";
// import config from "pages/PromptingAndRagTraining/ailab.config";
import { ragService } from "api/services/PROJECT-O";
import { v4 as uuidv4 } from 'uuid';
import { QueryRAG, GetLLMResponse } from "pages/PromptingAndRagTraining/context/functions/documentChatOps";

export const HotelOpsContext = createContext({});

export const HOProvider = ({ children }) => {

  
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedSample, setSelectedSample] = useState([]);
  // const [uploadedDocs, setUploadedDocs] = useState([]);
  const [uploadedDocsOrg, setUploadedDocsOrg] = useState([]);
  const [processLoading, setProcessLoading] = useState(false);
  const [processLoadingOrg, setProcessLoadingOrg] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('text_embedding');
  const [docChatConvo, setDocChatConvo] = useState([{role: 'system', message: "Hi! Feel free to ask questions and query from your hotel documents.", _id: uuidv4()}]);
  const [loading, setLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [displayDocs, setDisplayDocs] = useState([]);
  const [displayDocsSample, setDisplayDocsSample] = useState([]);
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  
  const [messages, setMessages] = useState([{ id: 1, role: "assistant", content: "Hi! Feel free to ask questions and query from your hotel documents." }])
  const [uploadedDocs, setUploadedDocs] = useState([]);

  const value={
    messages, setMessages,
    uploadedDocs, setUploadedDocs,
  }

  return (
      <HotelOpsContext.Provider value={value}>
          {children}
      </HotelOpsContext.Provider>
  );
};