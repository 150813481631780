import React, { useContext, useState } from 'react'
import './hotel-operations.css'
import SetupCard from '../../../components/AppSetup/SetupCard.jsx'
import SetupModal from '../../../components/AppSetup/SetupModal.jsx'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/GlobalState.js'
import Breadcrumb from 'components/Breadcrumb'
import { OrganizationContext } from "context/OrganizationContext";

export default function HotelOperationsDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'BeX AI Assistants for Hotel Operations',
        'Set of hotel policies, and documents .'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'This AI Assistant is designed to revolutionize document management by enabling users to efficiently query information from documents with advanced control options. Users can customize query outputs by setting parameters like word limits, presenting results in bullet points, tabular formats, or even extracting images directly from the source. Additionally, the AI Assistant provides precise reference details, including specific page numbers, allowing users to easily click and view the exact location within the document.',
        'This streamlined approach ensures faster access to relevant information, optimizing workflows and enhancing productivity for teams handling large volumes of documents.'    ];

    const navigate = useNavigate()
    const { selectedOrganization } = useContext(OrganizationContext);
    
    const { user,addNewNotifcation } = useContext(Context)
    const param = useParams()

    const [openModal, setOpenModal] = useState(false)

    const handleDemoClick = () => {
        // setOpenModal(!openModal)
        if (selectedOrganization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/hotel-op-demo`)
        } else {
            navigate(`/organizations`)
        }
        console.log(param.oragID, param.subOragID)
    }
    const handleSetupClick = () => {
        addNewNotification('No setup is needed for this assistant. Click Try Assistant.', 'success');
       
    };
    // const handleSetupClick = () => {
    //     if (user.organization) {
    //         navigate(`/organizations/organization/${user.organization}/hr-ai-assistant`)
    //     } else {
    //         navigate(`/organizations`)
    //     }
    // }

    return (
        <div className='hotel-op-assistant-dashboard-container'>
            <div className='hotel-op-assistant-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    {/* <BreadcrumbsCustom /> */}

                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for Hotel Operations'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant'
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                        <SetupModal title={'Demo Mode for BeX AI Assistant for Hotel Operations'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='hotel-op-assistant-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO: Job or candidate matching
                                    </div>
                                    <div className='demo-insturctions__text'>
                                        <span>
                                        HR teams can create tailored, compliant programs and content at scale while optimizing the employee lifecycle. Generative AI saves time on rote tasks to better focus on more strategic initiatives.
                                        </span>
                                    </div>
                                </div>

                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                             
                                
                            </SetupModal.StyledSetup>
                        </SetupModal>

                    </div>
                </div>
            </div>
        </div>
    )
}
