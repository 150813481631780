import React, { useContext, useState, useRef } from 'react'
import './secure-login-styles.css'
import { useNavigate} from 'react-router-dom'
import { Context } from '../../../context/GlobalState'
import { httpRequest } from '../../../utils/httpsRequest'
import LoginForm from '../../../pages/Login/LoginForm'
import { authService } from 'api'

export default function Login() {
    const [loading, setLoading] = useState(false)
    const { actions, addNewNotifcation } = useContext(Context);

    const navigate = useNavigate()

    const loginFormRef = useRef(null);

    const handleOnSubmit = (e) => {
        e.preventDefault()

        const emailRef = loginFormRef.current.getEmailRef();
        const passwordRef = loginFormRef.current.getPasswordRef();

        setLoading(true)
        authService.adminLogin({
            email: emailRef.value,
            password: passwordRef.value,
        })
        .then(async (res) => {
            actions({type: 'SET_USER_ADMIN', payload: res.data.admin})
            actions({type: 'SET_IS_ADMIN_AUTH', payload: res.data.auth})
            console.log(res);
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 403) {
                addNewNotifcation('Email or password is incorrect. Please reenter your credentials.', 'danger')
            } else {
                addNewNotifcation('Login failed. Please check your credentials.', 'danger')
            }
        }).finally(() => {
            setLoading(false)
            navigate('/secure/dashboard');
        })
    }

    return (
        <div className='secure-login-container'>
            <div className='secure-login-content-container'>
                <div className='secure-login-form-container'>
                    <div className='secure-login-form-title'>
                        <h2> Admin Login </h2>
                    </div>
                    <div className='flex flex-grow flex-col justify-center items-center'>
                        <LoginForm admin={true} ref={loginFormRef} loading={loading} onSubmit={handleOnSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}