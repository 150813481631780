import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";

const LoginForm = forwardRef((props, ref) => {
  const { loading, onSubmit, admin } = props

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEmailRef: () => emailRef.current,
    getPasswordRef: () => passwordRef.current
  }));

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>Enter your email and password to sign in to your account.</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <form onSubmit={onSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input ref={emailRef} id="email" type="email" placeholder="m@example.com" required />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="password">Password</Label>
              {!admin && <Link
                to="/auth/reset-password"
                className="text-sm font-medium text-[#B1092A] underline underline-offset-2 hover:text-[#8d0721] focus:text-[#8d0721]"
              >
                Forgot password?
              </Link>}
            </div>
            <Input ref={passwordRef} id="password" type="password" required />
          </div>
          <Button disabled={loading} onSubmit={onSubmit} variant='default' className="w-full">Sign in</Button>
        </form>
      </CardContent>
      {!admin && <CardFooter className='flex flex-col'>
        <div className="mt-4 text-center text-sm">
          Don&apos;t have an account?{" "}
          <Link
            to="/auth/signup"
            className="font-medium text-[#B1092A] underline underline-offset-2 hover:text-[#8d0721] focus:text-[#8d0721]"
          >
            Sign up
          </Link>
        </div>
      </CardFooter>}
    </Card>
  );
});

export default LoginForm;
