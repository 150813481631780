import { Copy, FolderPlus } from "lucide-react";
import { Button } from "components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Context } from "context/GlobalState";
import { useContext, useEffect, useState } from "react";

export function AddFolderDialog({ setFileSystem, findFolder, selectedFolder }) {
    const { addNewNotifcation } = useContext(Context);
    const [newFolderName, setNewFolderName] = useState("Untitled Folder");
    const [open, setOpen] = useState(false);

    const handleNewFolder = () => {
        if (newFolderName) {
            const newItem = {
                id: Date.now().toString(),
                name: newFolderName,
                type: "folder",
                ...{ children: [] },
            };
            setFileSystem((prev) => {
                const updatedSystem = [...prev];
                const folder = findFolder(selectedFolder, updatedSystem);
                if (folder && folder.children) {
                    // check if folder name exists
                    if (folder.children.find((item) => item.name === newItem.name)) {
                        addNewNotifcation("Folder name exists already. Please choose different name", "warning");
                    } else {
                        folder.children.push(newItem);
                        setOpen(false); // Close the dialog
                    }
                }
                return updatedSystem;
            });
        }
    };

    const onMount = () => {
        setNewFolderName("Untitled Folder");
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button onClick={onMount} variant="outline" className="flex gap-2 text-xs">
                    <FolderPlus className="w-4 h-4" />
                    <span>New Folder</span>
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-md">
                <DialogHeader>
                    <DialogTitle>Add New Folder</DialogTitle>
                    <DialogDescription>
                        Enter the name of the new folder. It will be added inside the current selected folder.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex items-center space-x-2">
                    <div className="grid flex-1 gap-2">
                        <Input value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
                    </div>
                    <Button type="submit" size="sm" className="px-3" onClick={handleNewFolder}>
                        Add
                    </Button>
                </div>
                <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
