import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, Ref, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { RxCaretDown } from "react-icons/rx";

const Dropdown = styled.div`
    position: relative;
    font-size:0.85rem;
    display: flex;
    align-items: center;
    height:2.5rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 5px;
    // border-style: solid;
    // border-width: 1.5px;
    border-color: rgba(220, 220, 220, 1);
    justify-content: space-between;
    &:hover{
        cursor:pointer;
    }
    #icon {
        transition: rotate 0.2s ease;
        rotate: ${({$isExpanded})=> $isExpanded ? '180deg':'0deg'};
    }

`
const ItemsList = styled.ul`
    position: absolute;
    overflow: auto;
    font-size:0.85rem;
    padding:0.25rem;
    list-style-type: none;
    top:2.6rem;
    z-index:9999;
    left:0;
    width: inherit;
    transition: height 1s ease;
    background-color: white;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(200, 200, 200, 1);
    box-shadow: 0 5px 10px rgba(0,0,0,0.3);
    &:hover {
        cursor: pointer;

`
const Item = styled.li`
    height:2rem;
    display:flex;
    gap:1rem;
    position: sticky;
    border-radius: 0.25rem;
    padding:0.5rem;
    align-items: center;
    &:hover {
        background-color: rgba(150, 150, 150, 0.2);
    }
    > div {
        margin: 0.25rem;
    }
    
`
const DropdownWhole = styled.div`
    position: relative;
    width: 100%;
`
const SearchBox = styled.input`
    height: 1.5rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    width: inherit;
    border-radius: 1.5rem;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(220, 220, 220, 1);
`
function ItemWithCheckbox({children, itemsListDisplay, onClick, items, selectedList, objValue}){
    const [isSelected, setIsSelected] = useState(false)
    useEffect(()=>{
    
        console.log("OBJ", objValue.value)
        if(selectedList.find((item)=>item.value.documentRecord._id === objValue.value.documentRecord._id)){
            setIsSelected(true)
        }
    },[itemsListDisplay])
    return (
        <Item onClick={(event)=>{
            onClick(objValue)
            setIsSelected(!isSelected)
        }}><input checked={isSelected} type="checkbox" readOnly/>{children}</Item>
    )
}
function DropdownMenuDisplay({isExpanded, originalItems, setter, multiple, selectedList, searchable, selectWhat}) {
    const [itemsListDisplay, setItemsListDisplay] = useState(originalItems)
    const SearchRef = useRef(null)
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight;
        if (bottom) { 
            console.log('Bottomed!')
         }
      }
    const itemHandler = () => {
        if(multiple){
            return (
            <> 
                {itemsListDisplay.length > 0 ? itemsListDisplay.map((items) =>
                <ItemWithCheckbox key={JSON.stringify(items.value)} selectedList={selectedList} itemsListDisplay={itemsListDisplay} items={itemsListDisplay} objValue={originalItems.find((originalItem)=>originalItem.value === items.value)} onClick={handleClickItem}>{items.name}</ItemWithCheckbox>)
                :<Item style={{color:'gray'}}>No Items</Item>}
            </>)
        } 
        return (
        <>
            {itemsListDisplay.length > 0 ? itemsListDisplay.map((items) => 
            <Item key={JSON.stringify(items.value)} onClick={() => handleClickItem(items)}>{items.name}</Item>)
            :<Item style={{color:'gray'}}>No Items</Item>}
        </>)

    }
    const handleClickItem = (value) => {
        
        if(multiple){
            if(selectedList.find((item)=>item.value === value.value)){
            setter((prevArr)=>prevArr.filter((item)=>item.value != value.value))
            } else {  
                setter((prevArr)=>[...prevArr, value])
            }    
        } else {
            console.log(value)
            setter([value])
        }
        
    }
    const handleOnChange = (event) => {
        // console.log(displayItems.itemsListDisplay.filter((item)=>item.name.toLowerCase().includes(event.target.value.toLowerCase())))
        setItemsListDisplay(originalItems.filter((item)=>item.name.toLowerCase().includes(event.target.value.toLowerCase())))
    }
    return (
        <ItemsList>
            {searchable ? <SearchBox ref={SearchRef} placeholder={`Search ${selectWhat}`} onChange={handleOnChange} type="text"/> : null}
            <div onScroll={handleScroll} style={{overflow:'auto', maxHeight: '20vh'}}>
                {itemHandler()}
            </div>
            
        </ItemsList>
    )
}
function DropdownMenu ({defaultValue, multiple, itemsList, withDefault, selectedItem,  selectWhat, listIndex, objectReplace, originalList, setter, searchable}, ref) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [selected, setSelected] = useState(defaultValue ? itemsList.filter((item)=>item.value === defaultValue) : selectedItem)
    const [displayed, setDisplayed] = useState(defaultValue ? itemsList.find((item)=>item.value === defaultValue).name : `Select ${selectWhat}`)
    const dropdownRef = useRef(document.createElement('div'))

    useEffect(() => {
        if (originalList) {
          const selectedItem = itemsList.find(item => item.value === originalList);
          if (selectedItem) {
            setDisplayed(selectedItem.name);
          } else {
            setDisplayed(`Select ${selectWhat}`);
          }
        }
    }, [itemsList, originalList, selectWhat]);

    useEffect(()=>{
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setIsExpanded(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    })
    useEffect(()=>{
        
        if(withDefault){

        }
        
        if(!multiple){
            console.log('fromUseeffect', selected, originalList)
            if(selected.length > 0){
                setDisplayed(selected[0].name)
            }

                if(setter){
                    if(listIndex !== undefined){
                    const forUpdate = [...originalList];
                    console.log('forUpdate',forUpdate, objectReplace)
                    if (forUpdate.length > 0) {
                        forUpdate[listIndex][objectReplace] = selected[0]?.value;
                        console.log('forUpdateiwth objectReplace',forUpdate, )
                        setter(forUpdate);
                    }
                } else {
                    console.log(typeof(originalList))
                    if(typeof(originalList) === 'string' || typeof(originalList) === 'number' || typeof(originalList) === 'undefined') {
                        console.log('STRING')
                        setter(selected[0]?.value);

                    } else if(typeof(originalList) === 'object'){
                            
                            if(objectReplace){
                                const forUpdate = {...originalList} 
                                forUpdate[objectReplace] = selected[0]?.value;
                                setter(forUpdate);
                            } else {
                                setter(selected[0]?.value);
                            }
                    }
                    
                }}

                setIsExpanded(false)
        } else {
            if(selected.length < 1){
                setDisplayed(`Select ${selectWhat}`)
            } else {
                setDisplayed(`(${selected.length}) Selected`)
            }
            setter(selected)
        }
    },[selected])

    useImperativeHandle(ref, ()=>({selected}))
    
    const handleClickDropdown = () => {
        setIsExpanded((prev)=>!prev)
    }

    return (
        <DropdownWhole className="font-inherit shadow-sm rounded-sm" ref={dropdownRef}>
            <Dropdown tabIndex={0} $isExpanded={isExpanded} onClick={handleClickDropdown}>
                <span>{displayed}</span>
                <RxCaretDown id="icon" size={'1.5rem'} />
                
            </Dropdown> 
            {isExpanded ? <DropdownMenuDisplay searchable={searchable} isExpanded={isExpanded} selectedList={selected} multiple={multiple} selectWhat={selectWhat} setter={setSelected} originalItems={itemsList} /> : null}         
        </DropdownWhole>
    )
}

export default React.forwardRef(DropdownMenu)