/* eslint-disable max-lines */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext } from "react";
import {
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Badge } from "components/ui/badge";
import { ScrollArea } from "components/ui/scroll-area";
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner, AppLoader } from "components/LoaderSpinner";
import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import { SubOrgContext } from "context/SubOrganizationContext";
import { assistantInstanceService } from "api";
import { Context } from "context/GlobalState";
import { useParams } from "react-router-dom";
import { projectService } from "api/services/BEX/project.service";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";

const UpdateCollectionDialog = ({ show, setShow, collectionId }) => {

    const SEARCH_TYPES = ['jina', 'openAI']

    const [btnLoading, setBtnLoading] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [loadingAssistants, setLoadingAssistants] = useState(true);
    const [selectedAssistant, setSelectedAssistant] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [embeddingModel, setEmbeddingModel] = useState("jina");

    const [dimensions, setDimensions] = useState("");
    const [chunkingStrategy, setChunkingStrategy] = useState("");

    const { project, actions } = useContext(SubOrgContext)
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    function filterArray(array1, array2) {
        const collection = array2.find(item => item._id === collectionId)
        const assistantIds = new Set(collection.assistants.map(assistant => assistant._id))

        return array1.filter(item => assistantIds.has(item._id)); //
    }

    const getData = async () => {
        if (!show) return;
        try {
            const assistantInstances = await assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
            setAssistants(assistantInstances.data);
            setSelectedAssistant(filterArray(assistantInstances.data, project.collections));
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoadingAssistants(false);
        }
    };

    useEffect(() => {
        getData();

        const collection = project.collections.find(item => item._id === collectionId)

        setEmbeddingModel(collection.embeddingModel || 'jina')
        setDimensions(collection.dimensions)
        setChunkingStrategy(collection.chunkingStrategy)
    }, [show]);

    const handleUpdateCollection = () => {
        setBtnLoading(true);

        const updatedCollectionObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            projectId: project._id,
            collectionId: collectionId,
            dimensions: dimensions,
            chunkingStrategy: chunkingStrategy,
            embeddingModel: embeddingModel,
            assistants: selectedAssistant.map(item => (item._id)),
        }
        projectService.updateCollection(updatedCollectionObj)
        .then((res) => {
            setShow(false);
            actions({type: 'SET_PROJECT', payload: res.data})
            addNewNotifcation("Sub-Organization updated successfully", "success");
        })
        .catch((error) => {
            console.log("error: ", error);
            if (error?.response?.status === 404) {
                addNewNotifcation("Collection not found", "danger");
            } else {
                addNewNotifcation("Something went wrong", "danger");
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    };

    const handleSelectAssistant = (assistant) => {
        if (!selectedAssistant.some((m) => m._id === assistant._id)) {
            setSelectedAssistant([...selectedAssistant, assistant]);
        }
    };

    const handleRemoveAssistant = (assistant) => {
        setSelectedAssistant(selectedAssistant.filter((m) => m._id !== assistant._id));
    };

    const filteredAssistants = assistants.filter((assistant) => assistant.title.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4 overflow-y-scroll'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">
                            Manage Collection
                        </DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">
                            Modify the assistants linked to this collection
                        </DialogDescription>
                    </DialogHeader>
                
                    <div className="space-y-8 pt-4">
                        <div className="space-y-3">
                            <div>
                                <Label htmlFor='dimensions'> Dimensions </Label>
                                <Input type='number' id='dimensions' value={dimensions} onChange={(e) => setDimensions(e.target.value)} />
                            </div>
                            <div>
                                <Label htmlFor='chunking-strategy'> Chunking Strategy </Label>
                                <Input id='chunking-strategy' value={chunkingStrategy} onChange={(e) => setChunkingStrategy(e.target.value)} />
                            </div>
                            <div>
                                <Label htmlFor='embedding-model'> Embedding Model </Label>
                                <Select onValueChange={(value) => setEmbeddingModel(value)} id="embedding-model" defaultValue={embeddingModel}>
                                    <SelectTrigger className="w-full capitalize">
                                        <SelectValue className="capitalize" placeholder="Select vector search type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {SEARCH_TYPES.map(item => (
                                            <SelectItem className='capitalize' key={item} value={item}>{item} </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>

                        <div className="space-y-3">
                            <div className="flex">
                                <ScrollArea className="rounded-md min-h-0 max-h-[100px]">
                                    <div className="flex flex-wrap gap-2">
                                        {selectedAssistant.map((assistant) => (
                                        <Badge
                                            key={assistant._id}
                                            variant="secondary"
                                            className="p-2 text-sm"
                                        >
                                            {assistant.title}
                                            <Cross1Icon
                                            className="ml-2 cursor-pointer"
                                            onClick={() => handleRemoveAssistant(assistant)}
                                            />
                                        </Badge>
                                        ))}
                                    </div>
                                </ScrollArea>
                            </div>
                            <div className="relative space-y-3">
                                <Label htmlFor="assistant-search" className="text-md">
                                    Assistants Selection
                                </Label>
                                <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                                <Input
                                id="assistant-search"
                                placeholder="Search assistants..."
                                type="search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                                />
                                <ScrollArea className="border-solid rounded-md h-56">
                                    <div className="flex flex-col p-3 space-y-3">
                                        {loadingAssistants ? (
                                        <AppLoader size={60} />
                                        ) : filteredAssistants.length === 0 ? (
                                        <div className="text-center text-muted-foreground">
                                            No assistants found
                                        </div>
                                        ) : (
                                        filteredAssistants.map((assistant) => (
                                            <div
                                            key={assistant._id}
                                            className="flex items-center space-x-3 cursor-pointer"
                                            onClick={() => handleSelectAssistant(assistant)}
                                            >
                                            <div>
                                                <div className="font-medium text-md">{assistant.title}</div>
                                            </div>
                                            <CheckIcon
                                                className={cn(
                                                "ml-auto h-6 w-6",
                                                selectedAssistant.some((m) => m._id === assistant._id)
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                            />
                                            </div>
                                        ))
                                        )}
                                    </div>
                                </ScrollArea>
                            </div>
                        </div>

                    </div>

                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button
                        variant="outline"
                        onClick={() => setShow(false)}
                        className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdateCollection}
                        className="px-5 py-3 text-md"
                    >
                        {btnLoading ? <LoaderSpinner /> : "Update Collection"}
                    </Button>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateCollectionDialog;
