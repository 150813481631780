import { useContext, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "components/ui/pagination";
import { Search } from "lucide-react";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import { Context } from "context/GlobalState";
import ViewOpCodeModal from "./ViewOpCodeModal";
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import DeleteDialog from "components/DeleteDialog";

export default function OpCodeGrid({ workflows = [], setWorkflows }) {
    const {
        selectedOpCode,
        setSelectedOpCode,
        contextKey,
        setContextKey,
        handleGetSavedOpcodes,
        isOpcodeLoading,
    } = useContext(OpCodeBuilderContext);
    const { addNewNotifcation } = useContext(Context);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingWorkflow, setLoadingWorkflow] = useState(null); // For individual loading
    const [deletingWorkflow, setDeletingWorkflow] = useState(null); // Track deletion of each workflow
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);

    const cardsPerPage = 8;
    const filteredWorkflows = workflows;
    const totalPages = Math.ceil(filteredWorkflows.length / cardsPerPage);
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = filteredWorkflows.slice(indexOfFirstCard, indexOfLastCard);

    const handleUse = async (workflow) => {
        if (selectedOpCode === workflow) {
            setSelectedOpCode(""); // Unselect if already selected
            setContextKey("");
            return;
        }

        setLoadingWorkflow(workflow);
        try {
            setSelectedOpCode("");
            setContextKey("");
            const body = {
                org_id: selectedOrganization._id,
                sub_org_id: selectedSubOrganization._id,
                unique_names: [workflow],
            };
            const res = await opCodeService.getOpCodesByUniqueNames(body);
            const details = res.data.OpCodes[workflow];

            const firstStep = details.steps[0];
            let contextKey = "";

            if (firstStep.type === "Non-LLM" && firstStep.params.api_details?.payload?.query_text) {
                const queryText = firstStep.params.api_details.payload.query_text;
                const match = queryText.match(/{{\s*(output|context)\[['"](\w+)['"]\]\s*}}/);
                if (match) {
                    contextKey = match[2];
                }
            } else if (firstStep.type === "LLM" && firstStep.params.query) {
                const query = firstStep.params.query;
                const match = query.match(/{{\s*(output|context)\[['"](\w+)['"]\]\s*}}/);
                if (match) {
                    contextKey = match[2];
                }
            }
            setContextKey(contextKey);
            setSelectedOpCode(workflow);
        } catch (error) {
            console.error("Error getting OpCode by unique names:", error);
            addNewNotifcation("Error getting OpCode details. Please try again.", "danger");
        } finally {
            setLoadingWorkflow(null);
        }
    };

    const handleDeleteWorkflow = async (workflow) => {
        setDeletingWorkflow(workflow); // Set loading for the specific workflow
        try {
            const body = {
                org_id: selectedOrganization._id,
                sub_org_id: selectedSubOrganization._id,
                opcode_ids: [workflow],
            };
            const res = await opCodeService.deleteOpCodes(body);

            if (res.data?.deleted) {
                addNewNotifcation(`Successfully deleted workflow: ${workflow}`, "success");

                // Remove the deleted workflow from the list and update workflows state
                const updatedWorkflows = workflows.filter((w) => w !== workflow);
                setWorkflows(updatedWorkflows);

                // If the deleted workflow was selected, reset selection
                if (workflow === selectedOpCode) {
                    setSelectedOpCode("");
                    setContextKey("");
                }
            }
        } catch (error) {
            console.error("Error deleting workflow:", error);
            addNewNotifcation("Error deleting workflow. Please try again.", "danger");
        } finally {
            setDeletingWorkflow(null); // Clear loading after deletion
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="w-full space-y-4 h-fit">
            <div className="flex gap-2 items-center">
                <div className="flex gap-2 items-center w-full">
                    <Search className="w-5 h-5" />
                    <Input
                        type="text"
                        placeholder="Search workflows..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1); // Reset to first page on search
                        }}
                        className="w-full mx-auto"
                    />
                </div>
                <div className="">
                    <Button
                        onClick={handleGetSavedOpcodes}
                        className="w-full flex gap-2"
                        type="button"
                        disabled={isOpcodeLoading}
                    >
                        {isOpcodeLoading ? "Loading Workflows..." : "Reload Workflows"}
                    </Button>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 h-fit min-h-[224px]">
                {currentCards.length === 0 && (
                    <div className='flex items-center justify-center w-full text-gray-500 col-span-full'>
                        <span>No workflows saved.</span>
                    </div>
                )}
                {currentCards.map((workflow, index) => (
                    <Card
                        key={index}
                        className={`flex flex-col h-fit ${
                            workflow === selectedOpCode ? "bg-black text-white" : ""
                        }`}
                    >
                        <CardHeader className="p-3 min-h-16">
                            <CardTitle className="text-sm font-medium w-full overflow-hidden whitespace-normal break-words line-clamp-2 truncate">
                                {workflow}
                            </CardTitle>
                        </CardHeader>
                        <CardFooter className="p-2 pt-0 gap-2">
                            <Button
                                variant="outline"
                                size="sm"
                                className="w-1/2 text-xs text-black bg-white"
                                onClick={() => handleUse(workflow)}
                                disabled={loadingWorkflow === workflow}
                            >
                                {loadingWorkflow === workflow
                                    ? "Fetching..."
                                    : workflow === selectedOpCode
                                    ? "Deselect"
                                    : "Select"}
                            </Button>
                            <ViewOpCodeModal workflow={workflow}/>
                            <DeleteDialog loading={deletingWorkflow === workflow} itemNames={[workflow]} onDelete={() => handleDeleteWorkflow(workflow)} iconOnly={true} size="sm"/>
                        </CardFooter>
                    </Card>
                ))}
                {isOpcodeLoading && (
                    <p className="text-gray-500 text-center align-middle sm:col-span-2 md:col-span-3 lg:col-span-4">
                        Loading the workflows...
                    </p>
                )}
            </div>
            <Pagination>
                <PaginationContent className="flex flex-wrap justify-center gap-1 sm:gap-2">
                    <PaginationItem>
                        <PaginationPrevious
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (currentPage > 1) handlePageChange(currentPage - 1);
                            }}
                            className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                        />
                    </PaginationItem>
                    {Array.from({ length: totalPages }).map((_, i) => {
                        const pageNumber = i + 1;
                        const isEllipsisBefore = pageNumber === 2 && currentPage > 4;
                        const isEllipsisAfter = pageNumber === totalPages - 1 && currentPage < totalPages - 3;

                        if (
                            pageNumber === 1 ||
                            pageNumber === totalPages ||
                            (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                        ) {
                            return (
                                <PaginationItem key={i}>
                                    <PaginationLink
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handlePageChange(pageNumber);
                                        }}
                                        isActive={currentPage === pageNumber}
                                    >
                                        {pageNumber}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        } else if (isEllipsisBefore || isEllipsisAfter) {
                            return <span key={i} className="px-2">…</span>;
                        }
                        return null;
                    })}
                    <PaginationItem>
                        <PaginationNext
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (currentPage < totalPages) handlePageChange(currentPage + 1);
                            }}
                            className={currentPage === totalPages ? "pointer-events-none opacity-50" : ""}
                        />
                    </PaginationItem>
                </PaginationContent>
            </Pagination>
        </div>
    );
}
