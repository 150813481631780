import { createApiClient } from "api/config";
const BEX_KB_API_BASE_URL = 'https://bex-kb.coolriots.ai'
// const BEX_KB_API_BASE_URL = 'http://localhost:8000'
const BEX_KB_API_ENDPOINT_PREFIX = "/projecto/api/v1";

const bexKbHttpClientStg = createApiClient(
    BEX_KB_API_BASE_URL, 
    BEX_KB_API_ENDPOINT_PREFIX
);
/**
 * Service class for performing RAG operations.
 */
class MilvusService {

    /**
     * Query embeddings from a collection.
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>}
     */
    async queryEmbeddings(body) {
        const res = await bexKbHttpClientStg.post(`/milvus/query_jina_embeddings`, body);
        return res;
    }
    /**
     * Query embeddings from a collection.
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>}
     */
    async hybridSearch(body) {
        // /projecto/api/v1/milvus/hybrid-search/
        const res = await bexKbHttpClientStg.post(`/milvus/hybrid-search/`, body);
        return res;
    }

    // async QueryAndRerank(body) {
    //     const res = await bexKbHttpClientStg.post(`/milvus/query_and_rerank/`, body);
    //     return res;
    // }
}

const milvusService = new MilvusService();
export { milvusService };
