import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../components/ui/card"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import {Link} from "react-router-dom"
import { Button } from "../../components/ui/button"
import { forwardRef, useImperativeHandle, useRef } from "react"

const ResetPasswordForm = forwardRef((props, ref) => {
  const { loading, onSubmit } = props

  const emailRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEmailRef: () => emailRef.current,
  }));

  return (
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl">Reset Password</CardTitle>
          <CardDescription>Enter your email to reset new password.</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
            <form onSubmit={onSubmit} className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input ref={emailRef} id="email" type="email" placeholder="m@example.com" required />
                </div>
                <Button disabled={loading} onSubmit={onSubmit} className="w-full">Send</Button>
            </form>

        </CardContent>
        <CardFooter className='flex flex-col'>
          <div className="mt-4 text-center text-sm">
          Already have an account?{"  "}
            <Link
              to="/auth/login"
              className="font-medium text-[#B1092A] underline underline-offset-2 hover:text-[#8d0721] focus:text-[#8d0721]"
            >
              Login
            </Link>
          </div>
        </CardFooter>
      </Card>
  )
})

export default ResetPasswordForm