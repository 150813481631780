import React, { useContext } from 'react';
import { Context } from 'context/GlobalState';
import SubOrgDropdown from 'components/SubOrgDropdown';
import { useParams } from 'react-router-dom';

export default function DashboardSubHeader() {
   // Constants if needed
   const { oragID, subOragID } = useParams()
    
    // Context
    const { organizations } = useContext(Context);
    
    const selectedOrganization = organizations.find(item => item._id === oragID)
    const userSubOrganizationCount = selectedOrganization?.subOrganizations?.length || 0;
    const hasEnoughSubOrganizations = userSubOrganizationCount > 0;

    if (!subOragID) return
    
    return (
        <header className="bg-white dark:bg-gray-900 px-6 flex items-center justify-between">
            <div className="flex items-center gap-4">
                {/* SubOrganizations Selection */}
                {hasEnoughSubOrganizations && (
                    <SubOrgDropdown
                    urlSubOrg={selectedOrganization.subOrganizations.find(subOrg => subOrg._id === subOragID)} />
                )}
            </div>
        </header>
    );
}