import React from 'react';
import { Container, PrimaryInput } from '../../../components/FormInput';

const ResetUserPasswordForm = ({ userPassInput, setUserPassInput }) => {

    const handleInputChange = (key, value) => {
        setUserPassInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div>
            <Container labelName={'Old Password'} style={{ fontWeight: '600' }}>
                <PrimaryInput onChange={(e) => handleInputChange('oldPass', e.target.value)}
                    type="password"
                    name="password"
                    placeholder="Type your old password here"
                    required
                    value={userPassInput.oldPass}
                />
            </Container>
            <Container labelName={'New Password'} style={{ fontWeight: '600' }}>
                <PrimaryInput onChange={(e) => handleInputChange('newPass', e.target.value)}
                    type="password"
                    name="password"
                    placeholder="Type your new password here"
                    required
                    value={userPassInput.newPass}
                />
            </Container>
            <Container labelName={'Confirm New Password'} style={{ fontWeight: '600' }}>
                <PrimaryInput onChange={(e) => handleInputChange('confirmNewPass', e.target.value)}
                    type="password"
                    name="password"
                    placeholder="Type confirm new password"
                    required
                    value={userPassInput.confirmNewPass}
                />
            </Container>
        </div>
    );
};

export default ResetUserPasswordForm;