import { useState } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Label } from "components/ui/label"
import { Save } from "lucide-react"

// Mock data for a single sub-organization
const mockSubOrg = {
  id: 1,
  name: "Sub-Org 1",
  collections: 15,
  projects: 25,
  documents: 75,
}

export default function SubOrgResourceDetail() {
    const [subOrg, setSubOrg] = useState(mockSubOrg)
    const [isEditing, setIsEditing] = useState(false)
    const [editedResources, setEditedResources] = useState(subOrg)

    const handleEdit = () => {
    setIsEditing(true)
    setEditedResources(subOrg)
    }

    const handleSave = () => {
    setSubOrg(editedResources)
    setIsEditing(false)
    // In a real application, you would send an API request here to update the sub-org data
    console.log("Saving updated resources:", editedResources)
    }

    const handleCancel = () => {
    setIsEditing(false)
    setEditedResources(subOrg)
    }

    const handleChange = (field) => (e) => {
    setEditedResources(prev => ({ ...prev, [field]: parseInt(e.target.value) || 0 }))
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle> Resources</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="grid gap-6">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold">Resource Numbers</h2>
                        {!isEditing ? (
                            <Button onClick={handleEdit}>Edit Resources</Button>
            ) : (
                <div className="space-x-2">
                    <Button onClick={handleSave} className="bg-green-600 hover:bg-green-700">
                        <Save className="mr-2 h-4 w-4" />
                        Save
                    </Button>
                    <Button variant="outline" onClick={handleCancel}>Cancel</Button>
                </div>
            )}
                    </div>
                    <div className="grid gap-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                                <Label htmlFor="collections">Collections</Label>
                                <Input
                id="collections"
                type="number"
                value={isEditing ? editedResources.collections : subOrg.collections}
                onChange={handleChange("collections")}
                disabled={!isEditing}
                className="mt-1"
                />
                            </div>
                            <div>
                                <Label htmlFor="projects">Projects</Label>
                                <Input
                id="projects"
                type="number"
                value={isEditing ? editedResources.projects : subOrg.projects}
                onChange={handleChange("projects")}
                disabled={!isEditing}
                className="mt-1"
                />
                            </div>
                            <div>
                                <Label htmlFor="documents">Documents</Label>
                                <Input
                id="documents"
                type="number"
                value={isEditing ? editedResources.documents : subOrg.documents}
                onChange={handleChange("documents")}
                disabled={!isEditing}
                className="mt-1"
                />
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}