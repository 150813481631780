/* eslint-disable max-lines */
import { useState } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Switch } from "components/ui/switch"
import { Checkbox } from "components/ui/checkbox"
import { Slider } from "components/ui/slider"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { BarChart, FolderOpen, Settings } from "lucide-react"
import Collection from "./Collection"

export default function KnowledgeBuilder() {
    const [repositoryUrl, setRepositoryUrl] = useState("")
    const [l1Processing, setL1Processing] = useState(false)
    const [l2Processing, setL2Processing] = useState(false)
    const [l3Processing, setL3Processing] = useState(false)

    // L1 Processing options
    const [l1Options, setL1Options] = useState({
        convertPDF: true,
        convertDOCX: true,
        convertXLSX: true,
        performOCR: false,
        audioTranscription: false,
    })

    // L2 Processing options
    const [l2Options, setL2Options] = useState({
        extractPII: true,
        detectEntities: true,
        analyzeSentiment: false,
        extractKeywords: true,
        generateSummaries: false,
    })

    // L3 Processing options
    const [l3Options, setL3Options] = useState({
        createRelationships: true,
        generateSchemas: false,
        dataNormalization: true,
        confidenceThreshold: 0.7,
    })

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">BeX Knowledge Builder</h1>
        
            <Tabs defaultValue="collections" className="space-y-4">
                <TabsList>
                    <TabsTrigger value="collections">Collections</TabsTrigger>
                    <TabsTrigger value="repository">Document Repository</TabsTrigger>
                    {/* <TabsTrigger value="processing">Document Processing</TabsTrigger> */}
                    {/* <TabsTrigger value="metrics">Metrics & Monitoring</TabsTrigger> */}
                </TabsList>
                
                <TabsContent value="repository">
                    <Card>
                        <CardHeader>
                            <CardTitle>Connect Document Repository</CardTitle>
                            <CardDescription>Link your document repository to start processing</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="repository-url">Repository URL</Label>
                                    <Input
                                        id="repository-url"
                                        placeholder="https://your-repository-url.com"
                                        value={repositoryUrl}
                                        onChange={(e) => setRepositoryUrl(e.target.value)}
                                    />
                                </div>
                                <Button>
                                    <FolderOpen className="mr-2 h-4 w-4" />
                                    Connect and Start Crawling
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
                
                <TabsContent value="collections">
                    <Collection />
                </TabsContent>
                
                <TabsContent value="processing">
                    <Card>
                        <CardHeader>
                            <CardTitle>Document Processing</CardTitle>
                            <CardDescription>Configure and initiate L1, L2, and L3 processing</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-6">
                                <ProcessingLevel
                                level="L1"
                                title="L1 Processing"
                                description="Basic conversion of files into textual content"
                                enabled={l1Processing}
                                setEnabled={setL1Processing}
                                options={l1Options}
                                setOptions={setL1Options}
                                >
                                    <div className="space-y-4">
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                                id="l1-convert-pdf"
                                                checked={l1Options.convertPDF}
                                                onCheckedChange={(checked) => setL1Options({...l1Options, convertPDF: checked})}
                                            />
                                            <label htmlFor="l1-convert-pdf">Convert PDF files</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                                id="l1-convert-docx"
                                                checked={l1Options.convertDOCX}
                                                onCheckedChange={(checked) => setL1Options({...l1Options, convertDOCX: checked})}
                                            />
                                            <label htmlFor="l1-convert-docx">Convert DOCX files</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                                id="l1-convert-xlsx"
                                                checked={l1Options.convertXLSX}
                                                onCheckedChange={(checked) => setL1Options({...l1Options, convertXLSX: checked})}
                                            />
                                            <label htmlFor="l1-convert-xlsx">Convert XLSX files</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                                id="l1-perform-ocr"
                                                checked={l1Options.performOCR}
                                                onCheckedChange={(checked) => setL1Options({...l1Options, performOCR: checked})}
                                            />
                                            <label htmlFor="l1-perform-ocr">Perform OCR on images</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                                id="l1-audio-transcription"
                                                checked={l1Options.audioTranscription}
                                                onCheckedChange={(checked) => setL1Options({...l1Options, audioTranscription: checked})}
                                            />
                                            <label htmlFor="l1-audio-transcription">Transcribe audio files</label>
                                        </div>
                                    </div>
                                </ProcessingLevel>

                                <ProcessingLevel
                                level="L2"
                                title="L2 Processing"
                                description="Information extraction (PII, entities, sentiments, etc.)"
                                enabled={l2Processing}
                                setEnabled={setL2Processing}
                                options={l2Options}
                                setOptions={setL2Options}
                                >
                                    <div className="space-y-4">
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l2-extract-pii"
                                            checked={l2Options.extractPII}
                                            onCheckedChange={(checked) => setL2Options({...l2Options, extractPII: checked})}
                                        />
                                            <label htmlFor="l2-extract-pii">Extract PII</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l2-detect-entities"
                                            checked={l2Options.detectEntities}
                                            onCheckedChange={(checked) => setL2Options({...l2Options, detectEntities: checked})}
                                        />
                                            <label htmlFor="l2-detect-entities">Detect named entities</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l2-analyze-sentiment"
                                            checked={l2Options.analyzeSentiment}
                                            onCheckedChange={(checked) => setL2Options({...l2Options, analyzeSentiment: checked})}
                                        />
                                            <label htmlFor="l2-analyze-sentiment">Analyze sentiment</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l2-extract-keywords"
                                            checked={l2Options.extractKeywords}
                                            onCheckedChange={(checked) => setL2Options({...l2Options, extractKeywords: checked})}
                                        />
                                            <label htmlFor="l2-extract-keywords">Extract keywords</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l2-generate-summaries"
                                            checked={l2Options.generateSummaries}
                                            onCheckedChange={(checked) => setL2Options({...l2Options, generateSummaries: checked})}
                                        />
                                            <label htmlFor="l2-generate-summaries">Generate summaries</label>
                                        </div>
                                    </div>
                                </ProcessingLevel>

                                <ProcessingLevel
                                level="L3"
                                title="L3 Processing"
                                description="Information enrichment (relationships, schemas, tagging)"
                                enabled={l3Processing}
                                setEnabled={setL3Processing}
                                options={l3Options}
                                setOptions={setL3Options}
                                >
                                    <div className="space-y-4">
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l3-create-relationships"
                                            checked={l3Options.createRelationships}
                                            onCheckedChange={(checked) => setL3Options({...l3Options, createRelationships: checked})}
                                        />
                                            <label htmlFor="l3-create-relationships">Create entity relationships</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l3-generate-schemas"
                                            checked={l3Options.generateSchemas}
                                            onCheckedChange={(checked) => setL3Options({...l3Options, generateSchemas: checked})}
                                        />
                                            <label htmlFor="l3-generate-schemas">Generate data schemas</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Checkbox
                                            id="l3-data-normalization"
                                            checked={l3Options.dataNormalization}
                                            onCheckedChange={(checked) => setL3Options({...l3Options, dataNormalization: checked})}
                                        />
                                            <label htmlFor="l3-data-normalization">Perform data normalization</label>
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="l3-confidence-threshold">Confidence Threshold</Label>
                                            <Slider
                                            id="l3-confidence-threshold"
                                            min={0}
                                            max={1}
                                            step={0.1}
                                            value={[l3Options.confidenceThreshold]}
                                            onValueChange={(value) => setL3Options({...l3Options, confidenceThreshold: value[0]})}
                                        />
                                            <div className="text-sm text-muted-foreground">
                                                Current: {l3Options.confidenceThreshold.toFixed(1)}
                                            </div>
                                        </div>
                                    </div>
                                </ProcessingLevel>

                                {/* <Button className="w-full">
                                    <Layers className="mr-2 h-4 w-4" />
                                    Start Processing
                                </Button> */}
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
                
                <TabsContent value="metrics">
                    <Card>
                        <CardHeader>
                            <CardTitle>Metrics & Monitoring</CardTitle>
                            <CardDescription>View system performance and processing metrics</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <MetricCard title="Processed Documents" value="1,234" />
                                    <MetricCard title="Storage Usage" value="45.6 GB" />
                                    <MetricCard title="Average Chunk Size" value="512 bytes" />
                                    <MetricCard title="Processing Speed" value="10 docs/min" />
                                </div>
                                <Button variant="outline">
                                    <BarChart className="mr-2 h-4 w-4" />
                                    View Detailed Metrics
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </div>
    )
}

function ProcessingLevel({ level, title, description, enabled, setEnabled, options, setOptions, children }) {
    return (
        <div className="flex items-center justify-between">
            <div className="space-y-0.5">
                <Label htmlFor={`${level.toLowerCase()}-processing`}>{title}</Label>
                <p className="text-sm text-muted-foreground">{description}</p>
            </div>
            <div className="flex items-center space-x-2">
                <Switch
                id={`${level.toLowerCase()}-processing`}
                checked={enabled}
                onCheckedChange={setEnabled}
                />
                <Dialog>
                    <DialogTrigger asChild>
                        <Button variant="outline" size="icon" disabled={!enabled}>
                            <Settings className="h-4 w-4" />
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>{title} Options</DialogTitle>
                            <DialogDescription>
                                Configure the options for {level} processing.
                            </DialogDescription>
                        </DialogHeader>
                        {children}
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}


function MetricCard({ title, value }) {
    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-sm font-medium">{title}</CardTitle>
            </CardHeader>
            <CardContent>
                <p className="text-2xl font-bold">{value}</p>
            </CardContent>
        </Card>
    )
}