import { projectOHttpClient } from "api/clients/project-o.httpClient";
/**
 * Service class for performing Unified Model operations.
 */
class UnifiedModelService {

    /**
     * Creates a new model.
     * @param {Object} data - The data to create a new model.
     * @returns {Promise<Object>} The response from the server regarding model creation.
     */
    async CreateModel(data) {
        const response = await projectOHttpClient.post(`/models/model/create`, data);
        return response;
    }

    /**
     * Deletes models by unique names.
     * @param {Object} data - The unique names to delete models.
     * @returns {Promise<Object>} The response from the server regarding model deletion.
     */
    async deleteModels(data){
        const response = await projectOHttpClient.delete(`/models/model/delete_models`, {data});
        return response;
    }
    
    /**
     * Updates a model.
     * @param {Object} data - The data to update the model.
     * @returns {Promise<Object>} The response from the server regarding model update.
     */
    async updateModel(data){
        const response = await projectOHttpClient.post(`/models/model/update_model`, data);
        return response;
    }


    /**
     * Predicts using the model.
     * @param {Object} data - The input data for prediction.
     * @param {String} model_type - The model type to use from ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing prediction results.
     */
    async Predict(data, model_type) {
        const response = await projectOHttpClient.post(`/models/model/predict?model_type=${model_type}`, data);
        return response;
    }

    /**
     * Runs an experiment using the model.
     * @param {Object} data - The experiment data to run.
     * @param {String} model_type - The model type to use from ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async Experiment(data, model_type) {
        const response = await projectOHttpClient.post(`/models/model/experiment?model_type=${model_type}`, data);
        return response;
    }

    /**
     * Fetches models by organization and sub-organization.
     * @param {String} organizationId - The organization ID.
     * @param {String} subOrganizationId - The sub-organization ID.
     * @param {String} modelType - The model type ["IBM", "Groq"]
     * @returns {Promise<Object>} The response containing the list of models.
     */
    async GetModelsByOrgSubOrg(organizationId, subOrganizationId, modelType) {
        const url = `/models/model/get_models_by_org_sub_org?org_id=${organizationId}&sub_org_id=${subOrganizationId}&model_type=${modelType}`;
        const response = await projectOHttpClient.post(url);
        return response;
    }

    /**
     * Fetches models by unique names.
     * @param {Object} data - The unique names to fetch models.
     * @returns {Promise<Object>} The response containing the list of models.
     */
    async getModelsByUniqueNames(data){
        const response = await projectOHttpClient.post(`/models/model/get_models_by_unique_names`, data);
        return response;
    }



    /**
     * Get List of Groq Models.
     * @returns {Promise<Object>}
     */
    async listGroqModels(){
        const response = await projectOHttpClient.get(`/models/groqModel/list_models`);
        return response;
    }
}

const unifiedModelService = new UnifiedModelService();
export { unifiedModelService };
