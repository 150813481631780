import React, { useContext } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "components/ui/card";
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/LivePromptContext';

export function Output(){
    const { output } = useContext(GlobalContext);

    return (
        <Card className="w-full flex flex-col h-full justify-between">
            {/* HEADER */}
            <CardHeader className="border-b-[1px] border-gray-200 px-6 py-4 select-none">
                <CardTitle className="text-sm">Prompt Output</CardTitle>
                <CardDescription className="text-xs">The generated output will be displayed here.</CardDescription>    
            </CardHeader>

            {/* CONTENT */}
            <CardContent className="p-0 flex flex-col px-6 py-4 flex-grow">
                <p className='break-words text-xs min-h-[122px] whitespace-pre-wrap'>
                    <pre>
                        <code className='whitespace-pre-wrap'>
                            {output}
                        </code>
                    </pre>
                </p>
            </CardContent>
        </Card>
    );
}
