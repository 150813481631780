import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdSearch } from 'react-icons/md'

// Define styled components
const TableWrapper = styled.div`
    width: 100%;
    overflow-x: auto;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.25);
    padding: 10px;
    border-raduis: 10px;
    max-height: 500px;
    overflow: scroll;
    background-color: #fff;
`;
    
const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
`;

const StyledTableHeader = styled.tr`
    // display: flex;
    background-color: #f4f4f4;
    border-right: .5px solid #e5e5e5;
`

const TableHeader = styled.th`
    // background-color: #f2f2f2;
    padding: 12px;
    text-align: left;
    border: none;
`;

const TableHeaderOption = styled.th`
    padding: 12px;
    text-align: center;
    border: none;
    width: 10%;
`;

const StyledTableRow = styled.tr`
    border-bottom: 2px solid #f2f2f2;
`

const TableData = styled.td`
    padding: 12px;
    border-bottom: 0.3px solid #ddd;
    border: none;

    .curvy-border-box {
        display: inline-block;
        padding: 5px 10px;
        margin: 5px;
        border: 1px solid #333;
        border-radius: 10px;
    }
`;

const TableDataOption = styled.td`
    padding: 12px;
    border-bottom: 0.3px solid #ddd;
    border: none;
    text-align: center;
`;

const OptionIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
    
const SpanIcon = styled.button`
    cursor: pointer;
    padding: 0 5px;
    background-color: transparent;
    border: none;

    &:disabled {
        color: #7f8c8d;
        cursor: not-allowed;
    }
`

const SearchInput = styled.input`
    width: 100%;
    padding: 12px 20px;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 3px;

    &:focus {
        outline: var(--primary-color);
        border: 1px solid var(--primary-color);
    }

    &::placeholder {
        color: #7f8c8d;
        font-size: 16px;
    }

`;


// Reusable Table component
const StyledTable = ({ columns, data, options, search = true }) => {
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        if (searchValue === '') {
            setFilteredData(data);
        } else {
            setFilteredData(data.filter((row) => {
                return columns.some((column) => {
                    return row[column.field].toString().toLowerCase().includes(searchValue.toLowerCase());
                });
            }));
        }
    }
    , [searchValue, data]);

    const handleSearchInputChanges = (e) => {
        setSearchValue(e.target.value);
    }

    return (
        <TableWrapper>
            <Table>
                {search && (
                    <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#fff' }}>
                        <tr>
                            <th colSpan={columns.length + options.length}>
                                <div style={{ position: 'relative' }}>
                                    <SearchInput
                                        type="text"
                                        value={searchValue}
                                        onChange={handleSearchInputChanges}
                                        placeholder="Search..."
                                        style={{ paddingLeft: '35px' }}
                                    />
                                    <MdSearch
                                        style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '45%',
                                            transform: 'translateY(-50%)',
                                            height: '20px',
                                            width: '20px'
                                        }}
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>
                )}
                <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#fff' }}>
                    <StyledTableHeader>
                        {columns.map((column, index) => (
                            <TableHeader key={index}>{column.headerName}</TableHeader>
                        ))}
                        {options?.length > 0 && options.map((option) => (
                            <TableHeaderOption key={option.name}>
                                {option.name}
                            </TableHeaderOption>
                        ))}
                    </StyledTableHeader>
                </thead>
                <tbody>
                    {filteredData.map((row) => (
                        <StyledTableRow key={row.id}>
                            {columns.map((column, colIndex) => (
                                <TableData key={colIndex}>
                                    {column.renderCell ? column.renderCell(row) : row[column.field]}
                                </TableData>
                            ))}
                            {options?.length > 0 && options.map((option) => (
                                <TableDataOption key={option.name}>
                                    <OptionIconContainer>
                                        <SpanIcon 
                                            disabled={row?.editable === false} 
                                            onClick={(event) => {
                                                event.preventDefault();
                                                option.onclick(row.id);
                                            }}
                                        >
                                            {option.icon}
                                        </SpanIcon>
                                    </OptionIconContainer>
                                </TableDataOption>
                            ))}
                        </StyledTableRow>
                    ))}
                </tbody>
            </Table>
        </TableWrapper>
    );
};

export default StyledTable;
