// import "./App.css";
import React, { forwardRef, useContext } from "react";
import "./queryPlayground.css";
import Panel from "./components/panel";
import QuestionField from "./components/questionFieldDemo";
import InputField from "./components/inputField";
import DocResultCard from "./components/docResultCard";
import FileDrop from "./components/fileDropZone";
import { useState, useRef, useEffect, useCallback, useImperativeHandle } from "react";
import fetchResponse from "../../../../../utils/fetchResponse_queryPlayground";
import { httpRequestY } from "../../../../../utils/httpsRequest";
import { FaTrash } from "react-icons/fa";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import Table from "./components/table";
import { FaUpload } from "react-icons/fa6";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import { PrimaryButton, SecondaryButton, RedButton, WhiteButton } from "../../../../../components/Buttons";
import { useParams } from "react-router-dom";
import {Dropdown, DropdownOne} from "../../../Projects/SelectDropdownQuery";
import styled from "styled-components";
import NavTab from '../../../../../components/NavTab'
import { v4 as uuidv4 } from 'uuid';
import { LoaderSpinner } from "../../../../../components/LoaderSpinner";
import { LoadingContext } from "../../../../Dashboard/SmartContract";

// require("dotenv").config();
const ForHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
  overflow:visible;
  flex-grow:1;
`;

const HeaderFlex = styled.div`
  display: flex;
  flex-direction:column;
  gap:10px;
  flex-grow:1;
`
const AddQueryButton = styled(WhiteButton)`
  cursor: ${(props) => props.resLoading ? 'not-allowed' :  'pointer'};
  opacity: ${(props) => props.resLoading ? '0.5' :  '1'};
  white-space: nowrap;
`

const PerField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
`

const DisplayContainer = styled.div`
  overflow: auto;
  
`

const SpinnerContainer = styled.div`
  height:20px;
  width:20px;
  display: flex;
  align-items: center;
`
const ResultContainer = styled.div`

  display: flex;
  flex-direction: column;
  width:100%;
`

const ButtonPrimary = styled.button`
padding: 10px 15px;
border: 1px solid var(--primary-color);
cursor: pointer;
background-color: var(--primary-color);
color: #fff;
`

const ViewPanel = styled.div`
display: flex;
overflow: visible;
flex-direction: ${(props)=> props.$flexDirection};
${(props)=> props.$gap ? `gap:${props.$gap}`: ""};
background-color: white;
margin: 0;
box-sizing: border-box;
flex-grow: ${(props)=> props.$flexGrow ? props.$flexGrow : "1"};
box-sizing: border-box;
${(props)=> props.$maxWidth ? `max-width:${props.$maxWidth}`: ""};
border-radius: 8px;
border-style: none;
border-width: 1px;
border-color: grey;
padding: 10px;
${(props)=> props.$shadow ? `box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);` : ""}
& label{
  font-weight: 600;
}
& h3 {
  margin:0;
}
& p {
  margin-bottom: 10px;
}

& svg {
  color:var(--primary-color);
  transition: scale 0.3s;
  &:hover{
    scale:1.2;
    cursor:pointer;

  }
}

`

const DropdownQueryPreset = styled.div`
  width: 30%;
`


function App({ collectionList, projectDemo }, ref) {
  const [exportState, setExportState] = useState(false);
  const { projectId } = useParams();
  // const endPoint = process.env.REACT_APP_LLM_RESPONSE_ENDPOINT;
  const keyAdd = useRef(0);
  const [documentList, setDocumentList] = useState([])
  const [xslxFile, setXslxFile] = useState(null);
  const queryList = useRef([]);
  const qListfromFile = useRef([]);
  const projId = useRef(projectDemo ? projectDemo : projectId);
  const [collectionIds, setCollectionIds] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const keyList = useRef([0]);
  const docStateArr = useRef([]);
  const correctAns = useRef([]);
  const [queries, setQueries] = useState([])
  const tableResult = useRef([]);
  const tableResultCorrect = useRef([]);
  const {resLoading, setResLoading} = useContext(LoadingContext)
  // console.log(keyList);
  const [percCorrect, setPercCorrect] = useState("--");
  const [llmResponse, setLlmResponse] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false)
  const [qList, setQlist] = useState([
    {
      label: `Query`,
      context: "",
      textValue: "",
      deleteButton: false,
      keyVal: uuidv4(),
    },
  ]);

  const add = (textVal, context, deleteButton=false) => {
    // keyAdd.current++;
    // keyList.current.push(keyAdd.current);
    setQlist((prevList) => [
      ...prevList,
      {
        label: `Query`,
        textValue: textVal,
        context: context,
        deleteButton: deleteButton,
        keyVal: uuidv4(),
      },
    ]);
  };

  const handleGetDocuments = async () => {
    let docList = []
    console.log("Fetching documents...")
    setLoadingDocs(!loadingDocs)
    for(let collection of collectionIds.map((collect)=>collect.id)){
      await httpRequestY.post('/Y/api/discovery/getdocumentsdetailed', {projectId: projId.current, collectionId: collection })
        .then((res) => {
          console.log('res: ', res);
          docList.push(...res.data.detailedDocument.map((doc)=>({name:doc.filename,id:doc.document_id})))
        }).catch((err) => {
          console.log('error: ', err);
        })
    }
    setLoadingDocs(!loadingDocs)
    console.log(docList)
    setDocumentList(docList)
    
  }
  const handleGetQueries= async () => {
    let queries = []
    console.log("Fetching queries...")
    setLoadingDocs(!loadingDocs)

    await httpRequestY.get('/Y/api/discovery/getquery/' + projId.current)
      .then((res) => {
        console.log('res: ', res);
        queries = res.data.queries
      }).catch((err) => {
        console.log('Error fetching preset queries: ', err);
      })

    setLoadingDocs(!loadingDocs)
    console.log(queries)
    setQueries(queries)
    
  }

  const handleSelectCollection = (selectedItems) => {

    setCollectionIds(selectedItems);
  };

  const handleSelectDocument = (selectedItems) => {
    setDocumentIds(selectedItems);
  };
  const handleSelectQuery = (selectedItems) => {
    setQlist(selectedItems)
    
  };

  const handleListQuery = () =>
    qList.map((v, k) => (
      
      
        <QuestionField
          textLabel={`${k + 1}`}
          deleteButton={v.deleteButton}
          key={`${v.keyVal}+${k}`}
          indexVal={k}
          setObj={{ setQ: setQlist, q: qList }}
          textAreaVal={{query: qList[k].textValue, context: qList[k].context}}
          resLoading={resLoading}
        />
      

    ));
  console.log("FROM USE PARAMS", projectId);

  const exportSheet = (tableData) => {
    let worksheet = XLSX.utils.aoa_to_sheet(tableData);
    let workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "output.xlsx");
  };
  // const onDrop = useCallback((acceptedFiles) => {
  //   setIsOverDropzone(false);
  //   for (let file of acceptedFiles) {
  //     if (
  //       file.type ==
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
  //       file.type == "text/csv"
  //     ) {
  //       const reader = new FileReader();

  //       reader.onabort = () => console.log("file reading was aborted");
  //       reader.onerror = () => console.log("file reading has failed");
  //       reader.onload = () => {
  //         // Do whatever you want with the file contents
  //         const bstr = reader.result;
  //         const wb = XLSX.read(bstr, { type: "array" });
  //         const wsname = wb.SheetNames[0];
  //         const ws = wb.Sheets[wsname];
  //         const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
  //         console.log(data);

  //         // props.file(data);

  //         for (let row = 0; row < data.length; row++) {
  //           if (row < 1) {
  //             setQlist([
  //               {
  //                 label: `Query`,
  //                 textValue: data[row][0],
  //                 deleteButton: false,
  //                 keyVal: keyList.current[0],
  //               },
  //             ]);
  //           } else {
  //             add(data[row][0]);
  //           }
  //         }
  //       };
  //       reader.readAsArrayBuffer(file);
  //       break;
  //     } else {
  //       alert("Unsupported file.");
  //     }
  //   }
  //   console.log("Uploaded and read.");
  // }, []);

  // const [isOverDropzone, setIsOverDropzone] = useState(false);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   onDragOver: () => setIsOverDropzone(true),
  //   onDragLeave: () => setIsOverDropzone(false),
  //   maxFiles: 1,
  // });
  // useEffect(() => {
  //   console.log(isDragActive);
  // }, [isDragActive]);
  // useEffect(() => {
  //   if (correctAns.current[0] && xslxFile) {
  //     setPercCorrect(
  //       (
  //         (correctAns.current.reduce((a, b) => a + b, 0) /
  //           (qList.length * (xslxFile.length - 1))) *
  //         100
  //       ).toFixed(0)
  //     );
  //     setExportState(true);
  //   }
  // }, [resLoading]);

  useEffect(()=> {
    handleGetQueries()

  },[])
  useEffect(() => {
    console.log(qList);
  }, [qList]);

  useEffect(() => {
    console.log(collectionIds);
    handleGetDocuments()
    setDocumentIds([])
  }, [collectionIds]);

  useEffect(() => {
    console.log("SELECTED DOCS:",documentIds);
  }, [documentIds]);

  const blankCheck = () => {
    console.log("projID:", projId);

    if (!collectionIds.length > 0) {
      return false;
    }
    for (let query of qList) {
      if (!query.textValue) {
        return false;
      }
    }
    if(documentIds.length < 1) {
      return false
    }
    return true;
  };
  // const cons = useCallback(() => console.log("Hello"));
  const testFunc = () => console.log('test')

  const processDoc = () =>{
    console.log(qList);
    console.log(projId.current);
    // console.log(collectionId.current);
    if(!resLoading){
      if (blankCheck()) {
        setResLoading(true);
        setLlmResponse([]);
        setExportState(false);
        setPercCorrect("--");
        docStateArr.current = [];

        queryList.current = qList.map((query)=>query.textValue)

        try {

          var res = fetchResponse( 
            documentIds.map((document)=>document.id),
            projId.current,
            collectionIds.map((collection)=>collection.id),
            qList.map((query)=>query.textValue),
            qList.map((query)=>query.context)
          ).then((res)=>{
            setLlmResponse((prevArr) => {
              // console.log(prevArr);
              return [...prevArr, { llmRes: res.data[0] }];
            });

            setResLoading(false);
          });

          
        } catch (e) {
          // console.log("FROM SEND TO LLM");
          correctAns.current = [];
          alert(
            "Something went wrong. Check connectivity or reference file."
          );
          console.log('Error fetching', e)
          setResLoading(false);
        }
      } else {
        alert(
          "Something went wrong. Check connectivity or reference file."
        );
        setResLoading(false);
      }
    
    } 
  }
  useImperativeHandle(ref, ()=>({
    add, processDoc,
    resLoading
  }))

  return (
    <DisplayContainer className="qpd-panel-grow">
      <ViewPanel>
        <ForHeader>
          <HeaderFlex>
            <Dropdown
              options={collectionList}
              title={"Collection"}
              onSelect={handleSelectCollection}
              selected={collectionIds}
              // fetchDoc={handleGetDocuments}
            />
            Selected:
            {collectionIds.map((col) => (
              <b key={col.collection_id}>{col.name}</b>
            ))}
          </HeaderFlex>
          {collectionIds.length > 0 ? <HeaderFlex>
            <Dropdown
              options={documentList}
              title={"Documents"}
              onSelect={handleSelectDocument}
              selected={documentIds}
              
            />
            Selected:
            {documentIds.map((doc) => (
              <b key={doc.id}>{doc.name}</b>
            ))}
          </HeaderFlex>: null}
        </ForHeader>
      
      </ViewPanel>
      <ViewPanel $flexDirection="column">
        <div className="labelHeader">
          <h3>Queries</h3>
          <DropdownQueryPreset>
            <DropdownOne
                options={queries.map((element)=>({
                  name: element.title,
                  query: element.query,
                  context: element.context,

                }))}
                title={"Query Preset"}
                onSelect={handleSelectQuery}
                selected={queries}
                add={add}
                // fetchDoc={handleGetDocuments}
              />
          </DropdownQueryPreset>
          {/* <div>
            <div {...getRootProps()}>
              <h5 className="buttonSvg">
                <FaUpload size={20} />
              </h5>
            </div>
          </div> */}
        </div>
        <p>
          {/* <i>
            Add your queries here. You can also drag and drop an excel file
            containing the queries. Be sure to put the queries per row at
            the first column.
          </i> */}
        </p>
        {/* <div
          className="inputFileApp"
          {...getRootProps()}
          onClick={(event) => event.stopPropagation()}
        >
          <input {...getInputProps()} />

          <div
            className="inputFile"
            style={{ opacity: isDragActive ? "50%" : "100%" }}
          >
            <div>{handleListQuery()}</div>
          </div>
          {isDragActive ? (
            <div className="overlayDisplay">
              <h3>Drop here to upload queries.</h3>
            </div>
          ) : (
            <></>
          )}
        </div> */}
        <PerField>{handleListQuery()}</PerField >
        {/* <div className="centerDiv">
          <AddQueryButton
          resLoading={resLoading}
            onClick={() => {
              if(!resLoading){
                add("","",true);
              }
              
            }}
          >
            Add query
          </AddQueryButton>
          <SendButton
            resLoading={resLoading}
            onClick={processDoc}
          >
            {!resLoading ? "Send": <LoaderSpinner/>}
          </SendButton>
        </div> */}
      </ViewPanel>
      {!resLoading ? <ViewPanel $flexDirection="column">
        <div className="resultPanelText">
          <h3>Result</h3>
          {/* <div style={{ width: "70%" }}></div> */}
          {resLoading ? (
            <SpinnerContainer>
              <LoaderSpinner/>
            </SpinnerContainer>
            
          ) : <></>}
          
        </div>
        {/* {cons()} */}
        {!resLoading ? (<ResultContainer>
            {queryList.current.map((query, i) => {
              console.log("llmREs", llmResponse);
              if (llmResponse[0]) {
                return (
                  <DocResultCard
                    key={i}
                    response={llmResponse[0].llmRes}
                    queryList={queryList}
                    query={query}
                    file={xslxFile}
                    cardIndex={i}
                    correctArr={correctAns}
                  />
                );
              }
            })}
          </ResultContainer>):<></>}
      </ViewPanel> : <></>}
      </DisplayContainer>
  );
}

export default forwardRef(App);
