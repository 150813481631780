import React, { useEffect, useState } from 'react';
import { httpRequest } from '../../../../utils/httpsRequest';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { ScrollArea } from 'components/ui/scroll-area';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';


const AddAdminForm = ({ userInput, setUserInput, openAddAdminModal, setOpenAddAdminModal, handleAddAdminOnSubmit, btnLoading }) => {

    const [roles, setRoles] = useState([]);

    const handleInputChange = (key, value) => {
        setUserInput(prev => ({
            ...prev,
            [key]: value
        }))
    }
    
    useEffect(() => {
        httpRequest.get('/role/get-roles')
        .then((res) =>{
            console.log('roles: ', res.data);
            setRoles(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [openAddAdminModal]);

    return (
        <Dialog open={openAddAdminModal} onOpenChange={setOpenAddAdminModal}>
            <DialogContent className="w-full max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Add New Admin</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up admin email and password.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4 mt-4">
                        <div className="space-y-2">
                            <Label htmlFor="admin-name" className="text-md">Admin Name</Label>
                            <Input
                                onChange={(e) => handleInputChange('name', e.target.value)}
                                value={userInput.name}
                                id="admin-name"
                                className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="email" className="text-md">Email</Label>
                            <Input
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                value={userInput.email}
                                id="email"
                                className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="password" className="text-md">Password</Label>
                            <Input
                                onChange={(e) => handleInputChange('password', e.target.value)}
                                value={userInput.password}
                                id="password"
                                className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="password" className="text-md">Role</Label>
                            <Select onValueChange={(value) => handleInputChange('role', value)} id="role" className="w-full" >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a role" />
                                </SelectTrigger>
                                <SelectContent>
                                    {roles.map(role => (
                                        <SelectItem key={role.name} value={role.name}>{role.name}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                    </div>
                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button variant="outline" onClick={() => setOpenAddAdminModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                        <Button disabled={btnLoading} onClick={handleAddAdminOnSubmit} className="px-5 py-3 text-md">
                            Add new admin
                        </Button>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};

export default AddAdminForm;