import FileManager from 'components/FileManager/FileManager'
import { SubOrgContext } from 'context/SubOrganizationContext'
import React, { useContext, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb';
import { Layers } from 'lucide-react';
import ProcessModal from './ProcessModal';

export default function FileManagerDocument() {
    const { project } = useContext(SubOrgContext)
    const [isProcessing, setIsProcessing] = useState(false);

    const [processModal, setProcessModal] = useState(false)

    const handleOpenProcessModal = () => {
        setProcessModal(true)
    };

    const ACTION_COMPONENTS = [
        {id: 1, name: 'Start Processing', icon: <Layers className="mr-2 h-4 w-4" />, onclick: handleOpenProcessModal}
    ]

    return (
        <div className='w-full'>
            <div className='h-auto py-8'>
                <div className='my-3 px-2'>
                    <Breadcrumb maxPaths={3} />
                </div>

                <FileManager
                    project={project}
                    actionComponents={ACTION_COMPONENTS}/>

                <ProcessModal isOpen={processModal} setIsOpen={setProcessModal} loading={isProcessing} setLoading={setIsProcessing}/>
            </div>
        </div>
    )
}
