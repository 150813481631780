import React, { useContext } from 'react'
import { ManagerContext } from '../../ManagerGlobalState'
import Modal from '../../../../components/Modal';
import { styled } from 'styled-components';
import { WhiteButton, RedButton } from '../../../../components/Buttons';

export const EntityIntentCloseBtn = styled.button`
    padding: 10px 0;
    cursor: pointer;
    margin-left: 10px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    width: 82px;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
`

export const PopUpModal=({text,children,onCloseProps,openModal,setOpenModal,entityModal}) => {
  const {onCloseModal}=useContext(ManagerContext)

  const onClose =()=>{
     setOpenModal(false)
      onCloseModal && onCloseModal(()=>{
          setOpenModal(false)
      })
      onCloseProps && onCloseProps()
  }

  const changeButton = () => {
    if (children&&children[1].type === 'button') {
        return <RedButton {...children[1].props}> {children[1].props.children} </RedButton>
    } else {
        return children[1]
    }
  }
  
  return (
      <Modal $isOpen={openModal}  setOpenModal={setOpenModal} onClose={onClose} entityModal={entityModal}>
          <Modal.Header>{text}</Modal.Header>
          <Modal.Body>{children&&children[0]}</Modal.Body>
          <Modal.Footer>
              <StyledButtonContainer>
                  <WhiteButton onClick={onClose}> Cancel</WhiteButton>
                {changeButton()}
              </StyledButtonContainer>
          </Modal.Footer>
      </Modal>
  )
}