import React, { useContext, useEffect, useRef, useState } from 'react'
import ResetPasswordForm from './ResetPasswordForm'
import { AppLoader } from '../../components/LoaderSpinner'
import { useParams } from 'react-router-dom'
import { Context } from '../../context/GlobalState'
import { authService } from 'api'

export default function ChangePassword() {
    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const [error, setError] = useState(false)

    const { token } = useParams();
    const changePasswordFormRef = useRef(null);
    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {

        authService.checkResetPasswordToken(token)
        .then((res) => {
            console.log('res: ', res);
        }).catch((error) => {
            console.log('error: ', error);
            setError(true)
        }).finally(() => {
            setLoading(false)
        })
        
    }, [])

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    const handleChangePassword = (e) => {
        e.preventDefault()

        const passwordRef = changePasswordFormRef.current.getPasswordRef();
        const confirmPasswordRef = changePasswordFormRef.current.getConfirmPasswordRef();

        if (passwordRef.value !== confirmPasswordRef.value) {
            return addNewNotifcation('Change password failed. Password does not match. Please enter the same password for confirmation.','danger')
        }

        setBtnLoading(true)
        authService.resetPasswordWithToken(token, passwordRef.value)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Password changed successfully', 'success')
            setTimeout(() => {
                window.location = '/auth/login'
            }, 1500)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })

    }


    return (
        <div className='flex flex-grow flex-col justify-center items-center'>
            {error ? (
                <div>
                    <h2 className='text-3xl'> Something Went Wrong. Invalid Link </h2>
                </div>
            ) : <ResetPasswordForm ref={changePasswordFormRef} loading={btnLoading} onSubmit={handleChangePassword} />}
        </div>
    )
}
