import { HeadphonesIcon, MailIcon, BookIcon, FileTextIcon, MicIcon, SpeakerIcon, HighlighterIcon, SearchIcon } from 'lucide-react';

const ASSETS_SIZE = 48;

export const assistantNames = [
    {name: 'Email Handling', label: 'emailHandling', title: 'BeX AI Assistant for Hotel Email Handling', icon: <MailIcon size={ASSETS_SIZE} className="h-10 w-10 text-red-500" />, path: 'email-handling', subOrgAssistant: 'emailHandling', description: 'An assistant that can handle emails for your hotel'},

    {name: 'ESG Questions', label: 'esgQuestions', title: 'BeX AI Assistant for Answering ESG questions', icon: <BookIcon size={ASSETS_SIZE} className="h-10 w-10 text-red-500"/>, path: 'document-management', subOrgAssistant: 'contractProcess', description: 'An assistant that can answer ESG questions'},

    {name: 'Legal Contract Management', label: 'legalContractManagement', title: 'BeX AI Assistant for Legal Contract Management', icon: <FileTextIcon size={ASSETS_SIZE} className="h-10 w-10 text-red-500" />, path: 'legal-management', subOrgAssistant: 'legalDocuments', description: 'An assistant that can manage legal contracts'},

    {name: 'Speech To Text', label: 'speechToText', title: 'BeX AI Assistant for Speech To Text', icon: <MicIcon size={ASSETS_SIZE} className="h-10 w-10 text-red-500" />, path: 'speech-to-text' , description: 'Create an assistant that can convert speech to text'},

    {name: 'Text To Speech', label: 'textToSpeech', title: 'BeX AI Assistant for Text To Speech', icon: <SpeakerIcon size={ASSETS_SIZE} className="h-10 w-10 text-red-500"/>, path: 'text-to-speech' , description: 'Create an assistant that can convert text to speech'},

    {name: 'Prompting And RAG Training', label: 'promptingAndRagTraining', title: 'BeX AI Assistant for Prompting and RAG Training', icon: <HighlighterIcon size={ASSETS_SIZE} className="h-10 w-10 text-red-500"/>, path: 'prompting-and-rag-training' , description: 'Create an assistant that can prompt and train RAG'},
]