import React, { useState , useEffect, useContext } from 'react'
import './document-styles.css'
import { AppLoader, LoaderContainerDiv } from '../../../components/LoaderSpinner'
import { httpRequestO } from '../../../utils/httpsRequest'
import { Context } from '../../../context/GlobalState'
import { useParams } from 'react-router-dom'
import Breadcrumb from 'components/Breadcrumb'
import ListDocuments from './ListDocuments'
import QueryDocs from './QueryDoc'
import UploadDocs from './UploadDocs'


export function hasDuplicateKeyValues(objects, key) {
    const seenValues = new Set();
  
    for (const obj of objects) {
      if (obj[key] !== undefined) {
        if (seenValues.has(obj[key])) {
          return true;
        }
        seenValues.add(obj[key]);
      }
    }
  
    return false;
}

export function containsKeyValue(objects, keyToCheck) {
    return objects.some(obj => obj[keyToCheck] === '');
}

export function arrayToObject(array) {
    return array.reduce((acc, obj) => {
      acc[obj.key] = obj.value;
      return acc;
    }, {});
}

export default function Document() {

    const [docs, setDocs] = useState([])
    const [queries, setQueries] = useState([])
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [showQueryModal, setShowQueryModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    const [documentQuery, setDocumentQuery] = useState([])

    const handleUpload = async () => {

        // check queries
        if (hasDuplicateKeyValues(queries, 'key')) {
            return addNewNotifcation("Duplicate key found. Keys should not be duplicated.", 'warning')
        } else if (containsKeyValue(queries, 'key') || containsKeyValue(queries, 'value')) {
            return addNewNotifcation("Empty key or value found. Keys and values should not be empty.", 'warning')
        }

        console.log('arrayToObject: ', JSON.stringify(arrayToObject(queries)));

        if (!documentQuery || documentQuery?.length <= 0) {
            return addNewNotifcation('Document could not be uploaded. Document is required. Please provide a document before proceeding.', 'danger')
        }

        setBtnLoading(true)
        const formData = new FormData();

        formData.append(`organization_id`, oragID);
        formData.append(`sub_organization_id`, subOragID);
        formData.append(`file`, documentQuery);
        formData.append(`chunk_size`, 2000);
        formData.append(`chunk_overlap`, 400);
        formData.append(`metadata`, JSON.stringify(arrayToObject(queries)));

        httpRequestO.post(`/projecto/api/v1/embedding_api_service/process_file/`, formData,
        {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data);
            // window.location.reload()
            addNewNotifcation('Document added successfully', 'success')
            setOpenModal(false)
            setDocumentQuery([])
            getDocs()
            .then((res) => {
                console.log('Done');
            })
        }).catch((err) => {
            console.log('err: ', err);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    const getDocs = async () => {
        try {
            setLoading(true)
            const res = await httpRequestO.get(`/projecto/api/v1/embedding_api_service/list-document/?organization_id=${oragID}&sub_organization_id=${subOragID}`)
            console.log('res: ', res);
            setDocs(res.data)
        } catch(err) {
            console.log('err: ',err);
        }finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        getDocs()
        .then((res) => {
            console.log('Done');
        })
    }, [])

    if (loading) {
        return <LoaderContainerDiv> <AppLoader size={50} /> </LoaderContainerDiv>;
    }

    return (
        <div className='document-manger-container'>
            <div className='dahsboard-breadcrumbs-container'>
                <Breadcrumb maxPaths={4} />
            </div>

            <ListDocuments handleUploadFiles={() => setOpenModal(true)} handleQueryAll={() => setShowQueryModal(true)} docs={docs} setDocs={setDocs} />

            <QueryDocs showQueryModal={showQueryModal} setShowQueryModal={setShowQueryModal} />
            <UploadDocs btnLoading={btnLoading} metadata={queries} setMetadata={setQueries} documentQuery={documentQuery} setDocumentQuery={setDocumentQuery} onSubmit={handleUpload} showUploadModal={openModal} setShowUploadModal={setOpenModal} />
        </div>
    )
}
