import { createApiClient } from './';

const REACT_ENV = process.env.REACT_APP_MODE || 'development'

const BEX_LOCAL_PORT = 4000;
const BEX_API_BASE_URL = REACT_ENV === 'development' ?  `http://localhost:${BEX_LOCAL_PORT}` : REACT_ENV === 'staging' ? 'https://stgbex.coolriots.ai' : 'https://bex.coolriots.ai'
const BEX_API_ENDPOINT_PREFIX = "/cm";

/**
 * HTTP client for the BeX API endpoints /  services.
 *
 * @type {ApiClient}
 * @see {@link https://github.com/coolriots/BeX-platform-server/tree/main/src/Routers}
 */
export const bexHttpClient = createApiClient(
    BEX_API_BASE_URL, 
    BEX_API_ENDPOINT_PREFIX
);