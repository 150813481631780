import React, { useContext, useState } from 'react'
import { useLocation } from "react-router"
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from 'context/GlobalState'
import './speech-to-text-styles.css'
import SpeechToTextForm from '../../SpeechToText/Components/SpeechToTextForm';
import SetupCard from 'components/AppSetup/SetupCard';
import SetupModal from 'components/AppSetup/SetupModal';
import Breadcrumb from 'components/Breadcrumb'
import { OrganizationContext } from "context/OrganizationContext";


export default function SpeechToTextDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Provide the source from which the BeX AI Assistant will retrieve text for conversion',
        'Specify the specific text or data you want the Assistant to extract and convert to speech.'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        "BeX AI Assistant's Speech To Text functionality is tailored for seamless conversion of text into speech while providing the added feature of downloading the generated audio files.",
        "This tool enhances communication and accessibility within the BeX Platform, enabling users to transform written content into natural-sounding speech and conveniently access audio versions for sharing or offline use."
    ];

    // States
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    // Context
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext);

    const param = useParams()

    const navigateToSpeechToText = () => {
        if (selectedOrganization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/speech-to-text`)
        } else {
            navigate(`/organizations`)
        }
    }
        
    // Actions
    const handleDemoClick = () => {
        navigateToSpeechToText()
    };

    const handleSetupClick = () => {
        addNewNotifcation("Setup is locked.", "danger")

    }
    
    return (
        <div className='speech-to-text-dashboard-container'>
            <div className='speech-to-text-dashboard-content'>
                <div className='dashboard-app-setup-container'>

                    <Breadcrumb maxPaths={4} />

                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle='BeX AI Assistant for Speech To Text'
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant'
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />
        
                        {/*  
                            Refactor: Decompse into smaller components
                        */}
                        <SetupModal title={'Demo for BeX AI Assistant (Speech To Text)'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='speech-to-text-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO: Drag and drop your audio file here or Browse files
                                    </div>
                                    <div className='demo-insturctions__text'>
                                        <span>
                                            The BeX AI Assistant provides real-time Speech-To-Text (STT) transcription from audio files, allowing users to upload audio files and convert them into text, with the option to download the audio file.
                                        </span>
                                        <span>
                                            Demo: In this demo, users can upload audio files to initiate the STT conversion process and download the resulting text output.
                                        </span>
                                    </div>
                                </div>
                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                                {/* 
                                    TODO: 
                                        - Remove this class after refactoring and improving the SetupModal 
                                        - Check why the dropdown is not visible in the modal but visible in the main page - Z-index issue
                                */}
                                <div className="speech-to-text-container">
                                    <SpeechToTextForm />
                                </div>  
                            </SetupModal.StyledSetup>
                        </SetupModal>
                    </div>
                </div>
            </div>
        </div>
    );
}