import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "components/ui/card"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "components/ui/dropdown-menu"
import { Button } from "components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { assistantInstanceService, channelService } from "api"
import AlertModal from "components/Alertmodal"
import UpdateWebChannelWrapper from "./WebChatUpdate"

export default function ChannelType({ type, channels, setEmailChannels }){

    const [emailDeleteDialog, setEmailDeleteDialog] = useState(false)
    const [btnDeleteLoading, setBtnDeleteLoading] = useState(false)

    const [emailUpdateDialog, setEmailUpdateDialog] = useState(false)
    const [emailUpdateObject, setEmailUpdateObject] = useState(null)
    
    const [webChatUpdateDialog, setWebChatUpdateDialog] = useState(false)

    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleDeleteEmail = () => {

        setBtnDeleteLoading(true)
        channelService.deleteChannel(oragID, subOragID, emailUpdateObject._id)
        .then(() => {
            setEmailChannels(prev => prev.filter(item => item._id !== emailUpdateObject._id))
            addNewNotifcation('Channel deleted successfully', 'success')
            setEmailDeleteDialog(false)
        }).catch(() => {
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setBtnDeleteLoading(false)
        })
    }

    const handleChannelRemove = (item) => {
        setEmailDeleteDialog(true)
        setEmailUpdateObject(item)
    }


    const handleChannelUpdate = (item) => {
        switch (item.type) {
            case 'Email':
                setEmailUpdateDialog(true);
                break;
            case 'Webchat':
                setWebChatUpdateDialog(true);
                break;
            default:
                setEmailUpdateDialog(true);
                break;
        }
        setEmailUpdateObject(item)
    }


    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-2xl font-bold mb-4">{type}</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {channels.map((item) => (
                        <Card key={item._id}>
                            <CardHeader>
                                <CardTitle>{item.address}</CardTitle>
                                <CardDescription>{item.topic}</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="flex justify-between items-center">
                                    <div>
                                        <p className="text-sm text-muted-foreground">
                                            Created: {item.created ? item.created.split('T')[0] : 'N/A'}
                                        </p>
                                    </div>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button variant="ghost" size="icon">
                                                <MoveVerticalIcon className="h-4 w-4" />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                            <DropdownMenuItem>
                                                <Button className='w-full' onClick={() => handleChannelUpdate(item)} variant="ghost" size="sm">
                                                    Manage
                                                </Button>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem>
                                                <Button className='w-full' onClick={() => handleChannelRemove(item)} variant="destructive" size="sm">
                                                    Delete
                                                </Button>
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            </CardContent>
                        </Card>
                    ))}

                    {/* delete channel alert */}
                    <AlertModal setopenModal={setEmailDeleteDialog} openModal={emailDeleteDialog} onDelete={handleDeleteEmail} loading={btnDeleteLoading} />

                    {/* update channel dialog */}
                    <ChannelTypeUpdateDialog
                    setEmailChannels={setEmailChannels}
                    isOpen={emailUpdateDialog}
                    setOpenEmailModal={setEmailUpdateDialog} 
                    setEmailUpdateObject={setEmailUpdateObject}
                    emailUpdateObject={emailUpdateObject} />

                    {/* update web chat channel dialog */}
                    <UpdateWebChannelWrapper
                    setEmailChannels={setEmailChannels}
                    isOpen={webChatUpdateDialog}
                    setOpenEmailModal={setWebChatUpdateDialog} 
                    setEmailUpdateObject={setEmailUpdateObject}
                    emailUpdateObject={emailUpdateObject} />

                </div>
            </CardContent>
        </Card>
    )
}

export const ChannelTypeUpdateDialog = ({ isOpen, setOpenEmailModal, emailUpdateObject, setEmailUpdateObject, setEmailChannels }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [assistants, setAssistants] = useState([])
    const [assistantsLoading, setAssistantsLoading] = useState(true)
    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {
        if (!isOpen) return
        assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            // filter only email channel assistants
            setAssistants(res.data.filter((item) => item.assistantId.channelType === emailUpdateObject?.type?.toLowerCase() || ''))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setAssistantsLoading(false)
        })
    }, [isOpen])

    const handleOnSubmit = () => {

        setBtnLoading(true)

        const emailObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            channelId: emailUpdateObject._id,
            topic: emailUpdateObject.topic,
            assistantId: emailUpdateObject?.assistantId === 'none' ? '' : emailUpdateObject?.assistantId
        }

        channelService.updateChannel(emailObj)
        .then((res) => {
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...emailUpdateObject}
                    } else {
                        return {...item}
                    }
                })
            })
            addNewNotifcation('Email Updated successfully', 'success')
            setOpenEmailModal(false)
        }).catch(error => {
            console.log('error: ', error);
            if (error.response.status === 404) {  
                addNewNotifcation('Email not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    const handleChange = (value, type) => {
        setEmailUpdateObject(prev => {
            return {...prev, [type]: value}
        })
    }

    if (!emailUpdateObject) return 

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle> Manage Channel</DialogTitle>
                    <DialogDescription> Update channel information</DialogDescription>
                </DialogHeader>
                <div className="p-6 space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="address">Address</Label>
                        <Input id='address' disabled={true} defaultValue={emailUpdateObject.address} />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="topic">Email Topic</Label>
                        <Input id='topic' value={emailUpdateObject.topic} onChange={(e) => handleChange(e.target.value, 'topic')} placeholder="Enter email topic" />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="assistant">Assistant</Label>
                        
                        <Select onValueChange={(value) => handleChange(value, 'assistantId')} disabled={assistantsLoading} id="assistant" defaultValue={emailUpdateObject?.assistantId || 'none'}>
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select an assistant" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="none">None</SelectItem>
                                {assistants.map(item => (
                                    <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>

                    </div>
                </div>

                <DialogFooter>
                    <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                    <Button onClick={handleOnSubmit} disabled={btnLoading}> Submit </Button>
                </DialogFooter>
            </DialogContent>

        </Dialog>
    )
}

function MoveVerticalIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <polyline points="8 18 12 22 16 18" />
            <polyline points="8 6 12 2 16 6" />
            <line x1="12" x2="12" y1="2" y2="22" />
        </svg>
    )
}