import React, { useState, useEffect, useContext } from 'react';
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter, DialogClose } from "components/ui/dialog";
import { Trash2, Search } from 'lucide-react';
import { ScrollArea } from "components/ui/scroll-area";
import { unifiedModelService } from "api/services/PROJECT-O/UnifiedModel.service"; 
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/LivePromptContext';
import { Context } from 'context/GlobalState';
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import DeleteDialog from 'components/DeleteDialog';

export default function InstructionSelectionModal({ model_type, setName, setDescription }) {
    const [open, setOpen] = useState(false); // State to control the modal open state
    const [selectedInstructions, setSelectedInstructions] = useState([]);
    const [modelDetails, setModelDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isFetchingModels, setIsFetchingModels] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [instructions, setInstructions] = useState([]);
    const {
        setInstruction,
        setSelectedModelType,
        setSelectedModel,
        setParametersWatsonX,
        setParametersGroq
    } = useContext(GlobalContext);
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const filteredInstructions = instructions.filter((instruction) =>
        instruction.name && instruction.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const resetState = () => {
        setSelectedInstructions([]);
        setModelDetails(null);
        setSearchTerm("");
    };

    const handleInstructionSelect = (instruction) => {
        if (selectedInstructions.includes(instruction.name)) {
            // If the instruction is already selected, remove it
            setSelectedInstructions(selectedInstructions.filter(name => name !== instruction.name));
            setModelDetails(null);
        } else {
            if (isDeleting) {
                // In delete mode, allow multi-selection
                setSelectedInstructions([...selectedInstructions, instruction.name]);
                setModelDetails(instruction);
            } else {
                // Not in delete mode, only allow one selection at a time
                setSelectedInstructions([instruction.name]);
                setModelDetails(instruction);
            }
        }
    };

    const fetchAllModels = async () => {
        try {
            setInstructions([]);
            setSelectedInstructions([]);
            setIsFetchingModels(true);
            setModelDetails(null);
            const res = await unifiedModelService.GetModelsByOrgSubOrg(selectedOrganization._id, selectedSubOrganization._id, model_type);
            if (!res.data?.FoundationModels) {
                return;
            }

            if (res.data?.FoundationModels.length === 0){
                console.log("No instructions saved.")
                return;
            }
            

            const body = {
                "model_type": model_type,
                "org_id": selectedOrganization._id,
                "sub_org_id": selectedSubOrganization._id,
                "unique_names": res.data?.FoundationModels
            };

            const resDetails = await unifiedModelService.getModelsByUniqueNames(body);
            const modelsObject = resDetails.data?.models || {};

            const instructions = Object.keys(modelsObject).map((key) => ({
                _id: modelsObject[key].unique_name,
                name: modelsObject[key].unique_name,
                value: modelsObject[key].unique_name,
                ...modelsObject[key]
            }));

            setInstructions(instructions);
        } catch (e) {
            console.error("Error fetching models:", e);
        } finally {
            setIsFetchingModels(false);
        }
    };

    const handleLoadInstruction = () => {
        if (selectedInstructions.length === 0) {
            addNewNotifcation("Please select an instruction first.", "warning");
            return;
        } 
        console.log("Selected instruction:", selectedInstructions[0])


        if (model_type === "IBM"){
            // Create a copy of the parameters
            const updatedParameters = { ...modelDetails.parameters };
        
            // Rename decoding_method to mode, and remove the original decoding_method
            if (updatedParameters.decoding_method) {
                updatedParameters.mode = updatedParameters.decoding_method;
                delete updatedParameters.decoding_method; // Remove the old key
            }
        
            // Set the updated parameters and other state values
            setInstruction(modelDetails.prompt);
            setSelectedModelType("watsonx");
            setSelectedModel(modelDetails.modelId);
            setParametersWatsonX(updatedParameters); // Set the updated parameters
            setName(modelDetails.unique_name); // Set the name
            setDescription(modelDetails.modelDescription); // Set the description
            setOpen(false); // Close the dialog after loading the instruction
        }
        else if (model_type === "Groq"){
            // Create a copy of the parameters
            const updatedParameters = { ...modelDetails.parameters, response_format: {"type": "text"} };
        
        
            // Set the updated parameters and other state values
            setInstruction(modelDetails.prompt);
            setSelectedModelType("groq");
            setSelectedModel(modelDetails.modelId);
            setParametersGroq(updatedParameters); // Set the updated parameters
            setName(modelDetails.unique_name); // Set the name
            setDescription(modelDetails.modelDescription); // Set the description
            setOpen(false); // Close the dialog after loading the instruction
        }
        addNewNotifcation("Successfully loaded the instruction.", "success");
    }

    const handleDelete = async () => {
        try {
            setIsDeleteLoading(true);
            const body = {
                org_id: selectedOrganization._id,
                sub_org_id: selectedSubOrganization._id,
                model_type: model_type,
                unique_names: selectedInstructions
            }
            console.log(body)
            const res = await unifiedModelService.deleteModels(body);
            if (res.data?.deleted){
                addNewNotifcation("Successfully deleted the instruction(s).", "success");
                setInstructions(instructions.filter(
                    (instruction) => !selectedInstructions.includes(instruction.name)
                ));
            }
        } catch (error) {
            console.error("Error deleting instructions:", error);
            addNewNotifcation("Error deleting instructions. Please try again.", "danger");
        } finally {
            setIsDeleteLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            resetState();
            fetchAllModels();
        }
    }, [open]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild >
                <Button variant="outline" className="text-left" disabled={!model_type}>
                    Load Instruction
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>{!isDeleting ? "Select an Instruction" : "Delete Instructions"} ({model_type})</DialogTitle>
                    <DialogDescription>
                        {!isDeleting ? "Choose an instruction and view its details." : "Choose instructions to delete."}
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex gap-4 items-center">
                        <div className="relative w-full">
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search instructions..."
                                className="pl-8 w-full"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Button onClick={fetchAllModels} className="flex gap-2" type="button" disabled={isFetchingModels}>
                            {isFetchingModels ? "Loading Instructions..." : "Reload Instructions"}
                        </Button>
                        <Button variant="outline" onClick={() => {setIsDeleting(!isDeleting); setSelectedInstructions([]);}} className="flex gap-2" type="button" disabled={isFetchingModels}>
                            {isDeleting ? (
                                <span className='flex flex-nowrap gap-2 items-center'>
                                    <Trash2 className='w-4 h-4'/>
                                    Cancel Delete
                                </span>
                            ) : (
                                <span className='flex flex-nowrap gap-2 items-center'>
                                    <Trash2 className='w-4 h-4'/>
                                    Delete Multiple
                                </span>
                            )}
                        </Button>
                    </div>
                    
                    <div className="flex flex-col md:flex-row gap-4">
                        {instructions.length === 0 && (
                            <div className='flex items-center justify-center w-full text-gray-500'>
                                <span>No {model_type} instruction(s) saved.</span>
                            </div>
                        )}
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 p-4">
                                {filteredInstructions.map((instruction) => (
                                    <Button
                                        key={instruction.name}
                                        variant={ selectedInstructions.includes(instruction.name) ? (isDeleting ? "destructive" : "default") : "outline" }
                                        onClick={() => handleInstructionSelect(instruction)}
                                        className="justify-start h-auto text-left"
                                    >
                                        <div className="w-full">
                                            <div className="font-semibold w-full truncate">
                                                {instruction.name}
                                            </div>
                                            <div className={`text-xs
                                                ${selectedInstructions.includes(instruction.name)
                                                ? (isDeleting ? "text-gray-800" : "text-muted-foreground")
                                                : "text-muted-foreground"}`}>
                                                {model_type}
                                            </div>
                                        </div>
                                    </Button>
                                ))}
                            </div>
                        </ScrollArea>

                        {loading ? (
                            <div className="md:w-1/3 p-2 bg-muted rounded-md">
                                <h4 className="mb-2 font-semibold">Loading Details...</h4>
                            </div>
                        ) : isDeleting ? (
                            <div className="md:w-1/3 bg-muted rounded-md">
                                <ScrollArea className="max-h-[400px] p-2">
                                    <div>
                                        <h4 className="mb-2 font-semibold text-md">Selected Instructions to Delete</h4>
                                        <ul className="list-disc text-xs space-y-2 pl-4">
                                            {selectedInstructions.length > 0 ? (
                                                selectedInstructions.map((name) => (
                                                    <li key={name} className="text-gray-700">
                                                        {name}
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="text-gray-500">No instructions selected for deletion.</li>
                                            )}
                                        </ul>
                                    </div>
                                </ScrollArea>
                            </div>
                        ) : modelDetails && model_type === "IBM" ? (
                            <div className="md:w-1/3 bg-muted rounded-md">
                                <ScrollArea className="max-h-[400px] p-2">
                                    <div>
                                        <h4 className="mb-2 font-semibold text-md">{modelDetails.unique_name}</h4>
                                        <ul className="list-disc text-xs space-y-2">
                                            <div>{modelDetails.modelDescription}</div>
                                            <div className='whitespace-pre-wrap'><strong>Prompt:</strong> {modelDetails.prompt}</div>
                                            <div><strong>Model ID:</strong> {modelDetails.modelId}</div>
                                            <div><strong>Parameters:</strong></div>
                                            <div className="pl-8">
                                                {Object.entries(modelDetails.parameters)
                                                    .filter(([key]) => key !== "repetition_penalty" && key !== "stop_sequence")
                                                    .map(([key, value]) => (
                                                        <li key={key} className="text-gray-700">
                                                            <strong>{key}: </strong>{value}
                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </ul>
                                    </div>
                                </ScrollArea>
                            </div>
                        ) : modelDetails && model_type === "Groq" ? (
                            <div className="md:w-1/3 bg-muted rounded-md">
                                <ScrollArea className="max-h-[400px] p-2">
                                    <div>
                                        <h4 className="mb-2 font-semibold text-md">{modelDetails.unique_name}</h4>
                                        <ul className="list-disc text-xs space-y-2">
                                            <div>{modelDetails.modelDescription}</div>
                                            <div className='whitespace-pre-wrap'><strong>Prompt:</strong> {modelDetails.prompt}</div>
                                            <div><strong>Model ID:</strong> {modelDetails.modelId}</div>
                                            <div><strong>Parameters:</strong></div>
                                            <div className="pl-8">
                                                {Object.entries(modelDetails.parameters)
                                                    .filter(([key]) => key !== "n" && key !== "stop" && key !== "stream" && key !== "seed")
                                                    .map(([key, value]) => (
                                                        <li key={key} className="text-gray-700">
                                                            <strong>{key}: </strong>{value}
                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </ul>
                                    </div>
                                </ScrollArea>
                            </div>
                        ) : (
                            <div className="md:w-1/3 p-4 bg-muted rounded-md">
                                <h4 className="mb-2 font-semibold">Select an instruction to see details.</h4>
                            </div>
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                    
                    {!isDeleting ? 
                    <Button  variant="default" onClick={handleLoadInstruction} disabled={selectedInstructions.length === 0}>
                        Load Instruction
                    </Button>
                    : 
                    // <Button  variant="destructive" onClick={handleDelete} disabled={selectedInstructions.length === 0}>
                    //     Delete Instructions
                    // </Button>
                    <DeleteDialog label="Delete Instructions" itemNames={selectedInstructions} loading={isDeleteLoading} onDelete={handleDelete}/>
                    }
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
