import React, { useState } from 'react';
import data from './data.json';
import './documentation-styles.css';
import { PopUpModal } from '../Manager/EntityIntent/components/PopUpModal';
import { Container, PrimaryInput } from '../../components/FormInput';
import { LoaderSpinner } from '../../components/LoaderSpinner';

export default function Documentation() {
    const [openModal, setOpenModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [titleValue, setTitleValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');

    const handleInsertDocumentationClick = () => {
        setOpenModal(true);
    };

    const handleTextareaChange = (event) => {
        setDescriptionValue(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitleValue(event.target.value);
    };

    const handleUpload = () => {
        // Log title and description to console as an object
        console.log({ title: titleValue, description: descriptionValue });
        // Reset values and close modal (if needed)
        setTitleValue('');
        setDescriptionValue('');
        setOpenModal(false);
    };

    return (
        <div>
            <div>
                <div className='insert-button-container'>
                    <button className='insert-button' onClick={handleInsertDocumentationClick}>Insert Documentation</button>
                </div>
                <PopUpModal setOpenModal={setOpenModal} openModal={openModal} text={'Upload Documents'}>
                <div className='upload-documents-form'>
                    <Container labelName='Title'>
                    <PrimaryInput value={titleValue} onChange={handleTitleChange} />
                    </Container>

                    <Container labelName='Description'>
                    <textarea className='docu-textarea'
                        value={descriptionValue}
                        onChange={handleTextareaChange}
                        placeholder='Enter description...'
                    />
                    </Container>
                </div>

                <button className='organizations-create-btn' onClick={handleUpload}>
                    {btnLoading ? <LoaderSpinner /> : 'Upload'}
                </button>
                </PopUpModal>
            </div>
            <div className='doc-container'>
                <div className='left-side'>
                    {data.map((item) => (
                        <a className='title-style' href={`#${item.title.replace(/\s/g, '')}`}>
                        {item.title}
                        </a>
                    ))}
                </div>

                <div className='right-side'>
                    {data.map((item, i) => (
                        <section id={item.title.replace(/\s/g, '')} key={i} className='section-subject'>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                        </section>
                    ))}
                </div>
            </div>
        </div>
    );
}
