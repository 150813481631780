import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FaCaretDown } from "react-icons/fa";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  position: relative;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: grey;
  border-radius: 5px;
  margin: 7px;
`;

const ToggleButton = styled.button`
  width: fit-content;
  height: fit-content;
  border-style: none;
  background-color: rgba(0, 0, 0, 0);
  transform: ${(props) =>
    props.containerState === "collapsed" ? "rotate(0deg)" : "rotate(180deg)"};
  transition: transform 0.2s;
  :hover {
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  overflow: hidden;
  max-height: ${(props) =>
    props.containerState === "expanded" ? "fit-content" : "0px"};
  transition: height 1s;
`;

export default function ({ children, title }) {
  const [containerState, setContainerState] = useState("collapsed");

  return (
    <Container>
      <Header
        onClick={() =>
          containerState === "collapsed"
            ? setContainerState("expanded")
            : setContainerState("collapsed")
        }
      >
        <div>
          <b>{title}</b>
        </div>
        <div>
          <ToggleButton
            containerState={containerState}
            onClick={() =>
              containerState === "collapsed"
                ? setContainerState("expanded")
                : setContainerState("collapsed")
            }
          >
            <FaCaretDown />
          </ToggleButton>
        </div>
      </Header>
      <ContentContainer containerState={containerState}>
        {children}
      </ContentContainer>
    </Container>
  );
}
