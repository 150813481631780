import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";

export const SecureRoute = () => {

    const { userAdmin, isAdminAuth } = useContext(Context)
    const navigate = useNavigate()
        
    useEffect(() => {
        // rediered logic
    }, [isAdminAuth])

    return (
        <Outlet />
    )
    
  };