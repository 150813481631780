import { useState, useEffect } from 'react';
import { httpRequest } from '../utils/httpsRequest';

const usePackages = () => {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPackages = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await httpRequest.get(`/db/packages/get-packages`);
            setPackages(response.data);
        } catch (err) {
            console.log('err: ', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
        };

        fetchPackages();
    }, []);

    return { packages, loading, error };
};

export default usePackages;
