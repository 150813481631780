import React, { useContext, useRef, useState } from 'react'
import './signup-styles.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Context } from '../../context/GlobalState'
import SignupForm from './SignupForm'
import { authService } from 'api'

export default function Signup() {

    const [loading, setLoading] = useState(false)

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const singupFormRef = useRef(null);

    const {addNewNotifcation} = useContext(Context)

    const navigate = useNavigate()
    
    const handleOnSubmit = (e) => {
        e.preventDefault()
        
        const nameRef = singupFormRef.current.getNameRef();
        const emailRef = singupFormRef.current.getEmailRef();
        const passwordRef = singupFormRef.current.getPasswordRef();
        const confirmPasswordRef = singupFormRef.current.getConfirmPasswordRef();

        if (passwordRef.value !== confirmPasswordRef.value) {
            addNewNotifcation('Signup failed. Password does not match. Please enter the same password for confirmation.','danger')
            return
        }

        if (passwordRef.value.length < 8) {
            return addNewNotifcation('Singup failed. Password length must be at least 8 characters long. Please reenter your password.','danger')
        }

        setLoading(true)

        const userOBj = {
            name: nameRef.value,
            email: emailRef.value,
            password: passwordRef.value
        }

        if (token) {
            userOBj['token'] = token
        }

        authService.signup(userOBj)
        .then((res) => {
            console.log(res);
            addNewNotifcation('Your account was created successfully. Please verify your account by using the verification link sent to your email.','success', 6)
            setTimeout(() => {
                navigate('/auth/login')
            }, 1500)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 409) {
                addNewNotifcation('Signup failed. Email is already registered. Please use a different email.','danger')
            } else {
                addNewNotifcation('Signup failed. Please check all your entries.','danger')
            }
        }).finally(() => {
            setTimeout(() => setLoading(false), 1500)
        })

    }

    return (
        <div className='signup-container'>
            <SignupForm ref={singupFormRef} loading={loading} onSubmit={handleOnSubmit} />
        </div>
    )
}

export const ContainerPass = ({ labelName, children }) => {
    return (
        <div className='input-container'>
            <label>{labelName}</label>
            <div className='input-wrapper'>
                {children}
                {/* <span className='password-toggle'></span> */}
            </div>
        </div>
    );
};
