import React, { useState } from 'react'
import Modal from '../Modal'
import styled from 'styled-components'
import { IoMdInformationCircleOutline } from 'react-icons/io'


const StyeldMoalContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 2250px;
    height: 550px;

    @media (max-width: 900px) {
        flex-direction: column;
        // height: auto;/
        overflow: scroll;
    }
`

const StyledInstructionsMenu = styled.div`
    width: 50px;
    position: relative;
    flex-shrink: 0;
`

const StyledInstructionsAbsolute = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.$isHover ? '400px' : '50px'};
    box-shadow: ${props => props.$isHover ? '2px 0px 9px 1px rgba(0,0,0,0.25)' : 'none'};
    display: flex;
    background-color: #fff;
    bottom: 0;
    overflow: hidden;
    transition: width 200ms ease;
    z-index: 999;
`

const StyledInstructionsMenuInner = styled.div`
    display: flex;
    flex-grow: 1;
    min-width: 400px;
`
const StyledInstructionsMenuIcon = styled.div`
    min-width: 50px;
    display: flex;
    padding: 10px;
    justify-content: center;
    
    &>svg {
        cursor: pointer;
    }
`

const StyledInstructionsContainer = styled.div`
    display: flex;
    flex-grow: 1;

    @media (max-width: 900px) {
        width: 100%;
    }
`

const StyledSetupContainer = styled.div`
    display: flex;
    flex-grow: 1;
`

export default function SetupModal({ title, isOpen, setOpenModal, onClose = () => { }, children }) {


    return (
        <Modal onClose={onClose} style={{border: 'none', width: '95%', maxWidth: 2250}} $isOpen={isOpen} setOpenModal={setOpenModal}>
            <Modal.Header isClose={false} style={{backgroundColor: 'black', color: '#fff'}}>
                {title}
            </Modal.Header>
            <StyeldMoalContainer>
                {children}
            </StyeldMoalContainer>
        </Modal>
    )
}

const StyledInstructions = ({ children }) => {

    const [isHover, setIsHover] = useState(false)

    return (
        <StyledInstructionsMenu>
            <StyledInstructionsAbsolute onMouseLeave={() => setIsHover(false)} $isHover={isHover}>
                <StyledInstructionsMenuInner>
                    <StyledInstructionsMenuIcon>
                        <IoMdInformationCircleOutline onMouseEnter={() => setIsHover(true)} size={25} />
                    </StyledInstructionsMenuIcon>
                    <StyledInstructionsContainer>
                        {children}
                    </StyledInstructionsContainer>
                </StyledInstructionsMenuInner>
            </StyledInstructionsAbsolute>
        </StyledInstructionsMenu>
    )
}

const StyledSetupInstructions = ({ children }) => {


    return (
        <StyledInstructionsContainer>
            {children}
        </StyledInstructionsContainer>
    )
}


const StyledSetup = ({ children }) => {


    return (
        <StyledSetupContainer>
            {children}
        </StyledSetupContainer>
    )
}

SetupModal.StyledSetupInstructions = StyledSetupInstructions
SetupModal.StyledInstructions = StyledInstructions
SetupModal.StyledSetup = StyledSetup