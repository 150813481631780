import React from "react";
import styled from "styled-components";
const MIN_TEXTAREA_HEIGHT = 32; // Minimum height for the textarea

const TextAreaContext = styled.textarea`
    height: fit-content;
    border-radius: 8px;
    border-width: 1px;
    padding: 10px;
    width: 100%;
    resize: vertical;
    text-wrap: true;
    font-family: inherit;
`

export default function AutoAdjustTextarea({value,onChangeText,}) {
  const textareaRef = React.useRef(null);
  const [valueText, setValue] = React.useState("");

  const onChange = (event) => {
    // console.log(event.target.value)
    onChangeText(event);
  };

  React.useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";

    // Set height based on content
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [value]);

  return (
    <TextAreaContext
      onChange={onChange}
      ref={textareaRef}
      style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }}
      value={value}
      placeholder="Type here..."
    />
  );
}
