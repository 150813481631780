import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { PrimaryInput } from '../../components/FormInput';
import { Context } from '../../context/GlobalState';
import { httpRequest } from '../../utils/httpsRequest';
import { LoaderSpinner } from '../../components/LoaderSpinner';

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    padding: 20px;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h2`
    margin-bottom: 10px;
`;

const Label = styled.label`
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end; 
    width: 100%; 
`;

const Button = styled.button`
    background: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 150px;
    margin-top: 15px;
`;

const AdminPasswordChangeModal = ({ onClose }) => {
    
    const { addNewNotifcation, user } = useContext(Context);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [userPassInput, setUserPassInput] = useState({ oldPass: '', newPass: '', confirmNewPass: '' });

  
    const handleChangePassword = (e) => {
        e.preventDefault();        
        if (newPassword !== confirmNewPassword) {
            addNewNotifcation('Passwords do not match!', 'warning');
            return
        }
        if (newPassword === confirmNewPassword) {
            
            const passObj = {
                userId: user.id,
                oldPassword: oldPassword,
                newPassword: newPassword
            }
            console.log(passObj);
            setBtnLoading(true);
            httpRequest.post('/db/users/update-password', passObj)
            .then((res) => {
                console.log('Response', res);
                addNewNotifcation('Password has been updated successfully.', 'success');
                setUserPassInput({ oldPass: '', newPass: '', confirmNewPass: '' })
                onClose();
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 404)
                    addNewNotifcation('Password could not be updated. User is not registered. Please check all your entries.', 'warning')
                else if (error.response?.status === 400)
                    addNewNotifcation('Password could not be updated. Old password is not correct. Please enter the correct password.', 'danger')
                else
                    addNewNotifcation('Password could not be updated. Please check all your entries.', 'danger')
            })
            .finally(() => setBtnLoading(false));
            console.log('Password changed successfully:', newPassword);
        } 
    };
  
    return (
        
        <ModalContainer>
            <Modal>
                <ModalContent>
                    <Title>Change Password</Title>
                    <p>You are required to change your password.</p>
                    <Label htmlFor="oldPassword">Old Password:</Label>
                    <PrimaryInput
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <Label htmlFor="newPassword">New Password:</Label>
                    <PrimaryInput
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Label htmlFor="confirmNewPassword">Confirm New Password:</Label>
                    <PrimaryInput
                        type="password"
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <ButtonContainer>
                        <Button onClick={handleChangePassword}>{btnLoading ? <LoaderSpinner /> : 'Change Password'}</Button>
                    </ButtonContainer>
                </ModalContent>
            </Modal>
        </ModalContainer>
    );
};

export default AdminPasswordChangeModal;
