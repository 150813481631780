import React, { useEffect, useState } from 'react'
import './verify-email-styles.css'
import { useSearchParams } from 'react-router-dom'
import { AppLoader, LoaderContainerDiv } from '../../components/LoaderSpinner';
import { FiCheckSquare } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { authService } from 'api'

export default function VerifyEmail() {

    const [loading, setLoading] = useState(true)
    const [isVerified, setIsVerified] = useState(false)

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    useEffect(() => {
        if (!token) return

        authService.verify(token)
        .then((res) => {
            console.log('res: ', res);

            setIsVerified(true)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div className='verify-email-container'>
            <div className='verify-email-box'>
                {loading ? (
                    <LoaderContainerDiv> <AppLoader size={50}/> </LoaderContainerDiv>
                ) : (
                    <div className='email-verification-container'>
                        {isVerified ? 
                        (
                            <>
                                <FiCheckSquare size={120} color='var(--primary-color)' />
                                <div className='success-email-verified'>
                                    <span> Email Verified successfully </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <AiOutlineClose size={120} color='var(--primary-color)' />
                                <div className='failed-email-verified'>
                                    <span> Something went wrong </span>
                                </div>
                            </>
                        )
                        }
                    </div>
                )}
            </div>
        </div>
    )
}
