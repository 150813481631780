import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";
import { AppLoader } from "../components/LoaderSpinner";
import { SubOrgContext } from "context/SubOrganizationContext";
import { WorkflowRequired } from "pages/Dashboard/AssistantsDashboard";
import SideMenuWrapper from "./SideMenuWrapper";
import { OrganizationContext } from "context/OrganizationContext";

export const ProtectedSubOrgRoute = () => {
    const { addNewNotifcation } = useContext(Context);
    const {
        actions,
        loading,
        workflow,
        subOrgMenu,
    } = useContext(SubOrgContext);
    const { selectedOrganization } = useContext(OrganizationContext);
    const navigate = useNavigate();
    const { subOragID, oragID } = useParams();
    const [protectLoading, setProtectLoading] = useState(true);

    useEffect(() => {
        const isMember = selectedOrganization?.subOrganizations.find((i) => i._id === subOragID);
        if (isMember) {
            actions({type: 'SET_SELECTED_SUB_ORGANIZATION', payload: isMember});
            localStorage.setItem("selectedSubOrg", isMember._id);
        } else {
            navigate(`/organizations/${oragID}/sub`)
            addNewNotifcation('You are not member of this sub organization', 'warning')
        }

        setProtectLoading(false);

        return () => {
            actions({type: 'SET_SELECTED_SUB_ORGANIZATION', payload: null})
        }

    }, [oragID, subOragID, navigate]);

    if (protectLoading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    // check if workflow exists in sub organization or display WorkflowRequired component
    return loading ? (
        <AppLoader size={30} />
    ) : workflow ? (
        <SideMenuWrapper section={subOrgMenu}>
            <Outlet />
        </SideMenuWrapper>
    ) : (
        <WorkflowRequired />
    );
};
