import styled from "styled-components"
import { MdAttachFile, MdDriveFolderUpload } from "react-icons/md";


const UploadContainer = styled.div`
    width: 100%;
    max-width: 450px;
    margin: auto;
    padding: 2rem;
    background: #ffffff;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
`

const FileUpload = styled.div`
    text-align: center;
    border: 3px dashed rgb(210, 227, 244);
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
`

const UploadText = styled.p`
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
`

const UploadInput = styled.input`
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
`

const StyledFileDisplay = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 0px;
`

const StyledFileRow = styled.li`
    display: flex;
    gap: 10px;
    padding: 5px 0px;
`


export const UploadDocument = ({ files=[], ...rest }) => {
    
    const filesArray = Array.from(files);

    return (
        <UploadContainer>
            <FileUpload>
                <MdDriveFolderUpload />
                    <h3>Click box to upload</h3>
                <UploadText>Maximun file size 10mb</UploadText>
                <UploadInput {...rest} type="file" />
            </FileUpload>
            <StyledFileDisplay>
                {filesArray?.map((file, i) => (
                    <StyledFileRow key={i}>
                        {file?.name && (
                            <>
                                <MdAttachFile />
                                {file?.name}
                            </>
                        )}
                    </StyledFileRow>
                ))}
            </StyledFileDisplay>
        </UploadContainer>
    )
}
