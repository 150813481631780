import React, { useState, useEffect, useContext } from "react";
import {
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Badge } from "components/ui/badge";
import { ScrollArea } from "components/ui/scroll-area";
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner, AppLoader } from "components/LoaderSpinner";
import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import { SubOrgContext } from "context/SubOrganizationContext";
import { Context } from "context/GlobalState";
import { useParams } from "react-router-dom";
import { projectService } from "api/services/BEX/project.service";

const UpdateCollectionDialog = ({ show, setShow, assistantId }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [collections, setCollections] = useState([]);
    const [loadingCollections, setLoadingCollections] = useState(true);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const { project, actions } = useContext(SubOrgContext);
    const { addNewNotifcation } = useContext(Context);
    const { oragID, subOragID } = useParams();

    const filterCollection = (item) => {
        return item.assistants.find(assistant => assistant._id === assistantId)
    }

    const getData = async () => {
        if (!show) return;
        try {
            setCollections(project.collections);
            setSelectedCollections(project.collections.filter(filterCollection));
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoadingCollections(false);
        }
    };

    useEffect(() => {
        getData();
    }, [show]);

    const handleUpdateCollections = () => {
        setBtnLoading(true);

        const updatedAssistantObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            projectId: project._id,
            assistantId: assistantId,
            collections: selectedCollections.map(item => item._id),
        };

        projectService.updateAssistantCollections(updatedAssistantObj)
            .then((res) => {
                setShow(false);
                actions({ type: 'SET_PROJECT', payload: res.data });
                addNewNotifcation("Assistant collections updated successfully", "success");
            })
            .catch((error) => {
                console.log("error: ", error);
                if (error?.response?.status === 404) {
                    addNewNotifcation("Assistant not found", "danger");
                } else {
                    addNewNotifcation("Something went wrong", "danger");
                }
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };

    const handleSelectCollection = (collection) => {
        if (!selectedCollections.some((m) => m._id === collection._id)) {
            setSelectedCollections([...selectedCollections, collection]);
        }
    };

    const handleRemoveCollection = (collection) => {
        setSelectedCollections(selectedCollections.filter((m) => m._id !== collection._id));
    };

    const filteredCollections = collections.filter((collection) => 
        collection.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="max-h-[650px] overflow-scroll w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                        Update Assistant Collections
                    </DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">
                        Modify the collections linked to this assistant
                    </DialogDescription>
                </DialogHeader>

                <div className="space-y-6">
                    <div className="flex">
                        <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                            <div className="flex flex-wrap gap-2">
                                {selectedCollections.map((collection) => (
                                    <Badge
                                        key={collection._id}
                                        variant="secondary"
                                        className="p-2 text-sm"
                                    >
                                        {collection.name}
                                        <Cross1Icon
                                            className="ml-2 cursor-pointer"
                                            onClick={() => handleRemoveCollection(collection)}
                                        />
                                    </Badge>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="relative space-y-3">
                        <Label htmlFor="collection-search" className="text-md">
                            Collection Selection
                        </Label>
                        <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                        <Input
                            id="collection-search"
                            placeholder="Search collections..."
                            type="search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                        <ScrollArea className="border-solid rounded-md h-56">
                            <div className="flex flex-col p-3 space-y-3">
                                {loadingCollections ? (
                                    <AppLoader size={60} />
                                ) : filteredCollections.length === 0 ? (
                                    <div className="text-center text-muted-foreground">
                                        No collections found
                                    </div>
                                ) : (
                                    filteredCollections.map((collection) => (
                                        <div
                                            key={collection._id}
                                            className="flex items-center space-x-3 cursor-pointer"
                                            onClick={() => handleSelectCollection(collection)}
                                        >
                                            <div>
                                                <div className="font-medium text-md">{collection.name}</div>
                                            </div>
                                            <CheckIcon
                                                className={cn(
                                                    "ml-auto h-6 w-6",
                                                    selectedCollections.some((m) => m._id === collection._id)
                                                        ? "opacity-100"
                                                        : "opacity-0"
                                                )}
                                            />
                                        </div>
                                    ))
                                )}
                            </div>
                        </ScrollArea>
                    </div>
                </div>

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button
                        variant="outline"
                        onClick={() => setShow(false)}
                        className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdateCollections}
                        className="px-5 py-3 text-md"
                    >
                        {btnLoading ? <LoaderSpinner /> : "Update Collections"}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateCollectionDialog;
