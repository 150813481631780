import React from 'react'

export default function Footer() {

    return (
        <footer className='footer-container border-t'>
            <div className='footer-content-container'>
                <h2> Executing with Agility, Velocity and Precision </h2>
            </div>
        </footer>
    )
}