import React, { useContext } from 'react'
import { Context } from '../context/GlobalState'
import AvatarDropdownMenu from '../components/AvatarDropdownMenu'
import { httpRequest } from '../utils/httpsRequest'

// Refactor: organize methods and variables orders
export default function Profile() {
    // Constants
    const PROFILE_MENU_ITEMS = [
        { name: "My Account", link: "/profile" , action: () =>{} },
        { name: "Logout", link: undefined, action: () => handleLogOut() }
    ];
    
    const { user } = useContext(Context)

    const handleLogOut = () => { // Refactor: this function to use the GlobalState context
        httpRequest.post('/auth/logout')
          .then((res) => {
              console.log(res);
            //   localStorage.removeItem('selectedSubOrg');
            console.log('selectedSubOrg: ', localStorage.getItem('selectedSubOrg'));
            // navigate('/auth/login');
            window.location = '/auth/login';
          }).catch((error) => {
              console.log(error);
          })
    }
    
    return (
        <div className={`profile-container`}>
            <AvatarDropdownMenu 
                user={user} 
                menuItems={PROFILE_MENU_ITEMS} />
        </div>
    )
}