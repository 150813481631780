import React, { useEffect, useState } from 'react'
import './organizations-styles.css'
import { AppLoader, LoaderContainerDiv, LoaderSpinner } from '../../../components/LoaderSpinner'
import StyledTable from '../../../components/StyledTable'
import { httpRequest } from '../../../utils/httpsRequest'
import { MdEdit, MdAccountCircle, MdPeopleAlt } from 'react-icons/md'
import { RedButton } from '../../../components/Buttons'
import { PopUpModal } from '../../Manager/EntityIntent/components/PopUpModal'
import Switch from '../../../components/Switch'
import { assistantNames } from '../../../data/assistants'
import NewStyledTable from '../../../components/ui/StyledTable'
import { Link } from 'react-router-dom'

export default function Organizations() {

    const [organization, setOrganization] = useState([])
    const [loading, setLoading] = useState(true)
    const [manageModal, setManageModal] = useState(false)
    const [membersModal, setMembersModal] = useState(false)
    const [subOrgsModal, setSubOrgsModal] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState({})

    useEffect(() => {
        httpRequest.get('admin/get-organizations')
        .then((res) => {
            console.log('res: ', res);
            setOrganization(res.data?.map((item) => (
                {...item,
                id: item._id, 
                created: item.created?.split('T')[0],
                adminName: item?.superAdmin?.name || 'None'
            })))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const handleUpdate = (id) => {
        setManageModal(true)
        setSelectedOrg(organization.find((item) => item.id === id))
    }

    const handleView = (id) => {
        console.log("");
        setMembersModal(true)
        setSelectedOrg(organization.find((item) => item.id === id))
    }
    
    const handleViewSub = (id) => {
        console.log("");
        setSubOrgsModal(true)
        setSelectedOrg(organization.find((item) => item.id === id))
    }
    // const tableOptions = [
    //     {'name': 'Manage', 'icon': <MdEdit size={18} />, 'onclick': handleUpdate},
    //     {'name': 'Members', 'icon': <MdAccountCircle size={18} />, 'onclick': handleView},
    // ]

    const subOrgRowActions = [
        {'name': 'Manage', 'icon': <MdEdit size={18} />, 'onclick': handleUpdate},
        {'name': 'Members', 'icon': <MdAccountCircle size={18} color='var(--primary-color)' />, 'onclick': handleView},
        {'name': 'SubOrgs', 'icon': <MdPeopleAlt size={18} />, 'onclick': handleViewSub},
    ]

    if (loading) {
        return <LoaderContainerDiv> <AppLoader size={50} /> </LoaderContainerDiv>;
    }

    return (
        <div className='admin-organization-container'>
            <div className='admin-organization-header'>
                Organizations
            </div>

            <div>
                <NewStyledTable
                    columns={[{ field: 'name', headerName: 'Name', renderCell: (row) => <Link className='text-blue-600' to={row._id}> {row.name} </Link>}, 
                        { field: 'created', headerName: 'Created'}, { field: 'adminName', headerName: 'Super Admin'}]}
                    data={organization}
                    rowActions={subOrgRowActions}
                    multiSelect={false}
                />
                <ManageOrganization 
                manageModal={manageModal}
                setManageModal={setManageModal}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg} />
                <OrganizationMembersView
                membersModal={membersModal}
                setMembersModal={setMembersModal}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg} />
                <SubOrganizationsView
                subOrgsModal={subOrgsModal}
                setSubOrgsModal={setSubOrgsModal}
                selectedOrg={selectedOrg} />
            </div>
        </div>

    )
}

const ManageOrganization = ({ manageModal, setManageModal, selectedOrg, setSelectedOrg }) => {

    const [btnLoading] = useState(false)

    const handleOnUpdate = () => {
        setManageModal(false)
    }

    if (!Object.keys(selectedOrg).length > 0) return

    return (
        <div> 
            <PopUpModal setOpenModal={setManageModal} openModal={manageModal} text={'Manage Organization'}>
                <div className='manage-organization-container'>

                    <div className='manage-organization-container__assistants'>
                        <h2> Assistants </h2>

                        <div className='manage-organization-assistants'>
                            {assistantNames.map((item, i) => (
                                <AssistantSwitch {...item} key={i} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
                            ))}
                        </div>
                    </div>
                </div>
                <RedButton disabled={btnLoading} className='secure-admins-permissions-create-btn' onClick={handleOnUpdate}>
                    {btnLoading ? <LoaderSpinner /> : 'Done'}
                </RedButton>
            </PopUpModal>
        </div>
    )
}

const OrganizationMembersView = ({ membersModal, setMembersModal, selectedOrg, setSelectedOrg }) => {

    const [btnLoading] = useState(false)
    const [selectedOrgMembers, setSelectedOrgMembers] = useState([]);

    const handleOnUpdate = () => {
        setMembersModal(false)
    }

    useEffect(() => {
        if (membersModal) {
            httpRequest.get(`admin/get-organization-members/${selectedOrg._id}`)
            .then((res) => {
                console.log(res);
                const tabledata = res.data.map((item) => {
                    return {id: item._id, name: item.name, email: item.email};
                })
                setSelectedOrgMembers(tabledata);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setSelectedOrgMembers([]);
        }
    }
    , [membersModal]);


    if (!Object.keys(selectedOrg).length > 0) return

    return (
        <div> 
            <PopUpModal setOpenModal={setMembersModal} openModal={membersModal} text={selectedOrg.name}>
                <div className='members-organization-container'>
                    <div className='members-organization-container'>
                        <div style={{display: "flex", gap: "16px", alignItems: "center"}}>
                            <h3>Members</h3>
                            <p>{selectedOrgMembers.length} Total</p>
                        </div>
                        <div className='members-organization'>
                            <NewStyledTable
                                columns={[{ field: 'name', headerName: 'Name' }, { field: 'email', headerName: 'Email' }]}
                                data={selectedOrgMembers}
                                options={[]}
                            />
                        </div>
                    </div>
                </div>
                <RedButton disabled={btnLoading} className='secure-admins-permissions-create-btn' onClick={handleOnUpdate}>
                    {btnLoading ? <LoaderSpinner /> : 'Done'}
                </RedButton>
            </PopUpModal>
        </div>
    )
}

const SubOrganizationsView = ({ subOrgsModal, setSubOrgsModal, selectedOrg }) => {

    const [btnLoading] = useState(false)
    const [subOrgs, setSubOrgs] = useState([])

    const handleOnUpdate = () => {
        
    }

    useEffect(() => {
        if (subOrgsModal) {
            httpRequest.get(`admin/get-sub-organizations/${selectedOrg._id}`)
            .then((res) => {
                console.log(res);
                const tabledata = res.data.map((item) => {
                    return {id: item._id, name: item.name, members: item.members.length};
                })
                setSubOrgs(tabledata);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setSubOrgs([]);
        }
    }
    , [subOrgsModal]);


    if (!Object.keys(selectedOrg).length > 0) return

    return (
        <div> 
            <PopUpModal setOpenModal={setSubOrgsModal} openModal={subOrgsModal} text={selectedOrg.name}>
                <div className='members-organization-container'>
                    <div className='members-organization-container'>
                        <div style={{display: "flex", gap: "16px", alignItems: "center"}}>
                            <h3>Sub Organization</h3>
                            <p>{subOrgs.length} Total</p>
                        </div>
                        <div className='members-organization'>
                            <StyledTable
                                columns={[{ field: 'name', headerName: 'Name' }, { field: 'members', headerName: 'Members'}]}
                                data={subOrgs}
                                options={[]}
                            />
                        </div>
                    </div>
                </div>
                <RedButton disabled={btnLoading} className='secure-admins-permissions-create-btn' onClick={handleOnUpdate}>
                    {btnLoading ? <LoaderSpinner /> : 'Done'}
                </RedButton>
            </PopUpModal>
        </div>
    )
}

const AssistantSwitch = ({ name, label, selectedOrg, setSelectedOrg }) => {

    const [switchLoading, setSwitchLoading] = useState(false)


    const onSwitchChange = () => {
        console.log('name: ', name);

        const assistantObj = {
            organizationId: selectedOrg.id,
            assistantName: label,
            isActive: !selectedOrg.assistants[label].isActive
        }

        setSwitchLoading(true)
        httpRequest.put('/db/organizations/update-organization-assistants', assistantObj)
        .then((res) => {
            console.log('res: ', res);
            setSelectedOrg(prev => ({
                ...prev,
                assistants: {
                    ...prev.assistants,
                    [label]: {
                        isActive: !prev.assistants[label].isActive
                    }
                }
            }))

        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setSwitchLoading(false)
        })
    }


    return (
        <div  className='organization-assistant-container'>
            <div className='organization-assistant-container__name'>
                <span> {name} </span>
            </div>  

            <div className='organization-assistant-container__label'>
                <div className='assistant-switch-button'>
                    {switchLoading ? (
                        <LoaderSpinner />
                    ) : (
                        <Switch active={selectedOrg.assistants[label]?.isActive} onChange={onSwitchChange} />
                    )}
                </div>
            </div>
        </div>
    )
}