import { AppLoader } from "components/LoaderSpinner";
import { Context } from "context/GlobalState";
import { OrganizationContext } from "context/OrganizationContext";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

export const ProtectedOrgRoute = () => {

    const [loading, setLoading] = useState(true);

    const { organizations, addNewNotifcation } = useContext(Context)
    const { actions } = useContext(OrganizationContext)
    const { oragID } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const isMember = organizations?.find((i) => i._id === oragID);
        if (isMember) {
            if (isMember.clusterStatus === 'DEPLOYED') {
                actions({type: 'SET_SELECTED_ORGANIZATION', payload: isMember});
                localStorage.setItem("selectedOrganization", isMember._id);
            } else {
                navigate('/organizations')
                addNewNotifcation('Organization not deployed yet', 'warning')
            }
        } else {
            navigate('/organizations')
            addNewNotifcation('You are not member of this organization', 'warning')
        }

        setLoading(false)

        return () => {
            actions({type: 'SET_SELECTED_ORGANIZATION', payload: null})
        }
    }, [oragID, navigate]);

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <Outlet />
    )
};

