import React, { useEffect, useRef } from "react";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../components/ui/tooltip";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { Info }from 'lucide-react';
import { Textarea } from "../../../components/ui/textarea";

function useAutoResize(value) {
    const textareaRef = useRef(null);
    

    useEffect(() => {
        const handleResize = () => {
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        };

        if (textareaRef.current) {
            textareaRef.current.addEventListener('input', handleResize);
            handleResize(); // Initial resize to fit existing content
        }

        return () => {
            if (textareaRef.current) {
                textareaRef.current.removeEventListener('input', handleResize);
            }
        };
    }, [value]);

    return textareaRef;
}

export default function StyledTextAreaCn ({ value, onChange, labelText, rows = 2, tooltipText, placeholder, disabled = false, required = false, readOnly = false }) {

    const textareaRef = useAutoResize(value);

    return (
        <div className="flex flex-col gap-[2px]">
            <div className="flex flex-row items-center justify-between">
                <TooltipProvider className="flex items-center justify-between">
                {
                    labelText && (
                        <Label htmlFor="email" className="select-none text-xs">
                            {labelText} {required && <span className="text-red-500">*</span>}
                        </Label>
                    )
                }
                
                {
                    tooltipText && (
                        <Tooltip>
                        <TooltipTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <Info className="h-4 w-4" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent className="w-[300px]">
                        <p>{tooltipText}</p>
                        </TooltipContent>
                    </Tooltip>
                    )
                }
                    
                </TooltipProvider>
                
            </div>
            <Textarea
                placeholder={placeholder ? placeholder : ""}
                onChange={onChange}
                value={value}
                className="w-full p-2 border border-gray-300 rounded-md text-xs"
                disabled={disabled}
                readOnly={readOnly}
                ref={textareaRef}
                rows={rows}
                />
        </div>
    );
}