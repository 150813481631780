import { createApiClient } from './';

const REACT_ENV = process.env.REACT_APP_MODE || 'development'

const FEO_LOCAL_PORT = 8000;
// Notice since we don't have a production environment, we are using the staging environment as the production environment
const FEO_API_BASE_URL = REACT_ENV === 'development' ?  `http://localhost:${FEO_LOCAL_PORT}` : REACT_ENV === 'staging' ? 'https://stgapp.coolriots.ai' : 'https://stgapp.coolriots.ai'
const FEO_API_ENDPOINT_PREFIX = "/feo-v3/api";

/**
 * HTTP client for the BeX FEO V3 API endpoints /  services.
 *
 * @type {ApiClient}
 * @see {@link https://github.com/coolriots/BeX-FEO-V3}
 */
export const feoHttpClient = createApiClient(
    FEO_API_BASE_URL, 
    FEO_API_ENDPOINT_PREFIX
);