import React from "react";
import styled from "styled-components";
type MessageAuthor = {
  $role: "user" | "assistant";
};
const Messages = styled.div<MessageAuthor>`
  padding: 10px;
  font-size: 0.9em;
  max-width: 70%;
  border-radius: ${({ $role }) => ($role === "user" ? "18px 18px 3px 18px;" : "18px 18px 18px 3px;")}
  background-color: ${({ $role }) =>
    $role === "user" ? "#b1092a" : "#d4d4d4"};
  color: ${({ $role }) => ($role === "user" ? "white" : "black")};
  white-space: pre-wrap;
`;

const MessageContainer = styled.div<MessageAuthor>`
  display: flex;
  justify-content: ${({ $role }) =>
    $role === "user" ? "flex-end" : "flex-start"};
  margin: 2.5px;
`;
type ReferenceSchema = {
  pageNumber: number;
};

type ChatMessage = {
  content: String;
  role: "user" | "assistant";
  date: number
  reference?: ReferenceSchema
};
export default function Message({ content, role }: ChatMessage) {
  return (
    <>
      <MessageContainer $role={role}>
        <Messages $role={role}>{content}</Messages>
      </MessageContainer>
    </>
  );
}
