import { Card, CardHeader, CardContent } from "../../components/ui/card"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { Button } from "../../components/ui/button"
import { forwardRef, useImperativeHandle, useRef } from "react"

const ResetPasswordForm = forwardRef((props, ref) => {
    const { loading, onSubmit } = props
  
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
  
    useImperativeHandle(ref, () => ({
      getPasswordRef: () => passwordRef.current,
      getConfirmPasswordRef: () => confirmPasswordRef.current,
    }));
  
    return (
        <Card className="w-full max-w-md space-y-8">
            <CardHeader>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50">
                Reset your password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                Enter your new password below to reset your account password.
            </p>
            </CardHeader>
            <CardContent>
            <form onSubmit={onSubmit} className="space-y-6" action="#" method="POST">
                <div>
                <Label htmlFor="new-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    New Password
                </Label>
                <div className="mt-1">
                    <Input ref={passwordRef} id="new-password" name="new-password" type="password" autoComplete="new-password" required />
                </div>
                </div>
                <div>
                <Label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Confirm New Password
                </Label>
                <div className="mt-1">
                    <Input
                    ref={confirmPasswordRef}
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    autoComplete="new-password"
                    required
                    />
                </div>
                </div>
                <div>
                    <Button onSubmit={onSubmit} disabled={loading} type="submit" className="w-full">Reset Password</Button>
                </div>
            </form>
            </CardContent>
        </Card>
    )
})

export default ResetPasswordForm